@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-basic-headline($name: '.basic-headline') {
    $align-left: '#{$name}--left';
    $align-center: '#{$name}--center';
    $align-right: '#{$name}--right';
    $size-small: '#{$name}--small';
    $size-medium: '#{$name}--medium';
    $size-large: '#{$name}--large';
    $no-margin: '#{$name}--no-margin';
    $inline: '#{$name}--inline';
    $color-light: '#{$name}--inverted &, #{$name}--light &';

    #{$name} {
        display: block;
        margin-bottom: $spacing-md;

        &#{$align-left} {
            text-align: left;
        }

        &#{$align-center} {
            text-align: center;
        }

        &#{$align-right} {
            text-align: right;
        }

        &#{$inline} {
            display: inline;
        }

        &#{$no-margin} {
            margin: 0;
        }

        &__title {
            margin: 0;

            #{$size-large} & {
                @include font-head-xlarge((
                    'breakpoint': xxlarge,
                ));
            }

            #{$size-medium} & {
                @include font-head-large((
                    'breakpoint': xxlarge,
                ));
            }

            #{$size-small} & {
                @include font-head-medium((
                    'breakpoint': xxlarge,
                ));
            }

            #{$color-light} {
                color: $color-white;
            }

            #{$inline} & {
                display: inline;
            }
        }

        &__subtitle {
            margin: 0;

            #{$size-large} & {
                @include font-head-xlarge((
                    'breakpoint': xxlarge,
                    'color': $color-gray-50,
                ));
            }

            #{$size-medium} & {
                @include font-head-large((
                    'breakpoint': xxlarge,
                    'color': $color-gray-50,
                ));
            }

            #{$size-small} & {
                @include font-head-medium((
                    'breakpoint': xxlarge,
                    'color': $color-gray-50,
                ));
            }

            #{$inline} & {
                display: inline;
            }
        }
    }

    @content;
}
