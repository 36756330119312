@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-basic-video($name: '.basic-video') {

    #{$name} {
        &__wrapper {
            position: relative;
            height: 0;
            padding-top: 0;
            padding-bottom: 56.25%;
            overflow: hidden;
        }

        &__video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &__sound-button {
            // stylelint-disable-next-line declaration-no-important
            position: absolute !important;
            bottom: 0;
            right: 0;
            margin-left: 0;
            z-index: 1;

            @include breakpoint(large) {
                // stylelint-disable-next-line declaration-no-important
                padding: 12px 11px !important;
            }
        }

        &__button-icon {
            &--hide {
                // stylelint-disable-next-line declaration-no-important
                display: none !important;
            }
        }
    }
}
