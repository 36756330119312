.buybox {
    &__productname {
        @include font-din-regular;
        margin: 0;
        color: $color-gray-20;
        font-size: 20px;
        line-height: #{ (24 * 0.05) };
        text-transform: none;

        @include breakpoint(medium up) {
            font-size: 24px;
            line-height: #{ (28 * 0.05) };
        }
    }

    // TODO: remove
    .buybox__size,
    .buybox__shape {
        width: 100%;
        padding: 12px 15px;
        border-radius: 0;
    }

    &__select-size-dropdown {
        text-transform: none;
    }

    .is-open .buybox__select-size-dropdown {
        background-color: $color-gray-10;
        border-color: $color-gray-10;
    }

    &__select-size-dropdown-content {
        border: 1px solid $color-gray-20;
    }

    &__ratings {
        display: block;
        margin-top: 8px;
    }

    &__ordernumber {
        @include font-din-regular;
        color: $color-gray-10;
        font-size: 12px;
        line-height: 1;
        display: block;
        margin-top: $sp-160;
    }

    /* details container */
    &__details {
        position: relative;
    }

    &__main {
        position: relative;
        height: 100%;
    }

    &__logo {
        display: none;

        @include breakpoint(medium) {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: $sp-240;
            height: 24px;

            > *,
            &__image {
                width: auto;
                height: 24px;
            }
        }
    }

    /* product description */
    &__description {
        margin: 25px 0 20px 0;
    }

    /* variants dropdown */
    &__variants {
        // TODO: remove
        &-list {
            list-style: none;
            margin: 0;
            padding: 0;

            &-item {
                &:not(:last-of-type) {
                    border-bottom: 1px solid $body-color;
                }

                a {
                    display: block;
                    text-decoration: none;
                    background: $color-white;
                    outline: none;
                    padding: 12px 15px;
                    @include font-din-regular;

                    &:hover,
                    &.is--active {
                        background: $color-gray-90;
                    }

                    p {
                        margin: 0;
                    }
                }

                &.sale-item {
                    position: relative;

                    .product-flags {
                        top: 10px;

                        &--item {
                            padding: $sp-020 10px;
                            font-size: $font-size-300;
                            width: 53px;
                            max-width: none;
                            min-width: 0;
                        }
                    }
                }
            }

            .sale-item & {
                &-title {
                    padding-left: 36px;
                }
            }

            &-property {
                padding-top: 10px;
                font-size: $font-size-200;
                @include font-din-bold;
            }

            &-content {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                text-transform: none;

                &-prices {
                    text-align: right;

                    &-price_sale {
                        color: $color-error;
                    }

                    &-strikeprice {
                        display: block;
                        @include font-din-regular;
                        font-size: $font-size-200;
                        color: $color-gray-20;
                    }
                }
            }
        }
    }

    /* properties list */
    &__properties {
        margin-top: $sp-160;

        &-list {
            list-style: none;
            margin: 0;
            padding: 0;

            &-item {
                @include font-din-regular;
                padding: 0 0 5px;
            }

            &-title {
                margin: 0;
            }

            &-content {
                @include clearfix();
            }

            .icon--info {
                margin: 0 0 0 5px;
            }

            .radiobutton--color {
                margin: 2px 8px 3px 0;
                float: left;
            }
        }

        .buybox--bike & {
            &-list {
                margin-top: 14px;
                &-item {
                    display: flex;
                    flex-wrap: wrap;
                    &:last-child {
                        border-bottom: 0;
                        padding: 0;
                    }

                    @include breakpoint(small only) {
                        padding: 5px 0;
                        border-bottom: none;
                        &:first-child {
                            padding-top: 0;
                        }
                    }
                }
                &-title {
                    font-size: $font-size-200;
                    min-width: 105px;

                    @include breakpoint(small only) {
                        font-size: $font-size-400;
                        text-transform: none;
                        strong {
                            display: block;
                            font-size: $font-size-200;
                        }
                    }
                }
                &-content {
                    font-size: 12px;
                    line-height: 17px;
                    width: calc(100% - 105px);
                }
                &-picker {
                    margin-top: 5px;
                }
            }
        }
    }

    // prize info
    &__prize {
        margin-top: $sp-160;

        &__wrapper {
            margin: 0;
        }

        &__details {
            @include font-din-bold;
            font-size: 16px;
            line-height: 1;
            letter-spacing: .5px;
            margin: $sp-240 0 0 0;
            color: $color-gray-10;

            @include breakpoint(medium up) {
                font-size: 20px;
            }

            &.buybox__prize__lowprize {
                color: $color-sale;
            }
        }

        &__strikeprize {
            @include font-din-regular;
            display: inline-block;
            color: $color-gray-20;
            margin-left: $sp-040;
        }

        &__baseprice {
            @include font-din-medium;
            color: $color-gray-10;
            font-size: 12px;
            line-height: 16px;
            margin-top: $sp-040;
        }

        &__delivery {
            @include font-text-xsmall;
            margin-top: $sp-040;
            margin-bottom: 0;
        }

        &__leasing-info {
            @include font-text-xsmall;
            color: $color-gray-10;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    /* biketown availability */
    &__biketown {
        margin-top: $sp-080;
        display: flex;
        flex-wrap: wrap;

        > * {
            flex: 0 0 50%;
            margin-bottom: $sp-080;
            padding-right: $sp-080;
        }

        > a {
            color: $color-gray-50;
        }
    }

    &__addtocart {
        .buybox--bike & {
            width: 100%;
        }
    }

    &__buttons {
        margin-bottom: $sp-160;
    }

    &__button {
        width: 100%;

        &--bookmark {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-gray-10;
            padding: 11px $sp-320;

            @include breakpoint(medium up) {
                font-size: 14px;
            }

            > .icon {
                margin-right: $sp-080;
                width: 16px;
                height: 16px;
            }
        }
    }

    &__subtitle {
        @include font-din-bold;
        color: $color-gray-10;
        font-size: 16px;
        line-height: 20px;
        margin: $sp-080 0;
        text-transform: none;

        > span {
            @include font-din-regular;
        }
    }

    .buybox--bike & {
        &__prize {
            text-align: left;

            &__details {
                font-weight: 800;
            }
        }
    }

    &__variantfinder {
        margin: 10px 0;

        strong {
            @include font-din-bold;
            font-size: 14px;
            text-transform: uppercase;
        }
    }

    .product-flags {
        margin-bottom: $sp-040;
    }
}
