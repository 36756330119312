@import '~ShopUiBundle/styles/shared';

@mixin pickup-store-list-header($name: '.pickup-store-list-header') {
    #{$name} {
        display: block;

        padding-bottom: $spacing-md;

        &__title {
            @include font-size-medium;

            margin: 0;
            color: $color-gray-10;
        }

        &__description {
            @include font-size-small;

            margin: $sp-040 0 0 0;
            color: $color-gray-50;
        }
    }
}
