@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-tag($name: '.tag') {
    $height: 18px;

    #{$name} {
        display: inline-block;
        max-width: 100%;
        height: auto;
        margin: 0;
        padding: 0 $sp-080 $sp-020;
        overflow: hidden;
        background: $color-gray-90;
        border: none;
        border-radius: calc(#{$height} / 2);
        line-height: $height;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-transform: none;

        &__text {
            @include font-text-xsmall;

            line-height: 1;
        }
    }

    @content;
}
