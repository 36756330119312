@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-bundle-category-short-cut-links(
    $name: '.category-short-cut-links'
) {
    $style-dark: '#{$name}--dark';
    $account-links: '#{$name}--account-links';
    $two-columns-desktop: '#{$name}--two-columns-desktop';
    $gap-width: 1px;

    #{$name} {
        display: block;
        width: 100%;
        overflow-x: hidden;

        @include breakpoint(medium) {
            overflow-x: unset;
        }

        &__list {
            display: flex;
            list-style: none;
            flex-wrap: wrap;
            width: calc(100% + #{3 * $gap-width});
            margin: 1px 0 0;
            padding: 1px 0 0;
            overflow: hidden;

            @media (max-width: 320px) {
                width: 100%;
            }

            @include breakpoint(medium) {
                width: calc(100% + #{4 * $gap-width});
            }

            @include breakpoint(large) {
                width: calc(100% + #{5 * $gap-width});
            }

            // stylelint-disable-next-line order/order
            @media (min-width: (map-get($breakpoints, 'xlarge') + 1px)) {
                width: calc(100% + #{5 * $gap-width});
                padding-left: 1px;
            }

            &#{$account-links} {
                @include breakpoint(medium) {
                    // stylelint-disable-next-line declaration-no-important
                    padding-left: 1px !important;
                }
            }
        }

        &__item {
            height: 48px + 2 * $gap-width;
            margin: -#{$gap-width} 0 0 -#{$gap-width};
            background-color: $color-white;
            border: 1px solid $color-gray-80;
            font-size: 12px;
            color: $color-gray-10;

            @media (max-width: 320px) {
                width: 100%;
                margin: -#{$gap-width} 0 0 0;
                border-left: 0;
                border-right: 0;
            }

            @include breakpoint(medium) {
                height: 64px + 2 * $gap-width;
            }

            &:hover {
                background-color: $color-gray-10;
                border-color: $color-gray-10;
                color: $color-white;
            }

            &:active,
            &:focus {
                background-color: $color-gray-20;
            }

            #{$style-dark} & {
                background-color: #1f1f1f;
                border-color: $color-gray-20;
                color: $color-white;

                &:hover {
                    background-color: $color-accent;
                    border-color: $color-accent;
                    color: $color-gray-10;
                }
            }

            #{$account-links} & {
                height: 52px + 2 * $gap-width;

                @media (min-width: (map-get($breakpoints, 'medium'))) and (max-width: (map-get($breakpoints, 'large'))) {
                    @include make-round-edges(2);
                }

                @include breakpoint(medium) {
                    height: 64px + 2 * $gap-width;
                }

                &:hover,
                &:focus {
                    background-color: $color-gray-90;
                    border-color: $color-gray-80;
                    color: $color-gray-10;
                }

                &:active {
                    background-color: $color-white;
                }
            }

            #{$account-links}:not(#{$two-columns-desktop}) & {
                @include breakpoint(large) {
                    @include make-round-edges;
                }
            }

            #{$account-links}#{$two-columns-desktop} & {
                @include breakpoint(large) {
                    @include make-round-edges(2);
                }
            }
        }

        &__item-name {
            @include font-text-medium;

            margin-bottom: 1px;
            margin-right: 8px;
            color: inherit;
            line-height: math.div(15, 12) * 1em;
            text-overflow: ellipsis;
            word-break: normal;

            @include breakpoint(medium) {
                margin-right: 4px;
            }

            #{$account-links} & {
                flex-grow: 1;
                padding-left: $sp-080;
                font-size: 14px;

                @include breakpoint(large) {
                    padding-left: $sp-160;
                }
            }
        }

        &__item-link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            padding: 0 16px;
            color: inherit;
            text-decoration: none;
            word-break: break-word;

            #{$account-links} & {
                justify-content: flex-start;
                padding-right: $sp-200;

                @include breakpoint(large) {
                    padding-left: $sp-320;
                }
            }
        }

        &__item-icon {
            color: inherit;

            #{$account-links} & {
                display: none;

                @include breakpoint(medium) {
                    display: block;
                }
            }
        }
    }
}

@mixin make-round-edges($columns: 3) {
    &:first-child {
        border-top-left-radius: 4px;
    }

    &:nth-child(#{$columns}) {
        border-top-right-radius: 4px;
    }

    &:nth-child(#{$columns}n + 1) {
        &:nth-last-child(-n + #{$columns}) {
            border-bottom-left-radius: 4px;
        }
    }

    &:nth-child(#{$columns}n) {
        &:last-child {
            border-bottom-right-radius: 4px;
        }
    }
}

@include shop-ui-bundle-category-short-cut-links;
