@import '~ShopUiBundle/styles/shared';

@mixin checkout-bundle-info-text-with-icon($name: '.info-text-with-icon') {
    $gray: '#{$name}--gray';
    $in-panel: '#{$name}--in-panel';

    #{$name} {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $sp-200;
        padding: 0;

        &#{$gray} {
            @include font-size-small;

            flex-direction: row-reverse;
            margin-bottom: $sp-160;
            padding: $spacing-sm;
            background-color: $color-gray-90;
            color: $color-gray-10;
        }

        &#{$in-panel} {
            margin-top: $sp-160;
            margin-bottom: 0;
        }

        &__text {
            flex: 0 0 70%;
            font-size: 12px;
            color: $color-gray-10;
            line-height: 16px;

            #{$gray} & {
                @include font-size-small;

                flex: 1 1;
                background-color: $color-gray-90;
                color: $color-gray-10;
                line-height: 20px;
            }
        }

        &__icon {
            margin-left: $sp-320;

            #{$gray} & {
                margin-left: 0;
                margin-right: $spacing-sm;

                @include breakpoint(mediumlarge) {
                    margin-left: $spacing-xs;
                    margin-right: 15px;
                }
            }
        }
    }
}
