@import '~ShopUiBundle/components/molecules/main-navigation-category/main-navigation-category';

@mixin shop-ui-main-navigation-item-index($name: '.main-navigation-item-index') {
    @include shop-ui-main-navigation-category($name);

    #{$name} {
        display: flex;

        &__index {
            display: flex;
            flex-wrap: wrap;
        }

        &__images {
            display: flex;
            flex: 0 0 auto;
            flex-direction: column;
            flex-wrap: wrap;
            order: 1;
            margin-left: auto;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }

        &__list--index-sublist {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0;
        }

        &__list--index {
            height: 100%;
            padding-right: 10px;
        }

        &__item {
            display: flex;
            flex-direction: column;
            margin-top: 5px;
        }

        &__item--index {
            margin-top: 0;
        }

        &__item--image {
            width: 50%;
            max-width: calc(50% - 8px);
            margin: 16px 16px 0 0;

            &:nth-child(1),
            &:nth-child(2) {
                margin-top: 12px;
            }

            &:nth-child(2n) {
                margin-right: 0;
            }
        }

        &__item-img {
            width: 100%;
            height: 85px;
            transition: filter 0.3s ease-in-out;
            object-fit: cover;
            filter: grayscale(100%);

            &:hover {
                filter: grayscale(0);
            }
        }

        &__link {
            &--more {
                text-decoration: underline;
            }

            &--title {
                @include font-head-small;

                color: $color-gray-20;
            }
        }
    }
}
