@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-catalog-faq($name: '.catalog-faq') {
    $theme-grey: '#{$name}--theme-grey';
    $open: '#{$name}__item--open';
    $more-open: '#{$name}__item--more-open';

    #{$name} {
        display: block;
        overflow-x: hidden;

        &__wrapper {
            padding-bottom: 32px;

            @include breakpoint(medium up) {
                padding-top: 35px;
                padding-bottom: 70px;

                #{$theme-grey} & {
                    background-color: $color-gray-90;
                }
            }
        }

        &__container {
            display: block;
        }

        &__list {
            display: block;
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &__item {
            display: block;
            margin: 0;
            padding: 0 10px;
            border-top: 1px solid $color-gray-80;

            @include breakpoint(medium up) {
                border-top: 0;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray-80;

                @include breakpoint(medium up) {
                    border-bottom: 0;
                }
            }

            .row-padding-mobile-200 & {
                @include breakpoint($row-padding-mobile-200-breakpoint) {
                    padding-left: $row-padding-mobile-200;
                    padding-right: $row-padding-mobile-200;
                }
            }
        }

        &__title {
            @include font-din-regular;

            margin: 0;
            padding: 32px 30px 0 10px;

            .row-padding-mobile-200 & {
                @include breakpoint($row-padding-mobile-200-breakpoint) {
                    padding-left: $row-padding-mobile-200;
                }
            }
        }

        &__button-icon {
            align-self: center;
            width: 3%;
            margin-left: 20px;
            color: $color-gray-10;
            justify-self: flex-end;

            @include breakpoint(medium up) {
                display: none;
            }

            #{$open} & {
                transform: rotate(180deg);
            }
        }

        &__button,
        &__desktop-button {
            @include font-head-small;

            display: flex;
            padding: 14px 0;
            text-decoration: none;

            @include breakpoint(medium up) {
                margin-top: 36px;
            }
        }

        &__button {
            display: flex;

            @include breakpoint(medium up) {
                display: none;
            }
        }

        &__desktop-button {
            display: none;

            @include breakpoint(medium up) {
                display: block;
            }
        }

        &__button-inner {
            width: 97%;
            color: $color-gray-10;
        }

        &__content {
            @include font-text-large;

            display: none;
            margin: 0;
            padding: 0 36px 22px 0;

            @include breakpoint(medium up) {
                display: block;
                padding: 0;
            }

            #{$open} & {
                display: block;
            }
        }

        &__more-button {
            @include font-text-large;

            display: none;
            margin-top: 14px;
            padding: 10px 0;

            @include breakpoint(medium up) {
                display: block;
                text-decoration: underline;
            }

            &-more {
                display: block;

                #{$more-open} & {
                    display: none;
                }
            }

            &-less {
                display: none;

                #{$more-open} & {
                    display: block;
                }
            }
        }

        &__content-more {
            @include breakpoint(medium up) {
                display: none;

                #{$more-open} & {
                    display: block;
                }
            }
        }
    }
}
