@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-selection-field-radio($name: '.selection-field-radio') {
    $breakpoint: medium;
    $disabled: '#{$name}--disabled';
    $icon-unchecked: '#{$name}--icon-unchecked';
    $show-details: '#{$name}--show-details';
    $hover: '#{$name}:not(#{$disabled}):hover';
    $focus-within: '#{$name}:focus-within';
    $input-checked: '#{$name}__input:checked';
    $content-checked: '#{$input-checked} + #{$name}__content';

    #{$name} {
        display: block;
        padding: 10px 0;

        @include breakpoint($breakpoint) {
            padding: 15px 0;
        }

        &__content {
            display: flex;

            position: relative;
            width: 100%;
            background-color: $color-white;
            border: 2px solid $color-gray-80;
            border-radius: $global-button-border-radius;

            #{$hover} &,
            #{$input-checked} + &,
            #{$focus-within} & {
                border-color: $color-gray-10;
            }

            #{$focus-within} & {
                box-shadow: 0 0 0 4px $color-gray-80;
            }

            #{$disabled} & {
                cursor: default;
            }

            #{$show-details} & {
                display: block;
            }
        }

        &__label {
            display: flex;
            flex-grow: 1;
            align-items: center;
            padding: $sp-200 15px;
            cursor: pointer;

            @include breakpoint($breakpoint) {
                padding: $sp-200;
            }
        }

        &__label-content {
            @include breakpoint($breakpoint) {
                display: flex;
                flex-grow: 1;
                align-items: center;
                justify-content: space-between;
            }
        }

        &__text-wrapper {
            @include font-size-small;

            flex-grow: 1;
            color: $color-gray-50;
            line-height: 24px;

            #{$disabled} & {
                color: $color-gray-70;
            }
        }

        &__title {
            color: $color-gray-20;

            #{$hover} &,
            #{$content-checked} &,
            #{$focus-within} & {
                color: $color-gray-10;
            }

            #{$disabled} & {
                color: $color-gray-70;
            }
        }

        &__close-button {
            display: none;
            position: absolute;
            top: 10px;
            right: 10px;

            #{$show-details} & {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                cursor: pointer;
            }
        }

        &__close-icon {
            #{$show-details} & {
                width: 20px;
                height: 20px;
                padding: 5px;
                background-color: $color-gray-90;
                border-radius: 50%;
            }
        }

        &__text {
            #{$show-details} & {
                display: none;
            }
        }

        &__icon {
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            margin-right: 15px;
        }

        &__icon--checked {
            padding: $sp-020;
            border: 2px solid $color-gray-80;
            border-radius: 50%;
            color: transparent;

            @include breakpoint($breakpoint) {
                margin-right: $sp-200;
            }

            #{$hover} &,
            #{$focus-within} & {
                background-color: $color-gray-90;
                border-color: $color-gray-10;
            }

            #{$content-checked} & {
                display: block;
                background-color: $color-positive;
                border-color: $color-positive;
                color: $color-white;
            }

            #{$icon-unchecked} & {
                display: none;
            }
        }

        &__icon--unchecked {
            #{$content-checked} & {
                display: none;
            }
        }

        &__button-wrapper {
            display: none;
            padding: 0 15px 15px;

            @include breakpoint($breakpoint) {
                padding: 0 $spacing-md $spacing-md $spacing-md;
            }

            #{$content-checked} & {
                display: block;
            }

            #{$show-details} #{$content-checked} & {
                display: none;
            }
        }

        &__button {
            padding-top: $spacing-md + 3px;
            cursor: pointer;
        }

        &__price {
            @include font-size-small;

            line-height: 24px;
        }

        &__details {
            display: none;
            padding: 0 $spacing-md $spacing-md $spacing-md;

            #{$show-details} & {
                display: block;
            }
        }
    }
}
