@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-basic-modal($name: '.basic-modal') {
    $style-center: '#{$name}--center';
    $style-desktop-center: '#{$name}--desktop-center';
    $style-mobile-from-bottom: '#{$name}--mobile-from-bottom';
    $style-mobile-from-left: '#{$name}--mobile-from-left';
    $style-backdrop-opacity-75: '#{$name}--backdrop-opacity-75';
    $style-medium-down-fill-screen: '#{$name}--medium-down-fill-screen';
    $style-padding-content-m-240-d-400: '#{$name}--padding-content-m-240-d-400';
    $style-padding-content-240: '#{$name}--padding-content-240';
    $style-remove-header: '#{$name}--remove-header';
    $style-mobile-height-90: '#{$name}--mobile-height-90';
    $style-mobile-height-100: '#{$name}--mobile-height-100';
    $style-content-height-100: '#{$name}--content-height-100';
    $is-closed: '#{$name}__closed';
    $padding: 10px;
    $max-height: 90%;
    $max-width-from-left: 480px;
    $max-width-from-left-medium: 600px;

    #{$name} {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;

        &__backdrop {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
            transition: background-color 0.4s linear;

            #{$name}--show & {
                background-color: rgba(0, 0, 0, 0.4);
            }

            #{$style-backdrop-opacity-75} & {
                background-color: rgba(0, 0, 0, 0.75);
            }
        }

        &__body {
            display: flex;
            position: absolute;
            flex: 0 0 auto;
            flex-direction: column;
            align-items: center;
            top: 0;
            right: 0;
            width: calc(100% - 50px);
            max-width: 500px;
            height: 100%;
            overflow: hidden;
            z-index: 1000;
            background-color: $color-white;
            transform: translateX(100%);
            transition: transform 0.4s linear;

            #{$name}--show:not(#{$style-center}):not(#{$style-desktop-center}) & {
                transform: translateX(0);
            }

            #{$style-center} & {
                top: 50%;
                left: 50%;
                height: auto;
                max-height: $max-height;
                opacity: 0;
                transform: translate(-50%, -50%);
                transition: opacity 0.4s linear;
            }

            #{$style-desktop-center} & {
                @include breakpoint(large) {
                    top: 50%;
                    left: 50%;
                    max-width: 680px;
                    height: auto;
                    max-height: 80%;
                    transform: translate(-50%, -50%);
                    transition: opacity 0.4s ease;
                }
            }

            #{$style-center}#{$name}--show & {
                opacity: 1;
            }

            #{$style-mobile-from-bottom} & {
                opacity: 0;
                transition: opacity 0.5s ease, transform 0.4s ease;

                @include breakpoint(mediumlarge down) {
                    top: auto;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    max-width: none;
                    height: auto;
                    overflow: ‚visible;
                    transform: translateY(100%);
                }
            }

            #{$style-mobile-height-90} & {
                @include breakpoint(mediumlarge down) {
                    max-height: $max-height;
                }
            }

            #{$style-mobile-height-100} & {
                @include breakpoint(mediumlarge down) {
                    height: 100%;
                }
            }

            #{$style-mobile-from-bottom}#{$name}--show & {
                opacity: 1;
                transition: opacity 0.5s ease, transform 0.4s ease;

                @include breakpoint(mediumlarge down) {
                    transform: translateY(0);
                }
            }

            #{$style-mobile-from-left} & {
                left: 0;
                right: auto;
                max-width: $max-width-from-left;
                opacity: 0;
                transform: translateX(-100%);
                transition: opacity 0.5s ease, transform 0.4s ease;

                @include breakpoint(medium down) {
                    top: 0;
                    bottom: auto;
                    left: 0;
                    width: calc(100% - 30px);
                    height: 100%;
                    max-height: none;
                }
            }

            #{$style-mobile-from-left}#{$name}--show & {
                opacity: 1;
                transition: opacity 0.5s ease, transform 0.4s ease;

                @include breakpoint(medium down) {
                    transform: translateX(0);
                }
            }

            #{$style-mobile-from-left}#{$style-content-height-100} & {
                @include breakpoint(medium) {
                    max-width: $max-width-from-left-medium;
                }
            }

            #{$style-remove-header} & {
                @include breakpoint(medium down) {
                    flex-direction: row-reverse;
                    align-items: flex-start;
                }
            }

            #{$style-medium-down-fill-screen} & {
                @include breakpoint(medium down) {
                    width: 100%;
                    max-width: none;
                    height: 100%;
                }
            }
        }

        &__close-button {
            flex-shrink: 0;
            align-self: flex-start;
            box-sizing: content-box;
            outline: none;
            color: $color-gray-10;
            cursor: pointer;

            #{$style-center} & {
                order: 1;
            }

            #{$style-mobile-from-bottom} & {
                align-self: center;
                width: 32px;
                height: 32px;
                margin-left: $sp-080;
                background-color: rgba($color-gray-90, 0);
                transition: background-color ease 0.2s;

                @include breakpoint(mediumlarge) {
                    width: 40px;
                    height: 40px;
                }

                &:hover {
                    background-color: rgba($color-gray-90, 1);
                    transition: background-color ease 0.2s;
                }
            }

            #{$style-remove-header} & {
                margin: 0;
                padding: 0;

                @include breakpoint(mediumlarge) {
                    padding: 0;
                }
            }
        }

        &#{$is-closed} {
            display: none;
        }

        &__content {
            width: 100%;
            height: 100%;
            // stylelint-disable-next-line declaration-no-important
            overflow: hidden !important;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                min-height: 400px;
            }

            #{$style-center} & {
                max-height: calc(90vh - 56px);
            }

            #{$style-mobile-from-left} & {
                min-height: 80vh;
            }

            #{$style-content-height-100} & {
                height: 100vh;
            }
        }

        &__content-wrapper {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            width: 100%;
            overflow: hidden;

            #{$style-padding-content-240} & {
                padding: 0 $sp-240 $sp-240 $sp-240;
            }

            #{$style-mobile-from-bottom} & {
                padding: $sp-200 0 $sp-240 0;

                @include breakpoint(medium) {
                    padding: $sp-200 0 $sp-400 0;
                }
            }

            #{$style-padding-content-m-240-d-400} & {
                padding: $sp-240 0 $sp-240 $sp-240;

                @include breakpoint(medium) {
                    padding: $sp-400 0 $sp-400 $sp-400;
                }
            }

            #{$style-remove-header} & {
                @include breakpoint(medium down) {
                    padding-top: $sp-200;
                }
            }
        }

        &__header {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: $sp-240;

            #{$style-mobile-from-bottom} & {
                flex-direction: row-reverse;
                padding: $sp-080 $sp-080 $sp-080 $sp-240;
                border-bottom: 1px solid $color-gray-80;
                cursor: move;

                @include breakpoint(medium) {
                    padding: $sp-160 $sp-160 $sp-160 $sp-400;
                }

                @include breakpoint(large) {
                    padding: $sp-240 $sp-240 $sp-240 $sp-400;
                    cursor: default;
                }
            }

            #{$style-remove-header} & {
                padding: $sp-200 $sp-240 0 0;
                border-bottom: none;

                @include breakpoint(medium down) {
                    width: auto;
                    padding: 12px $sp-160 0 $sp-160;
                }
            }

            #{$style-padding-content-m-240-d-400} & {
                @include breakpoint(medium) {
                    padding: $sp-240 $sp-240 $sp-240 $sp-400;
                }
            }
        }

        &__drag-anchor {
            position: absolute;
            top: -20px;
            right: 0;
            width: 100%;
            height: 40px;

            @include breakpoint(large) {
                display: none;
            }

            #{$style-mobile-height-100} & {
                display: none;
            }
        }

        &__drag-anchor-line {
            position: absolute;
            bottom: 27px;
            left: 50%;
            width: 40px;
            height: 2px;
            background-color: $color-gray-90;
            transform: translateX(-50%);

            @include breakpoint(large) {
                display: none;
            }
        }

        &__header-title {
            @include font-head-small;

            margin: 0 auto 0 0;
            padding: 0;
            line-height: 1;
        }

        &__footer {
            align-items: center;
            width: 100%;
            padding: $sp-240;
            box-shadow: 0 0 20px rgba($color-black, 0.2);

            @include breakpoint(mediumlarge) {
                box-shadow: none;
            }
        }

        &__footer-wrapper {
            flex: 0 0 auto;
            align-items: center;
            width: 100%;
        }
    }

    @content;
}
