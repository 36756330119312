@import '~ShopUiBundle/styles/shared';

@mixin product-bundle-product-main-features($name: '.product-main-features') {
    $light: '#{$name}--light';
    $center-prefix: '#{$name}--center';
    $padding-prefix: '#{$name}--padding';

    #{$name} {
        display: block;
        position: relative;
        color: $color-gray-10;

        &#{$light} {
            color: $color-white;
        }

        &#{$padding-prefix} {
            padding-left: $sp-240;
            padding-right: $sp-240;
        }

        &__list {
            list-style: none;
            margin-top: 0;
            margin-bottom: -#{$sp-320};
            padding: 0;

            &-item {
                display: flex;
                margin-bottom: $sp-320;
            }
        }

        &__title {
            @include font-text-large;

            padding-right: $sp-160;
            color: $color-gray-50;
        }

        &__additional-info,
        &__value {
            @include font-head-large;

            #{$light} & {
                color: $color-white;
            }
        }

        &__value {
            margin: 0;
            padding-right: $sp-160;
        }

        &__additional-info {
            @include font-din-regular;
        }

        @each $breakpoint, $width in $breakpoints {
            @include breakpoint($breakpoint up) {
                &#{$padding-prefix}--#{$breakpoint} {
                    padding-left: $sp-240;
                    padding-right: $sp-240;
                }

                #{$center-prefix}-#{$breakpoint} & {
                    &__list {
                        justify-content: center;

                        &-item {
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}
