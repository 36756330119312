@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-zoom-images($name: '.zoom-images') {

    #{$name} {
        display: block;
        text-align: center;

        &__chooser {
            margin-top: 20px;
        }

        &__image {
            display: none;
            margin: auto;
        }

        &__image--active {
            display: block;
        }
    }
}

@include shop-ui-zoom-images;
