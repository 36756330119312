.cmsteaser {
    $button-spacing: 8px;

    &__box {
        display: block;
        background-color: $color-gray-20;
        position: relative;
        margin-bottom: 1px;
        text-decoration: none;

        @include breakpoint(small down) {
            &:nth-child(2n)::before {
                content: "";
                display: inline-block;
                border-left: 1px solid $color-gray-80;
                position: absolute;
                top: 0;
                bottom: 0;
                z-index: 2;
            }
        }

        @include breakpoint(mediumsmall up) {
            margin-bottom: 20px;
        }

        img,
        picture {
            width: 100%;
            height: auto;
            opacity: 0.9;
        }

        &--wide {
            width: 100%;
            margin-bottom: 10px;
        }

        &:hover .cmsteaser__button .button {
            background-color: $color-gray-10;
            color: $color-white;
        }
    }

    &__button {
        width: calc(100% - #{$button-spacing} * 2);
        position: absolute;
        bottom: $button-spacing;
        left: $button-spacing;

        .button {
            @include font-din-medium();
            background-color: $color-white;
            color: $color-gray-10;
            text-transform: none;
            font-size: 16px;
            padding: 16px 8px;
            border-radius: $global-button-border-radius;
        }
    }
}

.cmsteaser--head {
    position: relative;
    display: block;
    margin-bottom: 20px;
    margin-top: 32px;

    @include breakpoint(large up) {
        margin-bottom: 32px;
    }

    img {
        width: 100%;
        height: auto;
    }

    a {
        text-decoration: none;
    }

    &__textblock {
        position: absolute;
        left: 25px;
        bottom: 30px;
        color: $color-white;

        a {
            color: $color-white;
            text-decoration: none;
        }

        h2 {
            margin: 0;
        }

        h3 {
            margin: 0;
        }

        &.lvl-1 {
            position: relative;
            left: auto;
            bottom: auto;
        }
    }
}

.cmsteaser__box--wide {
    @include breakpoint(mediumsmall down) {
        margin-bottom: 20px;
    }

    .cmsteaser__textblock {
        position: absolute;
        left: 20px;
        top: 20px;
        color: $color-gray-10;
        text-transform: uppercase;

        h2 {
            margin-top: 0;
            margin-bottom: 10px;
        }

        p {
            margin-top: 0;
            font-size: 13px;
        }
    }
}
