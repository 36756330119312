@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-header-search-form($name: '.header-search-form') {

    #{$name} {
        display: flex;
        width: 320px;
        height: 60px;
        background-color: $white;

        @include breakpoint(mediumlarge up) {
            position: absolute;
            align-items: center;
            top: 0;
            right: 0;
            z-index: 2;
            transition: width 0.2s ease-out;
        }

        @include breakpoint(mediumlarge down) {
            width: 100%;
        }

        &__form {
            width: 100%;
        }

        &__catalog-search-box {
            width: 100%;
        }

        &__search-box-view-loader {
            top: calc(100% + 9px);
            width: 100%;
            max-width: none;
            border: 0;

            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 50%;
                width: 100vw;
                height: 100%;
                background-color: $color-white;
                transform: translateX(-50%);
            }

            #{$name}--animation-running & {
                display: none;

                @include breakpoint(mediumlarge down) {
                    display: block;
                }
            }
        }

        &--has-search-focus {
            width: 100%;
        }

        &__wrapper--open {
            &::before {
                content: '';
                display: block;
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: -1;
                background-color: rgba(0, 0, 0, 0.3);
                pointer-events: none;
            }
        }
    }
}

@include shop-ui-header-search-form;
