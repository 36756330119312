@import '~ShopUiBundle/styles/shared';

@mixin shipment-bundle-packstation-list($name: '.packstation-list') {

    #{$name} {
        &__title {
            @include font-size-medium;

            display: flex;
            align-items: center;
            margin: $spacing-xs 0;
        }

        &__tooltip-icon {
            margin-left: $spacing-sm;
            color: $color-gray-50;
        }

        &__show-more {
            @include font-size-medium;

            padding-top: $spacing-xs;
            padding-bottom: $sp-320;
            color: $color-gray-20;
            text-align: center;
        }

        &__show-more-link {
            padding: $spacing-sm 0;
            cursor: pointer;
        }
    }
}
