$transition-100: 0.1s ease-in-out;
$transition-200: 0.2s ease-in-out;
$transition-300: 0.3s ease-in-out;
$transition-500: 0.5s ease-in-out;
$transition-600: 0.6s ease-in-out;
$global-button-transition-duration: 0.1s;
$global-button-transition-timing-function: ease-in-out;
$global-button-transition: color $global-button-transition-duration $global-button-transition-timing-function,
    background-color $global-button-transition-duration $global-button-transition-timing-function,
    border-color $global-button-transition-duration $global-button-transition-timing-function,
    backdrop-filter $global-button-transition-duration $global-button-transition-timing-function;
