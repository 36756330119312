@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-btn-star-select($name: '.btn-star-select') {
    $selected: '#{$name}--selected';
    $disabled: '#{$name}--disabled';
    $selected-color: $color-accent;

    #{$name} {
        display: flex;
        align-items: center;
        text-decoration: none;
        cursor: pointer;

        &#{$disabled} {
            cursor: default;
        }

        &__title {
            @include font-din-bold;

            padding-left: 8px;
            font-size: 14px;
            color: $color-gray-20;
            line-height: 20px;

            #{$selected} & {
                color: $color-gray-10;
            }

            #{$disabled}:not(#{$selected}) & {
                color: $color-gray-20;
            }
        }

        &__icon {
            stroke: $color-gray-20;
            color: $color-white;

            #{$selected} & {
                stroke: $selected-color;
                color: $selected-color;
            }

            #{$disabled}:not(#{$selected}) & {
                stroke: $color-gray-20;
                color: $color-white;
            }
        }

        &:hover,
        &:focus {
            outline: none;

            &:not(#{$disabled}) {
                text-decoration: underline;

                #{$name}__icon {
                    color: $color-gray-90;
                }
            }

            &#{$selected} {
                text-decoration: underline;

                #{$name}__icon {
                    stroke: $color-gray-20;
                    color: $selected-color;
                }

                #{$name}__title {
                    text-decoration: underline;
                }
            }
        }

        &:active:not(#{$disabled}) {
            #{$name}__icon {
                stroke: $color-gray-20;
                color: $color-gray-20;
            }
        }
    }
}
