@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-language-navigation($name: '.language-navigation') {

    #{$name} {
        @include font-din-regular;

        display: block;
        list-style: none;
        margin: 0;
        padding: $spacing-sm 0;

        &__item {
            display: block;
        }

        &__link {
            display: flex;
            align-items: baseline;
            padding: $spacing-sm $spacing-md;
            font-size: 14px;
            color: inherit;
            line-height: 1;
            text-decoration: none;

            &:hover {
                #{$name}__title {
                    text-decoration: underline;
                }
            }
        }

        &__flag {
            width: 14px;
            height: 10px;
            margin-top: -3px;
            margin-right: $spacing-sm;
        }

        &__title {
            display: inline-block;
            min-width: 50px;
        }

        &__currency {
            flex-grow: 1;
            padding-left: $spacing-lg;
            text-align: right;
        }
    }
}

@include shop-ui-language-navigation;
