@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-account-page-title($name: '.account-page-title') {

    #{$name} {
        display: flex;
        align-items: center;
        padding: $sp-240 0;
        color: $color-gray-10;

        @include breakpoint(medium) {
            flex-direction: column;
            align-items: flex-start;
            padding: $sp-160 0 $sp-400 0;
            background-color: $color-white;
        }

        &__title {
            display: none;

            @include breakpoint(medium) {
                @include font-din-bold;

                display: block;
                margin: 0;
                font-size: 24px;
                letter-spacing: 0.5px;
                line-height: 28px;
            }
        }

        &__text {
            margin: 0;
            font-size: 14px;
            color: $color-gray-20;
            line-height: 20px;

            @include breakpoint(medium) {
                padding-top: $sp-080;
                padding-left: 0;
                font-size: 20px;
                line-height: 28px;
            }
        }

        &__icon {
            flex-shrink: 0;
            width: auto;
            height: 40px;
            padding-right: $sp-160;

            @include breakpoint(medium) {
                margin-bottom: $sp-160;
            }
        }
    }
}
