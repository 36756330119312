@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-catalog-category-title-with-video($name: '.catalog-category-title-with-video') {
    $full-height: #{$name}--full-height;
    $left-align: #{$name}--left-align;
    $breakpoint-tablet: medium up;
    $breakpoint-desktop: mediumlarge up;
    $disable-overlay: #{$name}--disable-overlay;
    $header-height-mobile: 135px;
    $header-height-desktop: 147px;

    #{$name} {
        display: block;
        position: relative;

        &__video {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-color: $color-black;
        }

        &__video-img {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
        }

        &__video-wrapper {
            display: block;
            position: relative;
            height: 408px;
            max-height: 64vh;
            overflow: hidden;
            transition: all 0.2s ease-in-out;

            @include breakpoint($breakpoint-desktop) {
                height: 670px;
                max-height: 73vh;
            }

            #{$full-height} & {
                height: calc(100vh - #{$header-height-mobile});
                max-height: unset;

                @include breakpoint($breakpoint-desktop) {
                    height: calc(100vh - #{$header-height-desktop});
                    max-height: unset;
                }
            }
        }

        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.3;
            background-color: $color-gray-10;

            #{$disable-overlay} & {
                display: none;
            }
        }

        &__content {
            display: flex;
            position: absolute;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding-left: $grid-column-gutter;
            padding-right: $grid-column-gutter;
            text-align: center;

            #{$left-align} & {
                justify-content: end;
                padding: 40px;

                @include breakpoint($breakpoint-tablet) {
                    align-items: flex-start;
                    text-align: left;
                }

                @include breakpoint($breakpoint-desktop) {
                    padding: 60px;
                }
            }
        }

        &__link-wrapper {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &__title {
            #{$left-align} & {
                @include breakpoint($breakpoint-tablet) {
                    text-align: left;
                }
            }
        }

        &__subtitle {
            margin-top: $spacing-sm;

            #{$left-align} & {
                @include breakpoint($breakpoint-tablet) {
                    text-align: left;
                }
            }
        }

        &__image-header {
            max-width: 455px;

            @include breakpoint($breakpoint-tablet) {
                max-width: 500px;
            }
        }

        &__button-group {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            margin-top: 24px;

            #{$left-align} & {
                @include breakpoint($breakpoint-tablet) {
                    justify-content: left;
                }
            }
        }

        &__button,
        &__secondary-button,
        &__video-button {
            min-width: 240px;
            margin: $sp-040 $sp-080;

            @include breakpoint($breakpoint-tablet) {
                min-width: 180px;
            }

            #{$left-align} & {
                @include breakpoint($breakpoint-tablet) {
                    margin-bottom: 0;
                }
            }

            #{$left-align} &:first-child {
                @include breakpoint($breakpoint-tablet) {
                    margin-left: 0;
                }
            }

            #{$left-align} &:last-child {
                @include breakpoint($breakpoint-tablet) {
                    margin-left: 0;
                }
            }
        }

        &__video-link {
            position: static;
            width: auto;
            height: auto;
        }

        &__video-overlay {
            position: static;
        }

        &__breadcrumb {
            position: absolute;
            top: 0;
            z-index: 1;
        }
    }
}
