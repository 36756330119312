.progress {

    display: block;
    width: 100%;
    text-align: right;
    position: relative;
    height: 40px;
    margin: -60px 1px 20px 0;

    .meter {
        width: 145px;
        height: 39px;
        min-width: 145px;
        max-width: 145px;
        display: inline-block;
        font-size: $font-size-200;
        text-align: center;
    }

    .password-verdict {
        color: $text-color;
        line-height: 39px;
    }

    ~ .label--info {
        display: none;
    }

    &.alert {
        .meter {
            border-bottom: 5px solid $color-error;
        }

        ~ .label--info__alert {
            display: block;
        }
    }

    &.warning {
        .meter {
            border-bottom: 5px solid $color-green-600;
        }

        ~ .label--info__warning {
            display: block;
        }
    }

    &.success {
        .meter {
            border-bottom: 5px solid $color-positive;
        }

        ~ .label--info__success {
            display: block;
        }
    }

}
