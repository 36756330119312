@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-menu-mobile-button($name: '.menu-mobile-button') {

    #{$name} {
        display: block;
        padding: 0;
        color: $color-black;

        &__icon {
            width: 30px;
            height: 30px;
        }
    }
}
