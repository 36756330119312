@import '~ShopUiBundle/components/molecules/main-navigation-category/main-navigation-category';

@mixin shop-ui-main-navigation-category-with-media($name: '.main-navigation-category-with-media') {
    @include shop-ui-main-navigation-category($name);

    $content-height: 545px;
    $content-height-xl: 545px;

    #{$name} {
        display: flex;

        &__content {
            height: $content-height;
            max-height: $content-height;
            padding-top: $sp-240;

            @include breakpoint(xlarge up) {
                height: $content-height-xl;
                max-height: $content-height-xl;
            }
        }
    }
}
