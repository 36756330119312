@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-fullscreen-video-teaser($name: '.fullscreen-video-teaser') {

    #{$name} {
        display: block;
        position: relative;
        width: 100%;
        z-index: 12;

        &__video {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: calc(100vh - 116px);
            z-index: -1;
            transition: all 0.2s ease-in-out;

            @include breakpoint(large up) {
                height: calc(100vh - 155px);
            }

            &-player {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &-player::-webkit-media-controls-panel-container {
                // stylelint-disable-next-line declaration-no-important
                display: none !important;
            }
        }

        &__wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100vh - 116px);

            @include breakpoint(large up) {
                height: calc(100vh - 155px);
            }
        }

        &__content {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            padding: 0 15px;
            text-align: center;
            transform: translate(-50%, -50%);

            &-logo {
                max-width: 140px;

                @include breakpoint(mediumsmall up) {
                    max-width: 190px;
                }
            }

            &-title {
                @include font-din-medium;

                margin: 10px 0 20px;

                @include breakpoint(mediumlarge up) {
                    margin: 10px 0 40px;
                }
            }

            &-play,
            &-button {
                margin: 0 $sp-040;
            }
        }
    }
}

@include shop-ui-fullscreen-video-teaser;
