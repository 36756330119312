@import '~ShopUiBundle/styles/shared';

@mixin product-bundle-brand-highlights($name: '.brand-highlights') {
    $col-has-margin: '#{$name}__col--has-margin';
    $margin: 10px;

    #{$name} {
        display: block;
        position: relative;

        @include breakpoint(large up) {
            display: flex;
            margin-left: 0;
            margin-right: 0;
        }

        &__brand {
            display: block;
            position: relative;
            flex: 0 0 auto;
            width: 100%;
            height: 178px;
            margin-bottom: $spacing-md;
            overflow: hidden;

            @include breakpoint(large) {
                width: 100%;
                height: auto;
                margin-bottom: 0;
                border-top-left-radius: $global-border-radius;
                border-bottom-left-radius: $global-border-radius;
            }

            &-title {
                @include font-display-medium((
                    'color': $color-white,
                ));

                display: block;
                position: absolute;
                bottom: #{$margin * 1};
                left: #{$margin * 1};
                max-width: calc(100% - #{$margin * 2});
                margin: 0 0 10px 20px;
                padding: 0;
                overflow: hidden;
                z-index: 10;
                text-overflow: ellipsis;
                text-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5);

                @include breakpoint(large up) {
                    bottom: #{$margin * 2};
                    left: #{$margin * 2};
                    max-width: calc(100% - #{$margin * 4});
                }
            }

            &-logo {
                display: block;
                position: absolute;
                top: 20px;
                left: 20px;
                max-width: calc(50% - #{$margin * 2});
                z-index: 10;

                @include breakpoint(large up) {
                    max-width: calc(50% - #{$margin * 4});
                }
            }

            &-logo-img {
                display: block;
                position: relative;
                max-width: 100%;
                max-height: 36px;
                object-fit: contain;
                object-position: 50% 50%;

                @include breakpoint(large up) {
                    max-height: 40px;
                }
            }

            &-picture {
                display: block;
                position: relative;
                height: 100%;
                z-index: 1;

                &-img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: 50% 50%;
                }
            }
        }

        &__col {
            &#{$col-has-margin} {
                margin-top: $spacing-md;
            }
        }

        &__product {
            width: 100%;
        }
    }
}

@include product-bundle-brand-highlights;
