@import '~ShopUiBundle/styles/shared';

@mixin catalog-search-box($name: '.catalog-search-box') {
    $theme-light: #{$name}--theme-light;

    #{$name} {
        display: inline-block;
        z-index: 0;

        &__text-input {
            #{$theme-light} & {
                background-color: transparent;
            }
        }

        &__submit-button {
            #{$theme-light} & {
                background-color: transparent;
                color: $color-gray-20;

                &:hover {
                    background: $color-accent;
                }
            }
        }

        &__search-box {
            width: 100%;
        }
    }

    @content;
}
