@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin product-bundle-stage-slider($name: '.stage-slider') {
    $light: '#{$name}__slide--light';

    #{$name} {
        display: block;
        max-width: 1920px;
        margin: 0 auto;
        padding-bottom: 2px;
        overflow-x: hidden;

        &__container {
            overflow: visible;
        }

        &__wrapper {
            position: relative;
        }

        &__slide {
            position: relative;
            background: $color-gray-90;

            &#{$light} {
                background: $color-gray-70;
            }
        }

        &__head-wrapper {
            display: flex;
            position: absolute;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: $sp-400;

            @include breakpoint(mediumsmall) {
                max-width: 380px;
            }

            @include breakpoint(mediumlarge) {
                max-width: 500px;
            }

            @include breakpoint(large) {
                justify-content: center;
                max-width: 920px;
                padding-left: 220px;
                padding-right: 220px;
            }
        }

        &__caption {
            @include font-default;
            @include font-size-medium;

            // stylelint-disable-next-line value-no-vendor-prefix
            display: -webkit-box;
            position: relative;
            margin: 0;
            overflow: hidden;
            z-index: 2;
            // stylelint-disable-next-line property-no-vendor-prefix
            -webkit-line-clamp: 1;
            // stylelint-disable-next-line property-no-vendor-prefix
            -webkit-box-orient: vertical;

            @include breakpoint(large) {
                margin-bottom: 5px;
            }

            #{$light} & {
                @include font-default((
                    color: $color-white,
                ));
            }
        }

        &__head {
            @include font-display-large((
                'breakpoint': xxlarge,
            ));

            // stylelint-disable-next-line value-no-vendor-prefix
            display: -webkit-box;
            position: relative;
            margin: 0 0 $sp-200 0;
            overflow: hidden;
            z-index: 2;
            // stylelint-disable-next-line property-no-vendor-prefix
            -webkit-line-clamp: 3;
            // stylelint-disable-next-line property-no-vendor-prefix
            -webkit-box-orient: vertical;

            @include breakpoint(mediumsmall) {
                margin-bottom: $sp-400;
            }

            #{$light} & {
                color: white;
            }
        }

        &__button {
            position: relative;
            z-index: 3;
        }

        &__picture {
            display: block;
            position: relative;
            width: 100%;
            padding-top: percentage(math.div(640, 414));
            z-index: 0;

            @include breakpoint(mediumsmall) {
                padding-top: percentage(math.div(640, 768));
            }

            @include breakpoint(large) {
                padding-top: percentage(math.div(800, 1920));
            }
        }

        &__image {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            aspect-ratio: auto;
        }

        &__navigation-button {
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;
            top: 50%;
            width: 56px;
            height: 40px;
            z-index: 10;
            background: rgba($color-gray-10, 0.58);
            background-image: none;
            border-radius: 20px;
            outline: none;
            color: $color-white;
            transform: translateY(-50%);
            transition: background-color ease 0.2s;
            cursor: pointer;

            @include breakpoint(mediumlarge down) {
                display: none;
            }

            &::after {
                display: none;
            }

            &:hover {
                background: rgba($color-gray-10, 0.87);
                color: $color-white;
            }

            &:active {
                background: rgba($color-gray-10, 1);
            }

            &.swiper-button-prev {
                left: 80px;
            }

            &.swiper-button-next {
                right: 80px;
            }
        }

        &__pagination {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            // stylelint-disable-next-line declaration-no-important
            bottom: 0 !important;
            height: 20px;
            margin: auto;
            visibility: visible;
            text-align: center;

            @include breakpoint(mediumlarge) {
                height: 40px;
            }

            &.swiper-pagination-lock {
                display: none;
            }

            .swiper-pagination-bullet {
                display: block;
                position: relative;
                flex: 0 1 auto;
                width: 35px;
                height: 100%;
                // stylelint-disable-next-line declaration-no-important
                margin: 0 !important;
                visibility: visible;
                opacity: 1;
                background: transparent;
                border-radius: 0;
                outline: none;
                cursor: pointer;

                @media (pointer: coarse) {
                    pointer-events: none;
                }

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    width: 25px;
                    height: 2px;
                    margin: 0;
                    background-color: $color-gray-70;
                    border: 0;
                    border-radius: 2px;
                    transform: translate(-50%, -50%);
                }

                &-active::after {
                    background-color: $color-gray-10;
                    outline: none;
                }
            }
        }

        &__link-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            text-decoration: none;
        }
    }
}
