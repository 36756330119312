@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-shop-usp($name: '.shop-usp') {
    #{$name} {
        display: flex;
        justify-content: center;
        height: 36px;
        overflow: hidden;
        background-color: $color-gray-10;

        @include breakpoint(medium) {
            height: 38px;
        }

        &__list {
            display: inline-flex;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            max-width: $container-max-width;
            margin: 0;
            padding: 0;

            @include breakpoint(medium) {
                flex-direction: row;
                padding: 0 $grid-column-gutter * 0.5;
            }
        }

        &__item {
            @include font-text-small((
                'color': $text-color-light,
                'breakpoint': large,
            ));

            list-style-type: none;
            width: auto;
            padding: 9px 0 11px;
            white-space: nowrap;
            text-align: center;

            /* FIXME: at the moment we need higher specifitiy cause of header ul li selector. */
            // stylelint-disable-next-line declaration-no-important
            text-transform: none !important;

            @include breakpoint(mediumlarge down) {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 11px 0 9px;
            }
        }
    }
}

@include shop-ui-shop-usp;
