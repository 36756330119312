.slider-handle {
    top: 0;
    visibility: visible;
    background-color: $color-gray-20;

    &:focus {
        outline: none;
    }

    &:first-of-type:not(:last-of-type) {
        border-radius: 0 0 50% 50%;
        transform: translateY(0%);
    }

    &:last-of-type:not(:first-of-type) {
        border-radius: 50% 50% 0 0;
        transform: translateY(-100%);
    }

    &::before {
        content: '';
        width: 12px;
        height: 12px;
        display: block;
        border-radius: 50%;
        background-color: $color-gray-20;
        border: 1px solid $color-white;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        transition: all 0.2s ease;
    }

    &:hover {
        background-color: $color-gray-20;

        &::before {
            background-color: $color-green-600;
            border: 1px solid $color-green-600;
        }
    }
}


.slider-fill {
    background-color: $color-green-600;
    height: 4px;
    top: -1px;
}

.slider {
    height: 1px;
    margin-top: 1.75rem;
    background-color: $color-gray-20;
}
