@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-action-panel($name: '.action-panel') {
    $box-shadow: '#{$name}--box-shadow';

    #{$name} {
        display: flex;
        align-items: flex-start;
        padding: $sp-160;
        background-color: $color-white;
        border: 2px solid $color-gray-80;
        border-radius: $global-button-border-radius;
        box-shadow: none;

        @include breakpoint(mediumlarge) {
            padding: $sp-200;
        }

        &#{$box-shadow} {
            border-color: $color-white;
            box-shadow: 0 4px 8px rgba($color-gray-10, 0.2);
        }

        &__content {
            flex-grow: 1;
            text-align: left;
        }

        &__flag {
            @include font-din-regular;

            display: inline-block;
            margin-bottom: $sp-160;
            margin-left: -$sp-040;
            padding: 0 $sp-080;
            background-color: $color-accent-90;
            border-radius: 10px;
            font-size: 12px;
            color: $color-gray-20;
            line-height: 18px;
        }

        &__title {
            font-size: 14px;
            color: $color-gray-10;
            line-height: 24px;
        }

        &__link {
            color: $color-gray-10;
        }

        &__text {
            @include font-din-regular;

            overflow: hidden;
            font-size: 14px;
            color: $color-gray-50;
            line-height: 24px;
            text-overflow: ellipsis;
        }

        &__buttons {
            list-style: none;
            margin: 0;
            padding-left: $sp-200;
        }

        &__buttons-item:not(:last-child) {
            margin-bottom: $sp-080;
        }
    }
}
