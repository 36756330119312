.fixedsticky {
  position: sticky;
  top: 0;
}
/* When position: sticky is supported but native behavior is ignored */
.fixedsticky-withoutfixedfixed .fixedsticky-off,
.fixed-supported .fixedsticky-off {
  position: static;
}
.fixedsticky-withoutfixedfixed .fixedsticky-on,
.fixed-supported .fixedsticky-on {
  position: fixed;
}
.fixedsticky-dummy {
  display: none;
}
.fixedsticky-on + .fixedsticky-dummy {
  display: block;
}