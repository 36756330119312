@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-quantity-input($name: '.quantity-input') {
    $disabled: '#{$name}--disabled';
    $loading: '#{$name}--loading';

    #{$name} {
        display: inline-flex;
        border: 2px solid $color-gray-80;
        border-radius: 4px;

        &:hover,
        &:focus-within {
            border-color: $color-gray-10;
        }

        &#{$disabled} {
            border-color: $color-gray-90;
        }

        &__form {
            display: flex;
        }

        &__input-wrapper {
            position: relative;
        }

        &__input {
            @include font-din-regular;

            // stylelint-disable declaration-no-important
            width: 20px;
            height: 50px !important;
            margin: 0 !important;
            padding: $spacing-sm $spacing-xs;
            border: 0 !important;
            font-size: 14px !important;
            line-height: 20px;
            text-align: center;

            @include breakpoint(mediumlarge) {
                width: 50px;
                padding: $spacing-sm;
            }
            // stylelint-enable declaration-no-important

            &:disabled {
                background-color: transparent;
                color: $color-gray-70;
                cursor: default;
            }
        }

        &__icon-loading {
            display: none;

            #{$loading} & {
                display: block;
                position: absolute;
                top: 18px;
                right: -6px;
            }
        }
    }
}
