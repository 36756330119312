@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-image-or-video($name: '.image-or-video') {
    $desktop-breakpoint: mediumlarge;

    #{$name} {
        display: block;
        overflow-x: hidden;

        &__row {
            display: flex;
            flex-direction: column;

            @include breakpoint(#{$desktop-breakpoint}) {
                flex-direction: row;
            }
        }

        &__video {
            min-width: 100%;

            &--video-player {
                padding-bottom: 56.25%;
            }
        }

        &__link,
        &__picture {
            display: block;
            max-width: 100%;

            &-with-spacing {
                flex: 1;

                &:first-child {
                    margin-bottom: $sp-240;

                    @include breakpoint(#{$desktop-breakpoint}) {
                        margin-bottom: 0;
                        margin-right: $grid-column-gutter;
                    }
                }
            }
        }

        &__img {
            min-width: 100%;
        }

        &__link {
            display: block;
            align-self: flex-start;
            text-decoration: none;
            cursor: pointer;
        }

        &__link-title {
            display: inline-block;
            padding-top: $sp-080;
            font-size: 12px;
            color: $color-gray-10;
            line-height: 16px;

            @include breakpoint(#{$desktop-breakpoint}) {
                padding-top: $sp-160;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}
