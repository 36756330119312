@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-bundle-image-with-brand-icon-list-item($breakpoint, $columns, $gab: 25px) {
    @include breakpoint($breakpoint) {
        &:nth-child(n) {
            max-width: calc(#{math.div(100%, $columns)} - #{math.div(($columns - 1) * $gab, $columns)});
            margin-right: $gab;
        }

        &:nth-child(#{$columns}n) {
            margin-right: 0;
        }
    }
}

@mixin shop-ui-bundle-image-with-brand-icon-list($name: '.image-with-brand-icon-list') {
    $item-height: 270px;
    $image-height: 174px;
    $brand-icon-height: $item-height - $image-height;

    #{$name} {
        @include font-din-medium;

        display: flex;
        list-style: none;
        flex-wrap: wrap;
        padding: 0;

        &__item {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin: 0 0 18px;
            overflow: hidden;
            background-color: #f7f7f7;
            border-radius: 5px;

            // stylelint-disable-next-line order/order
            @include shop-ui-bundle-image-with-brand-icon-list-item(small, 2, 18px);
            @include shop-ui-bundle-image-with-brand-icon-list-item(mediumlarge, 3, 25px);
            @include shop-ui-bundle-image-with-brand-icon-list-item(large, 4, 25px);
        }

        &__image-picture {
            display: none;
            width: 100%;

            @include breakpoint(mediumsmall) {
                display: block;
            }
        }

        &__image {
            width: 100%;
            height: $image-height;
            object-fit: cover;
        }

        &__brand-icon-container {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 52px;
            text-align: center;

            @include breakpoint(mediumsmall) {
                height: $brand-icon-height;
            }
        }

        &__brand-icon {
            max-width: calc(90% - 40px);
            min-height: 25px;
            max-height: calc(90% - 20px);

            @include breakpoint(mediumsmall) {
                max-width: calc(100% - 40px);
                max-height: calc(100% - 20px);
            }
        }

        &__link {
            width: 100%;
        }
    }
}
