@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-product-icon-star($name: '.icon-star') {
    #{$name} {
        display: inline-block;

        &--active {
            color: $color-gray-20;
        }

        &--inactive {
            color: $color-gray-70;
        }
    }
}

@include shop-ui-product-icon-star;
