@import '~ShopUiBundle/components/molecules/main-navigation-category-bikes-level-2/main-navigation-category-bikes-level-2';

@mixin shop-ui-main-navigation-category-bikes-level-3($name: '.main-navigation-category-bikes-level-3') {
    @include shop-ui-main-navigation-category-bikes-level-2($name);

    $list-width: calc(#{$container-max-width} * 0.12);
    $menu-height: 640px;

    #{$name} {
        display: flex;
        position: static;

        &__content {
            padding-left: 0;
        }

        &__list {
            width: $list-width;
            max-width: $list-width;
            min-height: $menu-height;
        }
    }
}
