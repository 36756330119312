@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-action-panel-group-title($name: '.action-panel-group-title') {
    #{$name} {
        @include font-din-bold;

        display: block;
        padding: $sp-160 0;
        font-size: 16px;
        color: $color-gray-10;
        line-height: 20px;

        @include breakpoint(medium) {
            padding: $sp-240 0;
            font-size: 20px;
            line-height: 28px;
        }

        &__badge {
            @include font-din-regular;

            font-size: 14px;
            color: $color-gray-20;

            @include breakpoint(medium) {
                padding-left: $sp-040;
                font-size: 16px;
            }

            &::before {
                content: '(';
            }

            &::after {
                content: ')';
            }
        }

        &__text {
            @include font-din-regular;

            padding-top: $sp-080;
            font-size: 14px;
            color: $color-gray-20;
            line-height: 20px;
        }
    }
}
