@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-account-order-list($name: '.account-order-list') {
    $shadow: '#{$name}--shadow';

    #{$name} {
        display: block;
        border-radius: 1px;

        &#{$shadow} {
            margin-left: -24px;
            margin-right: -24px;
            padding-bottom: $sp-240;
            background-color: $color-white;
            border-top: none;
            box-shadow: 0 4px 8px 0 rgba($color-gray-10, 0.2);

            @include breakpoint(medium) {
                margin-left: 0;
                margin-right: 0;
                padding-bottom: $sp-160;
            }

            @include breakpoint(large) {
                padding-bottom: $sp-240;
            }
        }

        &__header {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-top: $sp-240;
            padding-bottom: $sp-160;

            @include breakpoint(medium) {
                padding: $sp-320 $sp-160 $sp-240 $sp-160;
            }

            @include breakpoint(large) {
                padding: $sp-400 $sp-240 $sp-240 $sp-240;
            }

            #{$shadow} & {
                padding: $sp-160 $sp-240 $spacing-lg $sp-240;

                @include breakpoint(medium) {
                    padding: $sp-160 $sp-160 $sp-240 $sp-160;
                }

                @include breakpoint(large) {
                    padding: $sp-240;
                }
            }
        }

        &__title {
            @include font-din-bold;

            font-size: 16px;
            color: $color-gray-10;
            line-height: 20px;

            @include breakpoint(medium) {
                font-size: 20px;
                line-height: 28px;
            }
        }

        &__subtitle {
            font-size: 12px;
            color: $color-gray-50;
            line-height: 18px;

            @include breakpoint(large) {
                font-size: 14px;
                line-height: 20px;
            }
        }

        &__button {
            order: 2;
            width: 100%;
            margin-top: $spacing-lg;

            @include breakpoint(medium) {
                order: initial;
                width: auto;
                margin-top: 0;
            }
        }

        &__shipping-details {
            display: flex;
            width: 100%;
            padding-top: $sp-080;

            @include breakpoint(medium) {
                padding-top: $sp-160;
            }

            @include breakpoint(large) {
                padding-top: $sp-240;
            }
        }

        &__list-item {
            padding-bottom: $sp-200;

            @include breakpoint(medium) {
                padding-bottom: $sp-240;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}
