table {
  border: none;

  tbody, tfoot, thead {
    background: none;
    border: none;
  }

  tbody tr:nth-child(1n) {
    background: rgba($color-white, .5);
  }

  tbody tr:nth-child(2n) {
    background: none;
  }

  tbody {
    th,
    td {
      border-color: $color-gray-70;
    }
  }

  th {
    text-align: left;
    text-transform: uppercase;
  }

  /* stacked table */
  &.stack {
    @include breakpoint(small only) {
      th {
        padding-bottom: 0;
      }
    }
  }

  /* scrollable table */
  &.scroll {
    @include breakpoint(medium up) {
      display: table;
    }
    @include breakpoint(small only) {
      td {
        border-left: 3px solid $color-gray-80;
      }
    }
  }

}
