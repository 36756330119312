@import '~ShopUiBundle/styles/shared';

@mixin catalog-filter-row($name: '.catalog-filter-row') {
    $max-width-first-item: 240px;
    $fixed-item-breakpoint: medium;

    #{$name} {
        display: block;

        &__list {
            display: flex;
            position: relative;
            list-style: none;
            flex-wrap: wrap;
            justify-content: flex-start;
            width: 100%;
            margin: 0;
            padding-left: 0;
            padding-right: 0;

            @include breakpoint($fixed-item-breakpoint) {
                // stylelint-disable-next-line declaration-no-important
                padding-right: $max-width-first-item + $spacing-sm !important;
            }

            &--hide {
                display: none;

                @include breakpoint(mediumsmall) {
                    display: flex;
                }
            }

            &-item {
                display: flex;
                align-items: center;
                width: 100%;
                margin-bottom: $spacing-sm;

                @include breakpoint(mediumsmall only) {
                    width: calc(50% -#{ $grid-column-gutter * 0.5 });

                    &:nth-child(2n - 1) {
                        margin-right: $grid-column-gutter * 0.5;
                    }

                    &:nth-child(2n) {
                        margin-left: $grid-column-gutter * 0.5;
                    }
                }

                @include breakpoint(medium) {
                    width: auto;
                    max-width: none;
                    margin-left: 0;
                    margin-right: $spacing-sm;
                    padding-left: 0;
                    padding-right: 0;
                }

                &--hide {
                    display: none;
                }

                &--first {
                    @include breakpoint($fixed-item-breakpoint) {
                        display: flex;
                        position: absolute;
                        align-items: center;
                        justify-content: flex-end;
                        right: 0;
                        max-width: $max-width-first-item;
                        margin-right: initial;
                    }
                }

                &--last {
                    justify-content: center;
                    width: 100%;
                    margin-top: $spacing-md;
                    margin-bottom: $spacing-md;

                    @include breakpoint($fixed-item-breakpoint) {
                        width: auto;
                        margin-top: 0;
                        margin-bottom: $spacing-sm;
                    }
                }

                // CSS Hack for now, to avoid oversizing CSS and to Support old Layout
                > * {
                    width: 100%;
                }
            }
        }

        &__item-label {
            display: none;

            @include breakpoint($fixed-item-breakpoint) {
                @include font-text-medium;

                display: block;
                margin-right: $spacing-sm;
                white-space: nowrap;
            }
        }

        &__toggle-buttons {
            margin-bottom: $spacing-md;
        }

        &__toggle-button,
        &__toggle-link {
            justify-content: center;
            white-space: nowrap;

            &--hide {
                // stylelint-disable-next-line declaration-no-important
                display: none !important;
            }
        }
    }
}
