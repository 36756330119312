@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin cms-bundle-banner-with-button($name: '.banner-with-button') {
    $theme-light: '#{$name}--light';
    $theme-narrow: '#{$name}--narrow';
    $theme-centered: '#{$name}--text-centered';

    #{$name} {
        display: block;
        position: relative;
        height: 178px;
        background-color: $color-white;

        @include breakpoint(large up) {
            height: 384px;
        }

        @include breakpoint(xxlarge up) {
            height: 400px;
        }

        &#{$theme-light} {
            background-color: $color-gray-10;
            color: $color-white;
        }

        &#{$theme-narrow} {
            background-color: transparent;
        }

        &__wrapper {
            height: 100%;
        }

        &__background {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            overflow: hidden;

            &-picture {
                display: flex;
                position: relative;
                justify-content: center;
                width: 100%;
                height: 100%;
                overflow: hidden;

                &-img {
                    display: block;
                    position: relative;
                    width: 100%;
                    max-width: none;
                    height: 100%;
                    object-fit: cover;
                    object-position: 50% 50%;
                }
            }
        }

        &__row,
        &__col {
            position: relative;
            height: 100%;
        }

        &__col {
            #{$theme-centered} & {
                margin: 0 auto;
            }
        }

        &__wrapper-flex {
            display: flex;
            align-items: center;

            #{$theme-centered} & {
                justify-content: center;
            }
        }

        &__content {
            position: relative;

            #{$theme-narrow} & {
                bottom: $sp-240;
                margin-top: auto;
                padding: 0 0 0 $sp-240;

                @include breakpoint(xlarge up) {
                    bottom: 0;
                    width: 50%;
                    padding: $sp-400;
                }
            }

            #{$theme-centered} & {
                text-align: center;
            }
        }

        &__title {
            @include font-display-medium;

            display: block;
            margin-top: $sp-080;
            margin-bottom: 0;

            @include breakpoint(mediumlarge down) {
                font-size: 30px;
            }

            @include breakpoint(large up) {
                margin-top: $sp-160;
            }

            #{$theme-narrow} & {
                font-size: 30px;
                line-height: #{ math.div(28, 30) };
            }

            #{$theme-light} & {
                color: $color-white;
            }
        }

        &__subtitle {
            @include font-text-small;

            display: block;
            margin: 0;

            #{$theme-narrow} & {
                font-size: 14px;
                line-height: #{ math.div(20, 14) };
            }

            #{$theme-light} & {
                color: $color-white;
            }
        }

        &__text {
            @include font-text-medium;

            display: none;
            margin-top: $sp-160;
            margin-bottom: 0;

            @include breakpoint(large up) {
                display: block;
            }

            #{$theme-light} & {
                color: $color-white;
            }
        }

        &__button {
            margin-top: $sp-160;

            @include breakpoint(large up) {
                margin-top: $sp-200;
            }
        }
    }
}
