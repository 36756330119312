.button {
    @include button;
    color: $color-white;
    text-align: center;
    background-color: $color-gray-20;
    width: 100%;
    cursor: pointer;
    border-radius: 1px;
    letter-spacing: 0;

    &:hover {
        background-color: $color-gray-20;
    }

    // display mode
    &--inline {
        display: inline-block;
        width: auto;
        text-align: left;
    }

    // disabled button
    &--disabled,
    &:disabled {
        background-color: $color-gray-20;
        cursor: default;
        pointer-events: none;
    }

    // colors
    &--white {
        color: $color-gray-10;
        background-color: $color-white;

        &:hover {
            color: $color-gray-10;
            background-color: $color-gray-90;
        }
    }

    &--lightgray {
        color: $text-color;
        background-color: $color-gray-80;

        &:hover {
            color: $text-color;
            background-color: $color-gray-80;
        }
    }

    &--highlight {
        color: $text-color;
        background-color: $color-green-600;

        &:hover {
            color: $color-white;
        }
    }

    &--green {
        color: $color-white;
        background-color: $color-positive;

        &:hover {
            background-color: $color-positive-60;
        }
    }

    &--transparent {
        color: $text-color;
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }
    }

    &--border {
        border: 1px solid $border-color;
    }

    &--underlined {
        text-transform: none;
        text-decoration: underline;
        padding: 0;
    }

    // icons
    &--icon {
        position: relative;
        padding-right: 40px;
        text-align: left;

        .icon {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);

            &--highlight {
                color: $color-green-600;
            }
        }

        &.button--no-text {
            width: 40px;
            height: 40px;
            padding: 0;
            position: relative;

            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                margin: 0;
                transform: translate(-50%, -50%);
            }
        }
    }

    &--primary {
        background-color: $color-gray-10;

        &:hover,
        &.active {
            @include font-din-medium;
            background-color: $color-accent;
            color: $color-gray-10;
        }
    }
}

button.button {
    width: 100%;

    // display mode
    &--inline {
        display: inline-block;
        width: auto;
    }
}

.link__green {
    color: $color-positive;
}
