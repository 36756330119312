@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-basic-teaser($name: '.basic-teaser') {
    $light: #{$name}--light;
    $align-top: #{$name}--align-top;
    $align-bottom: #{$name}--align-bottom;
    $slim: #{$name}--slim;
    $teaser-min-width: 280px;

    #{$name} {
        display: flex;
        position: relative;
        width: 100%;
        min-width: $teaser-min-width;
        height: 100%;
        overflow-y: auto;

        &__link-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            text-decoration: none;
        }

        &__background {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
            background-color: $color-gray-90;

            #{$light} & {
                background-color: $color-gray-10;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0;
                background-color: $color-white;
                transition: opacity $transition-600;

                #{$light} & {
                    background-color: $color-black;
                }

                #{$name}:hover & {
                    opacity: 0.6;
                }
            }
        }

        &__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &__content {
            margin: auto;
            padding: 0 $sp-200;
            z-index: 1;
            text-align: center;

            @include breakpoint(large) {
                padding: 0 $sp-640;

                #{$slim} & {
                    padding: 0 $sp-200;
                }
            }

            #{$align-top} & {
                margin: $sp-200 auto 0;

                @include breakpoint(large) {
                    margin: $sp-640 auto 0;
                }
            }

            #{$align-top}#{$slim} & {
                @include breakpoint(large) {
                    margin: $sp-400 auto 0;
                }
            }

            #{$align-bottom} & {
                margin: auto auto $sp-200;

                @include breakpoint(large) {
                    margin: auto auto $sp-640;
                }
            }

            #{$align-bottom}#{$slim} & {
                @include breakpoint(large) {
                    margin: auto auto $sp-400;
                }
            }
        }

        &__subline {
            @include font-text-medium;

            margin-top: 10px;
            margin-bottom: 0;

            #{$light} & {
                color: $color-white;
            }
        }

        &__link {
            display: block;
            margin-top: 10px;
            cursor: pointer;
        }

        &__button {
            margin-top: $sp-200;
        }
    }
}

@include shop-ui-basic-teaser;
