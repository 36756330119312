@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-account-info-box($name: '.account-info-box') {

    #{$name} {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 24px;

        @include breakpoint(mediumsmall) {
            background-color: $color-gray-90;
            border-radius: $global-button-border-radius;
        }

        &__title {
            @include font-head-medium($options:('color': $color-gray-20, 'scale': false ));

            padding-bottom: $spacing-lg;
            text-align: center;
        }

        &__text {
            @include font-din-regular;

            padding-bottom: $sp-320;
            font-size: 14px;
            color: $color-gray-20;
            line-height: 20px;
        }

        &__icon {
            margin-bottom: $spacing-md;
            color: $color-gray-10;

            @include breakpoint(mediumsmall) {
                margin-top: $spacing-lg;
            }

            @include breakpoint(medium) {
                width: 85px;
                height: 85px;
            }
        }

        &__link {
            width: 100%;

            @include breakpoint(mediumsmall) {
                width: 33%;
                margin-bottom: $spacing-lg;
            }
        }
    }
}
