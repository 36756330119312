@import '~ShopUiBundle/components/molecules/button-dropdown/button-dropdown';

@mixin shop-ui-stores-dropdown($name: '.stores-dropdown') {
    @include shop-ui-button-dropdown($name);

    #{$name} {
        &__button {
            @include font-text-small;

            height: 30px;
            padding: 0 $sp-200 0 0;

            @include breakpoint(xxlarge) {
                padding: 0 $sp-400 0 0;
            }

            &-icon {
                margin-left: 5px;
            }
        }

        &__content-wrapper {
            padding: 0;
        }
    }
}
