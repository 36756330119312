@import '~ShopUiBundle/styles/shared';

@mixin shop-cms-bundle-faq-contact($name: '.faq-contact') {

    #{$name} {
        display: block;

        &__title {
            @include font-head-small;

            margin: 20px 0;

            @include breakpoint(medium up) {
                &--mobile {
                    display: none;
                }

                &--desktop {
                    display: block;
                }
            }

            &--desktop {
                display: none;
            }
        }

        &__grey-wrapper {
            background-color: $color-gray-90;
            text-align: center;

            @include breakpoint(medium up) {
                background-color: transparent;
                text-align: inherit;
            }
        }

        &__phone {
            &-title {
                @include font-head-medium;

                margin: $sp-200 0 $sp-160 0;

                @include breakpoint(medium up) {
                    margin: 10px 0;
                }
            }

            &-number {
                @include font-text-xxlarge((
                    'color': $color-white,
                ));

                display: block;
                padding: 15px;
                background-color: $color-gray-10;
                text-decoration: none;

                @include breakpoint(medium up) {
                    padding: 0;
                    background-color: transparent;
                    color: inherit;
                }
            }
        }

        &__opening-times {
            @include font-text-small((
                'color': $color-gray-50,
            ));

            margin: 20px 0;

            @include breakpoint(medium up) {
                margin: 15px 0;
            }
        }

        &__list {
            list-style: none;
            margin-top: 25px;
            margin-bottom: 0;
            padding: 0;
            line-height: 1;
        }

        &__item {
            margin-bottom: 25px;

            &-title {
                @include font-text-medium;
            }
        }

        &__footer {
            @include font-text-small;

            margin-top: 30px;
        }
    }
}

@include shop-cms-bundle-faq-contact;
