@import '~ShopUiBundle/styles/shared';

@mixin shop-cms-bundle-faq-list-with-title($name: '.faq-list-with-title') {

    #{$name} {
        display: block;

        &__list {
            @include h-reset;

            list-style: none;
            line-height: 1;
        }

        &__item {
            @include font-text-medium;

            margin-bottom: 14px;

            @include breakpoint(medium) {
                margin-bottom: 16px;
            }
        }
    }
}

@include shop-cms-bundle-faq-list-with-title;
