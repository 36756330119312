.hide {
    display: none;
}

// Mobile first display utils
@mixin utils-create-visibility() {
    // $display, $has-down, $has-only
    $displays: 'none' true true,
        'block' false false;

    @each $display, $has-down, $has-only in $displays {
        .display-#{$display} {
            // stylelint-disable-next-line declaration-no-important
            display: #{$display} !important;
        }
    }

    @each $key, $value in $breakpoints {
        @each $display, $has-down, $has-only in $displays {
            .display-#{$display}-#{$key} {
                @include breakpoint($key up) {
                    // stylelint-disable-next-line declaration-no-important
                    display: #{$display} !important;
                }
            }

            @if $has-down {
                .display-#{$display}-#{$key}-down {
                    @include breakpoint($key down) {
                        // stylelint-disable-next-line declaration-no-important
                        display: #{$display} !important;
                    }
                }
            }

            @if $has-only {
                .display-#{$display}-#{$key}-only {
                    @include breakpoint($key only) {
                        // stylelint-disable-next-line declaration-no-important
                        display: #{$display} !important;
                    }
                }
            }
        }
    }
}

@include utils-create-visibility;
