@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-compare-bikes-table($name: '.compare-bikes-table') {
    $border: 1px solid $color-gray-80;
    $color-inverted: '#{$name}--style-inverted';

    #{$name} {
        @include font-din-regular;

        display: block;
        font-size: 14px;
        color: $color-gray-10;

        &#{$color-inverted} {
            background-color: $color-black;
            color: $color-white;
        }

        &--block {
            padding-top: 50px;
            padding-bottom: 80px;
            background-color: $color-gray-90;

            @include breakpoint(large up) {
                padding-top: 160px;
                padding-bottom: 160px;
            }
        }

        &__wrapper {
            display: block;
            overflow: hidden;

            @include breakpoint(large up) {
                overflow-x: visible;
            }
        }

        &__table {
            margin-bottom: 10px;
            border: $border;

            #{$color-inverted} & {
                border-color: $color-gray-20;
            }
        }

        &__row {
            // stylelint-disable-next-line declaration-no-important
            background-color: transparent !important;
        }

        &__title {
            @include font-din-medium;
        }

        &__cell {
            min-width: 200px;
            // stylelint-disable-next-line declaration-no-important
            padding: 10px 15px !important;
            border: $border;
            text-align: left;

            @include breakpoint(large up) {
                min-width: 260px;
            }

            #{$color-inverted} & {
                border-color: $color-gray-20;
            }

            &--header,
            &--title {
                @include font-din-medium;

                position: relative;
                min-width: 0;
                max-width: none;
                padding: 0 40px;
                text-transform: none;

                @include breakpoint(large up) {
                    min-width: 155px;
                    padding: 0;
                }
            }

            &-vertical {
                display: flex;
                flex-direction: column;
                padding: 15px 0;
                text-align: center;
            }
        }

        &__table-title {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 32px;
            line-height: 1;
            white-space: nowrap;
            word-break: keep-all;
            transform: translate(-50%, -50%) rotate(-90deg);

            @include breakpoint(large up) {
                font-size: 48px;
            }
        }

        &__picture {
            &-img {
                width: 120px;
                height: 80px;
                object-fit: contain;
                margin: 20px 0;

                @include breakpoint(large up) {
                    width: 200px;
                    height: 130px;
                }
            }
        }

        &__button {
            @include font-din-medium;

            display: inline-block;
            margin-top: 20px;
            padding: 7px 20px;
            border: 1px solid $color-gray-10;
            border-radius: 2px;
            text-decoration: none;

            #{$color-inverted} & {
                border: 1px solid $color-white;
                color: $color-white;
            }
        }
    }
}
