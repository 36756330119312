.colortile {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 0;
    width: 32px;
    height: 32px;
    border: 1px solid transparent;

    &.radiobutton__label {
        padding: 0;
    }

    @include breakpoint(mediumsmall down) {
        width: 40px;
        height: 40px;
    }

    .colortile__tile {

        flex-grow: 1;
        height: 100%;
        font-size: 0;
        border-color: $border-color;

        &--brown {
            background: #804000;
        }

        &--beige {
            background: #DEB887;
        }

        &--grey {
            background: #999999;
        }

        &--blue {
            background: #0000ff;
        }

        &--turquoise {
            background: #33ffff;
        }

        &--lila,
        &--purple {
            background: #6633cc;
        }

        &--oliv,
        &--olive {
            background: #667C26;
        }

        &--green {
            background: #009900;
        }

        &--red {
            background: #ff0000;
        }

        &--pink {
            background: #ff00ff;
        }

        &--orange {
            background: #ff6600;
        }

        &--yellow {
            background: #ffff00;
        }

        &--white,
        &--withe {
            background: #ffffff;
            box-shadow: inset 0 0 0 1px $border-color;
        }

        &--black {
            background: #000000;
        }

        &--empty {
            background: #ffffff;
            position: relative;

            &:after {
                content: '';
                width: 100%;
                height: 1px;
                background: $border-color;
                position: absolute;
                top: 50%;
                margin-top: -1px;
                transform-origin: 50% 50%;
                transform: rotate(-45deg);
            }
        }
    }
}
