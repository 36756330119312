@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-teaser-layout-2($name: '.teaser-layout-2') {
    $item-inverted: '#{$name}__item--inverted';
    $item-down: '#{$name}__item--down';
    $columns: 2;

    #{$name} {
        @include font-default;

        display: block;
        position: relative;

        &__list {
            display: flex;
            position: relative;
            flex-wrap: wrap;
            max-width: 120rem;
            margin-left: auto;
            margin-right: auto;
        }

        &__list-item {
            display: block;
            position: relative;
            width: 100%;
            margin-bottom: $spacing-md;

            @include breakpoint(mediumlarge) {
                width: math.div(100%, $columns);

                &:nth-child(2n) {
                    padding-left: $grid-column-gutter * 0.5;
                }

                &:nth-child(2n+1) {
                    padding-right: $grid-column-gutter * 0.5;
                }
            }
        }

        &__item {
            display: block;
            position: relative;
            padding-bottom: 100%;
        }

        &__background {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
        }

        &__img {
            display: block;
            position: relative;
            width: 100%;
        }

        &__content {
            display: block;
            position: absolute;
            top: $spacing-md;
            left: $spacing-lg;
            right: $spacing-lg;
            z-index: 2;

            @include breakpoint(mediumsmall) {
                top: $spacing-lg;
                left: $spacing-xl;
                right: $spacing-xl;
            }

            @include breakpoint(mediumlarge) {
                top: $spacing-md;
                left: $spacing-lg;
                right: $spacing-lg;
            }

            @include breakpoint(large) {
                top: $spacing-lg;
                left: $spacing-xl;
                right: $spacing-xl;
            }

            @include breakpoint(xxlarge) {
                top: $spacing-xl;
                left: $spacing-xxl;
                right: $spacing-xxl;
            }

            #{$item-down} & {
                top: auto;
                bottom: $spacing-md;

                @include breakpoint(mediumsmall) {
                    bottom: $spacing-lg;
                }

                @include breakpoint(mediumlarge) {
                    bottom: $spacing-md;
                }

                @include breakpoint(large) {
                    bottom: $spacing-lg;
                }

                @include breakpoint(xxlarge) {
                    bottom: $spacing-xl;
                }
            }
        }

        &__title,
        &__text,
        &__link {
            text-align: center;

            #{$item-inverted} & {
                color: $text-color-light;
            }
        }

        &__title {
            @include font-size-medium;

            @include breakpoint(large) {
                @include font-size-xlarge;
            }

            @include breakpoint(xxlarge) {
                @include font-size-xxlarge;
            }
        }

        &__text {
            @include font-size-xlarge;

            @include breakpoint(large) {
                @include font-size-xxlarge;
            }

            @include breakpoint(xxlarge) {
                @include font-size-xxxlarge;
            }

            * + & {
                margin-top: $spacing-xs;

                @include breakpoint(large) {
                    margin-top: $spacing-sm;
                }
            }
        }

        &__link {
            @include font-size-small;

            text-decoration: underline;

            @include breakpoint(large) {
                @include font-size-medium;
            }

            @include breakpoint(xxlarge) {
                @include font-size-xlarge;
            }

            * + & {
                margin-top: $spacing-sm;

                @include breakpoint(large) {
                    margin-top: $spacing-md;
                }

                @include breakpoint(xlarge) {
                    margin-top: $spacing-sm;
                }

                @include breakpoint(xxlarge) {
                    margin-top: $spacing-md;
                }
            }
        }
    }
}
