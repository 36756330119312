/* override the foundation breakpoints, adding some new */
$breakpoints: (
    small: 0,
    mediumsmall: 480px,
    medium: 640px,
    mediumlarge: 768px,
    large: 1024px,
    xlarge: 1200px,
    xxlarge: 1440px,
);

/* export the new breakpoints */
$breakpoint-classes: (small mediumsmall medium mediumlarge large xlarge);

/* offcanvas settings */
$offcanvas-sizes: 290px;
$offcanvas-shadow: none;
$offcanvas-exit-background: rgba(0, 0, 0, 0.6);

/* new gutter size */
$grid-column-gutter: 20px;
$row-padding-mobile-200: 20px;
$row-padding-mobile-200-breakpoint: mediumlarge down;
$container-max-width: 1200px;
