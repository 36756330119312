@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-range-slider-scroll-x-container($name: '.range-slider-scroll-x-container') {
    #{$name} {
        display: block;
        position: relative;
        overflow: hidden;
        text-align: center;

        &__wrapper {
            display: inline-block;
            position: relative;
            left: 0;
            width: auto;
            margin: auto;
        }

        &__range-slider {
            width: 190px;
            margin-top: 20px;
        }
    }
}

@include shop-ui-range-slider-scroll-x-container;
