@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-brand-navigation($name: '.brand-navigation') {
    #{$name} {
        display: block;
        background: $color-white;

        &__header {
            position: sticky;
            top: 0;
            z-index: 10;
            background: $color-white;
        }

        &__sticky-navi {
            overflow: hidden;
        }

        &__main-title {
            @include font-head-medium;

            margin-bottom: $sp-240;
            padding-top: $sp-640;
            overflow-x: hidden;

            @include breakpoint(large) {
                margin-bottom: $sp-320;
                padding-top: $sp-800;
            }
        }

        &__list {
            display: flex;
            list-style: none;
            flex-wrap: nowrap;
            justify-content: space-between;
            box-sizing: border-box;
            width: 100%;
            margin: 0;
            padding: 0 0 16px;
            background: $color-white;

            @include breakpoint(large) {
                padding: 0;
                border-bottom: 1px solid $color-gray-80;
            }
        }

        &__overview-list {
            display: flex;
            position: relative;
            list-style: none;
            flex-direction: column;
            align-content: flex-start;
            justify-content: flex-start;
            margin: 0;
            padding: 0;
        }

        &__overview-group-container {
            flex: 1;
            width: auto;
        }

        &__overview-group {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            max-width: 100%;
            padding-top: $sp-040;
        }

        &__overview-title {
            @include font-head-medium;

            max-width: 100%;
            padding-bottom: $sp-080;
            white-space: nowrap;
            text-decoration: none;

            @include breakpoint(large) {
                min-width: 100px;
                padding-bottom: 0;
            }
        }

        &__overview-button {
            text-decoration: none;
        }

        &__group-list {
            display: flex;
            list-style: none;
            flex-direction: column;
            flex-wrap: wrap;
            margin: 0;
            padding: 24px 0 48px;

            @include breakpoint(large) {
                flex-direction: row;
                flex-wrap: nowrap;
                padding: 40px 0 48px;
                border: none;
            }
        }

        &__overview-list-item:nth-child(n+2) {
            border-top: 1px solid $color-gray-80;
        }

        &__header-item {
            display: inline-block;
        }

        &__header-button {
            @include font-din-regular;

            display: inline-flex;
            justify-content: center;
            min-width: 50px;
            min-height: $sp-320;
            margin-right: $sp-080;
            padding: 0;
            border: solid 1px $color-gray-80;
            border-radius: 1px;
            font-size: 14px;
            color: $color-gray-10;
            line-height: 14px;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            transition: none;
            cursor: pointer;

            @include breakpoint(large) {
                width: auto;
                min-width: 0;
                margin-right: auto;
                border: none;
            }

            &:hover {
                background: $color-gray-10;
                border-color: $color-gray-10;
                color: $color-white;
                transition: none;

                @include breakpoint(large) {
                    background: $color-white;
                    border: none;
                    border-bottom: solid 2px $color-gray-10;
                    color: $color-gray-10;
                }
            }
        }

        &__button {
            @include font-text-medium;

            border: none;
            text-decoration: none;
            cursor: pointer;

            &--no-url {
                cursor: default;
            }

            &:hover {
                @include breakpoint(large) {
                    text-decoration: underline;
                }
            }
        }

        &__title {
            padding: $sp-080;
            text-decoration: none;

            #{$name}__header-button:hover > & {
                color: $color-white;
                transition: none;

                @include breakpoint(large) {
                    color: $color-gray-10;
                    transition: none;
                }
            }
        }

        &__brand-name {
            width: 50%;
            padding-bottom: $sp-160;

            @include breakpoint(large) {
                width: 260px;
            }
        }

        &__swiper-container {
            flex: 1;
            background-color: $color-white;
        }

        &__swiper-slide {
            // stylelint-disable-next-line declaration-no-important
            width: auto !important;

            @include breakpoint(large) {
                // stylelint-disable-next-line declaration-no-important
                flex-shrink: 1 !important;
            }
        }

        &__swiper-wrapper {
            // stylelint-disable-next-line declaration-no-important
            box-sizing: border-box !important;
            // stylelint-disable-next-line declaration-no-important
            height: auto !important;
        }
    }
}
