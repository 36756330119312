@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-catalog-category-bikes($name: '.catalog-category-bikes') {
    $level-2: '--level-2';

    #{$name} {
        display: block;
        color: $color-gray-10;

        &__link {
            color: $color-gray-10;
            text-decoration: none;
        }

        &__category-title {
            margin-top: 30px;
            margin-bottom: 30px;

            @include breakpoint(mediumlarge up) {
                margin-top: 60px;
                margin-bottom: 60px;
            }

            &#{$level-2} {
                margin-top: 64px;
                margin-bottom: 0;

                @include breakpoint(mediumlarge up) {
                    margin-top: 80px;
                    margin-bottom: 0;
                }
            }
        }

        &__category-subtitle {
            margin-top: $spacing-md;
        }

        &__list {
            display: block;
            list-style: none;
            margin: 0;
            padding: 0;

            &-item {
                display: block;
                margin: 64px 0 0;
                padding: 0;
            }

            &--no-title {
                &-item:first-child {
                    margin: 0;
                }
            }
        }

        &__content {
            @include breakpoint(large up) {
                max-width: 333px;
            }

            &-subtitle {
                margin-top: $spacing-sm;
            }
        }

        &__subtitle,
        &__title {
            @include font-din-regular;

            font-size: 16px;
            color: $color-gray-10;
            line-height: #{ math.div(20, 16) };

            @include breakpoint(mediumsmall up) {
                font-size: 20px;
            }
        }

        &__title {
            &-logo-img {
                display: inline-block;
                width: auto;
                max-width: 100%;
                height: 16px;

                @include breakpoint(mediumsmall up) {
                    height: 20px;
                }
            }
        }

        &__subtitle {
            margin-top: 8px;
        }

        &__info {
            display: block;
            margin-top: 16px;

            @include breakpoint(mediumsmall up) {
                margin-top: 24px;
            }
        }

        &__price {
            display: block;
            margin-top: 25px;

            &-title {
                @include font-text-xxlarge;

                display: block;
            }

            &-subtitle {
                @include font-text-xsmall;

                display: block;
                color: $color-gray-50;
            }

            &-leasing {
                @include font-text-small;

                display: block;
                color: $color-gray-20;
            }
        }

        &__button {
            margin-top: $sp-160;
        }

        &__picture-wrapper {
            display: block;
            position: relative;
            color: inherit;
            text-decoration: none;

            &--left {
                @include breakpoint(large) {
                    padding-right: 60px;
                }
            }

            &--right {
                @include breakpoint(large up) {
                    padding-left: 60px;
                }
            }
        }

        &__tags {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: $sp-080;

            @include breakpoint(mediumlarge) {
                position: absolute;
                flex-direction: column;
                flex-wrap: nowrap;
                top: 0;
                left: 0;
                margin-bottom: 0;
            }

            &--left {
                @include breakpoint(mediumlarge) {
                    align-items: flex-start;
                    left: $grid-column-gutter * 0.5;
                }
            }

            &--right {
                @include breakpoint(mediumlarge) {
                    align-items: flex-end;
                    right: $grid-column-gutter * 0.5;
                }
            }
        }

        &__tag {
            margin-bottom: $sp-040;
            margin-right: $sp-040;

            @include breakpoint(mediumlarge) {
                margin-right: 0;
            }
        }
    }
}
