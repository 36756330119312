@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-quantity-input-button($name: '.quantity-input-button') {
    $disabled: '#{$name}:disabled';
    $hover: '#{$name}:hover';
    $focus: '#{$name}:focus-visible';
    $active: '#{$name}:active';

    #{$name} {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        cursor: pointer;

        &#{$disabled} {
            opacity: 0.5;
            cursor: default;
        }

        &__button {
            display: flex;
            width: 30px;
            height: 30px;
            padding: $sp-080;
            background-color: $color-gray-90;
            border: 2px solid $color-gray-90;
            border-radius: 50%;
            color: $color-gray-10;
            transition: $global-button-transition;

            #{$hover}:not(#{$disabled}) & {
                background-color: $color-gray-20;
                border-color: $color-gray-20;
                color: $color-white;
            }

            #{$active} &,
            #{$focus} & {
                background-color: $color-gray-90;
                color: $color-gray-10;
            }

            #{$focus} & {
                border-color: $color-gray-10;
            }

            #{$active} & {
                border-color: $color-gray-90;
            }
        }
    }
}
