@import '~ShopUiBundle/styles/shared';

@mixin shipment-bundle-pickup-store-list($name: '.pickup-store-list') {
    $cards: '#{$name}--cards';

    #{$name} {
        display: block;

        &#{$cards} {
            padding-top: $sp-240;

            @include breakpoint(mediumlarge up) {
                padding-top: $sp-320;
            }
        }

        &__opt-out-header {
            display: inline-flex;
        }

        &__opt-out-container {
            margin: 0;
            padding-bottom: $spacing-md;
            font-size: 20px;

            @include breakpoint(medium) {
                font-size: 24px;
            }
        }

        &__opt-out-title {
            @include font-din-bold;

            padding-right: $spacing-xs;
        }

        &__opt-out-tooltip {
            top: -2px;
        }

        &__opt-out-radio,
        &__opt-out-radio:last-of-type,
        &__opt-out-radio:first-of-type {
            padding: 0;
        }

        &__show-more {
            padding-bottom: $sp-320;
            color: $color-gray-80;
            text-align: center;
        }

        &__more-link {
            margin: $sp-320 0;
            white-space: break-spaces;

            @include breakpoint(mediumlarge up) {
                margin: $sp-400 0;
            }
        }
    }
}
