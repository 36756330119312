@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-cart-navigation($name: '.cart-navigation') {
    $quantity-height: 14px;

    #{$name} {
        @include font-din-medium;

        display: block;

        &__button {
            display: block;
            position: relative;
            padding: 0;
            color: $color-gray-10;
            line-height: 1;
        }

        &__quantity {
            @include font-din-medium;

            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;
            bottom: 0;
            right: 0;
            width: auto;
            height: $quantity-height;
            padding: 0 5px;
            background-color: $color-gray-10;
            border-radius: $quantity-height * 0.5;
            font-size: 10px;
            color: $color-white;
            line-height: $quantity-height;
        }

        &__icon {
            width: 30px;
            height: 30px;
        }
    }
}

@include shop-ui-cart-navigation;
