@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-account-order-item($name: '.account-order-item') {
    $padding-mobile: '#{$name}--padding-mobile';

    #{$name} {
        display: block;

        &__row {
            @include breakpoint(large) {
                flex-wrap: nowrap;
                align-items: center;
            }
        }

        &__content-wrapper {
            display: flex;

            @include breakpoint(medium) {
                padding-left: $sp-160;
            }

            @include breakpoint(large) {
                align-items: center;
                padding-left: $sp-240;
            };

            #{$padding-mobile} & {
                padding-left: $sp-240;

                @include breakpoint(medium) {
                    padding-left: $sp-160;
                }

                @include breakpoint(large) {
                    padding-left: $sp-240;
                }
            }
        }

        &__picture-wrapper {
            flex-shrink: 0;
            box-sizing: content-box;
            width: 63px;
            min-height: 63px;
            padding-right: $sp-080;

            @include breakpoint(medium) {
                width: 69px;
                min-height: 69px;
            }

            @include breakpoint(large) {
                display: flex;
                align-items: center;
                width: 144px;
                min-height: 144px;
                padding-right: $sp-200;
            }
        }

        &__content {
            @include font-din-regular;

            flex-grow: 1;
            font-size: 14px;
            color: $color-gray-20;
            line-height: 20px;
        }

        &__brand {
            @include font-din-bold;

            padding-bottom: $sp-020;
            color: $color-gray-10;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        &__product-name {
            padding-bottom: $sp-020;
            color: $color-gray-10;
            text-decoration: underline;
        }

        &__details-list {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        &__quantity {
            color: $color-gray-10;
        }

        &__price-wrapper {
            display: flex;
            justify-content: flex-end;

            @include breakpoint(large) {
                flex-shrink: 1;
                justify-content: space-between;
            }
        }

        &__price {
            font-size: 14px;
            color: $color-gray-10;

            @include breakpoint(medium) {
                padding-right: $sp-160;
            }

            @include breakpoint(large) {
                padding-right: $sp-240;
            }

            #{$padding-mobile} & {
                padding-right: $sp-240;

                @include breakpoint(medium) {
                    padding-right: $sp-160;
                }

                @include breakpoint(large) {
                    padding-right: $sp-240;
                }
            }
        }

        &__checkbox {
            margin-top: $spacing-sm;
            margin-left: 103px;

            @include breakpoint(large) {
                margin-left: 0;
                margin-right: 28px;
            }
        }
    }
}
