@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-card($name: '.card') {
    $theme-dark: #{$name}--theme-dark;
    $theme-light: #{$name}--theme-light;
    $theme-cover-accent: #{$name}--theme-cover-accent;
    $theme-cover-dark: #{$name}--theme-cover-dark;
    $theme-cover-gray: #{$name}--theme-cover-gray;

    #{$name} {
        display: block;
        min-width: 280px;
        height: 400px;
        padding: $sp-240;
        background: $color-gray-90;
        border-radius: $global-button-border-radius;
        color: $color-gray-10;

        @include breakpoint(small down) {
            max-width: 280px;
        }

        &#{$theme-light} {
            background-color: $color-white;
            color: $color-gray-10;
        }

        &#{$theme-dark},
        &#{$theme-cover-dark} {
            background-color: $color-gray-10;
            color: $color-white;
        }

        &#{$theme-cover-accent} {
            background: $color-accent;
            color: $color-gray-10;
        }

        &#{$theme-cover-gray} {
            background: $color-gray-90;
            color: $color-gray-10;
        }

        &__content {
            display: flex;
            flex-direction: column;
            max-width: 300px;
            height: 100%;
        }

        &__icon-wrapper {
            margin-bottom: $spacing-md;
        }

        &__title {
            @include font-head-medium;

            display: block;
            margin-bottom: $sp-160;
            font-size: 24px;
            letter-spacing: $global-title-letter-spacing;
            line-height: 28px;
            overflow-wrap: break-word;

            #{$theme-dark} &,
            #{$theme-cover-dark} & {
                color: $color-white;
            }
        }

        &__text {
            max-height: 120px;
            margin-bottom: $sp-160;
            overflow: hidden;
            font-size: 14px;
            color: $color-gray-20;
            letter-spacing: 0;
            line-height: 20px;

            #{$theme-dark} &,
            #{$theme-cover-dark} & {
                color: $color-white;
            }
        }

        &__text-link {
            #{$theme-dark} &,
            #{$theme-cover-dark} & {
                color: $color-white;
            }
        }

        &__link-wrapper {
            display: block;
        }

        &__button {
            align-self: flex-start;
            margin-top: auto;
        }
    }
}
