@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-product-footer-newsletter($name: '.product-newsletter') {
    $theme-light: '#{$name}--light';
    $theme-highlight: '#{$name}--highlight';

    #{$name} {
        display: block;
        padding-bottom: $sp-400;

        &#{$theme-light} {
            background-color: transparent;
            color: $color-white;
        }

        &__title {
            margin: 0;
            font-size: 22px;
        }

        &__text {
            margin: 4px 0 20px;
        }

        &__button-group {
            display: flex;
            position: relative;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: center;
            width: 100%;
            padding: 0 $sp-240;
        }

        &__input {
            @include font-din-regular;

            // stylelint-disable declaration-no-important
            width: 300px;
            max-width: 80vw;
            height: 28px !important;
            margin: 0 !important;
            padding: 0;
            background-color: transparent;
            border: none !important;
            border-bottom: 1px solid $color-gray-10 !important;
            // stylelint-enable

            @include breakpoint(medium) {
                width: 492px;
                // stylelint-disable-next-line declaration-no-important
                margin-right: $sp-160 !important;
            }

            @include breakpoint(large) {
                width: 380px;
                // stylelint-disable-next-line declaration-no-important
                margin-top: 0 !important;
                // stylelint-disable-next-line declaration-no-important
                margin-right: 28px !important;
            }

            #{$theme-light} & {
                // stylelint-disable-next-line declaration-no-important
                border-bottom: 1px solid $color-white !important;
                color: $color-white;
            }

            &:focus {
                #{$theme-light} & {
                    background: $color-black;
                    color: $color-white;
                }

                &::placeholder {
                    color: transparent;
                }
            }

            &.error ~ .icon-alert {
                top: 68px;
                visibility: visible;

                @include breakpoint(large) {
                    top: 26px;
                    left: 377px;
                }
            }

            &.valid ~ .icon-check3 {
                top: 72px;
                visibility: visible;

                @include breakpoint(large) {
                    top: 30px;
                    left: 360px;
                }
            }

            &.error {
                // stylelint-disable-next-line declaration-no-important
                background-image: none !important;
            }

            &.valid {
                // stylelint-disable-next-line declaration-no-important
                background-image: none !important;
            }

            &:disabled {
                background-color: transparent;
            }
        }

        &__label {
            width: 300px;
            max-width: 80vw;
            // stylelint-disable-next-line declaration-no-important
            margin: 0 0 $sp-320 0 !important;

            @include breakpoint(medium) {
                width: 492px;
            }

            @include breakpoint(large) {
                width: 380px;
                // stylelint-disable-next-line declaration-no-important
                margin: 0 $sp-160 0 0 !important;
            }

            .form-error {
                position: absolute;
                top: 36px;
                color: $color-error;

                @include breakpoint(large) {
                    top: 54px;
                }
            }
        }

        &__button-group-append {
            width: 300px;
            max-width: 80vw;

            @include breakpoint(medium) {
                width: 492px;
            }

            @include breakpoint(large) {
                width: 380px;
            }
        }

        &__submit-button {
            @include font-din-bold;

            width: 50%;
            padding: 13px 24px;
            background-color: $color-gray-10;
            border: none;
            border-radius: $global-button-border-radius;
            font-size: 14px;
            color: $color-white;
            line-height: 1;
            text-transform: none;

            @include breakpoint(medium up) {
                padding: 16px 32px;
            }

            &:hover {
                background-color: $color-gray-20;
            }

            &:disabled {
                opacity: 0.35;
            }

            #{$theme-light} & {
                background-color: $color-white;
                border: 1px solid $color-white;
                border-radius: $global-button-border-radius;
                outline: 0;
                color: $color-gray-10;

                &:hover {
                    background-color: $color-gray-20;
                }

                &:disabled {
                    opacity: 0.5;
                }
            }

            #{$theme-highlight} & {
                background-color: $color-accent;
                border: none;
                border-radius: $global-button-border-radius;
                color: $color-gray-10;

                &:hover {
                    background-color: $color-green-600;
                    color: $color-gray-10;
                }

                &:disabled {
                    background-color: $color-green-900;
                    color: $color-gray-10;
                }
            }
        }
    }
}

@include shop-ui-product-footer-newsletter;
