.cart-item {
  margin-bottom: $sp-320;
  position: relative;

  .columns {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(small only) {
      &:first-child {
        justify-content: flex-start;
      }
    }

    @include breakpoint(medium up) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .product-flags {
    position: absolute;
    top: 10px;
    left: 20px;
    z-index: 10;

    @include breakpoint(small only) {
        max-width: 80px;
    }
  }

  &__imagewrapper {
    min-height: 1px; // IE11 bugfix
    @include breakpoint(small only) {
      max-width: 85px;
    }

    &.is--bike {
      padding: 15px 0 5px 0;
    }
  }

  &__inner {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    background: $color-white;
    margin: 0px;
  }

  &__name {
    @include font-din-bold;
    margin-bottom: $sp-040;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
  }

  &__attributes {
    @include font-din-regular;
    margin: 0;

    li {
      margin-top: 0;
    }

    dd.out-of-stock {
      color: #6b1d1c;
        font-weight: bold;
    }
  }

  &__configuration-headline {
    margin-top: 0;
    margin-bottom: 15px;
  }

  &__configuration {
    @include font-din-regular();
    margin-bottom: 0;
  }

  &__configuration-row {
    margin-bottom: 10px;

    @include breakpoint(large up) {
      margin-bottom: 0;
    }
  }

  .cart-item__configuration-cell {
    @include breakpoint(medium up) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__quantity,
  input[type="number"] {
    margin: 0 auto;
    @include font-din-medium();
    font-size: $font-size-500;
    max-width: 150px;
  }

  &__price {
    @include font-din-regular();
    font-size: $font-size-500;

    @include breakpoint(small only) {
      font-size: $font-size-600;
      text-align: right;
    }

    &--strikeprize {
      display: block;
      font-size: $font-size-400;
    }

    &--now {
      display: block;
    }

    &--lowprice {
      color: $color-error;
    }
  }

  &__remove {
    position: absolute;
    top: 10px;
    right: 10px;
    transform: scale(.6);
    cursor: pointer;

    @include breakpoint(medium up) {
      top: 20px;
      right: 20px;
      transform: none;
    }
  }

  &__remove-item-link,
  .availability {
    margin-bottom: 16px;
  }

  &__reveal {
    min-height: auto;
    height: auto;

    p {
      margin-top: 0;
    }
  }
}

.cart-item--checkout {
  padding-left: 0;
  padding-right: 0;
  /* stylelint-disable-next-line declaration-no-important */
  margin: 0 0 $sp-400 0 !important;

  &:after {
    content: '';
    display: block;
    height: 1px;
    background: $text-color;

    @include breakpoint(medium up) {
      margin: 0 15px;
    }
  }

  .columns {
    @include breakpoint(medium up) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .cart-item__name {
    @include font-din-bold;
    margin-bottom: $sp-040;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    color: $color-gray-10;
    max-width: calc(100% - 25px);
  }

  .cart-item__price {
    @include font-din-bold;
    margin-bottom: $sp-040;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
  }

  .cart-item__info-text {
    margin: 0;
  }

  .product-flags {
    font-size: $font-size-100;
    top: 0;
    left: 10px;
    z-index: 10;

    .product-flags--item {
      @include breakpoint(small only) {
        font-size: $font-size-100;
      }
    }
  }
}

.cart-item--history-group {
  margin-bottom: 20px;
}

.cart-item--history-group-label {
  @include font-din-bold;
  margin-bottom: 20px;
  font-size: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  @include breakpoint(medium up) {
    font-size: 24px;
    margin-top: 32px;
    margin-bottom: 24px;
  }
}

.cart-item--history {
  margin-bottom: 1px;
  border-bottom: 1px solid $color-gray-80;
  border-radius: 1px;

  + .cart-item--history {
    border-top: none;
  }

  .columns {
    @include breakpoint(small only) {
      &:last-child {
        text-align: right;
      }
    }

    &.end {
      @include breakpoint(mediumsmall down) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

  }

  .cart-item__inner {
    @include font-din-regular();
    margin: 0px;

    &:before,
    &:after {
      display: none;
    }
  }

  .cart-item__name {
    font-size: $font-size-400;
  }

}

.cart-item--wishlist {
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid $color-gray-80;

  .cart-item__info-wrapper {
    align-self: stretch;
    position: relative;
  }

  .cart-item__inner {
    position: static;
  }

  .cart-item__price-btn-wrapper {
    @include breakpoint(large) {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0 10px;
      width: 50%;
    }
  }

  .cart-item__price {
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
    flex-grow: 1;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .button--cart {
      margin-bottom: $sp-200;

      @include breakpoint(medium) {
        margin-bottom: 0;
      }

      @include breakpoint(large) {
        margin-bottom: $sp-200;
      }
  }

  .cart-item__name {
    margin-top: 0;
  }

  @include breakpoint(mediumlarge down) {
    .availability {
      margin: 0;
    }
  }

  @include breakpoint(mediumsmall down) {
    .cart-item__inner {
      padding-top: 10px;
    }

    .cart-item__remove {
      transform: scale(1);
      padding: 10px;
    }

    .availability {
      margin: 0 0 5px 0;
    }
  }
}

.cart-item--unavailable {
  .cart-item__imagewrapper {
    opacity: 0.3;
  }

  .cart-item__name,
  .cart-item__attributes,
  .cart-item__price,
  .cart-item__quantity {
    color: $color-gray-50;
  }

  .cart-item__name {
    text-decoration: underline;
  }

  .cart-item__quantity {
    background-color: $color-gray-90;
    border: none;
  }
}
