@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-password-discard($name: '.password-discard') {

    #{$name} {
        display: block;

        &__link {
            @include font-din-regular;

            margin: 0;
            font-size: 14px;
            color: $color-gray-20;
            line-height: 20px;
            text-decoration: none;

            &:hover,
            &:focus {
                background-color: transparent;
                border: none;
            }
        }

        &__title {
            text-decoration: underline;

            &:hover,
            &:focus {
                color: $color-gray-50;
            }
        }
    }
}
