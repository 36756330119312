.userrating {

  // statistics
  &__statistics {
    &__row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 10px 0;
    }

    &__stars {
      display: inline-flex;
      align-items: center;
      margin-right: 10px;
    }

    &__bar {
      display: inline-block;
      background-color: $color-gray-80;
      height: 15px;
      width: 100%;
      margin-right: 10px;
      position: relative;

      &--actual {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        background-color: $color-gray-10;
        height: 15px;
      }
    }

    &__count {
      width: 50px;
      font-size: $font-size-100;
    }
  }

  // actions
  &__actions {
    &__stars {
      display: inline-flex;
      align-items: center;
      color: $color-gray-10;

      .icon {
        margin-right: 2px;

        &:last-of-type {
          margin-right: 5px;
        }
      }
    }

    &__loggedout {
      font-size: $font-size-300;
    }

    &__checkbox {
      @include font-din-regular;
      margin-top: 15px;
      display: inline-block;
      font-size: 12px;
      height: auto;
    }

  }

  // filter
  &__filter {
    margin-top: 20px;

    &__button {
      max-width: 250px;
    }

    // TODO: remove
    &__dropdown {
      padding: 0;
      width: 250px;
      background-color: $color-white;
    }

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;

      &--static {
        // stylelint-disable-next-line declaration-no-important
        position: static !important;
      }
    }

    &__item {
      margin: 0;

      label {
        display: flex;
        padding: 0 10px 5px 30px;
        margin: 0;
        height: 32px;
        color: $text-color;

        .checkbox--box {
          left: 0;
        }

        .rating {
          color: inherit;
        }

      }

    }

  }

  // ratings
  &__listing {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    &:before,
    &:after {
      width: 0; /* safari flexbox fix */
    }
  }

  &__listingcontainer {
    transition: filter .2s;
    &.is-loading {
     filter: blur(4px);
    }
  }

  &__rating {
    padding: 20px 0;
    border-top: 1px solid $border-color;

    @include breakpoint(medium up) {
      padding-bottom: 40px;
      .product-rating-details:nth-child(-n+2) & {
        border-top: 1px solid $border-color;
      }
      .product-rating-details:nth-last-child(2):nth-child(even) & {
        border-bottom: 1px solid $border-color;
      }
    }

    &__meta {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      @include font-din-medium;
    }

    &__stars {
      display: inline-flex;
      margin-right: 10px;
    }

    &__author {
      margin-right: 10px;
      @include font-din-medium;
    }

    &__date {
      margin-right: 10px;
      @include font-din-regular;
    }

    &__title {
      margin: 0;
      text-transform: none;
      @include font-head-small;
    }

    &__variation {
      margin: 5px 0 25px;
      @include font-din-medium;

      dt {
        display: inline-block;
        font-weight: 600;
        margin: 0;
        @include font-din-regular;
      }
      dd {
        display: inline-block;
        margin: 0 10px 0 0;
      }
    }

    &__maxchars {
      text-transform: uppercase;
      font-size: 12px;
      color: $color-gray-20;
      margin: -10px 0 20px 0;
    }
  }

  // reveal
  &__reveal {

    &.reveal {
      width: 820px;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;

      @include breakpoint(medium down) {
        form {
          padding-bottom: 50px;
        }
      }
    }

    &__headline {

      &:after {
        margin-top: 20px;
      }

    }

    &__subline {
      margin-bottom: 4px;
    }

    .accordion-content {
      padding-top: 0;
    }
  }

  &__additional-info {
    margin-top: $spacing-md;

    @include font-text-xsmall((
      'color': $color-gray-50,
    ));

    @include breakpoint(medium) {
      padding-right: $spacing-md;
    }
  }
}
