@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-button-dropdown($name: '.button-dropdown') {
    $icon-animated: #{$name}--icon-animated;
    $block: #{$name}--block;
    $button-open: '[aria-expanded="true"]';
    $content-close: '[hidden]';

    #{$name} {
        display: inline-block;

        &#{$block} {
            display: block;
            width: 100%;
        }

        &__button {
            display: block;
            cursor: pointer;

            #{$block} & {
                justify-content: space-between;
                width: 100%;
            }
        }

        &__content-wrapper {
            display: block;
            position: absolute;
            width: auto;
            padding: 0;
            visibility: visible;
            opacity: 1;
            overflow: hidden;
            z-index: 10;
            transform: scaleY(1) translate(-1px, -1px);
            transform-origin: top;
            transition: opacity 0.3s ease-in-out;

            &#{$content-close} {
                visibility: hidden;
                opacity: 0;
                // stylelint-disable-next-line declaration-no-important
                transform: scaleY(0) translate(-1px, -1px) !important;
            }
        }

        &__content-inner {
            background-color: #ffffff;
            border: 2px solid $color-gray-90;
            border-radius: 5px;
        }

        &__header,
        &__footer {
            display: block;
            position: relative;
        }

        &__button-icon {
            transform: rotate(0deg);

            #{$button-open} & {
                #{$icon-animated} & {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
