@import '~SprykerProductBundle/components/molecules/product-tile-base/product-tile-base';

@mixin product-bundle-catalog-product-tile($name: '.catalog-product-tile') {
    @include product-bundle-product-tile-base($name);

    #{$name} {
        margin-bottom: $spacing-lg;

        @include breakpoint(mediumlarge) {
            margin-bottom: $spacing-xl;
        }
    }
}
