@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin shop-cms-faq-short-overview($name: '.faq-short-overview') {

    #{$name} {
        display: block;
        padding: 20px 10px;
        background: $color-white;

        &__list {
            @include h-reset;

            list-style: none;
            margin-bottom: 24px;
            line-height: 1;

            @include breakpoint(medium) {
                margin-bottom: 0;
            }
        }

        &__item {
            @include font-text-medium;

            margin-bottom: 8px;
            line-height: #{ math.div(20, 14) };

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__text {
            @include font-din-regular;

            margin: 0 0 24px;

            @include breakpoint(medium) {
                margin-bottom: 32px;
                padding-right: 20px;
            }
        }

        &__bottom-link {
            display: block;
            margin: auto 0 0;
        }

        &__text-container {
            display: flex;
            flex-direction: column;
        }
    }
}
