@import '~ShopUiBundle/components/molecules/main-navigation-category/main-navigation-category';

@mixin shop-ui-main-navigation-cms-category($name: '.main-navigation-cms-category') {
    @include shop-ui-main-navigation-category($name);

    #{$name} {
        display: flex;

        &__logo {
            display: block;
            height: 10px;
            margin-top: 1px;
            object-fit: contain;
        }
    }
}
