@import '~ShopUiBundle/styles/shared';

@mixin product-bundle-product-tile-variants($name: '.product-tile-variants') {
    #{$name} {
        @include font-text-medium((
            'color': $color-gray-50,
        ));

        display: block;
        position: relative;
    }
}

@include product-bundle-product-tile-variants;
