@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-trbo-slider($name: '.trbo-slider') {
    $hidden: #{$name}--hidden;

    #{$name} {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 316px;

        @include breakpoint(medium up) {
            min-height: 367px;
        }

        @include breakpoint(mediumlarge up) {
            min-height: 375px;
        }

        @include breakpoint(large up) {
            min-height: 399px;
        }

        &__view-loader {
            display: flex;
        }

        &__logo-loader {
            height: 100px;
        }
    }

    #{$hidden} {
        display: none;
    }
}

@include shop-ui-trbo-slider;
