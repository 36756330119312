@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-catalog-category-model($name: '.catalog-category-model') {
    $box-shadow: 0 5px 33px 0 rgba(18, 18, 18, 0.15);
    $image-height: 1200;
    $image-width: 1800;

    #{$name} {
        display: block;
        position: relative;
        color: $color-gray-10;

        &__link {
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            overflow: hidden;
            background-color: $color-white;
            border: 1px solid $color-gray-80;
            color: $color-gray-10;
            text-decoration: none;
            transition: box-shadow $transition-500;

            &:hover {
                box-shadow: $box-shadow;
            }
        }

        &__top,
        &__bottom {
            display: block;
        }

        &__bottom {
            padding: 0 $sp-160 $sp-160;

            @include breakpoint(mediumlarge up) {
                padding: 0 $sp-320 $sp-320;
            }
        }

        &__flags {
            display: flex;
            position: absolute;
            top: $sp-160;
            left: $sp-160;
            z-index: 2;
            transform: translate3d(0, 0, 0) perspective(1000px);

            @include breakpoint(mediumlarge up) {
                top: $sp-200;
                left: $sp-200;
            }
        }

        &__variants {
            margin: 0 0 $sp-240;

            @include breakpoint(mediumlarge up) {
                margin: 0 0 $sp-320;
            }
        }

        &__caption {
            @include font-text-medium;

            min-height: 20px;
            margin: 0;
            padding: 0 $sp-160;
            overflow: hidden;
            color: $color-green-900;
            white-space: nowrap;
            text-overflow: ellipsis;

            @include breakpoint(mediumlarge up) {
                padding: 0 $sp-320;
            }
        }

        &__title {
            margin: $sp-040 0 0;
            padding: 0 $sp-160;

            @include breakpoint(mediumlarge up) {
                margin: $sp-080 0 0;
                padding: 0 $sp-320;
            }
        }

        &__price {
            margin-top: $sp-040;
            padding: 0 $sp-080;

            @include breakpoint(mediumlarge up) {
                padding: 0 $sp-160;
            }
        }

        &__info {
            display: block;
            margin: $sp-160 0;
            padding: 0 $sp-160;

            @include breakpoint(mediumlarge up) {
                padding: 0 $sp-320;
            }

            &--with-header {
                margin-top: 0;
            }
        }

        &__additional-info {
            @include font-text-xsmall;

            display: block;
            margin: 0 0 $sp-160;
            color: $color-gray-20;
        }

        &__list {
            display: flex;
            position: relative;
            list-style: none;
            flex-wrap: wrap;
            min-height: 38px;
            margin: 0;
            padding: 0 $sp-160;
            z-index: 3;

            @include breakpoint(mediumlarge) {
                margin-top: $sp-200;
                padding: 0 $sp-320;
                transform: translateY(0);
            }

            &-item {
                display: flex;
                margin-left: $sp-160;
                cursor: pointer;

                @include breakpoint(mediumlarge) {
                    margin-left: $sp-080;
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        &__picture-list {
            display: flex;
            list-style: none;
            width: 100%;
            margin: 0;
            padding: 0;

            &::after {
                content: '';
                padding-top: percentage(math.div($image-height, $image-width));
            }

            &-item {
                display: none;
                position: relative;
                width: 100%;
                min-height: 240px;
                margin: 0;
                padding: 0;

                &--active {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        &__picture,
        &__image {
            width: 100%;
            height: 100%;
            transform: translate3d(0, 0, 0) perspective(1000px);
        }

        &__picture {
            display: block;
            position: relative;
            z-index: 1;
        }

        &__image {
            object-fit: contain;
        }

        &__circle {
            &:hover:not(#{$name}__circle--active) .outer-circle {
                stroke: $color-gray-80;
                transition: stroke 0.2s ease;
            }

            &:focus {
                outline: none;
            }

            &--active .outer-circle {
                stroke: $color-gray-10;
            }
        }

        &__loader {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 60px;
            z-index: 0;
            transform: translate3d(0, 0, 0) translateX(-50%) translateY(-50%) perspective(1000px);
        }

        &__availability-button {
            margin-bottom: $spacing-sm;
        }
    }
}
