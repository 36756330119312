.rating {
  @include font-din-regular;
  font-size: $font-size-200;

  [itemprop="ratingValue"] {
    font-size: 0;
    .icon {
      margin-right: 2px;

      &:last-of-type {
        margin-right: 5px;
      }
    }
    display: inline-block;
  }

  [itemprop="reviewCount"] {
    padding: 0;
    margin: 0;
    display: inline-block;
    position: relative;
    bottom: -4px;
    color: $color-gray-20;
  }

  .icon {
    width: 12px;
    height: 12px;
    margin-right: 2px;
  }

  .is-inactive {
    opacity: .3;
  }

  &__number {
    color: $color-gray-20;
    vertical-align: middle;
  }
}
