@import '~ShopUiBundle/styles/shared';
@import '~ShopUiBundle/components/atoms/form-input-abstract/form-input-abstract';

@mixin shop-ui-form-check($name: '.form-check') {
    $disabled: '#{$name}--disabled';
    $error: '#{$name}--error';
    $margin-top: '#{$name}--margin-top';
    $input-checked: '#{$name}__input:checked';
    $icon-checked: '#{$name}__input:checked + #{$name}__icon';
    $hover: '#{$name}__target:not(#{$disabled}):hover';
    $focus: '#{$name}__target:not(#{$disabled}):focus';
    $focus-within: '#{$name}__target:not(#{$disabled}):focus-within';
    $active: '#{$name}__target:not(#{$disabled}):active';
    $padding: $sp-240;

    #{$name} {
        @include shop-ui-form-make-input($name);

        margin-top: 0;
        margin-bottom: $sp-320;
        padding-left: $padding;
        color: $color-gray-20;

        &__title {
            display: block;
            color: inherit;
            cursor: pointer;
            user-select: none;

            a {
                color: inherit;
                text-decoration: underline;
            }

            &-text {
                color: $color-gray-20;

                span {
                    color: inherit;
                }

                #{$hover} &,
                #{$focus} &,
                #{$focus-within} &,
                #{$active} & {
                    color: $color-gray-10;
                }

                #{$disabled} & {
                    color: $color-gray-50;
                }

                #{$input-checked} ~ & {
                    color: $color-gray-10;

                    #{$hover} &,
                    #{$focus} &,
                    #{$focus-within} &,
                    #{$active} & {
                        color: $color-gray-20;
                    }

                    #{$disabled} & {
                        color: $color-gray-50;
                    }
                }
            }
        }

        &__input {
            position: absolute;
            margin: 0;
            opacity: 0;
            cursor: pointer;

            #{$disabled} & {
                pointer-events: none;
            }
        }

        &__icon {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 18px;
            height: 18px;
            overflow: visible;

            &-base--checkbox {
                stroke: $color-gray-50;
                transition: fill 0.1s ease, stroke 0.1s ease;
                fill: $color-white;

                #{$hover} &,
                #{$focus} &,
                #{$focus-within} & {
                    stroke: $color-gray-10;
                    fill: $color-gray-90;
                }

                #{$active} & {
                    stroke: $color-gray-10;
                    fill: $color-gray-50;
                }

                #{$disabled} & {
                    stroke: $color-gray-80;
                    fill: $color-white;
                }

                #{$icon-checked} & {
                    stroke: $color-accent;
                    fill: $color-accent;

                    #{$hover} &,
                    #{$focus} &,
                    #{$focus-within} & {
                        stroke: $color-gray-10;
                        fill: $color-accent;
                    }

                    #{$active} & {
                        stroke: $color-gray-10;
                        fill: $color-green-600;
                    }

                    #{$disabled} & {
                        stroke: $color-gray-90;
                        fill: $color-gray-90;
                    }
                }
            }

            &-tick--checkbox {
                stroke-dasharray: 16px;
                stroke-dashoffset: 16px;
                stroke: $color-gray-10;
                transition: stroke-dashoffset 0.1s ease;

                #{$icon-checked} & {
                    stroke-dashoffset: 0;

                    #{$disabled} & {
                        stroke: $color-gray-80;
                    }
                }
            }

            &-base--radio {
                stroke: $color-gray-20;
                transition: fill 0.1s ease, stroke 0.1s ease;
                fill: $color-white;

                #{$hover} &,
                #{$focus} &,
                #{$focus-within} & {
                    stroke: $color-gray-10;
                    fill: $color-gray-90;
                }

                #{$active} & {
                    stroke: $color-gray-50;
                    fill: $color-gray-50;
                }

                #{$disabled} & {
                    stroke: $color-gray-80;
                    fill: $color-white;
                }

                #{$icon-checked} & {
                    stroke: $color-gray-10;
                    fill: $color-white;

                    #{$hover} &,
                    #{$focus} &,
                    #{$focus-within} & {
                        stroke: $color-gray-10;
                        fill: $color-white;
                    }

                    #{$active} & {
                        stroke: $color-gray-10;
                        fill: $color-white;
                    }

                    #{$disabled} & {
                        stroke: $color-gray-80;
                        fill: $color-white;
                    }
                }
            }

            &-tick--radio {
                stroke-width: 0;
                stroke: transparent;
                transform-origin: center;
                transition: all ease 0.1s;
                fill: transparent;

                #{$disabled} & {
                    stroke: transparent;
                    fill: transparent;
                }

                #{$icon-checked} & {
                    stroke-width: 4;
                    stroke: $color-gray-10;
                    fill: $color-gray-10;

                    #{$hover} &,
                    #{$focus} &,
                    #{$focus-within} & {
                        stroke: $color-gray-10;
                        fill: $color-gray-10;
                    }

                    #{$active} & {
                        stroke: $color-gray-10;
                        fill: $color-gray-10;
                    }

                    #{$disabled} & {
                        stroke: $color-gray-80;
                        fill: $color-gray-80;
                    }
                }
            }

            #{$error}  &-base {
                // stylelint-disable-next-line declaration-no-important
                stroke: $color-error !important;
            }
        }

        &__error {
            left: $padding;
        }
    }

    #{$hover},
    #{$focus},
    #{$focus-within},
    #{$active} {
        color: $color-gray-10;
    }

    #{$margin-top} {
        margin-top: 30px;
        margin-bottom: 40px;
    }

    @content;
}
