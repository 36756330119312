@import '~ShopUiBundle/styles/shared';

@mixin product-bundle-products-slider($name: '.products-slider') {
    $border: solid 1px $color-gray-80;

    #{$name} {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;

        &__row {
            width: 100%;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: $spacing-lg;
        }

        &__title {
            @include font-head-large((
                'breakpoint': xxlarge,
            ));

            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0;
            padding: 0;
        }

        &__slider-container {
            display: flex;
            // stylelint-disable-next-line declaration-no-important
            overflow: visible !important;
            background-color: $color-white;

            #{$name}--background-transparent & {
                background-color: transparent;
            }
        }

        &__slider-container-wrapper {
            // stylelint-disable-next-line declaration-no-important
            overflow: visible !important;
        }

        &__slides-wrapper {
            #{$name}--center-slides & {
                width: auto;
            }
        }
    }
}
