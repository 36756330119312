@import '~ShopUiBundle/styles/shared';

@mixin catalog-suggestions($name: '.catalog-suggestions') {

    #{$name} {
        @include font-text-medium((
            'bold': false,
        ));

        // stylelint-disable-next-line declaration-no-important
        display: block !important;
        margin: 0;
        padding: 0;

        &__item {
            display: flex;
            align-items: center;
            min-height: 54px;
            // stylelint-disable-next-line declaration-no-important
            border: 0 !important;
            // stylelint-disable-next-line declaration-no-important
            border-bottom: solid 1px $body-color !important;

            &:last-of-type {
                // stylelint-disable-next-line declaration-no-important
                border-bottom: 0 !important;
            }
        }

        &__content {
            // stylelint-disable-next-line declaration-no-important
            display: flex !important;
            flex-shrink: 0;
            align-items: center;
            width: 100%;
            margin: 0;
            // stylelint-disable-next-line declaration-no-important
            padding: 7px 10px !important;
            border: 0;
            outline: none;
            text-decoration: none;
            transition: background 0.1s ease-in-out;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        &__content-item {
            display: inline-block;
            flex: 0 1 auto;
            margin: 0 10px 0 0;
            text-transform: none;
        }

        &__content-image {
            display: flex;
            flex: 0 0 30px;
            align-items: center;
            min-height: 30px;
            // stylelint-disable-next-line declaration-no-important
            text-decoration: none !important;

            &-img {
                width: 30px;
                height: 30px;
                object-fit: contain;
            }
        }

        &__content-title {
            flex: 4;

            &--text-center {
                text-align: center;
            }
        }

        &__content-type {
            display: inline-block;
            flex: 0 0 auto;
            margin: 4px 0 4px 10px;
            padding: 5px 10px;
            background: #ffffff;
            border: 1px solid #e5e8eb;
            text-align: center;

            @include breakpoint(small down) {
                margin-left: 0;
            }
        }

        &__item--show-all {
            // stylelint-disable-next-line declaration-no-important
            border: 0 !important;
        }
    }

    @content;
}
