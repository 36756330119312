.form-error,
.form-success {
    display: block;
    font-weight: normal;
    color: $color-error;
    font-size: $font-size-200;
    clear: both;

    ul, li {
        list-style: none;
        padding-left: 0;
        margin-left: 0;
    }
}

.form-success {
    color: $color-positive;
    float: left;
    margin: -10px 0 10px 0;
}

label.has-error {
    + .form-error {
        display: none;
    }
}
