/* ==========================================================================
    #SPACING-VARIABLES
    Sizes for margin, padding and "optical" distances between components
    and modules.
   ========================================================================== */
@use 'sass:math';

$spacing-xs: 5px;
$spacing-sm: 10px;
$spacing-md: 20px;
$spacing-lg: 40px;
$spacing-xl: 80px;
$spacing-xxl: 160px;

// deprecated: Please use $spacing-* instead.
$sp-base-unit: 10px;
$sp-factor: math.div(16px, $sp-base-unit);
$sp-020: round($sp-base-unit * $sp-factor * 0.125);   // 2px
$sp-040: round($sp-base-unit * $sp-factor * 0.25);    // 4px
$sp-080: round($sp-base-unit * $sp-factor * 0.5);     // 8px
$sp-160: round($sp-base-unit * $sp-factor * 1);       // 16px
$sp-200: round($sp-base-unit * $sp-factor * 1.25);    // 20px
$sp-240: round($sp-base-unit * $sp-factor * 1.5);     // 24px
$sp-320: round($sp-base-unit * $sp-factor * 2);       // 32px
$sp-400: round($sp-base-unit * $sp-factor * 2.5);     // 40px
$sp-640: round($sp-base-unit * $sp-factor * 4);       // 64px
$sp-800: round($sp-base-unit * $sp-factor * 5);       // 80px
$sp-960: round($sp-base-unit * $sp-factor * 6);       // 96px
$sp-1000: round($sp-base-unit * $sp-factor * 6.25);   // 100px
