@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-header-main-navigation($name: '.header-main-navigation') {

    #{$name} {
        display: block;
    }
}

@include shop-ui-header-main-navigation;
