@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-account-order($name: '.account-order') {

    #{$name} {
        &__list {
            margin-top: $sp-240;

            @include breakpoint(large) {
                margin-top: $sp-400;
            }

            &:first-of-type {
                margin-top: 0;
                border-top: none;
            }

            &--shadow + #{$name}__list--border {
                margin-top: 0;
                border-top: none;
            }

            &--border {
                border-top: 2px solid $color-gray-80;
            }
        }

        &__info-panel {
            margin-bottom: $spacing-md;

            @include breakpoint(medium) {
                margin-bottom: $spacing-lg;
            }
        }

        &__buttons {
            padding-bottom: $spacing-md;
        }

        &__button {
            width: 100%;
            margin-bottom: $spacing-md;

            @include breakpoint(medium) {
                width: auto;

                &:not(:last-child) {
                    margin-right: $spacing-md;
                }
            }
        }
    }
}
