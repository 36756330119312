@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-banner-message($name: '.banner-message') {
    $breakpoint: medium;
    $font-white: '#{$name}--font-white';
    $text-mobile: '#{$name}--text-mobile';

    #{$name} {
        display: flex;
        position: relative;
        justify-content: center;
        min-height: $sp-320;
        padding: 0 $sp-320;
        background-color: $color-accent;
        color: $color-gray-10;

        &__content {
            display: flex;
            flex: 0 1 100%;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            min-width: 0;
            color: $color-gray-10;
            text-decoration: none;
            cursor: default;

            #{$font-white} & {
                color: $color-white;
            }
        }

        &__link {
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                background-color: $color-black;
                outline: 0;
                transition: opacity 0.3s ease-in-out;
                -webkit-tap-highlight-color: transparent;

                #{$font-white} & {
                    background-color: $color-white;
                }
            }

            &:hover::after {
                opacity: 0.1;
            }
        }

        &__text,
        &__text-mobile {
            @include font-text-medium;

            min-width: 0;
            padding: 5px 0;

            @include breakpoint($breakpoint) {
                padding: 0;
            }

            #{$font-white} & {
                color: $color-white;
            }
        }

        &__text {
            #{$text-mobile} & {
                display: none;

                @include breakpoint($breakpoint) {
                    display: block;
                }
            }
        }

        &__text-mobile {
            #{$text-mobile} & {
                display: block;

                @include breakpoint($breakpoint) {
                    display: none;
                }
            }
        }

        &__icon-left {
            flex: 0 0 $sp-160;
            margin-right: $sp-080;
            color: $color-gray-10;

            #{$font-white} & {
                color: $color-white;
            }
        }

        &__icon {
            flex: 0 0 $sp-160;
            margin: $sp-020 0 0 $sp-080;
            color: $color-gray-10;

            #{$font-white} & {
                color: $color-white;
            }
        }

        &__icon-close {
            position: absolute;
            box-sizing: content-box;
            top: 0;
            right: 0;
            padding: $sp-080;
            color: $color-gray-10;
            cursor: pointer;

            #{$font-white} & {
                color: $color-white;
            }
        }
    }
}
