.checkbox {

    position: relative;
    overflow: hidden;
    padding-left: 35px;
    line-height: 25px;
    cursor: pointer;
    margin: 0 0 20px 0;

    &--box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border: 1px solid $color-gray-80;
        position: absolute;
        left: 0;
        background: $color-white;
        color: transparent;

        &--transparent {
            background: transparent;
        }

        &--disabled {
            background-color: $color-gray-20;
        }
    }

    input[type="checkbox"] {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        left: 0;
        z-index: 1;
        top: 0;
        cursor: pointer;

        &:checked + .checkbox--box {
            color: $color-positive;
        }

        &:checked + .checkbox--box--dark {
            color: $color-gray-20;
        }

    }

    &--boxed {
        background: $color-white;
        height: 40px;
        padding-left: 45px;
        display: flex;
        align-items: center;

        .checkbox--box {
            left: 0px;
            top: 8px;
        }
    }

    &--background {
        align-items: center;
        margin-bottom: 0px;
        padding: 10px 10px 10px $sp-400;
        background-color: $color-gray-90;
        border: 1px solid $color-gray-90;
        border-radius: $global-border-radius;
        font-size: 14px;
        line-height: 20px;
        color: $color-gray-10;

        &:checked .checkbox--box {
            color: $color-white;
            background: $color-gray-10;
            border: 2px solid $color-black;
        }

        .checkbox--box {
            left: 10px;
            top: 25%;
        }

        input[type="checkbox"] {

            &:checked + .checkbox--box {
                color: $color-white;
                background: $color-gray-10;
                padding: 2px;
            }
        }
    }

    &--order-items {
        text-transform: uppercase;
        font-size: $font-size-400;
        margin: 0;

        @include breakpoint(small down) {
            margin-bottom: 20px;
        }
    }
}
