@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-header-navigation-bikeleasing($name: '.header-navigation-bikeleasing') {

    #{$name} {
        display: block;
        list-style: none;
        margin: 0;

        &__link {
            @include font-text-small;

            height: 30px;
            padding: 10px $sp-400 0 0;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@include shop-ui-header-navigation-bikeleasing;
