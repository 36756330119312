@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-bike-benefits($name: '.bike-benefits') {
    $block: '#{$name}--block';
    $bike-benefits-border: 1px solid #dedede;

    #{$name} {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        &__headline-wrapper {
            width: 100%;
        }

        &__list-wrapper {
            overflow-x: hidden;
        }

        &__list {
            display: flex;
            list-style: none;
            flex-wrap: wrap;
            margin: 0 -1px;
            padding: 0;
            border-top: $bike-benefits-border;
            border-left: $bike-benefits-border;
            border-radius: $global-border-radius;

            #{$block} & {
                margin: 0 -1px;

                @include breakpoint(large up) {
                    margin: 0;
                }
            }
        }

        &__item {
            flex: 1 50%;
            align-items: center;
            justify-content: center;
            padding: 25px 20px;
            border-bottom: $bike-benefits-border;
            border-right: $bike-benefits-border;
            border-radius: $global-border-radius;
            text-align: center;

            @include breakpoint(large up) {
                flex: 1;
            }

            &::after {
                content: '';
                display: table;
                clear: both;
                height: 20px;
            }
        }

        &__item-image {
            display: flex;
            width: 100%;
            max-width: 100px;
            height: 100%;
            min-height: 41px;
            max-height: 41px;
            margin: 0 auto;

            @include breakpoint(large up) {
                max-height: 60px;
            }
        }

        &__item-icon {
            margin: 0 auto;
        }

        &__item-headline {
            @include font-text-medium;

            margin: 30px 0 10px;

            @include breakpoint(mediumlarge) {
                margin: 30px 0 15px;
            }
        }

        &__item-text {
            @include font-text-small;

            margin: 0;
        }
    }
}

@include shop-ui-bike-benefits;
