svg.icon {
  background-color: transparent;
  fill: currentcolor;
  max-height: 100%;
  vertical-align: middle;
}

.is-block {
  display: block;
}

.is-inline-block {
    display: inline-block;
}

.no-margin-top {
  margin-top: 0;
}

.m-t-10 {  margin-top: 10px !important; }
.m-t-20 {  margin-top: 20px; }
.m-t-30 {  margin-top: 30px; }
.m-t-40 {  margin-top: 40px; }
.m-t-50 {  margin-top: 50px; }
.m-t-60 {  margin-top: 60px; }
.m-t-65 {  margin-top: 65px; }
.m-t-100 {  margin-top: 100px; }

.m-r-10 {  margin-right: 10px !important; }
.m-r-20 {  margin-right: 20px !important; }
.m-r-30 {  margin-right: 30px !important; }

.m-b-10 {  margin-bottom: 10px; }
.m-b-20 {  margin-bottom: 20px; }
.m-b-100 {  margin-bottom: 100px; }
.m-b-40 {  margin-bottom: 40px; }

.p-t-0 {  padding-top: 0 !important; }
.p-t-40 {  padding-top: 40px !important; }

.p-r-0 {  padding-right: 0 !important; }
.p-r-20 {  padding-right: 20px !important; }
.p-r-40 {  padding-right: 40px; }

.p-l-0 {  padding-left: 0 !important; }

.p-b-0 {  padding-bottom: 0 !important; }
.p-b-40 {  padding-bottom: 40px !important; }

.text-decoration-none {
  text-decoration: none;
}

.m-t-40--large {
  @include breakpoint(large up) {
    margin-top: 40px;
  }
}

.p-r-0--large {
  @include breakpoint(large up) {
    padding-right: 0 !important;
  }
}

.nowrap {
  white-space: nowrap;
}

.pointer-events-none {
  pointer-events: none;
}

.round-borders {
  border-radius: 50%;
}