dl {

  &.table {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    overflow: visible;

    dt,
    dd {
      padding: 8px 10px;
    }

    dt {
      @include font-din-regular;
      @include font-size-medium;
      flex: 0 0 20%;
      max-width: 20%; //IE Fix
      overflow: hidden;
      text-overflow: ellipsis;
    }

    dd {
      flex: 0 0 80%;
      max-width: 80%; //IE Fix
      margin-left: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $color-gray-20;
    }

    @include breakpoint(mediumsmall down) {
      flex-flow: column;
      dt,
      dd {
        max-width: none;
        flex: 0 0 100%;
        margin: 0;
      }

      dd:nth-of-type(odd),
      dd:nth-of-type(even) {
        background: none;
      }
    }

  }

  &.inline {

    dt {
      float: left;
      margin-right: 5px;
    }

    dd {
      margin: 0;

      &:after {
        content: '';
        display: table;
        clear: both;
      }

    }

  }

}
