@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-footer-reduced($name: '.footer-reduced') {

    #{$name} {
        display: block;
        padding: $sp-400 14px;
        background: $color-gray-10;
        color: $color-white;

        a {
            color: inherit;
        }

        p {
            margin: 0 0 15px;
        }

        &__title {
            @include font-din-bold;

            margin: 0;
            font-size: 20px;

            @include breakpoint(large up) {
                font-size: $font-size-500;
            }
        }

        &__phone {
            @include font-din-regular;

            margin-top: 15px;
            margin-bottom: 5px;

            @include breakpoint(medium up) {
                margin-top: 0;
                font-size: 16px;
                line-height: 20px;
            }

            .icon {
                margin-left: 5px;
            }

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &__phone-title {
            display: block;

            @include breakpoint(mediumlarge up) {
                display: inline-block;
            }
        }

        &__openinghours {
            margin-top: 15px;
            font-size: $font-size-300;

            @include breakpoint(mediumlarge up) {
                margin-top: 0;
            }
        }

        &__openinghours-item {
            display: block;

            @include breakpoint(mediumlarge up) {
                display: inline-block;
            }

            &+#{$name}__openinghours-item {

                @include breakpoint(mediumlarge up) {

                    &::before {
                        content: ' / ';
                    }
                }
            }
        }

        &__backlink {
            margin-bottom: 0;

            @include breakpoint(small only) {
                text-align: left;
            }
        }
    }
}

@include shop-ui-footer-reduced;
