/*
    This CSS resource incorporates links to font software which is the valuable copyrighted
    property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
    redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
    Imaging with any questions regarding Web Fonts:  http://www.monotype.com/
*/

@font-face {
    font-family: 'DINNextW05-Light';
    src: url('../fonts/DINNextW05-Light.woff2') format('woff2'),
        url('../fonts/DINNextW05-Light.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'DINNextW05-Regular';
    src: url('../fonts/DINNextW05-Regular.woff2') format('woff2'),
        url('../fonts/DINNextW05-Regular.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'DINNextW05-Medium';
    src: url('../fonts/DINNextW05-Medium.woff2') format('woff2'),
        url('../fonts/DINNextW05-Medium.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'DINNextW05-Bold';
    src: url('../fonts/DINNextW05-Bold.woff2') format('woff2'),
        url('../fonts/DINNextW05-Bold.woff') format('woff');
    font-display: swap;
}

.font-light {
    @include font-din-light;
}

.font-regular {
    @include font-din-regular;
}

.font-medium {
    @include font-din-medium;
}

.font-bold {
    @include font-din-bold;
}

.headline--secondary {
    @include font-din-regular;

    margin: 0 0 0 5px;
    font-size: $font-size-300;
    color: $color-gray-20;
    text-transform: none;
}

.headline--action {
    position: relative;

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        padding-right: 25px;

        &.is-full-underlined {

            &::after {
                width: calc(100% + 25px);
            }
        }
    }

    &__link {
        position: absolute;
        bottom: 3px;
        right: 0;
    }
}
