.address {
    &__address-list {
        padding-bottom: $sp-240;

        &:last-child {
            padding-bottom: $sp-800;
        }

        @include breakpoint(large) {
            padding-bottom: $sp-400;
        }
    }

    &__address-list-item {
        margin-bottom: $sp-320;

        &:last-child {
            margin-bottom: 0;
        }

        @include breakpoint(large) {
            margin-bottom: $sp-200;

            & .action-panel-form__content {
                min-height: 144px;
            }
        }
    }

    &__btn-create-shipping-address {
        margin: $sp-160 0;
    }

    &__shipping-addresses-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__dialog-action-panel {
        margin-top: $sp-240;
    }

    &__shipping-subform {
        min-inline-size: unset;
    }

    &__invisible {
        display: none;
    }

    &__logo {
        max-width: 120px;
        float: right;
        margin: 0 0 $spacing-sm $spacing-sm;

        @include clearfix;
    }

    &__title {
        @include font-din-bold;
        display: block;
        margin: $sp-640 $sp-160 $sp-200 0px;
        font-size: 24px;
        color: $color-gray-10;
        letter-spacing: -0.1px;
        line-height: 28px;
        /* stylelint-disable-next-line declaration-no-important */
        text-transform: none !important;
    }

    &__link {
        @include font-din-regular;
        color: $color-gray-10;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: $spacing-md;

        &--radio {
            display: block;
            margin-top: -$spacing-sm;
            margin-bottom: $spacing-md;
        }
    }

    &__submit {
        margin-top: 0px;
        margin-bottom: $sp-1000;

        @include breakpoint(small only) {
            width: 100%;
            /* stylelint-disable-next-line declaration-no-important */
            text-transform: none !important;
        }
    }

    &__select,
    &__select:focus {
        border: none;
        border-bottom: 1px solid $color-gray-50;
        box-shadow: none;
        padding-left: 0;
        margin-bottom: $sp-320;
    }

    &__shipping-type-selection {
        /* stylelint-disable-next-line declaration-no-important */
        margin-bottom: $spacing-md !important;
    }
}
