@import '~ShopUiBundle/styles/shared';
@import '~SprykerCatalogBundle/components/molecules/catalog-category-model/catalog-category-model';

@mixin cms-bundle-product-box($name: '.product-box') {
    @include catalog-bundle-catalog-category-model($name);

    #{$name} {
        &__link {
            justify-content: flex-start;
            padding: $sp-160;

            @include breakpoint(mediumlarge up) {
                padding: $sp-240;
            }
        }

        &__header {
            margin: (-$sp-160) (-$sp-160) $sp-160;
            padding: 0;
            background: $color-gray-90;

            @include breakpoint(mediumlarge up) {
                margin: (-$sp-240) (-$sp-240) $sp-240;
            }
        }

        &__header-img {
            width: 100%;
            height: auto;
        }

        &__title {
            @include font-head-small;

            margin: 0 0 $spacing-sm 0;

            padding: 0;
            font-size: 24px;
            line-height: 24px;
        }

        &__price {
            min-height: 0;
            padding: 0;
        }

        &__info {
            display: block;
            padding: 0;

            @include breakpoint(mediumlarge up) {
                padding: 0;
            }
        }

        &__content {
            @include font-din-regular;

            display: block;
            margin-top: $spacing-sm;

            > ul {
                display: flex;
                list-style: none;
                flex-direction: column;
                margin: -$spacing-xs 0 0;
                padding: 0;
            }

            > ul > li {
                @include font-text-small;

                margin: $spacing-xs 0 0;
                padding: 0;
            }

            > ul > li > b:first-child {
                display: inline-block;
            }

            > p {
                margin: 0;
            }
        }

        &__bottom {
            margin-top: auto;
            padding: 0;
        }

        &__button {
            margin-top: $sp-320;
        }
    }
}
