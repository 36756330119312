@import '~ShopUiBundle/styles/shared';

@mixin product-bundle-product-tile-price($name: '.product-tile-price') {
    $new-price: '#{$name}--new-price';
    $multiline: '#{$name}--multiline';
    $no-scaling: '#{$name}--no-scaling';

    #{$name} {
        display: flex;
        position: relative;

        &__container {
            display: block;
            width: 100%;
        }

        &__link {
            display: block;
            color: inherit;
            text-decoration: none;
        }

        &__current {
            @include font-text-medium((
                'color': $color-gray-20,
            ));

            display: block;
            margin-right: $spacing-xs;

            #{$new-price} & {
                color: $color-sale;
            }

            #{$multiline} & {
                @include font-text-xlarge((
                    'color': $color-gray-20,
                ));
            }

            #{$no-scaling} & {
                @include font-size-medium;
            }
        }

        &__old {
            @include font-text-small((
                'color': $color-gray-50,
            ));

            display: block;

            #{$no-scaling} & {
                @include font-size-small;
            }
        }

        &__monthly,
        &__base {
            @include font-text-small((
                'color': $color-gray-20,
            ));

            display: block;

            #{$no-scaling} & {
                @include font-size-xsmall;
            }
        }

        &__leasing {
            @include font-text-small((
                'color': $color-gray-20,
            ));

            display: block;
            margin-top: 2px;
        }

        &__gp30 {
            @include font-text-xsmall((
                'color': $color-gray-20,
            ));

            display: block;
            margin-top: 4px;
        }
    }
}
