@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-modal-dialog($name: '.modal-dialog') {
    $open: '#{$name}--open';
    $closed: '#{$name}--closed';
    $red-icon: '#{$name}--red-icon';
    $box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.13);

    #{$name} {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        z-index: 100;
        background-color: rgba($color: $color-gray-10, $alpha: 0.6);
        transition: opacity 0.4s ease;

        &#{$closed} {
            display: none;
        }

        &#{$open} {
            opacity: 1;
        }

        &__content {
            @include font-din-regular;

            display: flex;
            position: absolute;
            flex-direction: column;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: $sp-240;
            background-color: $color-white;
            border-radius: $global-border-radius $global-border-radius 0 0;
            font-size: 14px;
            text-align: center;
            box-shadow: $box-shadow;
            transform: translate(0, 100%);
            transition: transform 0.3s ease;

            @include breakpoint(medium) {
                bottom: 50%;
                left: 50%;
                width: 480px;
                padding: $sp-400;
                border-radius: $global-border-radius;
                font-size: 16px;
                transform: translate(-50%, 50%);
                transition: none;
            }

            #{$open} & {
                transform: translate(0, 0);

                @include breakpoint(medium) {
                    transform: translate(-50%, 50%);
                }
            }
        }

        &__header {
            color: $color-gray-10;
        }

        &__icon {
            box-sizing: content-box;
            width: auto;
            height: 64px;
            padding-bottom: $sp-160;

            #{$red-icon} & {
                color: $color-negative-50;
            }
        }

        &__title {
            @include font-din-bold;

            padding-bottom: $sp-080;

            @include breakpoint(medium) {
                padding-bottom: $sp-200;
            }
        }

        &__body {
            color: $color-gray-20;
            line-height: 20px;
        }

        &__footer {
            display: flex;
            padding-top: $sp-320;
        }

        &__button {
            flex-grow: 1;

            &:not(:last-child) {
                margin-right: $sp-240;
            }
        }
    }
}
