@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-shop-footer($name: '.shop-footer') {
    $no-margin: #{$name}--no-margin;

    #{$name} {
        margin-top: auto;
        padding-top: $sp-040;
        overflow-x: hidden;

        &#{$no-margin} {
            padding-top: 0;
        }

        &__group {
            display: block;
            margin-top: 50px;
            padding-top: 60px;
            padding-bottom: 60px;
            background-color: $color-black;

            #{$no-margin} & {
                margin-top: 0;
            }

            &--bg-white {
                background-color: $color-white;
            }
        }

        &__row {
            display: flex;
            flex-wrap: wrap;

            &::before,
            &::after {
                display: none; /* safari-bug-fix */
            }
        }

        &__row .columns {
            margin-bottom: 30px;

            @include breakpoint(medium up) {
                margin-bottom: 20px;
            }

            @include breakpoint(medium down) {
                padding: 0 15px;
            }
        }

        &__columns--support,
        &__columns--alternative-info {
            order: 1;
        }

        &__columns--rose {
            order: 2;

            @include breakpoint(mediumsmall down) {
                margin-bottom: 30px;
            }
        }

        &__columns--payment {
            order: 3;

            @include breakpoint(mediumlarge) {
                order: 4;
                margin-left: auto;
            }
        }

        &__columns--shipment {
            order: 4;

            @include breakpoint(large) {
                order: 6;
                margin-left: auto;
            }
        }

        &__columns--legal {
            order: 6;

            @include breakpoint(mediumlarge) {
                order: 3;
            }
        }

        &__columns--certification {
            order: 5;

            @include breakpoint(large) {
                order: 4;
            }
        }

        &__columns--social {
            order: 7;

            @include breakpoint(large) {
                order: 5;
            }
        }

        &__columns--notes {
            order: 8;
        }

        .list-payment-icons,
        .list-shipment-icons {
            &__list {
                display: flex;
                position: relative;
                flex-flow: wrap;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 5px;
            }

            &__list-item {
                display: inline-flex;
                align-items: center;
                width: calc(25% - 5px);
                min-width: 90px;
                height: 45px;
                margin-bottom: 5px;
                margin-right: 5px;
                padding: 0;
                background-color: $color-gray-20;
                border-radius: 5px;
                transition: ease 0.1s opacity;

                &:hover {
                    opacity: 0.8;
                }

                &-icon {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    max-height: 100%;
                }

                &-link {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    padding: 0;
                }

                &-img {
                    width: 100%;
                    height: 100%;
                }

                svg {
                    width: 100%;
                    height: 100%;
                    color: $color-gray-70;
                }
            }
        }

        &__list-legal {
            margin-bottom: 0;
        }

        &__bottom-logo {
            background-color: $color-black;
        }

        &__bottom-logo-row {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            padding: $sp-240 0;
            border-top: 1px solid $color-gray-20;
        }

        &__bottom-logo-image {
            flex-shrink: 0;
            box-sizing: content-box;
            width: 69px;
            height: 69px;
            padding-left: 10px;
        }

        &__bottom-logo-text {
            @include font-text-small;

            padding-left: $sp-160;
            color: $color-white;
        }
    }
}

@include shop-ui-shop-footer;
