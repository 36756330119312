@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-range-slider-thumb {
    position: relative;
    width: 26px;
    height: 26px;
    background: $color-white;
    border: 2px solid $color-gray-10;
    border-radius: 50%;
    cursor: pointer;
    appearance: none;
}

@mixin shop-ui-range-slider-track-reset {
    background-color: transparent;
    border: 0;
    appearance: none;
}

@mixin shop-ui-range-slider($name: '.range-slider') {
    $color-inverted: '#{$name}--style-inverted';

    #{$name} {
        display: inline-block;
        position: relative;
        height: 26px;

        &__input {
            width: 100%;
            background: transparent;
            border-radius: 5px;
            outline: none;
            transition: opacity 0.2s;
            appearance: none;

            &::-webkit-slider-thumb {
                @include shop-ui-range-slider-thumb;
            }

            &::-moz-range-thumb {
                @include shop-ui-range-slider-thumb;
            }

            &::-ms-thumb {
                @include shop-ui-range-slider-thumb;
            }

            &::-webkit-slider-runnable-track {
                @include shop-ui-range-slider-track-reset;
            }

            &::-moz-range-track {
                @include shop-ui-range-slider-track-reset;
            }

            &::-ms-track {
                @include shop-ui-range-slider-track-reset;
            }
        }

        &__progress {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 0;
            width: 0%;
            height: 4px;
            background-color: black;
            border-radius: 15px;
            transform: translateY(-50%);

            #{$color-inverted} & {
                background-color: $color-accent;
            }
        }

        &__track {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #ededed;
            border-radius: 15px;
            transform: translateY(-50%);

            #{$color-inverted} & {
                background-color: $color-gray-20;
            }
        }
    }
}

@include shop-ui-range-slider;
