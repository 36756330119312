@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-card-slider($name: '.card-slider') {
    #{$name} {
        display: block;

        &__slides-wrapper {
            box-sizing: border-box;
        }

        &__navigation-button {
            // stylelint-disable-next-line declaration-no-important
            display: none !important;

            @include breakpoint(large) {
                // stylelint-disable-next-line declaration-no-important
                display: flex !important;
                align-items: center;
                justify-content: center;
                // stylelint-disable-next-line declaration-no-important
                width: 80px !important;
                // stylelint-disable-next-line declaration-no-important
                height: 80px !important;
                // stylelint-disable-next-line declaration-no-important
                background-color: transparent !important;
                border-radius: 50%;
                // stylelint-disable-next-line declaration-no-important
                outline: none !important;
                transition: color 0.2s ease, background-color ease 0.2s;

                &::before {
                    content: '';
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    z-index: -1;
                    background-color: $color-gray-10;
                    border-radius: 50%;
                    transition: background-color ease 0.2s;
                }

                &:hover {
                    color: $color-gray-20;
                }

                &:hover::before {
                    background-color: $color-gray-20;
                }
            }
        }
    }
}
