@import '~ShopUiBundle/styles/shared';
@import '~ShopUiBundle/components/atoms/form-input-abstract/form-input-abstract';

@mixin shop-ui-form-input($name: '.form-input') {
    $disabled: '#{$name}--disabled';
    $empty: '#{$name}--empty';
    $empty-disabled: '#{$name}--empty-disabled';
    $error: '#{$name}--error';
    $show-valid: '#{$name}--show-valid';
    $valid: '#{$name}--valid';
    $focus: '#{$name}--focus';
    $show: '#{$name}--show';
    $password: '#{$name}--password';
    $search: '#{$name}--search';
    $empty-actived: '#{#{$name}--empty}:not(#{$focus}):not(#{$empty-disabled})';
    $transition: all 0.1s ease;

    #{$name} {
        @include shop-ui-form-make-input($name);

        &__title {
            display: block;
            position: relative;
            height: 18px;
            margin-bottom: 4px;
            color: inherit;

            &-text {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 18px;
                overflow: hidden;
                font-size: 12px;
                color: $color-gray-50;
                line-height: 18px;
                white-space: nowrap;
                text-overflow: ellipsis;
                transition: $transition;

                #{$focus} & {
                    color: $color-gray-10;
                }

                #{$empty-actived} & {
                    top: calc(100% + 10px);
                    height: 20px;
                    font-size: 16px;
                    line-height: 16px;
                }

                #{$error}#{$empty-actived} &,
                #{$password}#{$empty-actived} &,
                #{$search}#{$empty-actived} & {
                    right: 20px;
                }

                #{$error}#{$password}#{$empty-actived} &,
                #{$error}#{$search}#{$empty-actived} & {
                    right: 44px;
                }

                #{$disabled} & {
                    // stylelint-disable-next-line declaration-no-important
                    color: $color-gray-50 !important;
                }
            }
        }

        &__wrapper {
            display: flex;
            position: relative;
            align-items: center;
            height: 24px;

            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: -1px;
                left: 50%;
                width: 100%;
                border-bottom: 1px solid $color-gray-50;
                transform: translateX(-50%);

                #{$error} & {
                    border-color: $color-error;
                }
            }

            &::after {
                width: 0%;
                border-color: $color-gray-10;
                transition: $transition;

                #{$focus} & {
                    width: 100%;
                }
            }
        }

        &__input {
            // Avoid specificity problems with _input.scss
            // stylelint-disable declaration-no-important
            @include font-din-regular;

            display: block !important;
            flex-basis: 99%;
            height: 20px !important;
            margin: 0 !important;
            padding: 0 !important;
            background-color: transparent !important;
            border: 0 !important;

            // Force autofill detection
            border-radius: 0;
            font-size: 16px !important;
            color: $color-gray-10 !important;
            line-height: 16px !important;
            box-shadow: none !important;
            transition: $transition !important;

            &:-webkit-autofill {
                background-color: transparent !important;

                // Force autofill detection
                border-radius: 1px;
                color: $color-gray-10 !important;
            }

            #{$disabled} & {
                color: $color-gray-50 !important;
            }
            // stylelint-enable declaration-no-important
        }

        &__icons {
            display: flex;
            position: relative;
            list-style: none;
            flex-basis: 1%;
            margin: 0;
            padding: 0;

            &-item {
                padding: 4px 0 4px 8px;

                &:first-child {
                    padding-left: 4px;
                }

                &--valid {
                    display: none;

                    #{$show-valid}#{$valid} & {
                        display: block;
                    }
                }

                &--error {
                    display: none;

                    #{$error} & {
                        display: block;
                    }
                }
            }
        }

        &__icon {
            display: block;
            position: relative;
            width: 16px;
            height: 16px;

            &--valid {
                color: $color-green-600;
            }

            &--error {
                color: $color-error;
            }
        }

        &__password {
            display: block;
            color: inherit;
            text-decoration: none;
            transition: $transition;

            &-show {
                display: block;

                #{$show} & {
                    display: none;
                }

                #{$disabled} & {
                    // stylelint-disable-next-line declaration-no-important
                    color: $color-gray-50 !important;
                }
            }

            &-hide {
                display: none;

                #{$show} & {
                    display: block;
                }

                #{$disabled} & {
                    // stylelint-disable-next-line declaration-no-important
                    color: $color-gray-50 !important;
                }
            }
        }
    }

    @content;
}
