input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="tel"] {

    border: 1px solid $input-border-color;
    height: 40px;
    color: $color-gray-10;
    box-shadow: none;
    margin: 5px 0 20px 0;
    font-size: 16px;
    appearance: none;
    -webkit-appearance: none;

    &:focus {
        border: 1px solid $input-border-color;
        box-shadow: none;
    }

    &.error {
        border: 1px solid $color-error;
        background-position: right 10px center;
        background-size: 20px 20px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 24' fill='%23#{str-slice(($color-error + unquote("")), 2)}'%3E%3Crect y='18' width='6' height='6'/%3E%3Crect width='6' height='15'/%3E%3C/svg%3E");
        background-repeat: no-repeat;

        &::-ms-clear,
        &::-ms-reveal {
            display: none;
            width: 0;
            height: 0;
        }
    }

    &.valid {
        background-position: right 10px center;
        background-size: 20px 20px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.59 18.83' fill='%23#{str-slice(($color-positive + unquote("")), 2)}'%3E%3Cpolygon points='25.59 3.15 22.45 0 9.9 12.54 3.15 5.79 0 8.93 9.89 18.83 9.9 18.82 9.91 18.83 25.59 3.15'/%3E%3C/svg%3E");
        background-repeat: no-repeat;

        &::-ms-clear,
        &::-ms-reveal {
            display: none;
            width: 0;
            height: 0;
        }
    }

    &.is-loading {
        background-image: url('../img/loading.svg');
        background-size: 20px 20px;
        background-position: 97% center;
        background-repeat: no-repeat;

        &::-ms-clear,
        &::-ms-reveal {
            display: none;
            width: 0;
            height: 0;
        }
    }
}

input[type="number"] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        appearance: none;
    }
}

@mixin payment-field-errors {
    margin: -10px 0 20px 0;
    padding-left: 0;

    li {
        list-style: none;
        color: $color-error;
        padding-left: 0;
        margin-left: 0;
    }
}

// input errors within the payment process
input[name*="payment_shipment"] {
    + ul {
       @include payment-field-errors();
    }
}
ul.field-error-list {
    @include payment-field-errors();
}

select {
    cursor: pointer;
    color: $color-gray-10;
    font-size: 16px;
    border: 1px solid $input-border-color;
    margin: 5px 0 20px 0;
    font-size: 16px;
    background-size: 14px 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6' fill='%23#{str-slice(($text-color + unquote("")), 2)}'%3E%3Cpolygon points='5.02 4.3 0.88 0.16 0.03 1.01 5.02 6 10.01 1.01 9.16 0.16 5.02 4.3'/%3E%3C/svg%3E");
    -moz-appearance: none;
}

textarea {
    border: 1px solid $input-border-color;
    color: $text-color;
    box-shadow: none;
    margin: 5px 0 20px 0;
    font-size: 16px;
    resize: vertical;

    &.error {
        border-color: $color-error;
    }
}

#productRatingForm_comment {
    + .form-error {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 45 40' x='0px' y='0px' width='45px' height='40px' enable-background='new 0 0 45 40'%3E%3Cpath fill='%23#{str-slice(($color-error + unquote("")), 2)}' d='M19.641,1.65L0.445,35.048C-0.82,37.25,0.761,40,3.29,40h38.394c2.528,0,4.109-2.751,2.846-4.951L25.332,1.65 C24.068-0.55,20.906-0.55,19.641,1.65'/%3E%3Crect fill='%23#{str-slice(($color-white + unquote("")), 2)}' x='21.487' y='27.5' width='2' height='2'/%3E%3Crect fill='%23#{str-slice(($color-white + unquote("")), 2)}' x='21.487' y='16.5' width='2' height='9'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 25px;
        background-position: 0 100%;
        padding: 10px 0 0 35px;
    }
}
