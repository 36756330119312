@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-basic-keyfeatures($name: '.basic-keyfeatures') {
    $lax-ease: transform 0.1s ease-out;
    $lax-ease-large: transform 0.2s ease-out;
    $light: '#{$name}--light';

    #{$name} {
        display: block;
        position: relative;
        width: 100%;

        &__wrapper {
            position: relative;
            padding: 50px 0;

            @include breakpoint(medium) {
                padding: 200px 0 100px;
            }
        }

        &__row {
            @include breakpoint(large down) {
                justify-content: flex-end;
            }
        }

        &__content {
            position: relative;
            margin-bottom: 40px;
            z-index: 1;
            vertical-align: top;
            transition: $lax-ease;

            @include breakpoint(large up) {
                margin-bottom: 0;
                transition: $lax-ease-large;
            }
        }

        &__vertical {
            @include font-text-medium;

            display: block;
            padding-left: $row-padding-mobile-200;

            @include breakpoint(medium) {
                padding-left: $grid-column-gutter;
            }

            #{$light} & {
                color: $color-white;
            }
        }

        &__headline {
            @include font-display-large;

            display: inline-block;
            margin: 0;
            padding: 0 50px 0 $row-padding-mobile-200;
            vertical-align: top;

            @include breakpoint(medium up) {
                padding: 0 40px 0 $grid-column-gutter;
            }

            #{$light} & {
                color: $color-white;
            }
        }

        &__text {
            @include font-text-large;

            padding-right: $grid-column-gutter * 0.5;

            #{$light} & {
                color: $color-white;
            }
        }

        &__picture {
            display: block;
            transition: $lax-ease;

            @include breakpoint(large up) {
                transition: $lax-ease-large;
            }
        }

        &__picture-wrapper {
            display: block;
            position: relative;
            overflow: hidden;
        }

        &__picture-text {
            text-align: left;
        }

        &__panorama {
            display: block;
            position: relative;
            width: 100%;

            &-img {
                width: 100%;
                height: 100%;
                margin: 0 auto 100px;
                object-fit: contain;
                object-position: center;
            }
        }
    }
}

@include shop-ui-basic-keyfeatures;
