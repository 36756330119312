@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-chatbot-help-widget($name: '.chatbot-help-widget') {
    $closed: '#{$name}--closed';
    $open: '#{$name}--open';
    $link-hover: '#{$name}__link-item:hover';
    $link-focus: '#{$name}__link-item:focus';
    $link-loading: '#{$name}__link-item--loading';
    $breakpoint: medium;
    $box-shadow: 0 8px 24px 0 rgba($color-black, 0.2);

    #{$name} {
        display: block;
        position: fixed;
        bottom: 0;
        right: 0;
        width: 100%;
        z-index: 10000;
        background-color: $color-white;
        border-radius: $global-border-radius $global-border-radius 0 0;
        box-shadow: $box-shadow;
        transform: translate(0, 100%);
        transition: transform $transition-300;

        @include breakpoint($breakpoint) {
            bottom: $sp-200;
            right: $sp-200;
            width: 460px;
            border-radius: $global-border-radius;
            transform: translate(480px, 0);
        }

        &#{$closed} {
            display: none;
        }

        &#{$open} {
            transform: translate(0, 0);
        }

        &__header {
            padding: $sp-160 $sp-240;

            @include breakpoint($breakpoint) {
                padding: $sp-200 $sp-200 $sp-160 $sp-240;
            }
        }

        &__title {
            @include font-din-bold;

            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            color: $color-gray-10;
            line-height: 20px;
        }

        &__close-button {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            color: $color-gray-10;
            cursor: pointer;

            &:focus {
                outline: none;
            }
        }

        &__arrow {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            width: 24px;
            height: 24px;
            color: $color-gray-10;

            #{$link-hover}:not(#{$link-loading}) & {
                color: $color-green-600;
            }
        }

        &__icon-loading {
            display: none;

            #{$link-loading} & {
                display: block;
            }
        }

        &__text {
            @include font-din-regular;

            padding-top: $sp-080;
            padding-right: $sp-160;
            font-size: 14px;
            color: $color-gray-20;
            line-height: 20px;

            @include breakpoint($breakpoint) {
                padding-right: $sp-320;
            }
        }

        &__link-item {
            display: flex;
            align-items: center;
            padding: $sp-160 $sp-160 $sp-160 $sp-240;
            color: $color-gray-10;
            text-decoration: none;
            cursor: pointer;

            @include breakpoint($breakpoint) {
                padding: $sp-160 $sp-200 $sp-160 $sp-240;
            }

            &#{$link-loading} {
                opacity: 0.5;
                cursor: default;
            }
        }

        &__link-icon {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            overflow: hidden;
            background-color: $color-gray-90;
            border-radius: 50%;
            box-shadow: none;
            transition: box-shadow $transition-300;

            #{$link-hover}:not(#{$link-loading}) &,
            #{$link-focus}:not(#{$link-loading}) & {
                background-color: $color-white;
                box-shadow: 0 0 0 2px $color-white, 0 0 0 4px $color-green-600;
            }
        }

        &__link-icon-item {
            display: block;

            #{$link-loading} & {
                display: none;
            }
        }

        &__link-icon-item-loading {
            display: none;

            #{$link-loading} & {
                display: block;
            }
        }

        &__link-description {
            flex-grow: 1;
            padding: 0 $sp-160;

            @include breakpoint($breakpoint) {
                padding: 0 $sp-240;
            }
        }

        &__link-title {
            @include font-din-bold;

            font-size: 14px;
            color: $color-gray-10;
            line-height: 20px;
        }

        &__link-text,
        &__link-text-loading {
            @include font-din-regular;

            padding-top: $sp-020;
            font-size: 12px;
            color: $color-gray-20;
            line-height: 16px;

            #{$link-hover}:not(#{$link-loading}) &,
            #{$link-focus}:not(#{$link-loading}) & {
                color: $color-gray-10;
            }
        }

        &__link-text {
            display: block;

            #{$link-loading} & {
                display: none;
            }
        }

        &__link-text-loading {
            display: none;

            #{$link-loading} & {
                display: block;
            }
        }
    }
}
