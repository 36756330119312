@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-title-with-content($name: '.title-with-content') {
    $style-white: #{$name}--style-white;
    $no-padding: #{$name}--no-padding;

    #{$name} {
        display: flex;
        flex-direction: column;
        align-items: center;

        &--bg-white {
            @include breakpoint(mediumsmall) {
                background-color: $color-white;
            }
        }

        &--bg-gray {
            @include breakpoint(mediumsmall) {
                background-color: $color-gray-90;
            }
        }

        &__top-section {
            margin: 0 $sp-240 $sp-240;

            @include breakpoint(mediumsmall) {
                margin: 0 0 $sp-320;
            }
        }

        &__title,
        &__title-before {
            flex: 0 0 auto;
            margin-top: 0;
            text-align: center;
        }

        &__title {
            @include font-head-medium;

            margin: 0;

            > b,
            > strong {
                @include font-head-medium;

                #{$style-white} & {
                    color: $color-white;
                }
            }

            #{$style-white} & {
                color: $color-white;
            }
        }

        &__title-before {
            @include font-text-large;

            flex: 0 0 auto;
            margin-bottom: $sp-040;

            @include breakpoint(mediumsmall) {
                margin-bottom: $sp-080;
            }

            #{$style-white} & {
                color: $color-white;
            }
        }

        &__subtitle {
            @include font-text-large;

            margin-top: $sp-040;
            margin-bottom: 0;
            text-align: center;

            @include breakpoint(mediumlarge) {
                margin-top: $sp-080;
            }

            #{$style-white} & {
                color: $color-white;
            }
        }

        &__content {
            flex: 0 0 auto;
            width: 100%;

            #{$no-padding} & {
                padding: 0;
            }

            #{$style-white} & {
                color: $color-white;
            }
        }
    }
}

@include shop-ui-title-with-content;
