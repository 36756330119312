@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-bullets-chooser($name: '.bullets-chooser') {
    $color-inverted: '#{$name}--style-inverted';

    #{$name} {
        display: inline-block;

        &__list {
            @include h-reset;

            display: inline-flex;
            list-style: none;
            flex-wrap: wrap;

            @include breakpoint(mediumsmall) {
                flex-wrap: nowrap;
            }
        }

        &__list-item {
            @include h-reset;

            display: inline-flex;
            flex: 1 1 33%;
            flex-direction: column;
            align-items: center;
            margin-bottom: $spacing-md;

            @include breakpoint(mediumsmall) {
                flex: 1;
                margin-right: 30px;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &__image {
            width: 48px;
            min-width: 48px;
            height: 48px;
            border-radius: 50%;
            object-fit: cover;
            cursor: pointer;
        }

        &__image--active {
            box-shadow: 0 0 0 2px $color-white, 0 0 0 4px $color-gray-10;

            #{$color-inverted} & {
                box-shadow: 0 0 0 2px $color-gray-20, 0 0 0 4px $color-white;
            }
        }

        &__title {
            @include h-reset;
            @include font-din-regular;

            margin-top: 15px;
            font-size: 14px;
            line-height: 18px;
            cursor: pointer;

            @include breakpoint(mediumsmall down) {
                font-size: 12px;
                line-height: 14px;
            }

            #{$color-inverted} & {
                color: $color-white;
            }
        }
    }
}

@include shop-ui-bullets-chooser;
