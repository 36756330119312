label,
legend {

    line-height: 1.45;
    font-size: $font-size-300;
    color: $color-gray-20;
    margin: 0;

    span,
    span.label__hint {
        color: $color-gray-20;
    }

    .label--info {
        display: block;
        padding: 0 14px;
        font-size: $font-size-200;
        margin: -14px 0 11px 0;

        &__success {
            color: $color-positive;
        }

        &__alert {
            color: $color-error;
        }

        &__warning {
            color: $color-green-600;
        }
    }

}
