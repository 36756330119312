@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-sticky-buybox($name: '.sticky-buybox') {
    $hide: #{$name}--hide;

    #{$name} {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 11;
        background-color: $color-white;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
        transition: transform ease 0.2s;

        &#{$hide} {
            transform: translateY(-100%);
        }

        &__content {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: flex-end;
            max-width: 75rem;
            margin: 0 0.625rem;
            padding: 2px 0;

            @include breakpoint(xlarge up) {
                margin: 0 auto;
            }

            &-logo {
                max-width: 120px;

                @include breakpoint(mediumsmall down) {
                    max-width: 75px;
                }
            }

            &-logo-wrapper {
                position: absolute;
                left: 0;
            }

            &-text {
                @include font-din-regular;

                margin-right: 15px;
                font-size: 16px;
                color: $color-gray-10;
                line-height: 36px;

                @include breakpoint(mediumsmall down) {
                    margin-right: 5px;
                    font-size: 14px;
                    line-height: 24px;
                }
            }

            &-button {
                @include font-din-bold;

                display: inline-block;
                min-width: 140px;
                margin: 0 5px;
                padding: 10px 30px;
                z-index: 9;
                background-image: linear-gradient(to left, transparent, transparent 50%, $color-black 50%, $color-black);
                background-position: 0 0;
                background-size: 200% 100%;
                border: 1px solid $color-black;
                font-size: 16px;
                color: $color-white;
                line-height: 26px;
                text-align: center;
                text-decoration: none;
                transition: all 0.2s ease-in-out;

                @include breakpoint(mediumsmall down) {
                    min-width: 84px;
                    padding: 7px 20px;
                    font-size: 14px;
                }

                &:hover {
                    background-position: 100% 0;
                    color: $color-black;
                }
            }
        }
    }
}

@include shop-ui-sticky-buybox;
