img[loading='lazy'][data-loaded] {
    &:not([data-loaded='true']) {
        display: block;
        min-height: 1px;
        opacity: 0;
    }
}

img,
iframe {
    &:not([src]) {
        visibility: hidden;
    }
}
