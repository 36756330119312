@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-main-navigation-category($name: '.main-navigation-category') {
    $highlight-color: $color-sale;
    $z-index: 50;
    $level1: '#{$name}--level-1';
    $has-children: '#{$name}--has-children';
    $sale: '--sale';
    $highlight: '--highlight';

    #{$name} {
        display: block;

        &__button {
            @include font-text-medium((
                'breakpoint': xlarge,
            ));

            display: flex;
            position: relative;
            align-items: center;
            height: 54px;
            padding: 5px 6px;
            color: inherit;
            text-decoration: none;
            cursor: pointer;

            @include breakpoint(large) {
                padding: 5px 10px;
            }

            @include breakpoint(xxlarge) {
                padding: 5px 20px;
            }

            &::before {
                content: '';
                display: none;
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 100%;
                height: 3px;
                z-index: #{$z-index};
                background-color: $black;
                transform: translate(-50%, 50%);
            }

            #{$name}:first-child > & {
                padding-left: 0;
            }

            &__title {
                display: block;
                white-space: nowrap;
                word-break: keep-all;
            }

            &#{$sale},
            &#{$highlight} {
                color: $highlight-color;
            }
        }

        &__content {
            @include font-text-medium;

            display: none;
            position: absolute;
            top: calc(100% + 1px);
            left: 0;
            right: 0;
            padding: $sp-400 0 $sp-320 0;
            z-index: #{$z-index};

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
                width: 100vw;
                z-index: -1;
                background-color: $white;
                transform: translateX(-50%);
            }
        }

        &__col {
            display: block;
            min-width: 20%;
            padding: 0 10px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        &__pictures {
            display: flex;
            flex-grow: 5;
        }

        &__list {
            display: block;
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &__item {
            display: block;
            position: relative;
            margin: 0 0 $sp-040 0;
        }

        &__link {
            display: inline-block;
            padding: 0 0 $sp-160 0;
            color: inherit;
            text-decoration: none;

            #{$level1} > & {
                @include font-text-medium;
            }

            #{$has-children} > & {
                @include font-head-small;
            }

            &[href]:hover {
                text-decoration: underline;
            }

            &#{$highlight} {
                color: $highlight-color;
            }
        }

        &__title {
            white-space: nowrap;
            word-break: keep-all;
        }
    }
}
