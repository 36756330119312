@import '~ShopUiBundle/styles/shared';
@import '~ShopUiBundle/components/atoms/form-input/form-input';

@mixin shop-ui-form-password($name: '.form-password') {
    @include shop-ui-form-input($name);

    $empty: '#{$name}--empty';
    $error: '#{$name}--error';
    $valid: '#{$name}--valid';
    $valid-hidden: '#{$name}--valid-hidden';
    $has-confirmation: '#{$name}--has-confirmation';
    $rendered: '#{$name}--rendered';
    $item-valid: '#{$name}__validation-item--valid';

    #{$name} {
        &#{$has-confirmation} {
            margin-bottom: $sp-240;
        }

        &#{$empty},
        &#{$valid} {
            margin-bottom: 44px;
        }

        &__error {
            top: 53px;

            #{$error} & {
                @supports (-webkit-line-clamp: 1) {
                    & {
                        // stylelint-disable-next-line value-no-vendor-prefix
                        display: -webkit-box;
                        // stylelint-disable-next-line property-no-vendor-prefix
                        -webkit-line-clamp: 1;
                        // stylelint-disable-next-line property-no-vendor-prefix
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }

        &__validation {
            display: none;
            position: relative;
            margin-top: $sp-240;

            #{$rendered} & {
                display: block;
            }

            #{$empty} & {
                display: none;
            }
        }

        &__validation-title {
            display: block;
            position: relative;
            font-size: 12px;
            color: $color-gray-20;
            line-height: 18px;

            &--invalid {
                display: block;

                #{$valid} & {
                    display: none;
                }
            }

            &--valid {
                display: none;
                padding-left: $sp-240;
                color: $color-gray-10;

                #{$valid} & {
                    display: block;
                }

                #{$valid-hidden} & {
                    display: none;
                }
            }
        }

        &__validation-title-icon {
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            #{$valid} & {
                color: $color-green-600;
            }
        }

        &__validation-list {
            display: block;
            list-style: none;
            margin: 0;
            padding: 0;

            #{$valid} & {
                display: none;
            }
        }

        &__validation-item {
            @include font-din-bold;

            display: block;
            position: relative;
            margin-top: $sp-080;
            padding-left: $sp-240;
            font-size: 12px;
            color: $color-gray-20;
            line-height: 16px;
        }

        &__validation-item-icon {
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            &--valid {
                display: none;
                color: $color-green-600;

                #{$item-valid} & {
                    display: block;
                }
            }

            &--invalid {
                display: block;
                color: $color-info;

                #{$item-valid} & {
                    display: none;
                }
            }
        }
    }

    @content;
}
