@import '~ShopUiBundle/styles/shared';
@import '~ShopUiBundle/components/atoms/form-check-group/form-check-group';

@mixin shop-ui-payment-shipment-list($name: '.payment-shipment-list') {
    @include shop-ui-form-check-group($name);

    $checked: '#{$name}__item--checked';
    $disabled: '#{$name}__item--disabled';
    $details: '#{$name}__item--details';

    #{$name} {
        margin: 0;
        padding-bottom: $sp-200;

        &__item {
            display: flex;
            flex-direction: row;
            height: auto;
            margin-top: $spacing-md;
            padding: $spacing-md;
            border: 2px solid $color-gray-80;
            border-radius: $global-button-border-radius;
            outline: none;
            cursor: pointer;

            &:active {
                background: $color-gray-90;
            }

            &:hover {
                background-color: $color-white;
                border-color: $color-gray-10;
            }

            &:not(#{$checked}):not(#{$disabled}):hover,
            &:not(#{$checked}):not(#{$disabled}):focus {
                & #{$name}__radio-button-circle {
                    stroke: $color-gray-10;
                    fill: $color-gray-90;
                }
            }

            &#{$checked}#{$details} {
                #{$name}__details {
                    display: block;
                }

                #{$name}__sub-form {
                    display: block;
                }
            }

            &#{$checked} {
                background-color: $color-white;
                border-color: $color-gray-10;

                #{$name}__title {
                    color: $color-gray-10;
                }
            }

            &#{$disabled} {
                background-color: $color-gray-90;
                border-color: $color-gray-90;
                cursor: default;

                & > *,
                &#{name}__title {
                    cursor: default;
                }
            }

            &:focus {
                border-color: $color-gray-10;
                box-shadow: 0 0 0 4px $color-gray-80;
            }
        }

        &__label {
            display: flex;
            position: relative;
            flex: 1 1 100%;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
        }

        &__image {
            width: 124px;
            max-width: 124px;
            object-position: center;
            object-fit: cover;

            @include breakpoint(medium) {
                width: 100px;
                max-width: 100px;
            }
        }

        &__wrapper {
            padding: $spacing-sm;

            @include breakpoint(medium) {
                padding: 0 $spacing-sm;
            }

            @include breakpoint(large) {
                padding: 0;
            }
        }

        &__content {
            display: flex;
            flex: 0 0 100%;
            flex-direction: row;
            align-items: center;
            width: 100%;
            height: 36px;
            max-height: 36px;
            overflow: hidden;
            -webkit-tap-highlight-color: transparent;
            cursor: pointer;
            overflow-wrap: break-word;

            &:focus {
                outline: none;
            }
        }

        &__details {
            display: none;
            flex: 0 0 100%;
            padding: 0;

            @include breakpoint(medium) {
                padding: 0 $spacing-md $spacing-sm $spacing-lg;
            }

            @include breakpoint(large) {
                padding-right: $spacing-lg;
            }
        }

        &__details-text,
        &__details-text a {
            @include font-text-small((
                'color': $color-gray-50,
            ));

            margin: 0;
            padding-top: $spacing-sm;
        }

        &__sub-form {
            display: none;
            flex: 0 0 100%;
            padding-right: 32px;

            @include breakpoint(medium) {
                padding-right: 52px;
            }
        }

        &__icon {
            position: relative;
        }

        &__logo-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            min-width: 90px;
            max-width: 90px;
            min-height: $spacing-lg;
            max-height: $spacing-lg;
            margin-left: 15px;
            max-block-size: auto;

            @include breakpoint(medium) {
                padding: 0 $spacing-xs;
            }
        }

        &__logo,
        &__logo-disabled {
            width: 100%;
            max-height: $spacing-lg;
        }

        &__logo-disabled {
            opacity: 0.4;
        }

        &__title {
            @include font-din-regular;

            margin-left: $sp-080;
            padding: 0;
            font-size: 14px;
            color: $color-gray-50;
            line-height: 18px;
            cursor: default;
        }

        &__error {
            position: relative;
            top: $spacing-xs;
            left: 0;
            height: auto;
        }

        &__outer-message-text {
            @include font-text-xsmall((
                'color': $color-gray-50
            ));

            display: none;
            margin: 0;
            padding: 0 2px;

            &:not(:empty) {
                display: block;
                padding-top: $spacing-sm;
            }
        }

        @content;
    }
}
