@import '~ShopUiBundle/styles/shared';

@mixin product-bundle-product-tile-rating($name: '.product-tile-rating') {
    $large: '#{$name}--large';

    #{$name} {
        @include font-din-regular;

        display: flex;
        align-items: center;
        height: 14px;

        &__stars {
            display: flex;
            margin-top: -1px;
        }

        &__count {
            @include font-default(('color': $color-gray-50));
            @include font-size-xxsmall;

            margin-left: $spacing-xs;
            line-height: 11px;

            #{$large} & {
                @include font-size-small;

                color: $color-gray-20;
                line-height: 13px;
                text-decoration: underline;
                cursor: pointer;

                &:hover {
                    color: $color-gray-50;
                }
            }
        }

        &__star {
            width: 11px;
            height: 11px;

            &:not(:last-child) {
                margin-right: 2px;
            }

            #{$large} & {
                width: 13px;
                height: 13px;

                &:not(:last-child) {
                    margin-right: 3px;
                }
            }
        }
    }
}

@include product-bundle-product-tile-rating;
