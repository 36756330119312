@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-catalog-categories-item($name: '.catalog-categories-item') {
    $position-left: '#{$name}--position-mobile-left';
    $position-right: '#{$name}--position-mobile-right';
    $img-format: percentage(math.div(120, 178));
    $img-format-large: percentage(math.div(232, 394));

    #{$name} {
        display: block;

        &__link {
            display: block;
            position: relative;
            text-decoration: none;

            &:hover {
                #{$name}__title,
                #{$name}__subtitle {
                    text-decoration: underline;
                }
            }
        }

        &__picture {
            display: block;
            position: relative;
            overflow: hidden;
            border-radius: $global-border-radius;

            #{$position-left} & {
                @include breakpoint(medium down) {
                    margin-left: -5px;
                    margin-right: 0;
                }
            }

            #{$position-right} & {
                @include breakpoint(medium down) {
                    margin-left: 0;
                    margin-right: -5px;
                }
            }

            &-img {
                display: block;
                position: relative;
                width: 100%;
                height: $img-format;
                object-fit: cover;
                object-position: 50% 50%;

                @include breakpoint(mediumlarge up) {
                    height: $img-format-large;
                }
            }
        }

        &__content {
            display: block;
            margin-top: 5px;

            @include breakpoint(mediumlarge up) {
                margin-top: 10px;
            }
        }

        &__title {
            @include font-text-medium;

            display: inline-block;
        }
    }
}
