// stylelint-disable-next-line selector-id-pattern
#GuuruMain {
    // stylelint-disable-next-line declaration-no-important
    z-index: 99 !important;

    @include breakpoint(medium) {
        & .guuru-chat {
            bottom: $sp-200;
            width: 460px;
        }
    }
}
