// will
.strength-meter {
    float: right;
    background: none !important;
    font-size: 12px !important;
    color: $text-color !important;
    font-weight: normal !important;
    text-align: center;
    border-bottom: 4px solid transparent;
    width: 140px;
    height: 40px;
    position: absolute;
    right: 0;
    margin-top: -60px;
    line-height: 35px;
    padding: 4px;

    &--bad {
        border-color: $color-error !important;
    }

    &--weak {
        border-color: $color-error !important;
    }

    &--good {
        border-color: $color-positive !important;
    }

    &--strong {
        border-color: $color-positive !important;
    }

}

.strength-meter-container {
    position: relative;

    &.has-success {
        .form-success {
            display: block;
        }
    }

    .form-success {
        display: none;
    }

    // hide the checkmark from the validate plugin
    input[type=password] {
        background-image: none !important;
    }
}
