@import '~ShopUiBundle/styles/shared';
@import '~foundation-sites/scss/foundation';

$global-flexbox: true !global;

@include foundation-global-styles; // TODO: später auch raus.
@include foundation-flex-grid;
@include foundation-forms;
@include foundation-close-button;

// TODO: remove
@include foundation-dropdown;
@include foundation-responsive-embed;
@include foundation-off-canvas;
@include foundation-slider; // TODO: entfernen, basic-slider.
@include foundation-reveal; // TODO: entfernen, basic-modal.
@include foundation-table;
@include foundation-visibility-classes;
@include foundation-flex-classes;

@mixin row-padding-reset($padding) {
    $margin: -($padding - ($grid-column-gutter * 0.5));

    margin-left: $margin;
    margin-right: $margin;
    padding-left: 0;
    padding-right: 0;
}

// stylelint-disable no-descending-specificity
@mixin row-padding($padding) {
    $row-extra-padding: $grid-column-gutter * 0.5;
    $row-padding: $padding - $row-extra-padding;

    .row.column {
        padding-left: $padding;
        padding-right: $padding;
    }

    &.row,
    .row {
        .row {
            margin-left: -$row-extra-padding;
            margin-right: -$row-extra-padding;
            padding-left: 0;
            padding-right: 0;
        }

        &.collapse {
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;

            > .column,
            > .columns {
                > .row {
                    margin-left: 0;
                    margin-right: 0;
                    padding-left: $row-padding;
                    padding-right: $row-padding;
                }
            }
        }

        &,
        &.is-collapse-child {
            margin-left: 0;
            margin-right: 0;
            padding-left: $row-padding;
            padding-right: $row-padding;
        }

        &.no-padding-mobile.row {
            margin-left: -$padding;
            margin-right: -$padding;
        }
    }
}
// stylelint-enable

.row-padding-mobile-200 {
    .shop-main,
    &.shop-footer {
        @include breakpoint($row-padding-mobile-200-breakpoint) {
            @include row-padding(20px);
        }
    }
}
