@import '~ShopUiBundle/styles/shared';

@mixin product-bundle-product-tile-title($name: '.product-tile-title') {
    #{$name} {
        @include font-default;
        @include font-size-small;

        display: block;
        position: relative;
    }
}

@include product-bundle-product-tile-title;
