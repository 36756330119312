.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.off-canvas {
    -webkit-overflow-scrolling: touch;
}

.search-results {
    margin-top: 20px;
}
