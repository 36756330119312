$name: '.checkout-shipment';
$panel-width: calc(50% - #{$sp-200 * 0.5});

#{$name} {

    &__title {
        @include font-din-bold;
        display: block;
        margin: $sp-400 $sp-160 0px 0px;
        font-size: 24px;
        color: $color-gray-10;
        letter-spacing: -0.1px;
        line-height: 28px;
        text-transform: none;
    }

    &__error {
        margin-top: $sp-080;
        margin-bottom: -14px;

        @include breakpoint(mediumlarge) {
            margin-top: $sp-080;
            margin-bottom: -22px;
        }
    }

    &__phone {
        margin-bottom: $sp-400;
    }

    &__list {
        margin: 0 0 60px 0;

        @include breakpoint(small only) {
            margin: 0 10px 40px 0;
        }
    }

    &__list-item-title {
        @include font-din-bold;
        font-size: 16px;
        line-height: 24px;
    }

    &__risk-check-error {
        margin: 0;
        display: inline-block;
        color: $color-error;
        padding: $sp-080 0;
    }

    &__submit {
        margin-top: 45px;
        margin-bottom: $sp-800;

        @include breakpoint(small only) {
            width: 100%;
            margin-top: 32px;
            /* stylelint-disable-next-line declaration-no-important */
            text-transform: none !important;
        }
    }

    &__packstation-instruction {
        color: $color-gray-10;
    }

    &__phone-instruction {
        margin: 20px 0px;
    }
}
