@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-basic-attention($name: '.basic-attention') {

    #{$name} {
        display: block;

        &__wrapper {
            display: block;
            padding: $spacing-lg;
            background-color: $color-gray-90;
            border-radius: $spacing-xs;

            &--link {
                text-decoration: none;
            }
        }

        &__icon {
            display: block;
            position: relative;
            max-width: 24px;
            max-height: 24px;
            margin-bottom: 15px;
        }

        &__title {
            @include font-head-small;

            display: block;
            position: relative;
            margin: 0;
        }

        &__text {
            @include font-text-medium;

            display: block;
            position: relative;
            margin: $spacing-md 0 0 0;
        }

        &__link-title {
            display: block;
            position: relative;
            margin-top: $spacing-sm;
            text-decoration: underline;
        }
    }
}

@include shop-ui-basic-attention;
