@keyframes background-pulsate-hint {

    from {
        background-color: $color-white;
    }

    to {
        background-color: $color-gray-90;
    }
}

.background-pulsate-hint {
    @include h-animation-background-pulsate-hint;
}

@keyframes global-ripple-animation {
    from {
        opacity: 1;
        transform: translate(-50%, -50%) scale(0);
    }

    to {
        opacity: 0;
        transform: translate(-50%, -50%) scale(2);
    }
}
