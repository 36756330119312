@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-column-layout($name: '.column-layout') {
    $full-width: '#{$name}--full-width';
    $remove-row-padding: '#{$name}--remove-row-padding';

    #{$name} {
        display: block;
        position: relative;

        #{$remove-row-padding} & {
            overflow-x: hidden;
        }

        &__wrapper {
            #{$remove-row-padding} & {
                margin: 0 #{ $spacing-sm * -1 };

                .row-padding-mobile-200 & {
                    @include breakpoint($row-padding-mobile-200-breakpoint) {
                        margin: 0 #{ $spacing-md * -1 };
                    }
                }
            }
        }

        &__row {
            #{$full-width} & {
                max-width: 100%;
                margin: 0;
            }
        }
    }
}
