@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-logo-loader($name: '.logo-loader') {
    $accent: '#{$name}--color-accent';
    $black: '#{$name}--color-black';
    $light: '#{$name}--color-light';

    #{$name} {
        display: block;
        opacity: 0;
        animation: shop-ui-logo-loader-fade-in 2s, shop-ui-logo-loader-fade-in reverse 20s 2s;

        &__svg {
            width: 100%;
            height: 100%;
        }

        &__circle {
            stroke-linecap: round;
            stroke-dasharray: 1;
            stroke-dashoffset: 1;
            transform: rotate(0);
            transform-origin: center;
            animation: shop-ui-logo-loader-moving 3s ease-in-out 10, shop-ui-logo-loader-rotate 2s linear 10;

            #{$accent} & {
                stroke: $color-accent;
            }

            #{$black} & {
                stroke: $color-black;
            }

            #{$light} & {
                stroke: $color-white;
            }
        }

        &__logo-path {
            #{$accent} & {
                fill: $color-accent;
            }

            #{$black} & {
                fill: $color-black;
            }

            #{$light} & {
                fill: $color-white;
            }
        }

        @keyframes shop-ui-logo-loader-fade-in {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        @keyframes shop-ui-logo-loader-rotate {
            100% {
                transform: rotate(360deg);
            }
        }

        @keyframes shop-ui-logo-loader-moving {
            0% {
                stroke-dashoffset: 1;
            }

            50% {
                stroke-dashoffset: 0.75;
            }

            100% {
                stroke-dashoffset: 1;
            }
        }
    }

    @content;
}
