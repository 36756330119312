@import '~ShopUiBundle/styles/shared';

$shop-ui-icon-options-default: (
    'breakpoint': mediumlarge,
);

@function shop-ui-icon-get-option($options, $key) {
    $value: map-get($options, $key);

    @return if($value != null, $value, map-get($shop-ui-icon-options-default, $key));
}

@function shop-ui-icon-get-option-breakpoint($options: ()) {
    @return shop-ui-icon-get-option($options, 'breakpoint');
}

@mixin shop-ui-icon($name: '.icon', $tag: 'svg') {
    // Used to set highlight color in SVGs
    :root {
        --color-accent: #{$color-accent};
    }
}

// Sizes
@mixin shop-ui-icon-size-xsmall() {
    width: 12px;
    height: 12px;
}

@mixin shop-ui-icon-size-small() {
    width: 14px;
    height: 14px;
}

@mixin shop-ui-icon-size-medium() {
    width: 16px;
    height: 16px;
}

@mixin shop-ui-icon-size-large() {
    width: 18px;
    height: 18px;
}

@mixin shop-ui-icon-size-xlarge() {
    width: 20px;
    height: 20px;
}

@mixin shop-ui-icon-size-xxlarge() {
    width: 24px;
    height: 24px;
}

// Responsive sizes
@mixin shop-ui-icon-responsive-xsmall($options: ()) {
    @include shop-ui-icon-size-xsmall;
}

@mixin shop-ui-icon-responsive-small($options: ()) {
    @include shop-ui-icon-size-xsmall;

    @include breakpoint(shop-ui-icon-get-option-breakpoint($options)) {
        @include shop-ui-icon-size-small;
    }
}

@mixin shop-ui-icon-responsive-medium($options: ()) {
    @include shop-ui-icon-size-small;

    @include breakpoint(shop-ui-icon-get-option-breakpoint($options)) {
        @include shop-ui-icon-size-medium;
    }
}

@mixin shop-ui-icon-responsive-large($options: ()) {
    @include shop-ui-icon-size-medium;

    @include breakpoint(shop-ui-icon-get-option-breakpoint($options)) {
        @include shop-ui-icon-size-large;
    }
}

@mixin shop-ui-icon-responsive-xlarge($options: ()) {
    @include shop-ui-icon-size-large;

    @include breakpoint(shop-ui-icon-get-option-breakpoint($options)) {
        @include shop-ui-icon-size-xlarge;
    }
}

@mixin shop-ui-icon-responsive-xxlarge($options: ()) {
    @include shop-ui-icon-size-xlarge;

    @include breakpoint(shop-ui-icon-get-option-breakpoint($options)) {
        @include shop-ui-icon-size-xxlarge;
    }
}

@mixin shop-ui-icon-size-responsive-by-name($size, $options: ()) {
    @if $size == 'xs' {
        @include shop-ui-icon-responsive-xsmall($options);
    } @else if $size == 'sm' {
        @include shop-ui-icon-responsive-small($options);
    } @else if $size == 'md' {
        @include shop-ui-icon-responsive-medium($options);
    } @else if $size == 'lg' {
        @include shop-ui-icon-responsive-large($options);
    } @else if $size == 'xl' {
        @include shop-ui-icon-responsive-xlarge($options);
    } @else if $size == 'xxl' {
        @include shop-ui-icon-responsive-xxlarge($options);
    } @else {
        @warn "Unknown size '#{$size}'.";
    }
}
