@import '~ShopUiBundle/styles/shared';
@import '~ShopUiBundle/components/atoms/form-check-group/form-check-group';

@mixin shop-ui-form-check-card-group($name: '.form-check-card-group') {
    @include shop-ui-form-check-group($name);

    $height: 140px;
    $checked: '#{$name}__item--checked';
    $gray: '#{$name}--gray';

    #{$name} {
        margin: 0;

        &__wrapper {
            padding-bottom: $spacing-lg;
        }

        &__item {
            display: flex;
            flex-direction: row;
            height: #{$height};
            padding: 0;
            border: 1px solid $color-gray-80;
            box-shadow: none;
            cursor: pointer;

            &:not(:first-of-type) {
                margin-top: $sp-160;
            }

            &:active {
                background: $color-gray-90;
            }

            &:hover:not(#{$checked}) {
                background: $color-gray-90;

                & * {
                    color: $color-gray-10;
                }
            }

            &#{$checked} {
                background-color: $color-white;
                border: 1px solid $color-white;
                border-radius: $global-border-radius;
                box-shadow: 0 4px 8px rgba($color-gray-10, 0.2);
            }
        }

        &__label {
            display: flex;
            position: relative;
            flex: 1 1 100%;
            flex-direction: row;
            cursor: pointer;
        }

        &__image {
            width: 124px;
            max-width: 124px;
            height: calc(#{$height} - #{$sp-020});
            object-position: center;
            object-fit: cover;

            @include breakpoint(medium) {
                width: #{$height};
                max-width: #{$height};
            }
        }

        &__image-icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 124px;
            min-width: 124px;
            max-width: 124px;
            height: 100%;
            background-color: $color-gray-90;

            @include breakpoint(medium) {
                width: #{$height};
                min-width: #{$height};
                max-width: #{$height};
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            padding: 5px $sp-240 5px $sp-160;
            overflow: hidden;
            overflow-wrap: break-word;
            color: $color-gray-20;

            #{$gray} & {
                color: $color-gray-50;
            }
        }

        &__item-title {
            @include font-din-bold;

            width: calc(100% - #{$sp-160});
            margin-bottom: $sp-020;
            overflow: hidden;
            font-size: 14px;

            line-height: 20px;
        }

        &__text {
            @include font-din-regular;

            font-size: 12px;
            color: $color-gray-20;
            line-height: 20px;
        }

        &__warning {
            @include font-size-xsmall;

            display: flex;
            align-items: center;
            padding-top: 10px;
            color: $color-gray-10;
        }

        &__warning-text {
            padding-left: $sp-040;
            line-height: 1;
        }

        &__icon {
            position: absolute;
            top: 0;
            left: calc(100% - #{$sp-320});
            width: 16px;
            height: 16px;
            margin: $sp-080;
            color: $color-accent;
        }

        &__tag {
            display: none;
            position: absolute;
            bottom: $sp-080;
            right: $sp-080;
            font-size: 12px;
            color: $color-gray-50;
            line-height: 16px;
        }

        &__input:not(:checked) {
            & ~ #{$name}__icon {
                visibility: hidden;
            }
        }

        &__error {
            position: absolute;
            top: -$sp-200;
        }

        @content;
    }
}
