@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-main-navigation-item-details-with-image($name: '.main-navigation-item-details-with-image') {
    $style-tile: '#{$name}--tile';

    #{$name} {
        display: flex;
        position: relative;
        list-style: none;
        align-items: flex-start;
        padding-bottom: $sp-240;
        padding-left: $sp-160;
        overflow: hidden;

        @supports not ((display: grid) and (grid-template-columns: auto)) {
            flex: 0 0 33%;
        }

        @include breakpoint(medium up) {
            padding: 0 0 $spacing-sm $sp-160;
        }

        &#{$style-tile} {
            @include breakpoint(small) {
                flex: 0 0 100%;
                padding: $sp-160 $sp-240 $sp-160 $sp-240;
            }

            @include breakpoint(mediumsmall) {
                flex: 0 0 50%;
                padding-top: $sp-400;

                &#{$name}--full-width {
                    flex: 0 0 100%;
                }
            }
        }

        &__title {
            @include font-text-medium;

            font-weight: bold;
            color: $color-gray-10;
        }

        &__subtitle {
            @include font-text-medium((
                'color': $color-gray-20,
            ));

            display: block;
            max-width: 50ch;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: keep-all;
        }

        &:hover #{$name}__title {
            text-decoration: underline;
        }

        &__content {
            @include font-text-small;

            align-self: flex-start;
            width: 100%;
        }

        &__price-title {
            @include font-text-small;

            font-weight: bold;
            white-space: nowrap;
            word-break: keep-all;
        }

        &__price-subtitle {
            @include font-text-xsmall;

            padding-top: $sp-040;
            padding-right: $sp-080;
            color: $color-gray-20;
        }

        &__img {
            width: 100%;
            max-width: 560px;
            aspect-ratio: 3000/2250; /* Ratio of Bike Images */
            object-fit: contain;
        }

        &__link {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            text-decoration: none;
        }
    }
}
