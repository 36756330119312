@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-catalog-bike-rating($name: '.catalog-bike-rating', $total-items: 5) {
    $item-width: ceil(math.div(100%, $total-items + 1));
    $item-margin: 0;
    $min-values-width: 100px;
    $max-values-width: 200px;
    $values-height: 10px;
    $bg-color: $color-gray-80;
    $bg-color-active: $color-gray-50;

    #{$name} {
        display: block;
        position: relative;

        &__list {
            display: block;
            list-style: none;
            margin: 0;
            padding: 0;

            &-item {
                display: flex;
                align-items: center;
                margin: 0;
                padding: $sp-080 0;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        &__title {
            @include font-text-small;

            display: block;
            min-width: 90px;
            margin: 0;
            // stylelint-disable-next-line declaration-no-important
            line-height: $values-height !important;
        }

        &__values-wrapper {
            display: block;
            position: relative;
            width: 100%;
            min-width: $min-values-width;
            max-width: $max-values-width;
            overflow: hidden;
            border-radius: $global-border-radius;
        }

        &__values {
            display: flex;
            position: relative;
            flex-wrap: nowrap;
            justify-content: space-between;
            width: 100%;
            height: $values-height;
            overflow: hidden;
        }

        &__values-item,
        &__values::before {
            display: block;
            position: relative;
            width: $item-width;
            height: 100%;
        }

        &__values-item {
            background-color: $bg-color;
            border: 1px solid $bg-color;

            &--active {
                background-color: $bg-color-active;
                border-color: $bg-color-active;
            }
        }

        &__values::before {
            content: '';
            background-color: $bg-color-active;
            border: 1px solid $bg-color-active;
        }
    }
}
