@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-selection-button-group($name: '.selection-button-group') {
    $input-checked: '#{$name}__input:checked';
    $button-checked: '#{$input-checked} + #{$name}__button';

    #{$name} {
        // stylelint-disable-next-line declaration-no-important
        margin: 0 (-$spacing-xs) !important;

        &__label {
            padding: 0 $spacing-xs;
        }

        &__button {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: $spacing-sm 0;
            background-color: $color-gray-90;
            border: 2px solid $color-gray-90;
            border-radius: 4px;
            text-decoration: none;
            cursor: pointer;

            #{$input-checked} + & {
                background-color: $color-gray-10;
                border-color: $color-gray-10;
            }

            &:hover,
            &:focus {
                border-color: $color-gray-10;
            }

            &:focus {
                outline: none;
                box-shadow: 0 0 0 4px $color-gray-80;
            }
        }

        &__icon {
            color: $color-gray-20;

            #{$button-checked} & {
                color: $color-white;
            }
        }

        &__title {
            @include font-size-small;

            max-width: 100%;
            padding-top: $spacing-xs;
            overflow: hidden;
            color: $color-gray-10;
            white-space: nowrap;
            text-overflow: ellipsis;

            #{$button-checked} & {
                color: $color-white;
            }
        }
    }
}
