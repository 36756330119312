$font-family-medium: 'DINNextW05-Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-bold: 'DINNextW05-Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-light: 'DINNextW05-Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-regular: 'DINNextW05-Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size-100: 10px;
$font-size-200: 12px;
$font-size-300: 14px;
$font-size-400: 15px;
$font-size-500: 18px;
$font-size-600: 24px;
$font-size-700: 32px;
$font-size-800: 42px;
