@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-tooltip-info($name: '.tooltip-info') {
    $inline: '#{$name}--inline';

    #{$name} {
        display: inline-block;

        &#{$inline} {
            display: inline;
        }

        &__container {
            @include font-din-regular;

            display: none;
            max-width: 400px;
            padding: 8px 10px;
            z-index: 100;
            background: $color-black;
            border-radius: 1px;
            font-size: $font-size-300;
            color: $color-white;

            &--active {
                display: block;
            }
        }

        &__content {
            color: $color-white;
        }

        &__arrow {
            position: absolute;
            width: 8px;
            height: 8px;
            z-index: -1;

            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                width: 100%;
                height: 100%;
                background: $color-black;
            }

            #{$name}__container[data-popper-placement^='top'] > & {
                bottom: 0;

                &::before {
                    transform: translateY(50%) rotate(45deg);
                }
            }

            #{$name}__container[data-popper-placement^='right'] > & {
                left: 0;

                &::before {
                    transform: translateX(-50%) rotate(45deg);
                }
            }

            #{$name}__container[data-popper-placement^='bottom'] > & {
                top: 0;

                &::before {
                    transform: translateY(-50%) rotate(45deg);
                }
            }

            #{$name}__container[data-popper-placement^='left'] > & {
                right: 0;

                &::before {
                    transform: translateX(-50%) rotate(45deg);
                }
            }
        }
    }
}
