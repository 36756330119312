@import '~ShopUiBundle/components/molecules/list-with-title/list-with-title';

@mixin shop-ui-list-social-icons($name: '.list-social-icons') {
    @include shop-ui-list-with-title($name);

    #{$name} {
        &__list {
            display: flex;
            align-items: center;
        }

        &__list-item {
            margin-bottom: 0;

            & + #{$name}__list-item {
                margin-left: 20px;

                @include breakpoint(mediumlarge) {
                    margin-left: 5px;
                }
            }
        }

        &__icon {
            @include breakpoint(mediumlarge) {
                width: 30px;
                height: 30px;
            }
        }
    }
}

@include shop-ui-list-social-icons;
