@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-catalog-category-logo-footer($name: '.catalog-category-logo-footer') {
    $padding: 10px;
    $style-max-width-800: '#{$name}--max-width-800';
    $style-full-witdh: '#{$name}--full-width';

    #{$name} {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 60px $padding;
        background-color: $color-black;

        @include breakpoint(mediumlarge up) {
            padding: 100px $padding;
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            overflow: hidden;
        }

        &__title {
            @include font-head-medium;

            margin: 0;

            color: $color-white;
            text-align: center;
        }

        &__logo {
            margin: 8px 0 60px;

            @include breakpoint(mediumlarge up) {
                margin-top: 32px;
            }

            #{$style-full-witdh} & {
                width: 100%;
            }

            #{$style-max-width-800} & {
                width: 100%;
                max-width: 800px;
            }
        }
    }
}
