@import '~ShopUiBundle/components/molecules/main-navigation-category-bikes-level-3/main-navigation-category-bikes-level-3';

@mixin shop-ui-main-navigation-category-bikes-level-4($name: '.main-navigation-category-bikes-level-4') {
    @include shop-ui-main-navigation-category-bikes-level-3($name);

    #{$name} {
        display: flex;

        &__detail-list {
            display: flex;
            position: relative;
            list-style: none;
            flex-flow: row wrap;
            justify-content: flex-start;
            margin: 0;
            padding-top: $sp-400;

            @supports (display: grid) and (grid-template-columns: auto) {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
            }
        }
    }
}
