@import '~ShopUiBundle/components/molecules/main-navigation-category/main-navigation-category';

@mixin shop-ui-main-navigation-category-bikes($name: '.main-navigation-category-bikes') {
    @include shop-ui-main-navigation-category($name);

    $menu-height: 580px;

    #{$name} {
        width: 100%;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: auto;
        }

        &__content {
            display: none;
            height: $menu-height;
            padding: 0;
        }

        &__list {
            padding: $sp-400 0 0 0;
        }

        @keyframes main-navigation-category-bikes-slide {
            0% {
                opacity: 0;
                transform: translateX(-30px);
            }

            100% {
                opacity: 1;
                transform: translateX(0);
            }
        }

        @keyframes main-navigation-category-bikes-slot {
            0% {
                opacity: 0;
                transform: translateX(-60%);
            }

            100% {
                opacity: 1;
                transform: translateX(-50%);
            }
        }

        @keyframes main-navigation-category-bikes-detail {
            0% {
                opacity: 0;
                transform: translateX(-30%);
            }

            100% {
                opacity: 1;
                transform: translateX(-18%);
            }
        }
    }
}
