$name: '.checkout-summary';

#{$name} {

  &__meta-title {
    @include font-din-bold;
    display: block;
    margin-top: $sp-400;
    font-size: 24px;
    letter-spacing: -0.1px;
    line-height: 28px;
    color: $color-gray-50;
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: none !important;
  }

  &__subline {
    margin-top: 0;
    margin-bottom: 5px;
    text-transform: none;
    color: $color-gray-10;
    font-size: 16px;
    line-height: 24px;
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: none !important;
  }

  &__paragraph {
    margin-top: 0;
  }

  &__easy-credit {
      @include font-din-regular();
  }

  &__sums {
    @include font-din-regular();

    &--border {
      height: 32px;

      & * .columns {
        padding-top: $sp-160;
        background: $color-gray-90;
      }

      hr {
        margin: 0;
        margin-top: $sp-160;
        border: 0;
        height: 1px;
        background: $color-gray-80;
      }

    }

    p {
      margin: 0;
    }

    strong {
      @include font-din-bold;
      font-size: 16px;
      line-height: 24px;
    }

    &-total {
      @include font-din-bold;
      font-size: 16px;
      line-height: 24px;
      color: $color-gray-10;
    }

    &__prize {
      @include font-din-regular();
      font-size: 14px;
      line-height: 20px;

      &--free {
        @include font-din-bold;
        color: $color-positive;
        text-transform: uppercase;
      }

      &--discount {
        color: #6b1d1c;
      }
    }

  }

  &__totals {
    & * .columns {
      background: $color-gray-90;
    }
  }

  &__form {
    clear: both;
    padding-top: $sp-400;

    .accordion-content {
      padding-top: 0;
    }

    p#{$name}__title {
      @include font-din-bold;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: $sp-020;
      color: $color-gray-10;
    }

    label {
      font-size: 14px;
      line-height: 20px;
      padding-bottom: $sp-160;
      color: $color-gray-10;
    }

    textarea {
      margin-top: 0;
    }

    .button--cart, .button--disabled {
      float: right;
      margin-top: 20px;
    }
  }

  &__title {
    display: inline-block;
    margin-bottom: $sp-200;
  }

  &__edit-title {
    position: relative;
    display: block;
  }

  &__edit-icon {
    position: absolute;
    right: 0px;
    top: 44px;
    width: 20px;
    height: 20px;
    color: $color-gray-10;
    margin-bottom: -2px;


    @include breakpoint(medium) {
      position: relative;
      top: 0px;
      color: $color-gray-10;
      margin-bottom: -2px;
    }
  }

  &__terms {
    margin: $sp-400 0px;
    color: $color-gray-10;

    @include breakpoint(medium) {
      margin-bottom: $sp-640;
    }
  }

  &__top-column {
    padding-top: $sp-160;
  }

  &__bottom-column {
    padding-bottom: $sp-160;
  }

  &__submit {
    margin-bottom: $sp-800;
  }

  &__item-price {
    @include font-din-regular;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    padding: 0px;
    margin-bottom: 16px;
    /* stylelint-disable-next-line declaration-no-important */
    justify-content: flex-end !important;
    color: $color-gray-10;

    &-total {
      @include font-din-bold;
      text-align: right;
      color: $color-gray-10;
    }
  }
}
