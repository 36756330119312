@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-action-message($name: '.action-message') {
    $error: '#{$name}--error';
    $success: '#{$name}--success';
    $warning: '#{$name}--warning';
    $animating: '#{$name}--animating';
    $open: '#{$name}--open';
    $swipe-out: '#{$name}--swipe-out';

    #{$name} {
        @include font-din-regular;

        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        padding: $sp-160 $sp-240;
        z-index: 101;
        background-color: $color-gray-10;
        border-radius: $global-border-radius $global-border-radius 0 0;
        color: $color-white;
        transform: translate(0, 170%);
        transition: transform ease 0.3s;

        @include breakpoint(mediumsmall) {
            bottom: $sp-240;
            left: $sp-240;
            width: 360px;
            padding: $sp-200 $sp-240;
            border-radius: $global-border-radius;
        }

        @include breakpoint(large) {
            bottom: $sp-400;
            left: $sp-400;
        }

        &#{$animating} {
            display: flex;
        }

        &#{$open} {
            display: flex;
            transform: translate(0, 0);
        }

        &#{$swipe-out} {
            display: flex;
            transform: translate(-100%, 0);
            transition: transform ease 0.5s;
        }

        &#{$error} {
            background-color: $color-error;
        }

        &#{$warning} {
            background-color: $color-success;
        }

        &#{$success} {
            background-color: $color-accent;
            color: $color-gray-10;
        }

        &__title {
            display: block;
            flex-grow: 1;
            font-size: 16px;
            line-height: 20px;
        }

        &__content {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            box-sizing: content-box;
            width: 80px;
            padding-left: $sp-240;
        }

        &__link {
            font-size: 12px;
            color: $color-white;
            line-height: 16px;
            text-align: center;

            #{$success} & {
                color: $color-gray-10;
            }
        }
    }
}
