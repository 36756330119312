@import '~ShopUiBundle/components/molecules/button-dropdown/button-dropdown';

@mixin shop-ui-customer-dropdown($name: '.customer-dropdown') {
    @include shop-ui-button-dropdown($name);

    #{$name} {
        &__button {
            @include font-text-small;

            padding: 0;
        }

        &__button-icon {
            width: 30px;
            height: 30px;
        }
    }
}
