@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-account-order-footer($name: '.account-order-footer') {

    #{$name} {
        padding-bottom: $sp-400;

        @include breakpoint(medium) {
            padding-bottom: $sp-640;
        }

        @include breakpoint(large) {
            padding-top: $sp-400;
            padding-bottom: 120px;
        }

        &__border-top {
            padding-top: $sp-320;

            @include breakpoint(medium) {
                padding-top: $sp-240;
            }

            @include breakpoint(large) {
                padding-top: $sp-400;
                border-top: 2px solid $color-gray-10;
            }
        }

        &__column {
            padding-top: $sp-160;
            padding-bottom: $sp-200;
            border-top: 2px solid $color-gray-80;

            @include breakpoint(large) {
                padding-top: 0;
                padding-bottom: 0;
                border-top: none;
            }
        }

        &__column--first {
            padding-top: $sp-320;
            border-color: $color-gray-10;

            @include breakpoint(medium) {
                padding-top: $sp-240;
            }

            @include breakpoint(large) {
                padding-top: 0;
            }
        }

        &__column-title {
            @include font-din-bold;

            padding-bottom: $sp-080;
            font-size: 18px;
            color: $color-gray-10;
            letter-spacing: 0.5px;
            line-height: 24px;

            @include breakpoint(large) {
                padding-bottom: $sp-160;
            }
        }

        &__sum {
            @include breakpoint(large) {
                order: 3;
            }
        }

        &__option-title {
            @include font-din-bold;

            font-size: 14px;
            color: $color-gray-10;
            line-height: 20px;

            &:nth-child(2) {
                padding-bottom: 2px;
            }
        }

        &__option-info {
            @include font-din-regular;

            font-size: 14px;
            color: $color-gray-20;
            line-height: 20px;

            &:nth-child(2) {
                padding-bottom: 2px;
            }
        }

        &__shipping-option {
            display: flex;
            align-items: center;
        }

        &__tooltip {
            height: 14px;
            padding-left: $sp-080;
        }

        &__address-title {
            @include font-din-bold;

            padding: $sp-080 0 $sp-040 0;
            font-size: 14px;
            color: $color-gray-10;
            line-height: 20px;
        }

        &__address-info {
            @include font-din-regular;

            font-size: 14px;
            color: $color-gray-10;
            line-height: 20px;
        }

        &__total {
            display: flex;
            justify-content: space-between;
            padding-bottom: 2px;
            color: $color-gray-20;

            &--bold {
                @include font-din-bold;

                padding-top: $sp-160;
                color: $color-gray-10;
            }
        }

        &__total-info {
            @include font-din-regular;

            font-size: 12px;
            color: $color-gray-20;
            line-height: 16px;
        }
    }
}
