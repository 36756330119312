@use 'sass:math';

$font-options-default: (
    'breakpoint': mediumlarge,
    'color': $color-gray-10,
    'bold': true,
    'scale': true,
);

@function font-get-option($options, $key) {
    $value: map-get($options, $key);

    @return if($value != null, $value, map-get($font-options-default, $key));
}

@function font-get-option-breakpoint($options: ()) {
    @return font-get-option($options, 'breakpoint');
}

@function font-get-option-color($options: ()) {
    @return font-get-option($options, 'color');
}

@function font-get-option-bold($options: ()) {
    @return font-get-option($options, 'bold');
}

@function font-get-option-scale($options: ()) {
    @return font-get-option($options, 'scale');
}

@mixin font-din-light($options: ()) {
    font-family: $font-family-light;
    font-weight: normal;

    b,
    strong,
    &.is-bold {
        @if font-get-option-bold($options) {
            font-family: $font-family-regular;
            font-weight: normal;
        } @else {
            // Avoid specifity problems
            // stylelint-disable-next-line declaration-no-important
            font-family: $font-family-light !important;
            // stylelint-disable-next-line declaration-no-important
            font-weight: normal !important;
        }
    }
}

@mixin font-din-regular($options: ()) {
    font-family: $font-family-regular;
    font-weight: normal;

    b,
    strong,
    &.is-bold {
        @if font-get-option-bold($options) {
            font-family: $font-family-medium;
            font-weight: normal;
        } @else {
            // Avoid specifity problems
            // stylelint-disable-next-line declaration-no-important
            font-family: $font-family-regular !important;
            // stylelint-disable-next-line declaration-no-important
            font-weight: normal !important;
        }
    }
}

@mixin font-din-medium($options: ()) {
    font-family: $font-family-medium;
    font-weight: normal;

    b,
    strong,
    &.is-bold {
        @if font-get-option-bold($options) {
            font-family: $font-family-bold;
            font-weight: normal;
        } @else {
            // Avoid specifity problems
            // stylelint-disable-next-line declaration-no-important
            font-family: $font-family-medium !important;
            // stylelint-disable-next-line declaration-no-important
            font-weight: normal !important;
        }
    }
}

@mixin font-din-bold() {
    font-family: $font-family-bold;
    font-weight: normal;
}

// Textstyles
@mixin font-default($options: ()) {
    @include font-din-regular($options);

    $color: font-get-option-color($options);

    text-transform: none;

    @if $color {
        color: $color;
    }
}

// Textsizes
@mixin font-size-xxxlarge() {
    font-size: 32px;
    letter-spacing: -0.6px;
    line-height: math.div(40px, 32px);
}

@mixin font-size-xxlarge() {
    font-size: 24px;
    letter-spacing: -0.4px;
    line-height: math.div(32px, 24px);
}

@mixin font-size-xlarge() {
    font-size: 20px;
    letter-spacing: -0.4px;
    line-height: math.div(24px, 20px);
}

@mixin font-size-large() {
    font-size: 18px;
    letter-spacing: -0.3px;
    line-height: math.div(26px, 18px);
}

@mixin font-size-medium() {
    font-size: 16px;
    letter-spacing: -0.2px;
    line-height: math.div(24px, 16px);
}

@mixin font-size-small() {
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: math.div(18px, 14px);
}

@mixin font-size-xsmall() {
    font-size: 12px;
    line-height: math.div(16px, 12px);
}

@mixin font-size-xxsmall() {
    font-size: 10px;
    line-height: math.div(14px, 10px);
}

// display
@mixin font-display-medium($options: ()) {
    @include font-default($options);
    @include font-din-medium($options);

    font-size: 40px;
    letter-spacing: -0.8px;
    line-height: math.div(43px, 40px);
}

@mixin font-display-large($options: ('breakpoint': large)) {
    @include font-display-medium($options);

    @if font-get-option-scale($options) {
        @include breakpoint(font-get-option-breakpoint($options)) {
            font-size: 64px;
            letter-spacing: -1px;
            line-height: math.div(69px, 64px);
        }
    }
}

// headings
@mixin font-head-xlarge($options: ()) {
    @include font-default($options);
    @include font-size-xxxlarge;

    @if font-get-option-scale($options) {
        @include breakpoint(font-get-option-breakpoint($options)) {
            font-size: 44px;
            letter-spacing: -0.8px;
            line-height: math.div(52px, 44px);
        }
    }
}

@mixin font-head-large($options: ()) {
    @include font-default($options);
    @include font-size-xxlarge;

    @if font-get-option-scale($options) {
        @include breakpoint(font-get-option-breakpoint($options)) {
            @include font-size-xxxlarge;
        }
    }
}

@mixin font-head-medium($options: ()) {
    @include font-default($options);
    @include font-size-xlarge;

    @if font-get-option-scale($options) {
        @include breakpoint(font-get-option-breakpoint($options)) {
            @include font-size-xxlarge;
        }
    }
}

@mixin font-head-small($options: ()) {
    @include font-default($options);
    @include font-size-large;

    @if font-get-option-scale($options) {
        @include breakpoint(font-get-option-breakpoint($options)) {
            @include font-size-xlarge;
        }
    }
}

// Text
@mixin font-text-xxlarge($options: ()) {
    @include font-head-medium($options);
}

@mixin font-text-xlarge($options: ()) {
    @include font-default($options);
    @include font-size-large;

    @if font-get-option-scale($options) {
        @include breakpoint(font-get-option-breakpoint($options)) {
            @include font-size-xlarge;

            line-height: math.div(28px, 20px);
        }
    }
}

@mixin font-text-large($options: ()) {
    @include font-default($options);
    @include font-size-medium;

    @if font-get-option-scale($options) {
        @include breakpoint(font-get-option-breakpoint($options)) {
            @include font-size-large;
        }
    }
}

@mixin font-text-medium($options: ()) {
    @include font-default($options);
    @include font-size-small;

    @if font-get-option-scale($options) {
        @include breakpoint(font-get-option-breakpoint($options)) {
            @include font-size-medium;
        }
    }
}

@mixin font-text-small($options: ()) {
    @include font-default($options);
    @include font-size-xsmall;

    @if font-get-option-scale($options) {
        @include breakpoint(font-get-option-breakpoint($options)) {
            @include font-size-small;
        }
    }
}

@mixin font-text-xsmall($options: ()) {
    @include font-default($options);
    @include font-size-xsmall;
}

@mixin font-text-by-name($size, $options: ('color': false, 'scale': true )) {
    @if $size == 'xs' {
        @include font-text-small($options);
    } @else if $size == 'sm' {
        @include font-text-medium($options);
    } @else if $size == 'md' {
        @include font-text-large($options);
    } @else if $size == 'lg' {
        @include font-text-xlarge($options);
    } @else if $size == 'xl' {
        @include font-text-xxlarge($options);
    } @else {
        @warn "Unknown size '#{$size}.'";
    }
}
