.rb-block-bikedetail {
    @include font-din-regular;

    .cms__module {
        margin-bottom: 30px;
        line-height: 20px;

        @include breakpoint(medium up) {
            line-height: 24px;
        }
    }

    .cms__headline {
        @include font-din-medium;
        font-size: 16px;
        line-height: 22px;
        margin-top: 20px;
        margin-bottom: 8px;
    }

    .cms__image {
        max-width: 200px;
        display: block;
        margin-left: auto;
        margin-right: auto;

        &--centered {
            height: $sp-800;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .has--image .cms__image {
        max-width: none;
    }

    .cms__paddingbox {
        padding-left: 0;
        padding-right: 0;
    }

    @include breakpoint(mediumlarge only) {
        .columns:nth-child(odd) {
            clear: left;
        }
    }

    @include breakpoint(medium only) {
        .columns:nth-child(odd) {
            clear: left;
        }
    }
}
