@mixin h-reset {
    // https://www.paulirish.com/2012/box-sizing-border-box-ftw/
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    color: inherit;
    -webkit-tap-highlight-color: rgba($color-white, 0);
}
