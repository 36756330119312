@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-chatbot-help-button($name: '.chatbot-help-button') {
    $button-light: '#{$name}--button-light';
    $show-bubble: '#{$name}--show-bubble';
    $animating-bubble: '#{$name}--animating-bubble';
    $bubble-closable: '#{$name}--bubble-closable';
    $box-shadow-button: 0 6px 16px rgba($color-gray-10, 0.15);

    #{$name} {
        display: flex;
        position: fixed;
        flex-direction: row-reverse;
        align-items: center;
        bottom: 0;
        right: 0;
        z-index: 10;

        &__button {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            margin: $sp-200 $sp-200 $sp-200 0;
            overflow: hidden;
            z-index: 10;
            background-color: $color-gray-10;
            border-radius: 50%;
            color: $color-accent;
            transition: $global-button-transition;
            cursor: pointer;

            &:hover,
            &:focus {
                background-color: $color-accent;
                outline: none;
                color: $color-gray-10;
                box-shadow: $box-shadow-button;
            }

            &:active {
                background-color: $color-gray-10;
                color: $color-accent;
                box-shadow: none;
            }

            #{$button-light} & {
                background-color: $color-white;
                color: $color-gray-10;

                &:hover,
                &:focus {
                    background-color: $color-accent;
                    outline: none;
                    box-shadow: $box-shadow-button;
                }

                &:active {
                    background-color: $color-white;
                    box-shadow: none;
                }
            }
        }

        &__bubble {
            display: none;
            position: relative;
            margin-right: $sp-080;
            padding: 0 $sp-160;
            opacity: 0;
            background-color: $color-accent-70;
            border-radius: 4px;
            transform: scale(0);
            transform-origin: right;
            transition: transform 0.7s, opacity 0.7s;

            #{$animating-bubble} & {
                display: block;
            }

            #{$show-bubble} & {
                display: block;
                opacity: 1;
                transform: scale(1);
                animation: helpwidget-bubble-animation 0.5s ease-out;
            }

            #{$bubble-closable} & {
                cursor: pointer;
            }

            @keyframes helpwidget-bubble-animation {
                0% {
                    transform: scale(0);
                }

                50% {
                    transform: scale(1.15);
                }

                75% {
                    transform: scale(0.95);
                }

                100% {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }

        &__bubble-arrow {
            display: block;
            position: absolute;
            top: 50%;
            left: 100%;
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent;
            border-left-color: $color-accent-70;
        }

        &__bubble-body {
            @include font-din-regular;

            display: flex;
            align-items: center;
            overflow: hidden;
            font-size: 14px;
            color: $color-gray-10;
            line-height: 1;
        }

        &__bubble-text {
            padding: $sp-160 0;
        }

        &__close-bubble-icon {
            margin-left: $sp-160;
            cursor: pointer;
        }
    }
}
