@import '~ShopUiBundle/styles/shared';

@mixin checkout-bundle-info-text($name: '.info-text') {
    #{$name} {
        display: block;
        padding: $sp-160 0;

        &__title {
            @include font-din-bold;

            display: block;
            margin: $sp-080 0 $sp-160;
            font-size: 24px;
            color: $color-gray-10;
            letter-spacing: -0.1px;
            line-height: 28px;
        }
    }
}
