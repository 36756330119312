@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-column-title($name: '.column-title') {
    #{$name} {
        @include font-din-bold;

        display: block;
        padding: $sp-160 0 $sp-240 0;
        font-size: 20px;
        color: $color-gray-10;
        line-height: 28px;

        @include breakpoint(medium) {
            padding: $sp-240 0;
        }
    }
}
