@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-checkbox-slider($name: '.checkbox-slider') {

    #{$name} {
        display: flex;
        padding: $spacing-md 0 $spacing-md 1px;

        &__label {
            display: inline-block;
            position: relative;
            min-width: 33px;
            height: 20px;
        }

        &__input {
            position: absolute;
            width: 0;
            height: 0;
            opacity: 0;
        }

        &__input:checked + &__slider {
            background-color: $color-positive-50;

            &::before {
                transform: translateX(12px);
            }
        }

        &__input:disabled + &__slider {
            cursor: not-allowed;

            &::before {
                content: '';
                position: absolute;
                bottom: 2px;
                left: 2px;
                min-width: 16px;
                min-height: 16px;
                background-color: $color-white;
                border-radius: 50%;
                transition: $transition-300;
            }
        }

        &__input:checked:disabled + &__slider {
            background-color: $color-positive-50;
            cursor: not-allowed;

            &::before {
                content: '';
                position: absolute;
                bottom: 2px;
                left: 2px;
                min-width: 16px;
                min-height: 16px;
                background-color: $color-white;
                border-radius: 50%;
                transition: $transition-300;
            }
        }

        &__slider {
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: space-between;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $color-gray-70;
            border-radius: 10px;
            transition: $transition-300;
            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                bottom: 2px;
                left: 2px;
                min-width: 16px;
                min-height: 16px;
                background-color: $color-white;
                border-radius: 50%;
                transition: $transition-300;
            }
        }

        &__text {
            @include font-text-small((
                'color': $color-gray-20,
            ));

            padding: 0 0 0 $spacing-sm;
        }
    }
}
