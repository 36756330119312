@import '~ShopUiBundle/components/molecules/main-navigation-category/main-navigation-category';

@mixin shop-ui-main-navigation-cms-category-item($name: '.main-navigation-cms-category-item') {
    @include shop-ui-main-navigation-category($name);

    #{$name} {
        display: block;

        &__title {
            white-space: normal;
        }
    }
}
