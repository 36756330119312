@import '~ShopUiBundle/styles/shared';

@mixin product-bundle-product-subtitle($name: '.product-subtitle') {
    #{$name} {
        @include font-text-medium;

        margin: 0;
    }
}

@include product-bundle-product-subtitle;
