@import '~ShopUiBundle/styles/shared';

@mixin shipment-bundle-shipment-customer-phone($name: '.shipment-customer-phone') {

    #{$name} {
        display: block;
        padding-top: $sp-200;

        &__description {
            @include font-text-small;
        }
    }
}
