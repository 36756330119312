$global-border-radius: 1px;
$global-button-border-radius: 5px;
$global-title-letter-spacing: 0.5px;
$global-catalog-color-map: (
    'beige': #e0d1b2,
    'black': #000000,
    'blue': #00008b,
    'brown': #653d0b,
    'green': #007e02,
    'grey': #757575,
    'lila': #7428a1,
    'oliv': #808000,
    'olive': #808000,
    'orange': #ff8e0d,
    'petrol': #17728f,
    'pink': #e61b89,
    'purple': #7428a1,
    'red': #eb0037,
    'turquoise': #48d1cc,
    'white': #f9f9f9,
    'withe': #f9f9f9,
    'yellow': #faee50,
    'empty': #ffffff,
);
$global-catalog-color-threshold: 10%;
