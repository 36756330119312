@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-usercentrics-dialog($name: '.usercentrics-dialog') {
    $rendered: '#{$name}--rendered';

    #{$name} {
        display: none;
        position: relative;
        min-height: 300px;
        background-color: $color-white;
        background-position: center center;
        background-size: cover;
        color: $color-gray-10;

        &__content {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 400px;
            max-width: calc(100% - #{$sp-400});
            max-height: calc(100% - #{$sp-320});
            padding: $sp-240;
            visibility: hidden;
            overflow: hidden auto;
            z-index: 1;
            background-color: rgba($color-white, 0.9);
            text-align: center;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.13);
            transform: translate(-50%, -50%);

            @include breakpoint(medium) {
                padding: $sp-400;
            }

            #{$rendered} & {
                visibility: visible;
            }
        }

        &__loading {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
            z-index: 2;
            transform: translate(-50%, -50%);

            #{$rendered} & {
                display: none;
            }
        }

        &__title {
            @include font-din-bold;

            margin: 0;
            font-size: 18px;
            line-height: 24px;
        }

        &__text {
            margin-top: $sp-240;
            margin-bottom: 0;
        }

        &__buttons {
            display: flex;
            flex-wrap: wrap;
            margin-top: $sp-240;
            gap: $sp-240;
        }

        &__more,
        &__accept {
            flex: 1 0 140px;
        }
    }
}
