@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-selection-field($name: '.selection-field') {
    #{$name} {
        display: flex;
        position: relative;

        @include breakpoint(large) {
            height: 100%;
        }

        &__content {
            flex-grow: 1;
            min-height: 104px;
            padding: $sp-160;
            background-color: $color-white;
            border: 1px solid $color-white;
            border-radius: $global-border-radius;
            box-shadow: 0 4px 8px rgba($color-gray-10, 0.2);
            cursor: pointer;
        }

        &__title {
            @include font-din-bold;

            width: calc(100% - #{$sp-160});
            margin-bottom: $sp-040;
            font-size: 14px;
            color: $color-gray-10;
            line-height: 20px;
        }

        &__text {
            @include font-din-regular;

            font-size: 12px;
            color: $color-gray-20;
            line-height: 16px;
        }

        &__icon {
            position: absolute;
            top: 0;
            right: 0;
            width: 16px;
            height: 16px;
            margin: $sp-080;
            padding: 2px;
            // stylelint-disable-next-line declaration-no-important
            background-color: $color-accent !important;
            border-radius: 50%;
        }

        &__input:not(:checked) {
            & + #{$name}__content {
                border: 1px solid $color-gray-80;
                box-shadow: none;
            }

            & + #{$name}__content > #{$name}__title {
                color: $color-gray-20;
            }

            & ~ #{$name}__icon {
                // stylelint-disable-next-line declaration-no-important
                background: transparent !important;
                color: transparent;
            }

            // stylelint-disable-next-line order/order
            @media (hover: hover) and (pointer: fine) {
                #{$name}:hover & {
                    & + #{$name}__content {
                        background: $color-gray-90;
                    }

                    & + #{$name}__content > * {
                        color: $color-gray-10;
                    }

                    & ~ #{$name}__icon {
                        // stylelint-disable-next-line declaration-no-important
                        background: $color-gray-80 !important;
                        color: transparent;
                    }
                }
            }
        }
    }
}
