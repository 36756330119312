@import '~ShopUiBundle/styles/shared';

@mixin catalog-suggestions-empty($name: '.catalog-suggestions-empty') {

    #{$name} {
        @include font-text-medium((
            'bold': false,
        ));

        display: block;
        min-height: 54px;
        padding: 15px 10px;
    }

    @content;
}
