@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-basic-checkbox($name: '.basic-checkbox') {
    $disabled: '#{$name}--disabled';
    $readonly: '#{$name}--readonly';
    $no-label: '#{$name}--no-label';
    $no-wrap: '#{$name}--no-wrap';
    $inline: '#{$name}--inline';
    $block: '#{$name}--block';
    $border: '#{$name}--border';
    $background: '#{$name}--background';

    #{$name} {
        display: flex;
        padding: $sp-160 0 $sp-160 1px;
        opacity: 1;
        overflow: hidden;
        transition: opacity 0.2s ease-out;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        &#{$disabled},
        &#{$readonly} {
            color: $color-gray-20;
            cursor: default;
        }

        &#{$disabled} {
            cursor: not-allowed;
        }

        &#{$no-label} {
            display: inline-flex;
            padding-right: 0;
        }

        &#{$inline} {
            display: inline-flex;
            padding-right: $sp-320;
        }

        &#{$block} {
            display: flex;
            padding-right: 0;
        }

        &#{$border} {
            align-items: center;
            padding: 10px 10px 10px $sp-160;
            border: 1px solid $color-gray-80;
            border-radius: $global-border-radius;
        }

        &#{$background} {
            align-items: center;
            margin-bottom: 0;
            padding: 10px 10px 10px $sp-160;
            background-color: $color-gray-90;
            border: 1px solid $color-gray-90;
            border-radius: $global-border-radius;
        }

        &__text {
            @include font-din-regular;

            overflow: hidden;
            font-size: 12px;
            color: $color-gray-20;
            line-height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;

            #{$no-wrap} & {
                overflow: visible;
                white-space: normal;
                text-overflow: unset;
            }

            #{$disabled} &,
            #{$readonly} & {
                color: $color-gray-20;
            }

            #{$border} & {
                @include font-din-bold;

                line-height: 16px;
            }

            #{$background} & {
                padding: 0 $sp-160;
                font-size: 14px;
                color: $color-gray-10;
                line-height: 20px;
            }
        }

        &__svg {
            display: inline-block;
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            margin-right: $sp-080;
            overflow: visible;
            vertical-align: top;
        }

        &__square {
            stroke: $color-gray-20;
            transition: fill 0.2s ease-out, stroke 0.2s ease-out;
            fill: $color-white;

            #{$disabled} &,
            #{$readonly} & {
                stroke: $color-gray-80;
            }
        }

        &__tick {
            stroke-dasharray: 16px;
            stroke-dashoffset: 16px;
            stroke: $color-white;
            transition: stroke-dashoffset 0.2s ease-out;
        }

        &__input {
            position: absolute;
            width: 16px;
            height: 16px;
            margin: 0;
            opacity: 0;
            cursor: pointer;

            &[readonly],
            &[disabled] {
                pointer-events: none;
            }

            &:checked + #{$name}__svg #{$name}__square {
                stroke: $color-gray-10;
                fill: $color-gray-10;

                #{$disabled} &,
                #{$readonly} & {
                    stroke: $color-gray-90;
                    fill: $color-gray-90;
                }
            }

            &:checked + #{$name}__svg #{$name}__tick {
                stroke-dashoffset: 0;

                #{$disabled} &,
                #{$readonly} & {
                    stroke: $color-gray-80;
                }
            }

            &:checked ~ #{$name}__text {
                color: $color-gray-10;

                #{$disabled} &,
                #{$readonly} & {
                    color: $color-gray-20;
                }
            }
        }

        &:focus {
            outline: none;
        }

        &:hover:not(#{$disabled}):not(#{$readonly}),
        &:focus:not(#{$disabled}):not(#{$readonly}) {
            #{$name}__square {
                fill: $color-gray-90;
            }

            #{$name}__input:checked + #{$name}__svg #{$name}__square {
                stroke: $color-gray-20;
                fill: $color-gray-20;
            }

            #{$name}__text {
                text-decoration: underline;
            }

            &#{$border} #{$name}__text {
                text-decoration: none;
            }
        }

        &:active:not(#{$disabled}):not(#{$readonly}) {
            #{$name}__square {
                fill: $color-gray-20;
            }

            #{$name}__input:checked + #{$name}__svg #{$name}__square {
                stroke: $color-gray-90;
                fill: $color-gray-90;
            }
        }
    }
}
