@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-main-navigation-category-bikes-level-2($name: '.main-navigation-category-bikes-level-2') {
    $list-width: calc(#{$container-max-width} * 0.12);
    $col-padding: $sp-800;
    $slot-width: calc(#{$container-max-width} * 0.55);
    $slot-width-large: calc(#{$container-max-width} * 0.45);
    $slot-width-xlarge: calc(#{$container-max-width} * 0.55);

    #{$name} {
        display: flex;
        position: relative;

        &__content {
            display: none;
            position: absolute;
            top: 0;
            left: 100%;
            padding-left: $sp-400;
            opacity: 0;

            &--open {
                display: block;
                animation: 0.35s main-navigation-category-bikes-slide 0.2s ease forwards;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    display: none;
                }
            }
        }

        &__detail-content {
            display: block;
            position: absolute;
            top: 0;
            left: calc(#{$list-width} + #{$col-padding});
            width: $slot-width;
            min-width: $slot-width;
            max-width: $slot-width;
            padding-top: $sp-400;
            opacity: 1;

            @include breakpoint(large up) {
                width: $slot-width-large;
                min-width: $slot-width-large;
                max-width: $slot-width-large;
            }

            @include breakpoint(xlarge up) {
                width: $slot-width-xlarge;
                min-width: $slot-width-xlarge;
                max-width: $slot-width-xlarge;
            }
        }

        &__list {
            display: block;
            position: relative;
            list-style: none;
            width: $list-width;
            min-width: $list-width;
            max-width: $list-width;
            margin: 0;
            padding: $sp-400 0 0 0;
        }

        &__list-item:first-of-type > #{$name}__item > #{$name}__link {
            padding-top: 0;
        }

        &__detail-list {
            display: block;
            position: absolute;
            list-style: none;
            width: calc(#{$container-max-width} - (#{$col-padding} * 2) - (#{$list-width} * 2) - #{$grid-column-gutter} - 80px);
            max-width: calc(#{$container-max-width} - (#{$col-padding} * 2) - (#{$list-width} * 2) - #{$grid-column-gutter} - 80px);
            margin: 0;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                display: none;
            }

            @include breakpoint(xlarge up) {
                width: calc(#{$container-max-width} - #{$col-padding} - (#{$list-width} * 2) - #{$grid-column-gutter});
                max-width: calc(#{$container-max-width} - #{$col-padding} - (#{$list-width} * 2) - #{$grid-column-gutter});
            }

            @include breakpoint(xxlarge up) {
                width: calc(#{$container-max-width} - #{$col-padding} - (#{$list-width} * 2));
                max-width: calc(#{$container-max-width} - #{$col-padding} - (#{$list-width} * 2));
            }
        }

        &__container {
            display: none;
            height: 100%;
            max-height: 420px;
            padding: $sp-400 0 0 0;
            opacity: 0;

            @include breakpoint(xlarge up) {
                max-height: 460px;
            }

            &--open {
                display: block;
                position: absolute;
                left: 50%;
                width: $slot-width;
                max-width: $slot-width;
                transform: translateX(-#{$slot-width});
                animation: 0.55s main-navigation-category-bikes-slot 0.2s ease forwards;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    display: none;
                }

                @include breakpoint(large up) {
                    width: $slot-width-large;
                    max-width: $slot-width-large;
                    transform: translateX(-#{$slot-width-large});
                }

                @include breakpoint(xlarge up) {
                    width: $slot-width-xlarge;
                    max-width: $slot-width-xlarge;
                    transform: translateX(-#{$slot-width-xlarge});
                }
            }
        }
    }
}
