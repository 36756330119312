@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-bundle-advantage-teaser($name: '.advantage-teaser') {

    #{$name} {
        &__headline-row {
            background: white;
        }

        &__headline {
            margin: 0;
            padding-top: 15px;
            padding-bottom: 15px;
            background: white;
            font-size: 22px;
            text-align: center;
        }
    }
}

@include shop-ui-bundle-advantage-teaser;
