@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-form-make-input($name: '.form-input-abstract') {

    @include font-din-regular;

    $disabled: '#{$name}--disabled';
    $error: '#{$name}--error';

    display: block;
    position: relative;
    margin-bottom: 44px;
    font-size: 14px;
    color: $color-gray-10;
    letter-spacing: 0;
    line-height: 20px;

    &#{$disabled} {
        pointer-events: none;
    }

    &__error {
        display: none;
        position: absolute;
        top: calc(100% + 5px);
        left: 0;
        right: 0;
        height: 32px;
        overflow: hidden;
        font-size: 12px;
        color: $color-error;
        line-height: 16px;
        text-overflow: ellipsis;
        transition: all 0.1s ease;
        cursor: default;

        #{$error} & {
            display: block;

            @supports (-webkit-line-clamp: 2) {
                & {
                    // stylelint-disable-next-line value-no-vendor-prefix
                    display: -webkit-box;
                    // stylelint-disable-next-line property-no-vendor-prefix
                    -webkit-line-clamp: 2;
                    // stylelint-disable-next-line property-no-vendor-prefix
                    -webkit-box-orient: vertical;
                }
            }
        }
    }

    @content;
}
