// TODO: remove
.dropdown {
    &-toggle {
        outline: none;

        > * {
            pointer-events: none;
        }

        .icon {
            transform-origin: top center;
        }

        &.hover {
            color: $color-white;
            background-color: $color-gray-20;

            .icon {
                transform: rotate(180deg) translateY(-100%);
            }
        }
    }
}

.toggle-dropdown {
    position: relative;

    &--margin-top {
        margin: $sp-160 0 0 0;
    }

    .toggle-dropdown__content {
        position: absolute;
        z-index: 10;
        width: 100%;
        background: $color-white;

        display: none;

        &.relative {
            position: relative;
        }
    }

    button:focus {
        outline: 0;
    }

    .toggle-dropdown__trigger {
        .icon {
            transform-origin: top center;
        }
    }

    &.is-open {
        outline: none;

        .toggle-dropdown__content {
            display: block;
        }

        .button--white {
            background-color: $color-gray-10;
            color: $color-white;
        }

        .toggle-dropdown__trigger {
            .icon {
                transform: rotate(180deg) translateY(-50%);
            }
        }
    }

    &.dropdown__filter {
        padding: 0;
        margin-bottom: 20px;
        position: relative;

        &.is-open {
            .dropdown__filter--title {
                background: $color-gray-10;
                color: $color-white;

                &:hover {
                    color: $color-white;
                    background-color: $color-gray-20;
                }

                &::before {
                    margin-top: -6px;
                    transform: rotate(-180deg);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3E%3Cpolygon fill='%23#{str-slice(($color-white + unquote("")), 2)}' points='5.02 4.3 0.88 0.16 0.03 1.01 5.02 6 10.01 1.01 9.16 0.16 5.02 4.3'/%3E%3C/svg%3E");
                }
            }
        }

        .dropdown__filter--title {
            @include font-din-regular();
            padding: 15px;
            text-transform: none;
            line-height: 1;
            height: 45px;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                right: 1rem;
                width: 12px;
                height: 10px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3E%3Cpolygon fill='%23#{str-slice(($text-color + unquote("")), 2)}' points='5.02 4.3 0.88 0.16 0.03 1.01 5.02 6 10.01 1.01 9.16 0.16 5.02 4.3'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: 100%;
                transform-origin: 50%;
                margin-top: -2px;
            }

            &:hover {
                background-color: $color-gray-90;
                color: $color-gray-20;
            }
        }

        .toggle-dropdown__content {
            padding: 15px;
            position: absolute;
            z-index: 30;
            width: 100%;
            border: 1px solid $color-gray-20;
        }

        @include breakpoint(small down) {
            margin-bottom: 10px;
        }
    }
}
