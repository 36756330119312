@import '~ShopUiBundle/styles/shared';

@mixin product-bundle-product-tile-sponsor-flag($name: '.product-tile-sponsor-flag') {
    #{$name} {
        @include font-default;
        @include font-size-xxsmall;

        display: block;
        overflow: hidden;
        z-index: 1;
        color: $color-gray-50;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: keep-all;

        @include breakpoint(mediumlarge) {
            @include font-size-xsmall;
        }
    }
}

@include product-bundle-product-tile-sponsor-flag;
