@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-intro-text($name: '.intro-text') {
    $light: '#{$name}--light';
    $bold: '#{$name}--bold';

    #{$name} {
        display: block;
        width: 100%;

        &__content {
            padding: 40px 0;
            text-align: center;

            &-text {
                @include font-head-xlarge;

                margin: 0;

                #{$light} & {
                    color: $color-white;
                }
            }
        }
    }
}

@include shop-ui-intro-text;
