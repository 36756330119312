@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-fancy-accordion($name: '.fancy-accordion') {
    $tab-is-open: '#{$name}__tab--is-open';
    $accordion-is-open: '#{$name}--is-open';
    $tab-on-hover: '#{$name}__tab:hover';
    $padding: 30px;
    $animation-speed: 0.5s;
    $animation-timing: ease-in-out;

    #{$name} {
        display: flex;
        position: relative;
        background-color: $color-gray-20;
        color: $color-white;

        @include breakpoint(large up) {
            display: flex;
            width: auto;
            height: 550px;
        }

        &__background-image {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &__tab-title {
            @include font-din-regular;

            margin: 0;
            padding: 0;
            font-size: 12px;

            #{$accordion-is-open} & {
                @include breakpoint(large up) {
                    writing-mode: vertical-lr;
                }
            }

            #{$tab-is-open} & {
                @include breakpoint(large up) {
                    writing-mode: horizontal-tb;
                }
            }
        }

        &__tab-subtitle {
            @include font-din-bold;

            margin: 7px 0 0;
            padding: 0;
            font-size: 22px;
            line-height: 1.3;

            #{$accordion-is-open} & {
                display: none;
            }

            #{$tab-is-open} & {
                display: block;

                @include breakpoint(large up) {
                    font-size: 35px;
                }
            }
        }

        &__list {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 100%;
            margin: 0;
            padding: 0;

            @include breakpoint(large up) {
                flex-direction: row;
            }

            #{$accordion-is-open} & {
                background-color: rgba(0, 0, 0, 0.2);
            }
        }

        &__tab {
            display: inline-flex;
            position: relative;
            flex: 1 1 auto;
            justify-content: center;
            overflow: hidden;
            border-bottom: 1px solid rgba($color-white, 0.7);
            transition: flex $animation-speed $animation-timing,
                background-color $animation-speed $animation-timing;

            @include breakpoint(large up) {
                min-width: 80px;
                border-bottom: 0;
                border-right: 1px solid rgba($color-white, 0.7);
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.3);
            }

            #{$accordion-is-open} & {
                flex: 0 1 auto;
            }

            &#{$tab-is-open} {
                flex: 1 1 auto;
                justify-content: flex-start;

                &:hover {
                    background-color: rgba(0, 0, 0, 0);
                }
            }
        }

        &__tab-header {
            @include font-din-medium;

            display: flex;
            position: relative;
            flex-direction: column;
            align-items: stretch;
            justify-content: center;
            min-height: 60px;
            margin: 0;
            padding: $padding;
            border-bottom: 1px solid rgba($color-white, 0);
            font-weight: bold;
            text-transform: uppercase;
            transition: height $animation-speed $animation-timing;
            cursor: pointer;

            @include breakpoint(large up) {
                flex: auto;
                justify-content: flex-start;
                height: 100%;
                padding: 2 * $padding $padding $padding $padding;
                border: 0;
            }

            #{$tab-is-open} & {
                position: static;

                @include breakpoint(large up) {
                    flex: 0 0 auto;
                    height: auto;
                    border-bottom: 0;
                }

                @include breakpoint(large down) {
                    padding-bottom: 0;
                }
            }
        }

        &__tab-headline {
            display: flex;
            flex-direction: column;
            padding-right: 35px;

            @include breakpoint(large up) {
                padding-right: 0;

                #{$accordion-is-open} & {
                    align-items: center;
                    transform: rotate(180deg);
                }

                #{$tab-is-open} & {
                    display: block;
                    align-self: flex-start;
                    transform: rotate(0);
                }
            }
        }

        &__tab-header-controls {
            display: inline-flex;
            position: absolute;
            align-content: center;
            justify-content: center;
            box-sizing: content-box;
            top: 50%;
            right: 20px;
            width: 20px;
            height: 20px;
            padding: 20px;
            font-size: 24px;
            font-weight: normal;
            transform: translateY(-50%);
            transition: top $animation-speed $animation-timing;
            cursor: pointer;

            @include breakpoint(large up) {
                top: auto;
                bottom: 30px;
                left: 50%;
                border: 2px solid $color-white;
                border-radius: 50%;
                transform: translateX(-50%);
            }

            #{$tab-on-hover} & {
                @include breakpoint(large up) {
                    background-color: $color-white;
                    color: black;
                }
            }

            #{$accordion-is-open} & {
                @include breakpoint(large up) {
                    border: 0;
                }
            }

            #{$tab-is-open} & {
                top: 15px;
                transform: rotate(45deg);

                @include breakpoint(large up) {
                    top: 30px;
                    bottom: auto;
                    left: auto;
                    right: 30px;
                }
            }
        }

        &__tab-body {
            position: relative;
            max-height: 0;
            overflow: hidden;
            transition: max-height $animation-speed $animation-timing;

            @include breakpoint(large up) {
                opacity: 0;
                transition: opacity $animation-speed $animation-timing;

                #{$tab-is-open} & {
                    flex: 1 0 auto;
                    opacity: 1;
                }
            }
        }

        &__tab-body-inner {
            margin: $padding;
            opacity: 0;
            transition: opacity $animation-speed $animation-timing;

            @include breakpoint(large up) {
                max-width: 70%;
            }

            @include breakpoint(large down) {
                margin-top: 15px;
            }

            #{$tab-is-open} & {
                opacity: 1;
            }
        }

        &__tab-inner {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;

            @include breakpoint(large up) {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

@include shop-ui-fancy-accordion;
