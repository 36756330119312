@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-account-order-overview-item($name: '.account-order-overview-item') {
    $breakpoint: 'medium';

    #{$name} {
        display: flex;
        flex-direction: column;
        padding-top: $sp-240;
        padding-bottom: $spacing-lg;
        border-bottom: 2px solid $color-gray-80;

        @include breakpoint($breakpoint) {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 0;
        }

        &__header {
            padding-bottom: $sp-080;

            @include breakpoint($breakpoint) {
                padding: 0;
            }
        }

        &:first-of-type {
            padding-top: $sp-080;

            @include breakpoint($breakpoint) {
                padding-top: 0;
            }
        }

        &__subtitle {
            font-size: 14px;
            color: $color-gray-50;
            line-height: 20px;
        }

        &__title {
            display: flex;
            align-items: center;
            font-size: 20px;
            color: $color-gray-10;
            line-height: 28px;
        }

        &__tooltip {
            height: 14px;
            padding-left: $spacing-sm;
        }

        &__buttons {
            display: flex;
            flex-direction: column;
            order: 3;

            @include breakpoint($breakpoint) {
                display: block;
                order: initial;
            }
        }

        &__button {
            width: 100%;

            @include breakpoint($breakpoint) {
                width: auto;
                margin-top: 0;
            }
        }

        &__button-return {
            order: 2;
            margin-top: $spacing-md;

            @include breakpoint($breakpoint) {
                order: initial;
                margin-right: $spacing-md;
            }
        }

        &__content {
            padding-bottom: $spacing-lg;

            @include breakpoint($breakpoint) {
                width: 100%;
                padding: $sp-240 0;
            }
        }

        &__images {
            display: flex;
            align-items: center;
            padding-top: $sp-160;

            @include breakpoint($breakpoint) {
                padding-top: 0;
            }
        }

        &__picture {
            display: inline-flex;
            flex-basis: 55px;
            align-items: center;
            box-sizing: content-box;
            height: 55px;
            padding-left: $sp-200;
            padding-right: 0;
            text-decoration: none;

            @include breakpoint($breakpoint) {
                flex-basis: 69px;
                height: 69px;
            }

            @include breakpoint(large) {
                flex-basis: 100px;
                height: 100px;
            }

            &:first-child {
                padding-left: 0;
            }
        }

        &__show-more-link {
            display: inline-flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            margin-left: $sp-200;
            background-color: $color-gray-90;
            border-radius: 50%;
            font-size: 14px;
            color: $color-gray-20;
            text-decoration: none;

            @include breakpoint(large) {
                width: 40px;
                height: 40px;
            }
        }
    }
}
