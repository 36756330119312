@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-bundle-info-banner($name: '.info-banner') {
    $background-color: $color-gray-80;
    $text-color: $color-gray-20;

    #{$name} {
        display: block;
        padding: 10px 0;
        background: $background-color;

        &__text {
            @include font-text-xsmall;

            margin: 0;
            text-align: center;
        }
    }
}

@include shop-ui-bundle-info-banner;
