@import '~ShopUiBundle/components/molecules/list-with-title/list-with-title';

@mixin shop-ui-list-payment-icons($name: '.list-payment-icons') {
    @include shop-ui-list-with-title($name);

    #{$name} {
        &__list-item-link {
            display: flex;
        }
    }
}

@include shop-ui-list-payment-icons;
