@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-title-with-content-v2($name: '.title-with-content-v2') {
    $breakpoint-desktop: mediumlarge;
    $background-gray: '#{$name}--background-gray';

    #{$name} {
        display: block;
        position: relative;
        z-index: 0;

        &#{$background-gray} {
            @include breakpoint($breakpoint-desktop) {
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: 100vw;
                    height: 100%;
                    z-index: -1;
                    background: $color-gray-90;
                    transform: translateX(-50%);
                }
            }
        }

        &__header {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: $spacing-lg;
        }

        &__title {
            @include font-head-large((
                'breakpoint': xxlarge,
            ));

            margin: 0;
        }

        &__content {
            width: 100%;

            #{$name}--no-padding & {
                padding: 0;
            }
        }
    }
}
