@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-search-box($name: '.search-box') {
    $border-width: 4px;
    $max-suggestions-view-width: 550px;
    $has-submit-button: #{$name}--has-submit-button;
    $has-caret-button: #{$name}--has-caret-button;
    $header: #{$name}--header;

    #{$name} {
        @include font-text-medium;

        display: inline-block;
        position: relative;
        height: 43px;
        z-index: 1;
        border: $border-width solid $color-white;
        outline: none;

        &#{$header} {
            height: 40px;
            border: 0;
        }

        &__text-input {
            height: 100%;
            padding-right: 10px;
            border-radius: $global-button-border-radius;
            box-shadow: none;

            #{$has-submit-button} & {
                padding-right: 0;
            }

            #{$has-caret-button} & {
                padding-left: 0;
            }

            // Avoid specifity problems with text-input.scss [#{&}__input]
            & #{$name}__input {
                @include font-default;
                @include font-size-small;
                // stylelint-disable-next-line declaration-no-important
                color: $text-color !important;

                &::placeholder {
                    // stylelint-disable-next-line declaration-no-important
                    color: $color-gray-50 !important;
                }
            }
        }

        &__loading-view {
            position: relative;
            min-height: 54px;
            z-index: 0;
        }

        &__view-loader {
            position: absolute;
            top: calc(100% + #{$border-width});
            right: 0;
            width: 100%;
            max-width: $max-suggestions-view-width;
            background: $color-white;
            border: 1px solid $body-color;
            border-top: none;
        }

        &__submit-button,
        &__caret-button {
            width: 38px;
            height: 100%;
            outline: none;
            color: $color-gray-10;
            transition: background-color 0.3s ease-in-out;
            cursor: pointer;

            &:hover,
            &:focus {
                background-color: $color-gray-90;
                color: $color-gray-10;
            }
        }

        &__caret-button {
            width: 20px;
            margin-right: $border-width;
            background-color: transparent;
            color: $color-gray-10;
        }

        &__submit-button {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 10px;
        }
    }

    @content;
}
