@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-catalog-category-model-info($name: '.catalog-category-model-info') {
    #{$name} {
        display: block;
        color: $color-gray-10;

        &__list {
            display: grid;
            grid-template-columns: auto 1fr;
            margin: -$sp-080 0 0 0;
            padding: 0;
        }

        &__key,
        &__value {
            @include font-text-small;

            margin: $sp-080 0 0 0;
            padding: 0;
        }

        &__key {
            margin-right: 5px;
        }
    }
}
