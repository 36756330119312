@import '~ShopUiBundle/components/molecules/list-with-title/list-with-title';

@mixin shop-ui-list-certification-logos($name: '.list-certification-logos') {
    @include shop-ui-list-with-title($name);

    #{$name} {
        &__list {
            display: flex;
            align-items: center;
        }

        &__list-item {
            margin-bottom: 0;
            color: $white;

            & + #{$name}__list-item {
                margin-left: 20px;

                @include breakpoint(mediumlarge) {
                    margin-left: 5px;
                }
            }
        }
    }
}

@include shop-ui-list-certification-logos;
