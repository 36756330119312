@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-catalog-category-models($name: '.catalog-category-models') {
    #{$name} {
        display: block;
        overflow-x: hidden;
        color: $color-gray-10;

        &__list {
            display: flex;
            list-style: none;
            flex-flow: nowrap;
            box-sizing: border-box;
            margin-top: 0;
            margin-bottom: $sp-320;
            padding: 0;

            @include breakpoint(mediumlarge down) {
                // stylelint-disable-next-line declaration-no-important
                margin-left: auto !important;
                // stylelint-disable-next-line declaration-no-important
                margin-right: auto !important;
            }

            @include breakpoint(large up) {
                flex-flow: wrap;
                width: auto;
            }

            &--justified {
                @include breakpoint(large up) {
                    justify-content: center;
                }
            }

            &-item {
                display: block;
                position: relative;
                // stylelint-disable-next-line declaration-no-important
                height: auto !important;
                margin: 24px 0 0;

                @include breakpoint(mediumlarge down) {
                    flex: none;
                    margin-top: 18px;
                    padding: 0;
                }
            }
        }

        &__wrapper {
            display: block;
            overflow: hidden;
        }

        &__title {
            margin-bottom: $sp-400;
        }

        &__model {
            display: block;
            height: 100%;
        }

        &__pagination {
            margin-top: 24px;

            @include breakpoint(large up) {
                display: none;
            }
        }
    }
}
