// stylelint-disable declaration-no-important

.text-center {
    text-align: center;
}

.text-bold {
    @include font-din-bold;
}

.is-uppercase {
    text-transform: uppercase;
}

.line-through {
    text-decoration: line-through;
}

.is-underlined {
    text-decoration: underline;
}

.is-full-underlined {
    display: block;
    width: 100%;

    &::after {
        content: '';
        display: block;
        height: 0;
        border-top: 1px solid currentcolor;
    }
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}
