@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-shop-footer-notes($name: '.shop-footer-notes') {
    #{$name} {
        @include font-text-small(('color': $color-gray-70));

        display: block;
        position: relative;
        margin-top: 20px;
        padding-top: $spacing-lg;
        border-top: 2px solid $color-gray-20;

        &__list {
            display: block;
            margin: 0;
            padding: 0;
        }

        &__list-item {
            display: block;
            margin: 0;
            padding: 0;

            &:not(:first-child) {
                margin-top: $spacing-sm;
            }
        }
    }
}
