@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-basic-tabs($name: '.basic-tabs') {
    $accordion: '#{$name}--accordion';
    $center: '#{$name}--center';
    $inverted: '#{$name}--inverted';
    $no-padding: '#{$name}--no-padding';
    $active-link: '#{$name}__link--active';
    $wrapper: '#{$name}__wrapper';
    $wrapper-item: '#{$name}__wrapper-item';
    $navigation-item: '#{$name}__navigation-item';
    $transition: all 0.3s ease-in-out;

    #{$name} {
        display: block;
        position: relative;
        color: $color-gray-10;

        &#{$inverted} {
            background-color: $color-gray-10;
            color: $color-white;
        }

        &__navigation {
            display: flex;
            position: relative;
            list-style: none;
            margin: 0;
            padding: 0;
            overflow-x: auto;

            &::before {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                border-bottom: 2px solid $color-gray-80;

                #{$inverted} & {
                    border-bottom-color: $color-gray-50;
                }
            }

            #{$center} > & {
                justify-content: center;
            }

            #{$accordion} & {
                display: none;
            }
        }

        &__wrapper {
            display: block;
            position: relative;

            &::before {
                #{$accordion} & {
                    content: ' ';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 1;
                    border-bottom: 1px solid $color-gray-80;
                }
            }
        }

        &__navigation-item {
            display: block;
            margin: 0;
            padding: 0;
        }

        &__link {
            @include font-head-small;

            display: flex;
            position: relative;
            align-items: center;
            padding: $spacing-md $spacing-lg $spacing-md $spacing-lg;
            overflow: hidden;
            outline: none;
            line-height: 1;
            white-space: nowrap;
            text-decoration: none;
            text-overflow: ellipsis;
            word-wrap: normal;
            word-break: keep-all;
            transition: $transition;

            #{$navigation-item}:first-child & {
                padding-left: 0;
            }

            &:hover {
                color: $color-gray-20;
            }

            #{$inverted} & {
                color: $color-white;

                &:hover {
                    color: $color-gray-80;
                }
            }

            &--active {
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: $spacing-lg;
                    right: $spacing-lg;
                    height: 2px;
                    z-index: 2;
                    background-color: $color-gray-10;

                    #{$navigation-item}:first-child & {
                        left: 0;
                    }

                    &:hover {
                        background-color: $color-gray-20;
                    }

                    #{$inverted} & {
                        background-color: $color-gray-90;
                    }
                }
            }

            &--accordion {
                @include font-head-medium((
                    'scale': false,
                ));

                display: none;
                align-items: center;
                padding: $spacing-md 0;

                #{$accordion} & {
                    display: flex;
                }

                &::after {
                    content: ' ';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: 1;
                    border-bottom: 1px solid $color-gray-80;
                }

                &::before,
                #{$active-link} &::before {
                    display: none;
                }
            }
        }

        &__link-title {
            #{$accordion} & {
                display: block;
                position: relative;
                padding-right: $spacing-lg;
                overflow: hidden;
                white-space: normal;
                text-overflow: ellipsis;
            }
        }

        &__link-badge {
            @include font-text-small((
                'color': $color-gray-20,
            ));

            display: inline-flex;
            position: relative;
            align-items: center;
            height: 25px;
            margin: -$spacing-xs 0; // Avoid Sizing Problems
            margin-left: $spacing-xs;
            padding: 0 $spacing-sm;
            background-color: $color-gray-90;
            border-radius: 25px * 0.5;
            vertical-align: middle;
            white-space: nowrap;
            word-break: keep-all;
        }

        &__link-icon {
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            width: $spacing-md;
            height: $spacing-md;
            transform: translateY(-50%);
        }

        &__link-icon-open,
        &__link-icon-close {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 12px;
            height: 12px;
            transform: translate(-50%, -50%);
        }

        &__link-icon-open {
            #{$active-link} & {
                display: none;
            }
        }

        &__link-icon-close {
            display: none;

            #{$active-link} & {
                display: block;
            }
        }

        &__panel {
            @include font-text-large((
                'color': $color-gray-20,
            ));

            display: none;
            position: relative;
            padding: $spacing-lg 0 0 0;

            &--active {
                display: block;
            }

            #{$no-padding} > #{$wrapper} > #{$wrapper-item} > & {
                padding: 0;
            }

            #{$accordion} & {
                padding: $spacing-md 0;
            }

            #{$no-padding}#{$accordion} > #{$wrapper} > #{$wrapper-item} > & {
                padding: 0;
            }

            #{$inverted} & {
                color: $color-gray-90;
            }
        }
    }
}
