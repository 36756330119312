@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-catalog-navigation($name: '.catalog-navigation') {
    $level-2: '#{$name}--level-2';
    $tabs: '#{$name}--tabs';
    $light: '#{$name}--light';

    #{$name} {
        display: block;
        color: $color-gray-10;

        &#{$tabs} {
            @include breakpoint(medium) {
                display: none;
            }
        }

        &__list {
            display: flex;
            list-style: none;
            flex-wrap: nowrap;
            margin: 0;
            padding: 0;

            #{$level-2} & {
                @include breakpoint(mediumlarge) {
                    width: fit-content;
                    margin: 0 auto;
                }
            }

            &-item {
                display: block;
                padding: 5px;

                &:first-child {
                    padding-left: 0;
                }

                #{$tabs} & {
                    padding: 0 $sp-160;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }

            #{$level-2} &-item {
                padding: 0;
                padding-left: 16px;

                @include breakpoint(mediumlarge up) {
                    padding-left: $sp-240;
                }

                &:first-child {
                    padding-left: 0;
                }
            }
        }

        &__link {
            @include font-text-medium;

            display: block;
            position: relative;
            padding: 10px;
            white-space: nowrap;
            text-decoration: underline;
            word-break: keep-all;

            #{$name}__list-item:first-child & {
                padding-left: 0;
            }

            #{$level-2} & {
                // stylelint-disable-next-line declaration-no-important
                padding: 18px 8px !important;
                line-height: 1;
                text-align: center;
                text-decoration: none;

                @include breakpoint(mediumlarge up) {
                    // stylelint-disable-next-line declaration-no-important
                    padding: 24px !important;
                }
            }

            #{$light} & {
                color: $color-white;
            }

            #{$tabs} & {
                padding: 10px 0;
                line-height: 1;
                text-decoration: none;
            }

            &:hover {
                color: $color-gray-20;

                #{$light} & {
                    color: $color-gray-80;
                }
            }

            &--active {
                #{$level-2} & {
                    @include font-din-bold;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        right: 0;
                        height: 3px;
                        background-color: $color-gray-10;
                    }

                    &:hover::before {
                        background-color: $color-gray-20;
                    }
                }

                #{$level-2}#{$light} & {
                    &::before {
                        background-color: $color-white;
                    }
                }

                #{$tabs} & {
                    @include font-din-bold;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        border-bottom: 2px solid $color-gray-10;
                    }
                }

                #{$tabs}#{$light} & {
                    &::before {
                        border-bottom-color: $color-white;
                    }
                }
            }
        }

        &__wrapper {
            display: block;

            .row-padding-mobile-200 & {
                margin-right: -$row-padding-mobile-200;

                @include breakpoint(mediumlarge) {
                    margin-right: -$row-padding-mobile-200 + $grid-column-gutter * 0.5;
                }

                @include breakpoint(large) {
                    margin-right: 0;
                }
            }

            #{$level-2} &,
            #{$tabs} & {
                margin-right: 0;
                border-bottom: 1px solid $color-gray-80;
            }

            #{$light}#{$level-2} &,
            #{$light}#{$tabs} & {
                border-bottom-color: $color-gray-20;
            }
        }

        &__nav {
            overflow: auto hidden;
        }
    }
}
