@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-header-mobile-menu($name: '.header-mobile-menu') {
    $max-width: 480px;
    $max-width-medium: 600px;

    #{$name} {
        display: flex;
        flex-direction: column;
        min-width: 290px;
        max-width: $max-width;
        height: 100%;
        overflow: hidden;
        color: $color-gray-10;

        @include breakpoint(medium up) {
            max-width: $max-width-medium;
        }

        &__modal {
            // due to external trbo classes for mobile
            // stylelint-disable-next-line declaration-no-important
            z-index: 1000000 !important;
        }

        &__menu {
            @include h-reset;

            display: none;
            position: absolute;
            flex-direction: column;
            top: 0;
            left: 0;
            width: 100%;
            max-width: $max-width;
            min-height: 100%;
            margin-bottom: $sp-240;
            z-index: 0;
            background-color: $color-white;

            @include breakpoint(medium up) {
                max-width: $max-width-medium;
            }

            &--tiles {
                flex-flow: row wrap;
                align-content: flex-start;
                align-items: flex-start;
                padding-bottom: $sp-400;
            }

            &--before-show {
                display: flex;
                left: 100%;
            }

            &--show-animation {
                transition: left ease 0.5s;
            }

            &--show {
                left: 0;
                z-index: 1;
            }
        }

        &__header-item {
            @include font-din-regular;
            // stylelint-disable-next-line declaration-no-important
            display: flex !important;
            align-items: center;
            order: -1;
            min-width: 100%;
            padding: $sp-240;
            border: 0;
            outline: none;
            font-size: 14px;
            font-weight: normal;
        }

        &__link-back {
            display: flex;
            align-items: center;
            justify-content: left;
            outline: none;
            color: $color-gray-10;
            text-decoration: underline;
            text-transform: lowercase;
            cursor: pointer;

            &::before {
                // stylelint-disable-next-line declaration-no-important
                display: none !important;
            }
        }

        &__close-button {
            margin-left: auto;
            color: $color-gray-10;
            cursor: pointer;
        }
    }
}
