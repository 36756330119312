@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-header-mobile-menu-item($name: '.header-mobile-menu-item') {
    $style-text-link: '#{$name}--text-link';
    $style-cms-navi: '#{$name}--cms-navi';
    $style-root-list: '#{$name}--root-list';
    $style-tile: '#{$name}--tile';
    $border-color: $color-gray-80;
    $item-height: 40px;

    #{$name} {
        @include font-text-medium;
        // stylelint-disable-next-line declaration-no-important
        display: flex !important;
        align-items: center;
        height: $item-height;
        padding: 0 $sp-240;
        border-bottom: 1px solid $border-color;
        color: $color-gray-10;

        &--title {
            @include font-head-medium;

            order: -1;
            width: 100%;
            height: $item-height;
        }

        &#{$style-text-link} {
            width: 100%;
            padding: 0 $sp-240;
            border: none;
            text-decoration: underline;
        }

        &#{$style-cms-navi} {
            margin-top: $sp-240;
        }

        &#{$style-root-list} {
            border-top: 1px solid $border-color;
        }

        &#{$style-tile} {
            border: none;
        }

        &--margin-bottom {
            margin-bottom: $item-height;
            border-bottom: 1px solid $border-color;
        }

        &--margin-top {
            margin-top: $sp-400;

            &::before {
                content: ' ';
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                height: $item-height;
                z-index: -1;
                border-bottom: 1px solid $border-color;
                transform: translateY((-$item-height + 1px));
            }
        }

        &--sub-title {
            @include font-din-medium;

            margin-top: $item-height;
            border-top: 0;
            border-bottom: 1px solid $color-gray-10;
            font-size: 14px;
            text-transform: uppercase;
        }

        &--highlight {
            color: $color-sale;
        }

        &__flag {
            width: 14px;
            height: 10px;
            margin-right: $spacing-sm;
            color: $color-gray-20;
        }

        &__icon {
            height: 10px;

            #{$name}--lang > #{$name}__content > #{$name}__picture > & {
                max-width: 14px;
            }
        }

        &__picture {
            margin-left: 5px;

            #{$name}--lang > #{$name}__content > & {
                order: -1;
                margin-right: 5px;
            }

            #{$name}--icon-only > #{$name}__content > & {
                margin-left: 0;
            }
        }

        &__arrow-right {
            margin-left: auto;
            color: $color-gray-10;
        }

        &__content {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: left;
            width: 100%;
            height: 100%;
            outline: none;
            color: inherit;
            text-decoration: inherit;
            cursor: pointer;

            &::after {
                // stylelint-disable-next-line declaration-no-important
                display: none !important;
            }
        }

        &__currency {
            flex-grow: 1;
            text-align: right;
        }

        &--more {
            text-decoration: underline;
        }

        &--order-first {
            order: -1;
        }
    }
}
