@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-flash-message($name: '.flash-message') {
    $error: '#{$name}--error';
    $success: '#{$name}--success';
    $warning: '#{$name}--warning';

    #{$name} {
        @include font-din-regular;

        display: flex;

        position: relative;

        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 16px 0;
        padding: 20px;

        overflow: hidden;
        background-color: $color-gray-10;
        border-radius: $global-border-radius $global-border-radius 0 0;
        color: $color-gray-10;
        animation: 0.4s show 0s ease-in;

        &#{$error} {
            background: #e5c0bb;
            color: #ff3f3f;
        }

        &#{$warning} {
            background: #e9e1bf;
            color: #121212;
        }

        &#{$success}, a {
            background: #d8fff1;
            color: #029539;
        }

        &__icon-close {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            color: $color-gray-10;
            cursor: pointer;
        }

        &__content {
            width: 100%;
            padding: 0 20px;
            overflow: hidden;

            @include breakpoint(large) {
                padding-left: 0 60px;
            }
        }

        @keyframes show {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }
}
