@import '~ShopUiBundle/styles/shared';
@import '~ShopUiBundle/components/atoms/form-check/form-check';

@mixin shop-ui-form-check-group($name: '.form-check-group') {
    @include shop-ui-form-check($name);

    $horizontal: '#{$name}--horizontal';
    $disabled: '#{$name}--disabled';
    $hover: '#{$name}__target:not(#{$disabled}):hover';
    $focus: '#{$name}__target:not(#{$disabled}):focus';
    $focus-within: '#{$name}__target:not(#{$disabled}):focus-within';
    $active: '#{$name}__target:not(#{$disabled}):active';
    $root-hover: '#{$name}:not(#{$disabled}):hover';
    $root-focus: '#{$name}:not(#{$disabled}):focus';
    $root-focus-within: '#{$name}:not(#{$disabled}):focus-within';
    $root-active: '#{$name}:not(#{$disabled}):active';

    #{$name} {
        padding: 0;

        &__title {
            display: block;
            position: relative;
            padding-left: $sp-240;
        }

        &__group-title {
            font-size: 12px;
            color: $color-gray-50;

            #{$root-hover} &,
            #{$root-focus} &,
            #{$root-focus-within},
            #{$root-active},
            #{$hover} &,
            #{$focus} &,
            #{$focus-within},
            #{$active} & {
                color: $color-gray-10;
            }
        }

        &__wrapper {
            display: block;
            position: relative;

            #{$horizontal} & {
                display: flex;
            }
        }

        &__item {
            display: block;
            position: relative;
            padding-top: $sp-160;
            padding-right: $sp-320;

            #{$horizontal} & {
                display: flex;
                padding-top: 8px;
            }
        }

        &__error {
            left: 0;
        }

        @content;
    }
}
