@import '~ShopUiBundle/styles/shared';
@import '~ShopUiBundle/components/atoms/basic-checkbox/basic-checkbox';

@mixin shop-ui-basic-radiobutton($name: '.basic-radiobutton') {
    @include shop-ui-basic-checkbox($name);

    $disabled: '#{$name}--disabled';
    $readonly: '#{$name}--readonly';

    #{$name} {
        &__outer-circle {
            stroke: $color-gray-20;
            transition: fill 0.2s ease-out, stroke 0.2s ease-out;
            fill: $color-white;

            #{$disabled} &,
            #{$readonly} & {
                stroke: $color-gray-80;
            }
        }

        &__inner-circle {
            stroke-width: 0;
            transform-origin: center;
            transition: all ease 0.2s;
            fill: $color-white;

            #{$disabled} &,
            #{$readonly} & {
                fill: $color-white;
            }
        }

        &__input {
            &:checked + #{$name}__svg {
                #{$name}__inner-circle {
                    stroke-width: 4;
                    stroke: $color-gray-10;
                    fill: $color-gray-10;

                    #{$disabled} &,
                    #{$readonly} & {
                        stroke: $color-gray-80;
                        fill: $color-gray-80;
                    }
                }

                #{$name}__outer-circle {
                    stroke: $color-gray-10;

                    #{$disabled} &,
                    #{$readonly} & {
                        stroke: $color-gray-80;
                    }
                }
            }
        }

        &:hover:not(#{$disabled}):not(#{$readonly}),
        &:focus:not(#{$disabled}):not(#{$readonly}) {
            #{$name}__inner-circle,
            #{$name}__outer-circle {
                fill: $color-gray-90;
            }

            #{$name}__input:checked + #{$name}__svg #{$name}__outer-circle {
                stroke: $color-gray-20;
            }

            #{$name}__input:checked + #{$name}__svg #{$name}__inner-circle {
                stroke: $color-gray-20;
                fill: $color-gray-20;
            }
        }

        &:active:not(#{$disabled}):not(#{$readonly}) {
            #{$name}__input:not(:checked) + #{$name}__svg #{$name}__outer-circle {
                fill: $color-gray-20;
            }

            #{$name}__input:not(:checked) + #{$name}__svg #{$name}__inner-circle {
                fill: $color-gray-20;
            }
        }
    }
}
