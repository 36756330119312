@import '~ShopUiBundle/styles/shared';
@import '~ShopUiBundle/assets/scss/base/mixins';

// Default values
$shop-ui-btn-icon-left-modifier: 'icon-left';
$shop-ui-btn-icon-right-modifier: 'icon-right';
$shop-ui-btn-icon-responsive-modifier: 'icon-responsive';
$shop-ui-btn-alt-modifier: 'inverted';
$shop-ui-btn-disabled-modifier: 'disabled';

// $height,
// $padding-x,
// $padding-y,
// $padding-icon-inner,
// $padding-icon-outer,
// $font-size,
$shop-ui-btn-size-sm: (
    40px,
    15px,
    13px,
    10px,
    15px,
    14px,
);
$shop-ui-btn-size-md: (
    50px,
    20px,
    17px,
    10px,
    20px,
    16px,
);
$shop-ui-btn-size-lg: (
    60px,
    25px,
    22px,
    10px,
    25px,
    16px,
);
$shop-ui-btn-size-xl: (
    65px,
    25px,
    23px,
    10px,
    25px,
    18px,
);

// Available sizes
$shop-ui-btn-responsive-sizes: (
    'sm': $shop-ui-btn-size-sm,
    'md': $shop-ui-btn-size-md,
    'lg': $shop-ui-btn-size-lg,
    'xl': $shop-ui-btn-size-xl,
);

// Mixins
@mixin shop-ui-btn($name: '.btn') {
    $block: '#{$name}--block';

    #{$name} {
        @include shop-ui-make-btn;
        @include shop-ui-make-btn-size-default;
        @include shop-ui-make-btn-responsive($shop-ui-btn-responsive-sizes, true);
        @include shop-ui-make-btn-theme-default;
        @include shop-ui-make-btn-theme-primary;
        @include shop-ui-make-btn-theme-ghost;
        @include shop-ui-make-btn-theme-secondary;
        @include shop-ui-make-btn-theme-cart;
    }

    #{$block} {
        display: flex;
        width: 100%;
    }

    @content;
}

@mixin shop-ui-make-btn(
    $transition: $global-button-transition,
) {
    @include font-default;
    @include font-size-medium;

    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    border-width: 2px;
    border-style: solid;
    border-radius: $global-button-border-radius;
    vertical-align: text-bottom;
    white-space: nowrap;
    text-decoration: none;
    transition: $transition;
    cursor: pointer;

    &__title {
        overflow: hidden;
        color: inherit;
        vertical-align: text-bottom;
        text-overflow: ellipsis;
    }

    &__icon {
        display: inline-block;
        position: relative;
        vertical-align: text-bottom;
        object-fit: cover;
        object-position: 50% 50%;

        &-item {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
            transform: translate(-50%, -50%);
            backface-visibility: visible;
        }
    }

    &:disabled,
    &--#{$shop-ui-btn-disabled-modifier} {
        transition: none;
        cursor: default;
        pointer-events: none;
    }

    @content;
}

@mixin shop-ui-make-btn-size-default(
    $size: $shop-ui-btn-size-md,
    $icon-bem-fix: false,
) {
    @include shop-ui-make-btn-size(
        nth($size, 1),
        nth($size, 2),
        nth($size, 3),
        nth($size, 4),
        nth($size, 5),
        nth($size, 6),
        &,
        $icon-bem-fix,
    );

    @content;
}

@mixin shop-ui-make-btn-size(
    $height,
    $padding-x,
    $padding-y,
    $padding-icon-inner,
    $padding-icon-outer,
    $font-size,
    $simple-parent-class: false, // Solve a naming problem when using complex class names
    $icon-bem-fix: false, // Solve a naming problem when using complex class names
) {
    $icon-size: ($height - ($padding-y * 2));
    $child-class: if($simple-parent-class, '#{$simple-parent-class}', '#{&}');
    $self: '#{&}';
    $icon-bem-separator: if($icon-bem-fix, '-', '__');

    height: $height;
    padding: $padding-y $padding-x;
    font-size: $font-size;

    #{$child-class}#{$icon-bem-separator}icon {
        width: $icon-size;
        height: $icon-size;

        &--left {
            margin-right: $padding-icon-inner;
        }

        &--right {
            margin-left: $padding-icon-inner;
        }
    }

    &#{$child-class}--#{$shop-ui-btn-icon-left-modifier} {
        padding-left: $padding-icon-outer;
    }

    &#{$child-class}--#{$shop-ui-btn-icon-right-modifier} {
        padding-right: $padding-icon-outer;
    }

    @content;
}

@mixin shop-ui-make-btn-responsive(
    $sizes: $shop-ui-btn-responsive-sizes,
    $fixXlSize: false,
    $isIconResponsive: true,
) {
    $self: #{&};
    $class-selectors: '';

    @each $size, $values in $sizes {
        &--#{$size} {
            @include shop-ui-make-btn-size(
                nth($values, 1),
                nth($values, 2),
                nth($values, 3),
                nth($values, 4),
                nth($values, 5),
                nth($values, 6),
                $self,
            );

            // Fix: xl sizes should be lg in mediumsmall down breakpoints
            @if $fixXlSize and $size == 'xl' {
                @include breakpoint(mediumsmall down) {
                    @include shop-ui-make-btn-size(
                        nth($shop-ui-btn-size-lg, 1),
                        nth($shop-ui-btn-size-lg, 2),
                        nth($shop-ui-btn-size-lg, 3),
                        nth($shop-ui-btn-size-lg, 4),
                        nth($shop-ui-btn-size-lg, 5),
                        nth($shop-ui-btn-size-lg, 6),
                        $self,
                    );
                }
            }
        }
    }

    @each $breakpoint, $width in $breakpoints {
        @each $size, $values in $sizes {
            &--#{$size}-#{$breakpoint} {
                @include breakpoint($breakpoint up) {
                    @include shop-ui-make-btn-size(
                        nth($values, 1),
                        nth($values, 2),
                        nth($values, 3),
                        nth($values, 4),
                        nth($values, 5),
                        nth($values, 6),
                        $self,
                    );
                }
            }
        }
    }

    @if $isIconResponsive {
        &--#{$shop-ui-btn-icon-responsive-modifier} #{$self}__icon {
            @include breakpoint(medium down) {
                // stylelint-disable-next-line declaration-no-important
                margin-left: 0 !important;
                // stylelint-disable-next-line declaration-no-important
                margin-right: 0 !important;
            }
        }

        &--#{$shop-ui-btn-icon-responsive-modifier} #{$self}__title {
            @include breakpoint(medium down) {
                // stylelint-disable-next-line declaration-no-important
                display: none !important;
            }
        }
    }

    @content;
}

// Themes
@mixin shop-ui-make-btn-theme-default(
    $modifier: '',
) {
    $self: &;

    // stylelint-disable-next-line order/order
    @include shop-ui-make-btn-theme-primary-color($self);

    &--#{$shop-ui-btn-alt-modifier} {
        @include shop-ui-make-btn-theme-primary-color-inverted($self);
    }

    @content;
}

@mixin shop-ui-make-btn-theme-primary(
    $modifier: 'primary',
) {
    $self: &;

    &--#{$modifier} {
        @include shop-ui-make-btn-theme-primary-color($self);
    }

    &--#{$modifier}#{$self}--#{$shop-ui-btn-alt-modifier} {
        @include shop-ui-make-btn-theme-primary-color-inverted($self);
    }

    @content;
}

@mixin shop-ui-make-btn-theme-secondary(
    $modifier: 'secondary',
) {
    $self: &;

    &--#{$modifier} {
        @include shop-ui-make-btn-theme-secondary-color($self);

        border-width: 2px;
    }

    &--#{$modifier}#{$self}--#{$shop-ui-btn-alt-modifier} {
        @include shop-ui-make-btn-theme-secondary-color-inverted($self);
    }

    @content;
}

@mixin shop-ui-make-btn-theme-ghost(
    $modifier: 'ghost',
) {
    $self: &;

    &--#{$modifier} {
        @include shop-ui-make-btn-theme-ghost-color($self);
    }

    &--#{$modifier}#{$self}--#{$shop-ui-btn-alt-modifier} {
        @include shop-ui-make-btn-theme-ghost-color-inverted($self);
    }

    @content;
}

@mixin shop-ui-make-btn-theme-cart(
    $modifier: 'cart',
) {
    $self: &;

    &--#{$modifier} {
        @include shop-ui-make-btn-theme-cart-color($self);
    }

    @content;
}

// Color Schemas
@mixin shop-ui-make-btn-theme-primary-color(
    $target,
) {
    &,
    &:visited {
        background-color: $color-gray-10;
        border-color: $color-gray-10;
        color: $color-gray-90;
    }

    &:hover {
        background-color: $color-gray-20;
        border-color: $color-gray-20;
        color: $color-gray-90;
    }

    &:active,
    &:focus {
        background-color: $color-gray-50;
        border-color: $color-gray-50;
        color: $color-gray-70;
    }

    &#{$target}:disabled,
    &#{$target}--#{$shop-ui-btn-disabled-modifier} {
        background-color: $color-gray-70;
        border-color: $color-gray-70;
        color: $color-gray-80;
    }

    @content;
}

@mixin shop-ui-make-btn-theme-primary-color-inverted(
    $target,
) {
    &,
    &:visited {
        background-color: $color-gray-90;
        border-color: $color-gray-90;
        color: $color-gray-10;
    }

    &:hover {
        background-color: $color-gray-70;
        border-color: $color-gray-70;
        color: $color-gray-10;
    }

    &:active,
    &:focus {
        background-color: $color-gray-50;
        border-color: $color-gray-50;
        color: $color-gray-20;
    }

    &#{$target}:disabled,
    &#{$target}--#{$shop-ui-btn-disabled-modifier} {
        background-color: $color-gray-20;
        border-color: $color-gray-20;
        color: $color-gray-10;
    }

    @content;
}

@mixin shop-ui-make-btn-theme-secondary-color(
    $target,
) {
    &,
    &:visited {
        background-color: $color-white;
        border-color: $color-gray-70;
        color: $color-gray-10;
    }

    &:hover {
        background-color: $color-gray-90;
        border-color: $color-gray-70;
        color: $color-gray-10;
    }

    &:active,
    &:focus {
        background-color: $color-gray-70;
        border-color: $color-gray-70;
        color: $color-gray-50;
    }

    &#{$target}:disabled,
    &#{$target}--#{$shop-ui-btn-disabled-modifier} {
        background-color: $color-white;
        border-color: $color-gray-70;
        color: $color-gray-70;
    }

    @content;
}

@mixin shop-ui-make-btn-theme-secondary-color-inverted(
    $target,
) {
    &,
    &:visited {
        background-color: $color-gray-10;
        border-color: $color-gray-20;
        color: $color-gray-90;
    }

    &:hover {
        background-color: $color-gray-20;
        border-color: $color-gray-20;
        color: $color-gray-90;
    }

    &:active,
    &:focus {
        background-color: $color-gray-50;
        border-color: $color-gray-50;
        color: $color-gray-70;
    }

    &#{$target}:disabled,
    &#{$target}--#{$shop-ui-btn-disabled-modifier} {
        background-color: $color-gray-10;
        border-color: $color-gray-20;
        color: $color-gray-20;
    }

    @content;
}

@mixin shop-ui-make-btn-theme-ghost-color(
    $target,
) {
    &,
    &:visited {
        background-color: rgba($color-gray-90, 0.05);
        border-color: $color-gray-10;
        color: $color-gray-20;

        @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            backdrop-filter: blur(1px);
        }
    }

    &:hover {
        background-color: rgba($color-gray-90, 0.4);
        border-color: $color-gray-50;
        color: $color-gray-50;

        @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            backdrop-filter: blur(2px);
        }
    }

    &:active,
    &:focus {
        background-color: rgba($color-gray-90, 0.6);
        border-color: $color-gray-70;
        color: $color-gray-70;

        @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            backdrop-filter: blur(2px);
        }
    }

    &#{$target}:disabled,
    &#{$target}--#{$shop-ui-btn-disabled-modifier} {
        background-color: rgba($color-gray-90, 0.05);
        border-color: $color-gray-70;
        color: $color-gray-70;

        @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            backdrop-filter: blur(1px);
        }
    }

    @content;
}

@mixin shop-ui-make-btn-theme-ghost-color-inverted(
    $target,
) {
    &,
    &:visited {
        background-color: rgba($color-gray-10, 0.05);
        border-color: $color-gray-90;
        color: $color-gray-90;

        @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            backdrop-filter: blur(1px);
        }
    }

    &:hover {
        background-color: rgba($color-gray-10, 0.4);
        border-color: $color-gray-70;
        color: $color-gray-70;

        @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            backdrop-filter: blur(2px);
        }
    }

    &:active,
    &:focus {
        background-color: rgba($color-gray-10, 0.6);
        border-color: $color-gray-50;
        color: $color-gray-50;

        @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            backdrop-filter: blur(2px);
        }
    }

    &#{$target}:disabled,
    &#{$target}--#{$shop-ui-btn-disabled-modifier} {
        background-color: rgba($color-gray-10, 0.05);
        border-color: $color-gray-50;
        color: $color-gray-50;

        @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            backdrop-filter: blur(1px);
        }
    }

    @content;
}

@mixin shop-ui-make-btn-theme-cart-color(
    $target,
) {
    &,
    &:visited {
        background-color: $color-positive-50;
        border-color: $color-positive-50;
        color: $color-gray-90;
    }

    &:hover {
        background-color: $color-positive-60;
        border-color: $color-positive-60;
        color: $color-gray-90;
    }

    &:active,
    &:focus {
        background-color: $color-positive-70;
        border-color: $color-positive-70;
        color: $color-gray-80;
    }

    &#{$target}:disabled,
    &#{$target}--#{$shop-ui-btn-disabled-modifier} {
        background-color: $color-positive-90;
        border-color: $color-positive-90;
        color: $color-gray-90;
    }

    @content;
}
