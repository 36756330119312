.header {
  background: $color-white;
  @include font-din-regular();
  position: static;
  z-index: 15;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform $transition-300;

  /* main container */
  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* reduced header */
  &--reduced {

    .header__main {
      padding: 18px 24px;
      position: relative;

      @include breakpoint(large up) {
        padding: 20px 15px;
      }

      .header__logo {
        height: 24px;

        @include breakpoint(large up) {
          height: 34px;
        }
      }

      .header__title {
        @include font-din-regular();
        font-size: $font-size-400;
        text-transform: uppercase;
        margin: 0;
        padding: 0 10px;
        @include breakpoint(large up) {
          font-size: $font-size-500;
          padding: 15px;
        }
      }
    }
  }
}
