@import '~ShopUiBundle/styles/shared';

@mixin product-rating-bundle-rating-stars($name: '.rating-stars') {
    $theme-no-gap: '#{$name}--no-gap';

    #{$name} {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;

        &__star {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 $sp-040;
            color: #c9c9c9;
            cursor: pointer;

            &--is-active {
                color: $color-accent;
            }

            #{$theme-no-gap} & {
                padding-right: 0;
            }
        }
    }

    @content;
}

@include product-rating-bundle-rating-stars;
