@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-tooltip-icon-info($name: '.tooltip-icon-info') {
    $absolute: '--absolute';
    $gray: '--gray';
    $hidden: '--hidden';
    $trigger-padding: 5px;

    #{$name} {
        display: inline-block;
        position: relative;
        color: $color-black;
        line-height: 0;

        &#{$gray} {
            color: $color-gray-50;

            > #{$name}__icon {
                width: 18px;
                height: 18px;
            }
        }

        &#{$absolute} {
            position: absolute;
            right: $sp-020;
            z-index: 1;
        }

        &#{$hidden} {
            visibility: hidden;
        }

        &__icon {
            &--active {
                color: $color-gray-10;
            }
        }

        &__trigger {
            display: block;
            position: absolute;
            top: -$trigger-padding;
            left: -$trigger-padding;
            width: calc(100% + 2 * #{ $trigger-padding });
            height: calc(100% + 2 * #{ $trigger-padding });
            cursor: pointer;
        }

        &__wrapper {
            display: flex;
            align-items: center;
        }

        &__origin-text {
            padding-right: $sp-040;
            font-size: 16px;
            color: $color-gray-10;
            line-height: 20px;
        }
    }
}
