@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-image-button-slider($name: '.image-button-slider') {
    $style-square: '#{$name}--style-square';

    #{$name} {
        overflow: hidden;

        &__slide {
            position: relative;
            width: 290px;
            height: 460px;

            #{$style-square} & {
                height: 290px;
            }
        }

        &__image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &__button {
            @include font-text-medium;

            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;
            bottom: 10px;
            left: 10px;
            right: 10px;
            height: 40px;
            min-height: 72px;
            padding: $sp-160;
            background: $color-white;
            border-radius: $global-button-border-radius;
            line-height: #{ math.div(20, 16) };
            text-align: center;
            text-decoration: none;
            transition: none;

            b,
            strong {
                @include font-text-medium;

                #{$name}__slide:hover &,
                #{$name}__slide:active &,
                #{$name}__slide:focus & {
                    color: $color-white;
                }
            }

            #{$name}__slide:hover & {
                background: $color-gray-10;
                color: $color-white;
            }

            #{$name}__slide:active &,
            #{$name}__slide:focus & {
                background: $color-gray-20;
                color: $color-white;
            }
        }

        &__button-text {
            overflow: hidden;

            @supports (-webkit-line-clamp: 2) {
                & {
                    // stylelint-disable-next-line value-no-vendor-prefix
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    // stylelint-disable-next-line property-no-vendor-prefix
                    -webkit-line-clamp: 2;
                    // stylelint-disable-next-line property-no-vendor-prefix
                    -webkit-box-orient: vertical;
                }
            }
        }

        &__slider-container {
            @include breakpoint(mediumlarge down) {
                overflow: visible;
            }
        }

        &__slider-container-wrapper {
            @include breakpoint(mediumlarge down) {
                overflow: visible;
            }
        }
    }
}
