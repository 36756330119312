@import '~ShopUiBundle/styles/shared';
@import '~ShopUiBundle/components/atoms/form-check-group/form-check-group';

@mixin shop-ui-selection-field-radio-group($name: '.selection-field-radio-group') {
    @include shop-ui-form-check-group($name);

    #{$name} {
        margin: 0;

        &__radio {
            &:first-of-type {
                padding-top: $spacing-lg;
            }

            &:last-of-type {
                padding-bottom: $spacing-lg;
            }
        }

        &__error {
            top: $spacing-md;
        }
    }
}
