.breadcrumb {
    display: block;
    min-width: 320px;

    &__list {
        padding: 0;

        @include breakpoint(mediumsmall down) {
            margin: 0.5rem 0;
        }

        li {
            display: inline-block;
            color: $color-gray-20;
            @include font-din-regular();
            font-size: $font-size-400;

            @include breakpoint(mediumsmall down) {
                display: none;
                font-size: $font-size-200;
                &:nth-last-of-type(-n+2) {
                    display: inline-block;
                }
            }

            &:after {
                content: '>';
                display: inline-block;
                margin: 0 2px 0 6px;
                position: relative;
                top: -1px;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

    }
}
