@use 'sass:math';

@import '~ShopUiBundle/styles/shared';
@import '~SprykerCatalogBundle/components/molecules/catalog-category-model/catalog-category-model';

@mixin cms-bundle-partner-box($name: '.partner-box') {
    @include catalog-bundle-catalog-category-model($name);

    #{$name} {
        &__link {
            justify-content: flex-start;
            padding: $sp-160;

            @include breakpoint(mediumlarge up) {
                padding: $sp-240;
            }
        }

        &__header {
            margin: (-$sp-160) (-$sp-160) $sp-160;
            padding: $sp-160;
            background: $color-gray-90;

            @include breakpoint(mediumlarge up) {
                margin: (-$sp-240) (-$sp-240) $sp-240;
                padding: $sp-160 $sp-240;
            }
        }

        &__header-logo {
            width: auto;
            height: 30px;
        }

        &__info {
            display: block;
            padding: 0;

            @include breakpoint(mediumlarge up) {
                padding: 0;
            }

            > h4,
            > h5,
            > p,
            > p > small,
            > ul > li {
                margin: 0;
                padding: 0;
            }

            > h4 {
                margin-bottom: 10px;
            }

            > h5 {
                margin-bottom: 5px;
            }

            > p {
                @include font-size-small;

                margin-bottom: $sp-200;

                > small {
                    @include font-size-xsmall;

                    color: $color-gray-20;
                }
            }

            > ul {
                list-style: disc;
                margin: 0;
                padding: 0;

                > li {
                    @include font-din-regular;

                    margin: 5px 0 0 15px;
                    line-height: #{ math.div(16, 12) };
                }
            }

            > ul > li:last-child {
                margin-bottom: $sp-160;

                @include breakpoint(mediumlarge up) {
                    margin-bottom: $sp-200;
                }
            }
        }

        &__bottom {
            margin-top: auto;
            padding: 0;
        }
    }
}
