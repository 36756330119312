.login__button {
    width: 100%;
    margin-top: $sp-080;

    @include breakpoint(medium) {
        width: auto;
    }

    &--register {
        margin-top: 0;

        @include breakpoint(medium) {
            margin-top: $sp-080;
        }

        @include breakpoint(large) {
            margin-top: 0;
        }
    }
}

.login__forgot-password-link {
    display: block;
    margin: $sp-160 0 $sp-240 0;
    color: $color-gray-10
}
