@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-selection-field-radio-small($name: '.selection-field-radio-small') {
    $icon-top: '#{$name}--icon-top';
    $disabled: '#{$name}--disabled';
    $icon-unchecked: '#{$name}--icon-unchecked';
    $hover: '#{$name}:not(#{$disabled}):hover';
    $focus-within: '#{$name}:focus-within';
    $input-checked: '#{$name}__input:checked';
    $label-checked: '#{$input-checked} + #{$name}__content';

    #{$name} {
        display: block;
        padding: $spacing-sm 0;

        &#{$icon-top} {
            padding: 15px 0;
        }

        &__content {
            display: flex;
            align-items: center;
            width: 100%;
            padding: $spacing-sm;
            background-color: $color-white;
            border: 2px solid $color-gray-80;
            border-radius: 4px;
            cursor: pointer;

            @include breakpoint(medium) {
                padding: 15px;
            }

            #{$hover} &,
            #{$input-checked} + &,
            #{$focus-within} & {
                border-color: $color-gray-10;
            }

            #{$focus-within} & {
                box-shadow: 0 0 0 4px $color-gray-80;
            }

            #{$disabled} & {
                cursor: default;
            }

            #{$icon-top} & {
                align-items: flex-start;
                padding: $spacing-md $spacing-md $spacing-md 15px;
            }
        }

        &__text-wrapper {
            @include font-size-small;

            flex-grow: 1;
        }

        &__text,
        &__text span {
            color: $color-gray-50;

            #{$disabled} & {
                color: $color-gray-70;
            }

            #{$icon-top} & {
                padding-top: $spacing-xs;
            }
        }

        &__title,
        &__title span {
            color: $color-gray-20;

            #{$hover} &,
            #{$label-checked} &,
            #{$focus-within} & {
                color: $color-gray-10;
            }

            #{$disabled} & {
                color: $color-gray-70;
            }
        }

        &__icon {
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            margin-right: $spacing-sm;
            padding: $sp-020;
            border: 2px solid $color-gray-80;
            border-radius: 50%;
            color: transparent;

            @include breakpoint(medium) {
                margin-right: 15px;
            }

            #{$hover} &,
            #{$focus-within} & {
                background-color: $color-gray-90;
                border-color: $color-gray-10;
            }

            #{$label-checked} & {
                display: block;
                background-color: $color-positive;
                border-color: $color-positive;
                color: $color-white;
            }

            #{$icon-top} & {
                margin-right: 15px;
            }
        }

        &__warning {
            display: flex;
            align-items: center;
        }

        &__warning-icon {
            width: 13px;
            height: 13px;
            margin-right: $spacing-xs;
        }

        &__warning-text {
            #{$disabled} & {
                color: $color-gray-50;
            }
        }
    }
}
