@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-address-form-symfony($name: '.address-form-symfony') {
    #{$name} {
        &__address-wrapper {
            display: flex;
            flex-direction: column;
        }

        &__street-container {
            display: flex;
            width: 100%;
        }

        &__street {
            flex: 1 1 80%;
            padding-right: $sp-160;
        }

        &__street-number {
            flex: 1 1 20%;
        }

        &__zip-code-city {
            display: flex;
        }

        &__zip-code {
            flex: 1 1 30%;
            padding-right: 20px;
        }

        &__city {
            flex: 1 1 60%;
        }

        &__shipping-label {
            @include font-din-bold;

            margin: 0;
            font-size: 14px;
            line-height: 20px;
        }

        &__tooltip {
            display: inline;
        }
    }
}
