@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-icon-box-slider($name: '.icon-box-slider') {
    $item-link-state-hover: '#{$name}__item--link:hover';
    $block: '#{$name}--block';

    #{$name} {
        display: block;
        overflow: hidden;

        &__item {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;
            width: 130px;
            max-width: 100%;
            height: 100%;
            padding: 16px 8px;
            border: 1px solid $color-gray-80;
            border-right: 0;
            border-radius: 1px;
            text-decoration: none;
            cursor: pointer;

            @include breakpoint(medium) {
                width: 100%;
                padding: 24px 16px;
            }

            &:last-child {
                border-right: 1px solid $color-gray-80;
            }
        }

        &__item-title {
            @include font-din-regular;

            margin: 0;
            font-size: 12px;
            color: $color-gray-20;
            line-height: 16px;
            text-align: center;

            @include breakpoint(medium) {
                font-size: 14px;
                line-height: 20px;
            }

            #{$item-link-state-hover} & {
                text-decoration: underline;
            }
        }

        &__item-image {
            width: 32px;
            height: 32px;
            margin: 0 0 8px;

            @include breakpoint(medium) {
                width: 64px;
                height: 64px;
                margin: 0 0 16px;
            }
        }

        &__slide {
            width: auto;
            height: auto;
        }
    }
}
