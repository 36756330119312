@import '~ShopUiBundle/styles/shared';

@mixin shop-cms-bundle-faq-banner($name: '.faq-banner') {

    #{$name} {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        height: 160px;

        @include breakpoint(medium) {
            height: 260px;
        }

        &__title {
            @include font-display-medium;

            position: relative;
            width: 100%;
            padding: 0;
            color: $color-white;

            @include breakpoint(medium) {
                margin: 0;
            }
        }

        &__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }
    }
}

@include shop-cms-bundle-faq-banner;
