@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-sidebar-more-button($name: '.sidebar-more-button') {
    $state-active: '.is-active';

    #{$name} {
        display: block;
        padding-left: 15px;
        background-color: $color-white;
        font-size: $font-size-300;
        font-weight: bold;
        color: $text-color;
        text-align: center;
        cursor: pointer;

        &:hover {
            background-color: $color-white;
            color: $text-color;
        }

        .icon {
            margin-left: 5px;
            color: inherit;
        }

        &__title {

            #{$state-active} & {
                display: none;
            }

            &--active {
                display: none;

                #{$state-active} & {
                    display: inline-block;
                }
            }
        }

        .show-more-active &,
        .show-more-active &:hover {
            background-color: $text-color;
            color: $color-white;

            #{$name}__icon {
                transform: rotate(180deg);
            }
        }
    }
}

@include shop-ui-sidebar-more-button;
