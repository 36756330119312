@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-basic-pagination($name: '.basic-pagination') {
    #{$name} {
        display: block;

        &__flex-content {
            height: 40px;

            @include breakpoint(mediumlarge) {
                max-width: 100%;
            }
        }

        &__list {
            display: flex;
            list-style: none;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            padding: 0;

            &-item:not(:first-child):not(:last-child) {
                margin: 0 10px;

                @include breakpoint(mediumlarge) {
                    margin: 0 5px;
                }
            }

            &-item:first-child {
                margin-right: auto;
            }

            &-item:last-child {
                margin-left: auto;
            }
        }

        &__item {

            @include font-size-small;

            $self: #{&};

            color: $color-gray-50;

            &:hover {
                color: $color-gray-70;
            }

            &--active#{$self} {
                color: $color-gray-10;
                // stylelint-disable-next-line declaration-no-important
                text-decoration: none !important;
            }
        }
    }

    @content;
}
