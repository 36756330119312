.kiosk {

  &__infoteaser {
    padding: 20px 15px;
    margin-bottom: 20px;
    background: $color-white;
    text-align: center;

    &__headline {
      margin: 15px 0 10px;
    }

    &__text {
      margin: 0;

      @include breakpoint(medium up) {
        font-size: $font-size-500;
      }

    }

  }

}