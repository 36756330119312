@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-catalog-breadcrumb($name: '.catalog-breadcrumb') {
    $theme-light: #{$name}--theme-light;
    $theme-opaque: #{$name}--theme-opaque;

    #{$name} {
        display: block;
        position: relative;
        width: 100%;
        padding: 20px 0;
        overflow: hidden;

        @include breakpoint(mediumlarge) {
            padding: 30px 0;
        }

        &__wrapper {
            width: 100%;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &__nav-wrapper {
            display: flex;
        }

        &__list {
            display: flex;
            position: relative;
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &__list-item-text {
            margin: 0;
            color: $color-gray-50;
            text-decoration: none;

            #{$theme-light} & {
                color: $color-gray-90;
            }
        }

        &__list-item {
            @include font-text-small;

            display: flex;
            position: relative;
            flex-shrink: 0;
            width: max-content;
            min-width: 0;
            color: $color-gray-50;

            #{$theme-light} & {
                color: $color-white;
            }

            & + #{$name}__list-item::before,
            &--last::before {
                content: '/';
                display: inline-block;
                margin: 0 5px;

                #{$theme-light} & {
                    color: $color-white;
                }
            }

            &--navi {
                @include breakpoint(mediumsmall down) {
                    max-width: 30px;
                }
            }

            &--navi::before {
                @include breakpoint(mediumsmall down) {
                    display: none;
                }
            }

            &--last {
                margin: 0;
                padding-right: $row-padding-mobile-200;

                #{$name}__list-item-text {
                    color: $color-gray-10;

                    #{$theme-light} & {
                        color: $color-white;
                    }
                }
            }
        }

        &__list-item-link:hover {
            text-decoration: underline;
        }
    }

    #{$theme-opaque} {
        background: $color-white;
    }

    @content;
}
