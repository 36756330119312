@import '~ShopUiBundle/styles/shared';

// This function calculates a gap of a dynamic width. The calculation is:
// Take the full viewport-width.
// remove the paddings on foundations row and column to get space available for the scroll-container.
// remove the width of the given number of items. Resulting in the space left for the gaps.
// Devide the remainig space by the given gaps.
// (To end with an item - not a gap, there is 1 gap less then there are items)

// On some desktop-browsers there can be a scrollbar. Its width is not included in this calculation.
// Therefore in those cases the last item will be cropped.
// This is accepted because the breakpoints (ml, l) are mainly on tablet-devices without scrollbars.
@function shop-ui-category-slider-calculate-dynamic-gap(
    $width,
    $padding,
    $image-width,
    $no-of-icons
) {
    @return calc(((#{$width} - #{$padding}) - #{$no-of-icons * $image-width}) / #{$no-of-icons - 1});
}

@mixin shop-ui-category-slider($name: '.category-slider') {
    $center: #{$name}--center;
    $light: #{$name}--light;
    $two-rows: #{$name}--two-rows;
    $image-width: 62px;
    $image-height: 37px;
    $image-width-medium: 78px;
    $image-height-medium: 46px;
    $image-width-large: 100px;
    $image-height-large: 60px;
    $item-width: 2 * $sp-080 + $image-width;
    $item-width-medium: 2 * $sp-160 + $image-width-medium;
    $item-width-large: 2 * $sp-160 + $image-width-large;

    #{$name} {
        display: block;

        &__title {
            margin-bottom: $sp-240;

            @include breakpoint(large) {
                margin-bottom: $sp-400;
            }
        }

        // To mimic the foundation-grid I had to override the default padding of the scrollbar
        &__scrollpane .os-scrollbar {
            // stylelint-disable-next-line declaration-no-important
            padding: 0 $row-padding-mobile-200 !important;

            @include breakpoint(large) {
                // stylelint-disable-next-line declaration-no-important
                padding: $grid-column-gutter * 0.5 !important;
            }
        }

        &__container {
            display: flex;
            width: fit-content;
            gap: $grid-column-gutter;
            padding: 0 $row-padding-mobile-200;

            @include breakpoint(mediumlarge) {
                gap: shop-ui-category-slider-calculate-dynamic-gap(
                    100vw,
                    2 * $row-padding-mobile-200,
                    $item-width-medium,
                    6
                );
            }

            @include breakpoint(large) {
                gap: shop-ui-category-slider-calculate-dynamic-gap(
                    100vw,
                    $grid-column-gutter,
                    $item-width-large,
                    7
                );
                padding: 0 ($grid-column-gutter * 0.5);
            }

            @include breakpoint(xlarge) {
                gap: shop-ui-category-slider-calculate-dynamic-gap(
                    $container-max-width,
                    $grid-column-gutter,
                    $item-width-large,
                    8
                );
            }

            #{$center} & {
                margin: 0 auto;
            }

            #{$two-rows} & {
                @include breakpoint(medium down) {
                    @supports (grid-auto-flow: column) {
                        display: grid;
                        grid-auto-columns: $item-width;
                        grid-auto-flow: column;
                        grid-template-rows: repeat(2, minmax(0, 1fr));
                    }
                }
            }
        }

        &__item {
            display: flex;
            position: relative;
            flex-direction: column;
            max-width: $item-width;
            border-radius: $global-button-border-radius;
            text-align: center;
            text-decoration: none;
            box-shadow: inset 0 0 0 1px $border-color;
            transition: box-shadow 0.3s ease-in-out;

            @include breakpoint(mediumlarge) {
                max-width: $item-width-medium;
            }

            @include breakpoint(large) {
                max-width: $item-width-large;
            }

            &:hover {
                box-shadow: inset 0 0 0 1px $color-gray-50;
            }
        }

        &__item-picture {
            padding: $sp-080;

            @include breakpoint(mediumlarge) {
                padding: $sp-160;
            }
        }

        &__item-image {
            object-fit: contain;
            width: $image-width;
            min-width: $image-width;
            height: $image-height;
            min-height: $image-height;

            @include breakpoint(mediumlarge) {
                width: $image-width-medium;
                min-width: $image-width-medium;
                height: $image-height-medium;
                min-height: $image-height-medium;
            }

            @include breakpoint(large) {
                width: $image-width-large;
                min-width: $image-width-large;
                height: $image-height-large;
                min-height: $image-height-large;
            }
        }

        &__item-title {
            @include font-text-small;

            padding: 0 $sp-080 $sp-080;
            overflow: hidden;
            text-overflow: ellipsis;

            @include breakpoint(mediumlarge) {
                padding-bottom: $sp-160;
            }

            #{$light} & {
                color: $color-white;
            }
        }
    }
}

@include shop-ui-category-slider;
