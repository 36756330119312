@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-header-navigation-user($name: '.header-navigation-user') {

    #{$name} {
        &__list {
            display: flex;
            list-style: none;
            align-items: center;
            margin: 0;
            padding: 0;
        }

        &__item {
            display: block;
            margin-left: $sp-200;
            padding: 0;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

@include shop-ui-header-navigation-user;
