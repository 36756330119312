@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-catalog-categories($name: '.catalog-categories') {
    $img-format: percentage(math.div(120, 178));
    $img-format-large: percentage(math.div(232, 394));

    #{$name} {
        display: block;
        color: $color-gray-10;

        &__list {
            list-style: none;
            margin: 0;
            padding: 0;

            &-item {
                position: relative;
                margin-bottom: 20px;

                @include breakpoint(mediumlarge up) {
                    margin-bottom: 40px;
                }
            }
        }

        &__link {
            display: block;
            position: relative;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &__picture {
            display: block;
            position: relative;
            overflow: hidden;
            border-radius: $global-border-radius;

            @include breakpoint($row-padding-mobile-200-breakpoint) {
                &--left {
                    margin-left: -8px;
                    margin-right: -$grid-column-gutter;
                }

                &--right {
                    margin-left: -$grid-column-gutter;
                    margin-right: -8px;
                }
            }

            &-img {
                display: block;
                position: relative;
                width: 100%;
                height: $img-format;
                object-fit: cover;
                object-position: 50% 50%;

                @include breakpoint(mediumlarge up) {
                    height: $img-format-large;
                }
            }
        }

        &__content {
            display: block;
            margin-top: 8px;

            @include breakpoint(mediumlarge up) {
                margin-top: 15px;
            }
        }

        &__title,
        &__subtitle {
            @include font-din-medium;

            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            color: $color-gray-10;
            line-height: #{ math.div(20, 14) };

            @include breakpoint(mediumlarge up) {
                font-size: 16px;
            }

            #{$name}__link:hover & {
                color: $color-gray-20;
                text-decoration: underline;
            }
        }

        &__subtitle {
            @include font-din-medium;

            font-weight: normal;
        }
    }
}
