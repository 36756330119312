.infotile {
  @include font-din-medium;
  background: $color-white;
  margin: 20px auto;
  padding: 20px 40px;
  text-align: center;
  font-size: $font-size-500;


  &__text {
    margin: 0 auto;

      &--light {
          @include font-din-regular;
      }
  }

  .icon {
    margin: 0 auto 10px auto;
    width: 34px;
    height: 34px;
  }
}
