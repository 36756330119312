@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-country-zip-code-select($name: '.country-zip-code-select') {

    #{$name} {
        &__select,
        &__select:focus {
            margin: 5px 0 20px;
            margin-bottom: 32px;
            padding-left: 0;
            background-size: 14px 10px;
            border: none;
            border-bottom: 1px solid $input-border-color;
            font-size: 16px;
            color: $color-gray-10;
            box-shadow: none;
            cursor: pointer;
            appearance: none;
        }
    }
}
