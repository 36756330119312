@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-account-form-title($name: '.account-form-title') {
    $has-description: '#{$name}--has-description';

    #{$name} {
        display: block;
        padding-top: $sp-160;

        &__title {
            @include font-din-bold;

            margin-bottom: 30px;
            padding-top: $sp-240;
            font-size: 24px;
            color: $color-gray-10;
            line-height: 28px;
            text-transform: none;

            #{$has-description} & {
                margin-bottom: $spacing-sm;
            }
        }

        &__link {
            display: flex;
            align-items: center;
            color: $color-gray-10;
            text-decoration: none;

            &:hover,
            &:focus {
                color: $color-gray-20;
            }
        }

        &__link-icon {
            width: 12px;
            height: 12px;
            margin-top: 1px;
            margin-right: $sp-040;
        }
    }
}
