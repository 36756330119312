@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-basic-text($name: '.basic-text') {
    $align-left: '#{$name}--left';
    $align-center: '#{$name}--center';
    $align-right: '#{$name}--right';
    $color-light: '#{$name}--light';
    $size-small: '#{$name}--small';
    $size-medium: '#{$name}--medium';
    $size-large: '#{$name}--large';
    $inline: '#{$name}--inline';

    #{$name} {
        @include font-din-regular;

        display: block;
        margin: 0;
        letter-spacing: 0;

        p {
            margin: 0;

            + p {
                margin-top: $sp-160;
            }
        }

        > b,
        > strong {
            @include font-din-medium;
        }

        a:not(.btn) {
            text-decoration: underline;

            &:hover {
                color: $color-gray-50;
            }
        }

        &#{$inline} {
            display: inline;
        }

        &#{$align-left} {
            text-align: left;
        }

        &#{$align-center} {
            text-align: center;
        }

        &#{$align-right} {
            text-align: right;
        }

        &#{$size-small} {
            @include font-text-medium((
                'breakpoint': xxlarge,
                'color': $color-gray-20,
            ));
        }

        &#{$size-medium} {
            @include font-text-large((
                'breakpoint': xxlarge,
                'color': $color-gray-20,
            ));
        }

        &#{$size-large} {
            @include font-text-xlarge((
                'breakpoint': xxlarge,
                'color': $color-gray-20,
            ));
        }

        &#{$color-light} {
            color: $color-white;

            a:not(.btn) {
                color: $color-white;

                &:hover {
                    color: $color-gray-80;
                }
            }
        }
    }

    @content;
}
