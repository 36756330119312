@import '~ShopUiBundle/styles/shared';

@mixin catalog-bundle-catalog-bike-info($name: '.catalog-bike-info') {
    $max-width: 306px;

    #{$name} {
        &__info {
            display: flex;
            justify-content: left;

            &-left {
                display: block;
                position: relative;
                flex: 0 1 $max-width;
                padding-right: $sp-160;
            }

            &-right {
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;
                padding-left: $sp-160;
            }
        }

        &__video {
            display: block;
            position: relative;
            outline: 0;
            color: $color-gray-10;
            text-decoration: none;
            cursor: pointer;

            &:hover {
                color: $color-gray-20;
            }

            &-logo {
                display: inline-block;
                max-width: 100%;
                max-height: 100%;
                color: $color-gray-20;
                object-fit: contain;
                object-position: 50% 50%;

                &-wrapper {
                    display: block;
                    text-align: center;
                }
            }

            &-title {
                @include font-text-xsmall;

                display: block;
                margin-top: 5px;
                margin-bottom: 0;
                white-space: nowrap;
                text-decoration: underline;
            }

            &-wrapper {
                display: block;
                width: auto;
                height: auto;
            }

            &-overlay {
                display: block;
                position: static;
                top: auto;
                bottom: auto;
                left: auto;
                right: auto;
            }
        }
    }
}
