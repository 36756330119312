@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-zoom-image($name: '.zoom-image') {

    #{$name} {
        display: block;
        position: relative;
        max-width: 1200px;
        text-align: center;

        &__image-wrapper {
            display: block;
            width: auto;
            min-width: 150%;

            @include breakpoint(mediumsmall up) {
                min-width: 100%;
            }

            &::before {
                content: '';
                display: block;
                padding-top: percentage(math.div(1200, 1800));
            }
        }

        &__image {
            position: absolute;
            top: 0;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translateX(-50%);
            object-fit: cover;
        }
    }
}

@include shop-ui-zoom-image;
