@import '~ShopUiBundle/styles/shared';

@mixin shop-cms-bundle-faq-icon-list-with-title($name: '.faq-icon-list-with-title') {

    #{$name} {
        display: block;

        &__list {
            @include h-reset;
            @include font-head-small;

            list-style: none;
            max-width: 800px;
            line-height: 1;

            @include breakpoint(medium) {
                columns: 2;
            }
        }

        &__item {
            @include font-text-medium;

            box-sizing: content-box;
            height: 46px;
            padding: 7px 0;
            border-top: 1px solid $color-gray-80;

            @include breakpoint(medium) {
                height: 50px;
                margin-bottom: 16px;
                padding: 0;
                border: none;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray-80;

                @include breakpoint(medium) {
                    border: none;
                }
            }
        }

        &__link {
            display: inline-flex;
            align-items: center;
            width: 100%;
            height: 100%;
            text-decoration: none;
        }

        &__text {
            text-decoration: none;

            @include breakpoint(medium) {
                text-decoration: underline;
            }
        }

        &__icon {
            width: 38px;
            max-height: 38px;
            margin-right: 25px;
            object-fit: contain;

            @include breakpoint(medium) {
                width: 44px;
                max-height: 44px;
            }
        }

        &__image {
            width: 100%;
            height: auto;
        }

        &__arrow-right {
            margin-left: auto;
            color: $color-gray-10;

            @include breakpoint(medium) {
                display: none;
            }
        }
    }
}

@include shop-cms-bundle-faq-icon-list-with-title;
