@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-list-with-title($name: '.list-with-title') {
    $light: '#{$name}--light';
    $gray: '#{$name}--gray';

    #{$name} {
        @include font-text-medium;

        display: block;

        &#{$light} {
            color: $color-gray-80;

            // Not BEM: Solves a problem when using raw HTML
            a {
                &,
                &:visited {
                    color: $color-gray-80;
                }

                &:active,
                &:focus {
                    color: $color-gray-90;
                }
            }
        }

        &#{$gray} {
            color: $color-gray-80;

            // Not BEM: Solves a problem when using raw HTML
            a {
                &,
                &:visited {
                    color: $color-gray-80;
                }

                &:active,
                &:focus {
                    color: $color-gray-70;
                }
            }
        }

        a {
            text-decoration: none;
            transition: ease 0.1s opacity;

            &:hover {
                opacity: 0.7;
                text-decoration: none;
            }
        }

        &__title {
            @include font-head-small;

            margin-top: 20px;
            margin-bottom: 20px;

            #{$light} &,
            #{$gray} & {
                color: $color-gray-80;

                // Not BEM: Solves a problem when using raw HTML
                a {
                    &,
                    &:visited {
                        color: $color-gray-80;
                    }

                    &:active,
                    &:focus {
                        color: $color-gray-90;
                    }
                }
            }
        }

        &__list-item {
            // stylelint-disable-next-line declaration-no-important
            margin-top: 0 !important;
            margin-bottom: 10px;
        }

        &__list-item-img {
            max-height: 100%;
        }

        &__more-link {
            &,
            &:visited {
                #{$light} & {
                    color: $color-gray-90;
                }

                #{$gray} & {
                    color: $color-gray-70;
                }
            }

            &:active,
            &:focus {
                #{$light} & {
                    color: $color-gray-90;
                }

                #{$gray} & {
                    color: $color-gray-50;
                }
            }
        }
    }
}
