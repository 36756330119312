input[type="radio"] {

    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    left: -9999px;
    margin: 0;
    padding: 0;

    & + label,
    & + .label {

        color: $color-gray-10;
        margin-left: 0;

        .help-block {
            display: block;
        }

        .label {
            max-width: 80%;
            display: inline-block;
            float: left;

            @include breakpoint(small down) {
                float: none;
                display: block;
            }
        }

        &::before {
            content: '';
            display: block;
            border-radius: 50%;
            margin-right: 10px;
            position: relative;
            background: $color-white;
            box-shadow: 0px 0px 0px 3px $color-white, 0px 0px 0px 4px $color-gray-10;
            width: 0px;
            height: 0px;
            border: 6px solid $color-white;
            top: 5px;
            left: $sp-040;
        }

    }

    &:checked + label,
    &:checked + .label {
        &::before {
            background: $color-gray-10;
            box-shadow: 0px 0px 0px 3px $color-white, 0px 0px 0px 4px $color-gray-10;
            border: 6px solid $color-gray-10;
        }
    }

    &[disabled] + label,
    &[disabled] + label span {
        color: $color-gray-50;
        cursor: no-drop;
    }

    &.is-loading {
        & + label,
        & + .label {
            &::before {
                background-image: url('../img/loading.svg');
                background-size: 20px 20px;
                background-position: 97% center;
                background-repeat: no-repeat;
            }
        }
    }

}

.radiobutton--square {
    position: relative;
    overflow: hidden;
    float: left;
    margin: 5px 0 20px 0;

    .radiobutton__label {
        display: block;
        color: $color-white;
        text-align: center;
        font-size: 16px;
        line-height: 40px;
        height: 40px;
        background: $color-gray-20;
        cursor: pointer;
        transition: all .2s;

        &:hover {
            background: $color-gray-20;
        }

    }

    input[type="radio"],
    input[type="checkbox"] {
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
        left: -99999px;

        &:checked + .radiobutton__label {
            background: $color-gray-20;
            box-shadow: 0 0 0 4px $color-accent inset;
        }

    }

    &:last-of-type {
        border-left: 1px solid $color-gray-80;
    }

}

.radiobutton--button {
    flex: 1 0 calc((100% - 16px) / 2);

    @include breakpoint(mediumsmall) {
        flex: 0 0 80px;
    }
    position: relative;
    overflow: hidden;
    margin: $sp-040 0 $sp-200 0;

    &:first-of-type {
        margin-right: 16px;
    }

    .radiobutton__label {
        display: block;
        color: $color-gray-10;
        text-align: center;
        line-height: 38px;
        height: 40px;
        background: transparent;
        cursor: pointer;
        transition: all .2s;
        border: solid 1px $color-gray-10;
        border-radius: $global-button-border-radius;
        max-width: 100%;
        width: 100%;
        font-size: 16px;

        &:hover,
        &:focus {
            background-color: $color-gray-10;
            color: $color-white;
        }
    }

    input[type="radio"],
    input[type="checkbox"] {
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
        left: -99999px;

        & .radiobutton__label {

            &:hover,
            &:focus {
                background-color: $color-gray-10;
                border-color: $color-gray-10;
                color: $color-white;
            }
        }

        &:checked + .radiobutton__label {
            background: $color-gray-10;
            border-color: $color-gray-10;
            color: $color-white;
        }
    }

    &:last-of-type {
        border-left: 1px solid $color-gray-80;
    }
}

.radiobutton--color {
    display: inline-block;

    &.is-disabled {
        opacity: .3;
        pointer-events: none;
        cursor: default;
        cursor: not-allowed;
    }

    .radiobutton__label:not(.colortile) {
        width: 24px;
        height: 24px;
        cursor: pointer;
        font-size: 0;
        border: 2px solid transparent;
        transition: border-color $transition-100;
    }

    input[type="radio"],
    input[type="checkbox"] {
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
        left: -99999px;

        &:checked + .radiobutton__label {
            border-color: $color-accent;
            box-shadow: 0 0 0 1px $color-accent;
        }

    }

    &.is-active {

        .radiobutton__label {
            border-color: $color-black;
            border-width: 1px;
            padding: 2px;
        }
    }

    &__small {
        height: 20px;
        width: 20px;
        float: left;
        margin-right: 10px;

        .colortile {
            height: 20px;
            width: 20px;
            border: none;
        }
    }
}

.radiobutton--filter-color {
    display: inline-block;
    margin: 0 5px 5px 0;

    .radiobutton__label {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        min-width: 36px;
        height: 36px;
        color: $color-gray-10;
        font-size: $font-size-300;
        transition: background-color $transition-100;
        // stylelint-disable-next-line declaration-no-important
        padding: 2px !important;
        border: 1px solid transparent;
        background: $color-white;
    }

    input[type='radio'],
    input[type='checkbox'] {
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
        left: -99999px;

        &:not([disabled])
        + .radiobutton__label {
            cursor: pointer;
            background: $color-white;

            &:hover {
                @include breakpoint(medium up) {
                    background-color: $color-gray-90;
                }
            }
        }

        &:checked + .radiobutton__label {
            border-color: $color-black;
            background: $color-white;
        }
    }

    &.is-active {
        &:not([disabled]) {
            .radiobutton__label {
                box-shadow: none;
                color: $color-white;
                background: $color-black;
            }
        }
    }
}

.radiobutton--text {
    display: inline-block;
    margin: 0 5px 5px 0;

    .radiobutton__label {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        min-width: 36px;
        height: 36px;
        padding: 0 8px;
        color: $color-gray-10;
        font-size: $font-size-300;
        background: $body-color;
        transition: background-color $transition-100;
    }

    input[type="radio"],
    input[type="checkbox"] {
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
        left: -99999px;

        &:not([disabled])
        + .radiobutton__label {
            cursor: pointer;
            background: $color-white;
            box-shadow: 0 0 0 1px $border-color inset;

            &:hover {
                @include breakpoint(medium up) {
                    box-shadow: 0 0 0 1px $color-gray-10 inset;
                }
            }

        }

        &:checked + .radiobutton__label {
            color: $color-white;
            background-color: $color-gray-20;
        }
    }

    &.is-active {
        &:not([disabled]) {
            .radiobutton__label {
                box-shadow: none;
                color: $color-white;
                background: $color-black;
            }
        }
    }

    &.is-sold-out {
        position: relative;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to top left,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) calc(50% - 0.8px),
                $border-color 50%,
                rgba(0, 0, 0, 0) calc(50% + 0.8px),
                rgba(0, 0, 0, 0) 100%);
            pointer-events: none;
        }
    }
}

a.radiobutton--text {
    @include font-din-regular;
    text-decoration: none;

    .radiobutton__label {
        cursor: default;
        color: $color-gray-10;
        font-size: $font-size-300;
        background: $body-color;
    }

    &:not(.is-disabled) {
        .radiobutton__label {
            cursor: pointer;
            background: $color-white;
            box-shadow: 0 0 0 1px $border-color inset;

            &:hover {
                box-shadow: none;
                background: $color-gray-90;
                color: $color-gray-10;
            }
        }
    }
}

.radiobutton--list {
    margin-bottom: 20px;

    input[type="radio"] + label, a {
        width: 100%;
        margin-bottom: 15px;
        padding-left: 40px;
        position: relative;

        &:before {
            position: absolute;
        }

        .logo {
            margin-left: 15px;
            max-height: 25px;
            max-width: 80px;
            @include breakpoint(small only) {
                display: block;
                margin-left: 0;
                margin-top: 5px;
                float: none;
            }
        }

        + .content {
            width: 100%;
            margin-bottom: 15px;
            padding-left: 40px;

            a {
                padding: 0;
            }
        }

        + .content--no-margin-bottom {
            margin-bottom: 0;

            > p {
                &:first-child {
                    margin-top: 0;
                }
            }
        }

        + .content--negative-margin-top {
            @include font-din-regular;
            font-size: 14px;
            line-height: 20px;
            margin-top: -$sp-200;
        }

        + .content--preserve-space {
            min-height: 1em;
        }

        &:disabled {
            margin-bottom: 0;
        }
    }

    span {
        color: $color-gray-10;
    }

    span.checkbox--box {
        color: transparent;
    }

    label.checkbox {
        margin-top: 10px;
    }

    &--indented {
        @include breakpoint(medium up) {
            padding-left: 40px;
        }
    }
}

.radiobutton--label-disabled {
    margin-bottom: 0 !important;
}

.radiobutton--rating {
    width: 39px;
    height: 39px;
    float: left;
    margin: 5px 0 20px 0;
    cursor: pointer;

    input[type="radio"],
    input[type="checkbox"] {
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
        left: -99999px;
    }

    .form-error {
        position: absolute;
        padding: 15px 0 0 35px;

        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 45 40' x='0px' y='0px' width='45px' height='40px' enable-background='new 0 0 45 40'%3E%3Cpath fill='%23#{str-slice(($color-error + unquote("")), 2)}' d='M19.641,1.65L0.445,35.048C-0.82,37.25,0.761,40,3.29,40h38.394c2.528,0,4.109-2.751,2.846-4.951L25.332,1.65 C24.068-0.55,20.906-0.55,19.641,1.65'/%3E%3Crect fill='%23#{str-slice(($color-white + unquote("")), 2)}' x='21.487' y='27.5' width='2' height='2'/%3E%3Crect fill='%23#{str-slice(($color-white + unquote("")), 2)}' x='21.487' y='16.5' width='2' height='9'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 25px;
        background-position: 0 100%;
    }

}

.radiobutton-subform-label {
    // stylelint-disable-next-line declaration-no-important
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    padding: $sp-200 0;

    &::before {
        // stylelint-disable-next-line declaration-no-important
        margin-right: $sp-160 !important;
    }
}
