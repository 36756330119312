html {
    overflow-x: hidden;

    &.has-offcanvas {
        overflow: hidden;
    }
}

body {
    @include font-text-medium;

    font-display: swap;
    background: $body-color;
    color: $text-color;
    font-synthesis: none;

    &.has--fixed-content {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        overflow: hidden;
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.body {
    &--white {
        background-color: $color-white;
    }

    &--black {
        background-color: $color-black;
    }
}

h1,
.h1 {
    @include font-head-xlarge;
}

h2,
.h2 {
    @include font-head-large;
}

h3,
.h3 {
    @include font-head-medium;
}

h4,
.h4,
h5,
.h5 {
    @include font-head-small;
}

h1,
h2,
h3,
h4 {
    margin: 0 0 $sp-200 0;

    &.is-underlined {
        text-decoration: none;

        &::after {
            content: '';
            display: block;
            height: 1px;
            background: $border-color;
        }
    }
}

p:not([class]) {
    margin: 0 0 20px;
}

a {
    color: $text-color;
    text-decoration: underline;
    transition: color $transition-300, background-color $transition-300;
    -webkit-tap-highlight-color: transparent;

    &[data-open] {
        cursor: pointer;
    }

    html:not(.rendered) & {
        &,
        & * {
            /* stylelint-disable-next-line declaration-no-important */
            transition: none !important;
        }
    }
}

img[width='1'][height='1'] {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
}
