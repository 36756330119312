@import '~ShopUiBundle/styles/shared';

@mixin product-bundle-top-products($name: '.top-products') {

    #{$name} {
        display: block;

        &__product {
            width: 100%;
            margin-bottom: $spacing-lg;

            @include breakpoint(mediumlarge) {
                margin-bottom: $spacing-xl;
            }
        }
    }
}

@include product-bundle-top-products;
