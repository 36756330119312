@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-view-loader($name: '.view-loader') {
    $loading-state: #{$name}--loading;
    $hide-while-loading: #{$name}--hide-while-loading;

    #{$name} {
        display: inline-flex;
        position: relative;
        min-height: 40px;
        z-index: 1;
        background-color: transparent;

        &__content-view {
            position: relative;
            width: 100%;

            #{$loading-state}#{$hide-while-loading} & {
                display: none;
            }
        }

        &__loading-view {
            display: none;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            #{$loading-state} & {
                display: flex;
            }
        }

        &__loading-indicator {
            width: 30px;
        }
    }

    @content;
}

@include shop-ui-view-loader;
