// Must match the value of maxMobileCategories from filter-category-tree.html.twig
$max-mobile-categories: 5;

.sidebar {
    @include breakpoint(mediumlarge down) {
        margin-left: -15px;
        margin-right: -15px;
    }

    &__title {
        @include button;
        @include font-din-medium();
        background: $color-white;
        margin-bottom: 1px;
        padding-top: 24px;
        padding-bottom: 24px;
        text-transform: none;
    }

    &__list {
        margin: 0 0 20px 0;

        @include breakpoint(mediumlarge down) {
            &.has-current-children {
                margin-bottom: 20px;
                display: flex;
                flex-direction: column;
            }
        }

        &__item {
            // stylelint-disable-next-line declaration-no-important
            margin-top: 0 !important;
            max-width: 100%;
            order: 9999;

            @for $i from 1 through $max-mobile-categories {
                &[data-position='#{$i}'] {
                    order: #{$i};
                }
            }

            @for $i from 1 to 3 {
                .sidebar__list__link--padding-#{$i} {
                    padding-left: calc(15px * #{$i});
                }
            }
        }

        &__link {
            @include button;
            text-decoration: none;
            text-transform: none;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 0;
            margin-bottom: 1px;
            background: $color-white;
            letter-spacing: 0.5;
            align-items: center;

            @include breakpoint(mediumlarge down) {
                display: none;
                margin-bottom: 0;
                border-bottom: 1px solid $color-gray-80;
                padding-top: 15px;
                padding-bottom: 15px;

                &.is-visible-level {
                    display: block;
                }

                &.is-current-child {
                    // stylelint-disable-next-line declaration-no-important
                    padding-left: 15px !important;

                    [data-position] & {
                        display: block;
                    }

                    .show-more-active & {
                        display: block;
                    }
                }
            }

            &:hover {
                text-decoration: underline;
            }

            &.is-active:hover {
                text-decoration: none;
            }

            &.is-active,
            &.is-current {
                @include font-din-bold;
            }

            .sidebar__list__link-icon {
                display: none;

                @include breakpoint(mediumlarge down) {
                    display: block;
                    float: right;
                    margin-top: 1px;
                }
            }

            &.sidebar__list__link--more {
                display: none;

                @include breakpoint(mediumlarge down) {
                    display: block;
                }
            }

            .row-padding-mobile-200 & {
                @include breakpoint($row-padding-mobile-200-breakpoint) {
                    padding-left: $row-padding-mobile-200 !important;
                    padding-right: $row-padding-mobile-200 !important;
                }
            }
        }

        &__quantity {
            @include font-din-regular;
            color: $color-gray-20;
            transition: all $transition-300;
        }
    }
}

.sidebar--searchresults {
    > .sidebar__list {
        > li:first-child {
            padding-top: 15px;
        }

        > li:last-child {
            padding-bottom: 15px;
        }
    }
}

.sidebar--customer {
    @include breakpoint(medium up) {
        .sidebar__list__item {
            .sidebar__list__item {
                padding-left: 15px;

                &:first-child {
                    padding-top: $sp-040;
                }

                &:last-child {
                    padding-bottom: $sp-040;
                }

                .sidebar__list__link {
                    padding-top: $sp-020;
                    padding-bottom: ($sp-020 * 0.5);
                }
            }
        }
    }

    @include breakpoint(mediumsmall down) {
        display: none !important;


        .sidebar__list {
            display: none;
        }
    }

    .sidebar__list__link {
        display: inline-block;
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        padding-top: $sp-080;
        padding-bottom: $sp-040;
        color: $color-gray-10;
        border-bottom: none;

        .row-padding-mobile-200 & {
            @include breakpoint($row-padding-mobile-200-breakpoint) {
                padding-left: 10px !important;
            }
        }

        & > a {
            color: $color-gray-10;
            text-decoration: none;
            color: $color-gray-10;
        }
    }

    @include breakpoint(medium down) {
        margin-bottom: 20px;
    }
}
