@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-image-360($name: '.image-360') {

    #{$name} {
        display: block;
        position: relative;
        margin: auto;
        text-align: center;

        &__pictures-container {
            max-width: 1200px;
            margin: auto;
        }

        &__picture {
            position: absolute;
            visibility: hidden;
            pointer-events: none;
        }

        &__picture--active {
            position: static;
            visibility: visible;
        }

        &__image {
            user-select: none;
        }

        &__range-slider {
            width: 190px;
            margin-top: 40px;
        }
    }
}

@include shop-ui-image-360;
