.list {
    margin-left: 0;
    padding-left: 0;
    list-style: none;

    li {
        margin-top: 15px;

        &:first-child {
            margin-top: 0;
        }
    }

    // bullet list
    &--bullet {
        list-style: disc;
        padding-left: 15px;

        li {
            padding-left: 15px;
        }
    }

    // checkmark list
    &--checkmark {
        li {
            margin-top: 15px;
            padding-left: 30px;
            background-position: 0 6px;
            background-size: 14px 11px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.59 18.83'%3E%3Cpolygon fill='%23#{str-slice(($color-positive + unquote("")), 2)}' points='25.59 3.15 22.45 0 9.9 12.54 3.15 5.79 0 8.93 9.89 18.83 9.9 18.82 9.91 18.83 25.59 3.15'/%3E%3C/svg%3E");
            background-repeat: no-repeat;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &--arrow {
        padding-left: 18px;
        list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 12' x='0px' y='0px' width='7px' height='12px' enable-background='new 0 0 7 12'%3E%3Cpolygon points='5.317,5.999 0,11.315 0.675,12 6.675,5.999 0.675,0 0.016,0.667'/%3E%3C/svg%3E");
    }
}
