/// Neutral
$color-black: #000000;
$color-gray-10: #121212;
$color-gray-20: #303030;
$color-gray-50: #757575;
$color-gray-70: #c9c9c9;
$color-gray-80: #e9e9e9;
$color-gray-90: #f5f5f5;
$color-white: #ffffff;

/// Accent
$color-accent-50: #3affb7;
$color-accent-60: #61ffc5;
$color-accent-70: #89ffda;
$color-accent-80: #b0ffe2;
$color-accent-90: #d8fff1;

/// Negative
$color-negative-50: #ff3f3f;
$color-negative-60: #ff6565;
$color-negative-70: #ff8c8c;
$color-negative-80: #ffb2b2;
$color-negative-90: #ffd9d9;

/// Warning
$color-warning-50: #f28342;
$color-warning-60: #f59c68;
$color-warning-70: #f7b58e;
$color-warning-80: #facdb3;
$color-warning-90: #fce6d9;

/// Positive
$color-positive-50: #029539;
$color-positive-60: #35aa61;
$color-positive-70: #67bf88;
$color-positive-80: #9ad5b0;
$color-positive-90: #ccead7;

/// Sale
$color-sale-50: #eb0037;
$color-sale-60: #ef335f;
$color-sale-70: #f36687;
$color-sale-80: #f799af;
$color-sale-90: #fbccd7;

/// Info
$color-info-50: #0067ff;
$color-info-60: #3385ff;
$color-info-70: #66a4ff;
$color-info-80: #99c2ff;
$color-info-90: #cce1ff;

/// Deprecated, please do not use or update definitions in zeroheight
$color-success-green-100: #b3dfc4;
$color-green-600: #34e5a4;
$color-green-700: #2ecc92;
$color-green-900: #22996d;

/// Context
$color-accent: $color-accent-50;
$color-positive: $color-positive-50;
$color-success: $color-green-700;
$color-warning: $color-warning-50;
$color-negative: $color-negative-50;
$color-error: $color-negative;
$color-sale: $color-sale-50;
$color-info: $color-info-50;

/// Status
$color-availability-in-stock: $color-positive;
$color-availability-limited: $color-warning;
$color-availability-out-of-stock: $color-negative;

/// Global Usage
$text-color: $color-gray-10;
$body-color: $color-gray-90;
$border-color: $color-gray-80;
$text-color-light: $color-gray-90;
$input-border-color: $color-gray-50;
