@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-bundle-accordion-block($name: '.accordion-block') {
    $theme-dark: '#{$name}--theme-dark';
    $theme-white: '#{$name}--theme-white';

    #{$name} {
        display: block;
        position: relative;
        color: $color-black;

        &#{$theme-dark} {
            background-color: $color-black;
            color: $color-white;
        }

        &#{$theme-white} {
            background-color: $white;
            color: $color-black;
        }

        &__wrapper {
            display: block;
            position: relative;
            z-index: 1;
        }

        &__background {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 0;

            &-img {
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 50% 0%;
            }
        }

        &__title {
            padding: 30px 20px;

            @include breakpoint(large up) {
                padding: 50px 20px;
            }
        }

        &__accordion {
            display: block;
            z-index: 1;
        }
    }
}

@include shop-ui-bundle-accordion-block;
