@import '~ShopUiBundle/components/molecules/button-dropdown/button-dropdown';

@mixin shop-ui-language-dropdown($name: '.language-dropdown') {
    @include shop-ui-button-dropdown($name);

    #{$name} {
        &__button {
            @include font-text-small;

            height: 30px;
            padding: 0 $sp-200;

            &-icon {
                margin-left: 5px;
            }
        }
    }
}
