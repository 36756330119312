@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-basic-slider($name: '.basic-slider') {
    $bullet-style-dark-color: $color-gray-80;
    $navi-button-breakpoint: 'large';
    $has-gaps: '#{$name}--has-gaps';
    $pagination-within: '#{$name}--pagination-within';
    $bullets-style-white: '#{$name}--bullets-style-white';
    $bullets-style-dark: '#{$name}--bullets-style-dark';
    $bullets-style-dark-2: '#{$name}--bullets-style-dark-2';
    $arrows-style-background-transparent: '#{$name}--arrows-style-background-transparent';
    $arrows-style-no-hover: '#{$name}--arrows-style-no-hover';
    $arrows-style-rounded: '#{$name}--arrows-style-rounded';
    $has-scrollbar-navigation: '#{$name}--has-scrollbar-navigation';
    $render-overflow: '#{$name}--render-overflow';
    $render-overflow-medium-down: '#{$name}--render-overflow-medium-down';
    $style-inverted: '#{$name}--style-inverted';

    #{$name} {
        display: block;
        position: relative;

        &__navigation-button {
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;
            top: 50%;
            width: 22px;
            height: 44px;
            z-index: 10;
            background-color: rgba(0, 0, 0, 0.4);
            background-image: none;
            outline: none;
            color: $color-white;
            transform: translateY(-50%);
            cursor: pointer;

            #{$arrows-style-rounded} & {
                width: 56px;
                height: 40px;
                background: rgba($color-gray-10, 0.58);
                border-radius: 20px;
                transition: background-color ease 0.2s;
            }

            &:hover {
                color: $color-accent;

                #{$arrows-style-rounded} & {
                    background: rgba($color-gray-10, 0.87);
                    color: $color-white;
                }

                #{$arrows-style-no-hover} & {
                    color: $color-white;
                }
            }

            &:active {
                #{$arrows-style-rounded} & {
                    background: rgba($color-gray-10, 1);
                }
            }

            #{$arrows-style-background-transparent} & {
                background-color: transparent;
                color: $color-black;
            }

            #{$has-scrollbar-navigation} & {
                width: 0;

                @include breakpoint($navi-button-breakpoint) {
                    position: static;
                    flex: 0 0 auto;
                    box-sizing: content-box;
                    width: 15px;
                    height: 15px;
                    margin: 0;
                    padding: 0;
                    background-color: transparent;
                    color: $color-gray-10;
                    transform: none;
                }
            }

            #{$has-scrollbar-navigation}#{$style-inverted} & {
                @include breakpoint($navi-button-breakpoint) {
                    color: $color-white;
                }
            }
        }

        &__navigation-button--prev {
            left: 0;

            #{$arrows-style-rounded} & {
                left: $sp-200;
            }

            #{$has-scrollbar-navigation} & {
                display: none;

                @include breakpoint($navi-button-breakpoint) {
                    display: block;
                    width: $sp-400;
                    height: $sp-240;
                    margin-right: $sp-240;
                    outline: 0;
                }
            }
        }

        &__navigation-button--next {
            right: 0;

            #{$arrows-style-rounded} & {
                right: $sp-200;
            }

            #{$has-scrollbar-navigation} & {
                display: none;

                @include breakpoint($navi-button-breakpoint) {
                    display: block;
                    width: $sp-400;
                    height: $sp-240;
                    margin-left: $sp-240;
                    outline: 0;
                }
            }
        }

        // exception cause of swiper
        // stylelint-disable-next-line no-duplicate-selectors
        &__navigation-button {
            &.swiper-button-disabled {
                visibility: hidden;

                #{$arrows-style-rounded} & {
                    visibility: visible;
                    opacity: 0.2;
                    pointer-events: none;
                }
            }
        }

        &__icon-arrow-left {
            #{$has-scrollbar-navigation} & {
                transform: none;
                transition: transform $transition-200;
            }
        }

        &__icon-arrow-right {
            #{$has-scrollbar-navigation} & {
                transition: transform $transition-200;
            }
        }

        &__pagination {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            height: 20px;
            margin: auto;
            visibility: hidden;
            text-align: center;

            @include breakpoint(mediumlarge) {
                height: 40px;
            }

            #{$pagination-within} & {
                position: absolute;
                bottom: 20px;
                left: 50%;
                min-height: auto;
                z-index: 1;
                transform: translateX(-50%);
            }

            &.swiper-pagination-lock {
                display: none;
            }
        }

        &__pagination-bullet {
            display: block;
            position: relative;
            flex: 0 1 auto;
            width: 35px;
            height: 100%;
            visibility: visible;
            overflow: hidden;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            outline: none;
            cursor: pointer;

            @media (pointer: coarse) {
                pointer-events: none;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: auto;
                bottom: 0;
                left: 50%;
                width: 25px;
                height: 2px;
                background-color: $color-gray-70;
                border: 0;
                border-radius: 2px;
                transform: translate(-50%, -50%);

                #{$bullets-style-dark-2} & {
                    background-color: $color-gray-80;
                }

                #{$bullets-style-white} & {
                    background-color: $color-gray-20;
                }
            }

            &--active::after {
                background-color: $color-gray-10;
                outline: none;

                #{$bullets-style-dark} & {
                    background-color: $bullet-style-dark-color;
                }

                #{$bullets-style-dark-2} & {
                    background-color: $color-gray-10;
                }

                #{$bullets-style-white} & {
                    background-color: $color-white;
                }
            }
        }

        &__slides-wrapper {
            list-style: none;
            padding: 0;
        }

        &__container-wrapper {
            position: relative;
            overflow: hidden;

            #{$render-overflow} & {
                overflow: visible;
            }

            #{$render-overflow-medium-down} & {
                @include breakpoint(medium down) {
                    overflow: visible;
                }
            }
        }

        &__container {
            #{$has-gaps} & {
                max-width: calc(100% - 100px);
                overflow: visible;

                @include breakpoint(xlarge up) {
                    max-width: 1000px;
                }

                @include breakpoint(xxlarge up) {
                    max-width: 1200px;
                }
            }

            #{$render-overflow} & {
                overflow: visible;
            }

            #{$render-overflow-medium-down} & {
                @include breakpoint(medium down) {
                    overflow: visible;
                }
            }
        }

        &__scrollbar {
            display: flex;
            position: relative;
            flex: 1;
            align-items: center;
            height: $sp-240;
            margin: 0;
            overflow: hidden;

            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                height: 2px;
                background-color: $color-gray-80;
                border-radius: 1px;
            }

            #{$style-inverted} & {
                &::before {
                    background-color: $color-gray-20;
                }
            }
        }

        &__scollbar-drag {
            cursor: pointer;

            @include breakpoint(mediumsmall) {
                padding-top: 11px;
                padding-bottom: 11px;
            }

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 2px;
                background-color: $color-gray-10;
                border-radius: 1px;
            }

            #{$style-inverted} & {
                &::after {
                    background-color: $color-accent;
                }
            }
        }

        &__scrollbar-container {
            display: flex;
            position: relative;
            align-items: center;
            margin-top: $spacing-lg;
            overflow: hidden;
        }

        #{$render-overflow} & {
            overflow: visible;
        }
    }
}
