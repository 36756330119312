@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-header-navigation-logo($name: '.header-navigation-logo') {

    #{$name} {
        display: block;

        &__img {
            width: auto;
            height: 28px;
        }
    }
}

@include shop-ui-header-navigation-logo;
