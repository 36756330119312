@import '~ShopUiBundle/components/molecules/main-navigation-category/main-navigation-category';

@mixin shop-ui-main-navigation-category-with-tiles($name: '.main-navigation-category-with-tiles') {
    @include shop-ui-main-navigation-category($name);

    #{$name} {
        display: flex;

        &__list {
            margin-top: $sp-400;
        }

        &__container {
            display: flex;
            flex-basis: 100%;
            flex-wrap: wrap;
            max-width: 630px;
            height: fit-content;
            margin-top: $sp-400;
        }

        &__tile {
            display: block;
            position: relative;
            flex-basis: 20%;
            padding-bottom: 4px;
            padding-left: 2px;
            padding-right: 2px;

            &:nth-child(5n) {
                padding-right: 0;
            }

            &:nth-child(5n+1) {
                padding-left: 0;
            }

            &-link {
                display: block;
                position: relative;
                min-height: 164px;
            }

            &-img {
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                overflow: hidden;
                transition: all $transition-300;
                object-fit: cover;
                filter: grayscale(1);

                #{$name}__tile-link:hover & {
                    filter: grayscale(0);
                }
            }

            &-title {
                display: block;
                position: absolute;
                bottom: 16px;
                left: 0;
                width: 100%;
                color: $color-white;
                text-align: center;
                text-decoration: none;
                text-transform: uppercase;
            }
        }
    }
}
