@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-customer-id($name: '.customer-id') {
    $show-name: '#{$name}--show-name';

    #{$name} {
        display: block;
        padding-bottom: $sp-240;

        &#{$show-name} {
            padding-bottom: $sp-080;
        }

        &__name {
            @include font-din-bold;

            display: none;
            margin: 0;
            padding-bottom: $sp-020;
            font-size: 14px;
            color: $color-gray-10;
            line-height: 20px;

            #{$show-name} & {
                display: block;
            }
        }

        &__number {
            @include font-din-regular;

            margin: 0;
            font-size: 14px;
            color: $color-gray-20;
            line-height: 20px;

            #{$show-name} & {
                font-size: 12px;
                line-height: 12px;
            }
        }
    }
}
