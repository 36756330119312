@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-bundle-content-slider($name: '.content-slider') {

    #{$name} {
        $padding: 15px;

        display: block;
        margin-top: 20px;

        a {
            text-decoration: none;
        }

        &__slide {
            width: 290px;
            height: auto;
            padding-bottom: 54px;
            background-color: $color-white;

            @include breakpoint(medium up) {
                width: 25%;
            }
        }

        &__slide-image-wrapper {
            position: relative;
            width: 100%;
            padding-top: percentage(math.div(250, 380));
        }

        &__slide-title,
        &__slide-description {
            padding: $padding;
            padding-bottom: 0;
            line-height: 17px;
        }

        &__slide-description {
            @include font-din-regular;

            margin-bottom: 0;
            padding-bottom: 0;
            font-size: 14px;
            font-weight: normal;
        }

        &__slide-title {
            @include font-din-bold;

            font-size: 15px;
            text-transform: uppercase;
        }

        &__bullets {
            position: static;
            margin-top: 10px;
            text-align: center;
        }

        &__bullet {
            width: 10px;
            height: 10px;
            margin-right: 10px;
            background: $color-gray-20;
            border: 0;
            border-radius: 50%;
            outline: none;
            cursor: pointer;

            &.swiper-pagination-bullet-active {
                background: $color-accent;
                outline: none;
            }
        }

        &__more {
            @include font-din-regular;

            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 18px $padding $padding $padding;
        }

        .swiper-button-prev,
        .swiper-button-next {
            display: none;
            top: 125px;
            background-color: rgba(0, 0, 0, 0.4);
            color: $color-white;

            @include breakpoint(medium up) {
                display: block;
            }
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-next {
            right: 0;
        }
    }
}

@include shop-ui-bundle-content-slider;
