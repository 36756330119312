@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-radio-button-check-icon($name: '.radio-button-check-icon') {
    $checked: '#{$name}--checked';
    $disabled: '#{$name}--disabled';

    #{$name} {
        display: flex;
        cursor: pointer;

        &__svg {
            width: 100%;
            height: 100%;
        }

        &:not(#{$disabled}):not(#{$checked}):hover,
        &:not(#{$disabled}):not(#{$checked}):focus {
            #{$name}__circle {
                stroke: $color-gray-10;
                fill: $color-gray-90;
            }
        }

        &__circle {
            stroke: $color-gray-80;
            fill: none;

            #{$checked} & {
                stroke: $color-positive;
                fill: $color-positive;;
            }

            #{$disabled} & {
                stroke: $color-gray-80;
                fill: $color-gray-90;
                cursor: not-allowed;
            }
        }

        &__check {
            stroke: none;

            #{$checked} & {
                stroke: $color-white;
            }
        }
    }

    @content;
}
