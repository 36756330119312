@import '~ShopUiBundle/styles/shared';

@mixin shipment-bundle-packstation-loader($name: '.packstation-loader') {

    #{$name} {
        &__view-loader {
            width: 100%;
        }
    }
}
