@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-customer-navigation($name: '.customer-navigation') {
    #{$name} {
        @include font-text-medium;

        display: block;
        list-style: none;
        margin: 0;
        padding: 10px 0;

        @include breakpoint(mediumlarge) {
            @include font-text-small;
        }

        &__item {
            display: block;
        }

        &__link {
            display: block;
            padding: 10px 20px;
            color: inherit;
            line-height: 1;
            text-decoration: none;

            &:hover {
                #{$name}__title {
                    text-decoration: underline;
                }
            }
        }

        &__title {
            display: inline-block;
            min-width: 50px;
        }
    }
}

@include shop-ui-customer-navigation;
