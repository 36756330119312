@import '~ShopUiBundle/styles/shared';
@import '~ShopUiBundle/components/atoms/btn/btn';
@import '~ShopUiBundle/components/atoms/icon/icon';

// Default values
$shop-ui-text-link-inverted-modifier: 'inverted';
$shop-ui-text-link-inline-modifier: 'inline';
$shop-ui-text-link-standalone-modifier: 'standalone';
$shop-ui-text-link-card-modifier: 'card';
$shop-ui-text-link-sizes: xs sm md lg xl;
$shop-ui-text-link-breakpoint: mediumlarge;

@mixin shop-ui-text-link($name: '.text-link') {
    #{$name} {
        @include shop-ui-make-text-link;
        @include shop-ui-make-text-link-responsive;
        @include shop-ui-make-text-link-theme-default;
        @include shop-ui-make-text-link-theme-gray;

        @content;
    }
}

@mixin shop-ui-make-text-link() {
    @include font-default;
    @include font-size-medium;

    $self: &;
    $card-modifier: #{&}--#{$shop-ui-text-link-card-modifier};
    $inline-modifier: #{&}--#{$shop-ui-text-link-inline-modifier};
    $standalone-modifier: #{&}--#{$shop-ui-text-link-standalone-modifier};

    display: inline-flex;
    position: relative;
    text-decoration: none;
    transition: opacity $global-button-transition-duration $global-button-transition-timing-function,
        color $global-button-transition-duration $global-button-transition-timing-function;

    &:disabled,
    &--disabled {
        cursor: none;
        pointer-events: none;
    }

    &#{$inline-modifier} {
        font-size: inherit;
        line-height: inherit;
        text-decoration: underline;
    }

    &#{$standalone-modifier} {
        white-space: nowrap;
        text-decoration: underline;
    }

    &#{$card-modifier} {
        text-decoration: none;
    }

    &__icon {
        @include breakpoint($shop-ui-text-link-breakpoint) {
            #{$self}#{$card-modifier} & {
                opacity: 0.0001;
                transform: translateX(-50%);
                // stylelint-disable declaration-no-important
                transition: opacity ($global-button-transition-duration * 2) ease-in-out,
                    transform ($global-button-transition-duration * 2) ease-in-out !important;
                // stylelint-enable declaration-no-important
            }

            #{$card-modifier}#{$self}:hover &,
            #{$card-modifier}#{$self}:active &,
            #{$card-modifier}#{$self}:focus & {
                opacity: 1;
                transform: translateX(0%);
            }

            #{$card-modifier}#{$self}:disabled &,
            #{$card-modifier}#{$self}--disabled & {
                // stylelint-disable-next-line declaration-no-important
                opacity: 0 !important;
                // stylelint-disable-next-line declaration-no-important
                transform: translateX(-100%) !important;
            }
        }

        &--left {
            margin-right: $spacing-sm;
        }

        &--right {
            margin-left: $spacing-sm;
        }
    }

    &__icon-item {
        transform: translateY(-1px);
    }
}

// Themes
@mixin shop-ui-make-text-link-responsive() {
    $self: &;
    $inline-modifier: #{&}--#{$shop-ui-text-link-inline-modifier};

    @each $size in $shop-ui-text-link-sizes {
        &#{$self}--#{$size} {
            @include font-text-by-name($size,  $options:('color': false, 'scale': false ));

            #{$self}__icon-item {
                @include shop-ui-icon-size-responsive-by-name($size,  $options:('color': false, 'scale': false ));
            }
        }
    }

    @content;
}

// Themes
@mixin shop-ui-make-text-link-theme-default(
    $modifier: '',
    $breakpoint: $shop-ui-text-link-breakpoint,
) {
    $self: &;
    $inverted-modifier: #{&}--#{$shop-ui-text-link-inverted-modifier};
    $inline-modifier: #{&}--#{$shop-ui-text-link-inline-modifier};
    $card-modifier: #{&}--#{$shop-ui-text-link-card-modifier};

    // stylelint-disable-next-line order/order
    & {
        @include shop-ui-make-text-link-theme-default-color($self, $card-modifier);
    }

    &#{$inverted-modifier} {
        @include shop-ui-make-text-link-theme-default-color-inverted($self, $card-modifier);
    }

    @content;
}

@mixin shop-ui-make-text-link-theme-gray(
    $modifier: 'gray',
    $breakpoint: $shop-ui-text-link-breakpoint,
) {
    $self: &;
    $inverted-modifier: #{&}--#{$shop-ui-text-link-inverted-modifier};
    $inline-modifier: #{&}--#{$shop-ui-text-link-inline-modifier};
    $card-modifier: #{&}--#{$shop-ui-text-link-card-modifier};

    &--#{$modifier} {
        @include shop-ui-make-text-link-theme-gray-color($self, $card-modifier);
    }

    &--#{$modifier}#{$inverted-modifier} {
        @include shop-ui-make-text-link-theme-gray-color-inverted($self, $card-modifier);
    }

    @content;
}

@mixin shop-ui-make-text-link-theme-default-color(
    $target,
    $card-modifier: false,
    $breakpoint: $shop-ui-text-link-breakpoint,
) {
    &,
    &:visited {
        color: $color-gray-20;
    }

    &:hover {
        color: $color-gray-50;
    }

    @if $card-modifier {
        &#{$card-modifier}:hover {
            @include breakpoint($breakpoint) {
                color: $color-gray-20;
            }
        }
    }

    // stylelint-disable-next-line order/order
    &:active,
    &:focus {
        color: $color-gray-20;
    }

    &#{$target}:disabled,
    &#{$target}--disabled {
        color: $color-gray-70;
    }

    @content;
}

@mixin shop-ui-make-text-link-theme-default-color-inverted(
    $target,
    $card-modifier: false,
    $breakpoint: $shop-ui-text-link-breakpoint,
) {
    &,
    &:visited {
        color: $color-gray-90;
    }

    &:hover {
        color: $color-gray-50;
    }

    @if $card-modifier {
        &#{$card-modifier}:hover {
            @include breakpoint($breakpoint) {
                color: $color-gray-90;
            }
        }
    }

    // stylelint-disable-next-line order/order
    &:active,
    &:focus {
        color: $color-gray-90;
    }

    &#{$target}:disabled,
    &#{$target}--disabled {
        color: $color-gray-50;
    }

    @content;
}

@mixin shop-ui-make-text-link-theme-gray-color(
    $target,
    $card-modifier: false,
    $breakpoint: $shop-ui-text-link-breakpoint,
) {
    &,
    &:visited {
        color: $color-gray-50;
    }

    &:hover {
        color: $color-gray-70;
    }

    @if $card-modifier {
        &#{$card-modifier}:hover {
            @include breakpoint($breakpoint) {
                color: $color-gray-50;
            }
        }
    }

    // stylelint-disable-next-line order/order
    &:active,
    &:focus {
        color: $color-gray-50;
    }

    &#{$target}:disabled,
    &#{$target}--disabled {
        color: $color-gray-80;
    }

    @content;
}

@mixin shop-ui-make-text-link-theme-gray-color-inverted(
    $target,
    $card-modifier: false,
    $breakpoint: $shop-ui-text-link-breakpoint,
) {
    &,
    &:visited {
        color: $color-gray-70;
    }

    &:hover {
        color: $color-gray-50;
    }

    @if $card-modifier {
        &#{$card-modifier}:hover {
            @include breakpoint($breakpoint) {
                color: $color-gray-70;
            }
        }
    }

    // stylelint-disable-next-line order/order
    &:active,
    &:focus {
        color: $color-gray-70;
    }

    &#{$target}:disabled,
    &#{$target}--disabled {
        color: $color-gray-50;
    }

    @content;
}
