@import '~ShopUiBundle/styles/shared';

@mixin catalog-search-form($name: '.catalog-search-form') {

    #{$name} {

        &--position-header {
            @include breakpoint(medium down) {
                border-bottom: 1px solid #e5e8eb;
                box-shadow: 1px 1px 5px #e0e3e8;
            }
        }

        &__catalog-search-box {
            width: 100%;
        }
    }

    @content;
}
