@import '~ShopUiBundle/styles/shared';
@import '~ShopUiBundle/components/molecules/action-panel/action-panel';

@mixin shop-ui-action-panel-link-button($name: '.action-panel-link-button') {
    @include shop-ui-action-panel($name);

    #{$name} {
        flex-direction: column;
        align-content: space-between;

        &__content {
            padding-bottom: $spacing-md;
        }
    }
}
