@import '~ShopUiBundle/styles/shared';

@mixin shop-cms-bundle-faq-nav-list-with-text($name: '.faq-nav-list-with-text') {

    #{$name} {
        @include h-reset;

        display: block;
        list-style: none;
        line-height: 1;

        &__list {
            @include h-reset;

            list-style: none;
            line-height: 1;
        }

        &__item {
            border-top: 1px solid $color-gray-80;
        }

        &__item-title {
            margin: $sp-200 0;
        }

        &__item-text {
            @include font-text-medium;

            margin: 0 0 $sp-200;
        }
    }
}

@include shop-cms-bundle-faq-nav-list-with-text;
