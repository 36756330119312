@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-main-navigation($name: '.main-navigation') {
    $z-index: 60;
    $no-js: '#{$name}--no-js';
    $open: '#{$name}--open';

    #{$name} {
        &__list {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
            justify-items: center;
        }

        &__item {
            $item-hover: '#{$no-js} #{&}:hover';

            &.icon-only {
                flex-shrink: 0;
            }

            &--open {
                &::before {
                    content: ' ';
                    display: block;
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: -1;
                    background-color: rgba(0, 0, 0, 0.3);
                    pointer-events: none;
                }
            }

            &-button {
                &::before {
                    #{$no-js} & {
                        display: none;
                    }

                    #{$item-hover} & {
                        display: block;
                    }
                }
            }

            &-content {
                #{$no-js} & {
                    display: none;
                }

                #{$item-hover} &,
                #{$open} & {
                    display: flex;
                }
            }
        }

        &__decoration {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 3px;
            z-index: #{$z-index};
            background-color: $black;
            transform: translate3d(-50%, 1px, 0.00001px);
            transition-property: all;
            transition-duration: 0.2s;
            transition-timing-function: cubic-bezier(0.25, 1.14, 0.81, 0.99);
            pointer-events: none;
        }
    }
}
