// overwrite foundation styles
.accordion {

    color: $text-color;
    padding: 0 0 20px 0;

    .accordion-title {
        padding: 0;
        font-size: inherit;
        color: $text-color;
        border: none;
        text-decoration: none;
        transition: color $transition-300, background-color $transition-300;

        &:hover,
        &:active,
        &:focus {
            background: $color-white;
        }

        > * {
            margin-top: 4px;
            margin-bottom: 4px;
            padding-right: 40px;
        }

        &::before {
            content: '';
            width: 14px;
            height: 10px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3E%3Cpolygon fill='%23#{str-slice(($text-color + unquote("")), 2)}' points='5.02 4.3 0.88 0.16 0.03 1.01 5.02 6 10.01 1.01 9.16 0.16 5.02 4.3'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: 100%;
            transition: transform $transition-100;
            transform-origin: 50%;
            margin-top: -2px;
        }

    }

    .accordion-item {
        &.is-active {
            .accordion-title{
                &::before {
                    margin-top: -6px;
                    transform: rotate(-180deg);
                }
            }
        }

        &--border {
            @include font-din-regular();
            border-top: 1px solid $color-gray-80;
            border-bottom: 1px solid $color-gray-80;
            color: #121212;
            display: block;
            font-size: 24px;
            font-weight: 400;
            letter-spacing: -.1px;
            line-height: 28px;
            margin: 0px;
            padding: 12px 0px 12px 0px;
            text-transform: none!important;
        }
    }

    .accordion-content {
        color: $text-color;
        border: none;
        padding: 20px 0 0 0;
    }

    > .accordion-content:last-child {
        border: none;
    }
}

.accordion-item.is-active .accordion-title {
    &::before {
        transform: rotate(-180deg);
    }
}

:last-child>.accordion-content:last-child,
:last-child:not(.is-active)>.accordion-title {
    border: none;
}

.accordion.accordion__filter {
    padding: 0;
    margin-bottom: 20px;

    .accordion-item {

        position: relative;

        &.is-active {

            .accordion-title {

                background: $color-gray-20;
                color: $color-white;

                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3E%3Cpolygon fill='%23#{str-slice(($color-white + unquote("")), 2)}' points='5.02 4.3 0.88 0.16 0.03 1.01 5.02 6 10.01 1.01 9.16 0.16 5.02 4.3'/%3E%3C/svg%3E");
                }
            }
        }

        .accordion-content {
            position: absolute;
            z-index: 100;
            width: 100%;
            border: 1px solid $color-gray-20;
        }
    }

    .accordion-title {
        padding: 15px;
        text-transform: uppercase;
        font-size: $font-size-400;
    }

    .accordion-content {
        padding: 15px;
    }

    @include breakpoint(small down) {
        margin-bottom: 10px;
    }
}


