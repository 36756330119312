@import '~ShopUiBundle/styles/shared';
@import '~ShopUiBundle/components/molecules/action-panel/action-panel';

@mixin shop-ui-action-panel-form($name: '.action-panel-form') {
    @include shop-ui-action-panel($name);

    #{$name} {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;

        &__content-wrapper {
            display: flex;
        }

        &__form {
            padding-top: $sp-160;
        }
    }
}
