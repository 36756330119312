@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-teaser-layout($name: '.teaser-layout') {
    $title-align-left: #{$name}--title-align-left;
    $light: #{$name}--light;
    $three: #{$name}--three;
    $last-two-teasers: ':nth-child(n + 3)';

    #{$name} {
        display: block;

        &__title {
            @include font-din-bold;

            margin: 0 0 $sp-160;
            font-size: 20px;
            color: $color-gray-10;
            letter-spacing: $global-title-letter-spacing;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;

            @include breakpoint(mediumlarge) {
                margin: 0 0 $sp-320;
                font-size: 28px;
                line-height: 32px;
            }

            #{$light} & {
                color: $color-white;
            }

            #{$title-align-left} & {
                text-align: left;
            }
        }

        // To mimic the foundation-grid I had to override the default padding of the scrollbar
        &__scrollpane .os-scrollbar {
            // stylelint-disable-next-line declaration-no-important
            padding: 0 $row-padding-mobile-200 !important;

            @include breakpoint(large) {
                // stylelint-disable-next-line declaration-no-important
                padding: ($grid-column-gutter * 0.5) !important;
            }

            @include breakpoint(xlarge) {
                // stylelint-disable-next-line declaration-no-important
                padding: 0 !important;
            }
        }

        &__container {
            display: flex;
            padding: 0 $row-padding-mobile-200;

            @include breakpoint(mediumlarge) {
                flex-wrap: wrap;
            }

            @include breakpoint(large) {
                padding: $grid-column-gutter * 0.5;
            }

            &::after {
                content: '';
                padding-right: $row-padding-mobile-200;

                @include breakpoint(large) {
                    padding-right: $grid-column-gutter * 0.5;
                }
            }

            #{$three} & {
                flex-wrap: nowrap;

                @include breakpoint(large) {
                    padding-right: 0;
                }
            }
        }

        &__item {
            display: flex;
            flex: 1 0 50%;
            margin-right: $sp-200;

            @include breakpoint(mediumlarge) {
                margin: 0;
                padding-left: $sp-040;
                padding-right: $sp-040;

                &:nth-child(odd) {
                    padding-left: 0;
                    padding-right: $grid-column-gutter * 0.5;
                }

                &:nth-child(even) {
                    padding-left: $grid-column-gutter * 0.5;
                    padding-right: 0;
                }

                &#{$last-two-teasers} {
                    padding-top: $grid-column-gutter;
                }
            }

            &:last-of-type {
                margin-right: 0;
            }

            #{$three} & {
                flex: 1 0 33%;

                @include breakpoint(mediumlarge) {
                    padding-left: math.div($grid-column-gutter, 3);
                    padding-right: math.div($grid-column-gutter, 3);

                    &:last-of-type {
                        padding-left: math.div($grid-column-gutter, 3) * 2;
                        padding-right: 0;
                    }

                    &:first-of-type {
                        padding-left: 0;
                        padding-right: math.div($grid-column-gutter, 3) * 2;
                    }

                    &#{$last-two-teasers} {
                        padding-top: 0;
                    }
                }
            }
        }

        &__item::after {
            content: '';
            padding-top: 100%;
        }
    }
}

@include shop-ui-teaser-layout;
