@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-platform-logo($name: '.platform-logo') {

    #{$name} {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: $sp-200;
        padding-bottom: $sp-200;

        @include breakpoint(mediumlarge) {
            padding-top: $sp-640;
            padding-bottom: $sp-640;
        }

        &__picture {
            height: 24px;

            @include breakpoint(mediumlarge) {
                height: 52px;
            }
        }

        &__image {
            height: 100%;
        }
    }
}
