@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-bundle-banner-slider($name: '.banner-slider') {
    $style-min-height-desktop-360: '#{$name}--min-height-desktop-360';
    $style-image-origin-right-desktop-75: '#{$name}--image-origin-right-desktop-75';

    #{$name} {
        display: block;
        position: relative;

        &__basic-slider {
            width: 100%;
            height: 100%;
        }

        &__slide-picture {

            &--layout-format-mobile {
                @include breakpoint(medium down) {
                    display: block;
                    position: relative;
                    width: 100%;
                }
            }

            &--layout-format-desktop {
                @include breakpoint(medium) {
                    display: block;
                    position: relative;
                    width: 100%;
                }
            }
        }

        &__slide-image {
            width: 100%;
            object-fit: cover;
            object-position: center;

            #{$name}__slide-picture--layout-format-mobile &,
            #{$name}__slide-picture--layout-format-desktop & {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            #{$style-min-height-desktop-360} & {
                @include breakpoint(medium) {
                    min-height: 360px;
                }
            }

            #{$style-image-origin-right-desktop-75} & {
                @include breakpoint(medium) {
                    object-position: 75%;
                }
            }
        }

        &__navigation-button {
            @include breakpoint(mediumsmall down) {
                display: none;
            }
        }
    }
}

@include shop-ui-bundle-banner-slider;
