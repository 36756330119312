@import '~ShopUiBundle/components/molecules/main-navigation-category/main-navigation-category';

@mixin shop-ui-main-navigation-cms-category-pictures-item($name: '.main-navigation-cms-category-pictures-item') {
    @include shop-ui-main-navigation-category($name);

    $text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);

    #{$name} {
        display: flex;
        position: relative;
        flex-basis: 100%;
        margin: 0;

        &__link {
            display: flex;
            position: relative;
            flex-basis: 100%;
            padding: 2px 0;
        }

        &__picture {
            display: block;
            width: 100%;
            overflow: hidden;

            &-img {
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                transition: all $transition-300;
                filter: grayscale(1);
                object-fit: cover;

                #{$name}__link:hover & {
                    filter: grayscale(0);
                }
            }
        }

        &__title {
            @include font-head-small;

            display: block;
            position: absolute;
            top: 50%;
            width: 100%;
            max-height: 100%;
            padding: 15px;
            overflow: hidden;
            color: $white;
            white-space: normal;
            text-align: center;
            text-overflow: ellipsis;
            text-shadow: $text-shadow;
            word-break: normal;
            transform: translateY(-50%);
        }
    }
}
