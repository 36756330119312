@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-slider-pagination($name: '.slider-pagination') {
    $size: 10px;

    #{$name} {
        display: flex;

        // Overwriting default styles from .swiper-pagination
        position: relative;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        // stylelint-disable-next-line declaration-no-important
        bottom: auto !important;
        min-height: $size;

        .swiper-pagination-bullet {
            flex: 0 0 auto;
            width: $size;
            height: $size;
            margin-right: $size;
            opacity: 1;
            background: $color-gray-80;
            border: 0;
            border-radius: 50%;
            outline: none;
            cursor: pointer;

            @media (pointer: coarse) {
                pointer-events: none;
            }

            &-active {
                background: $color-gray-10;
            }
        }
    }
}
