@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-basic-scrollbar($name: '.basic-scrollbar') {
    $light: #{$name}--light;
    $style-width-m-240-d-400: '#{$name}--width-m-240-d-400';
    $style-horizontal-scrollbar-with-padding: '#{$name}--horizontal-scrollbar-with-padding';
    $style-horizontal-scrollbar-with-small-padding: '#{$name}--horizontal-scrollbar-with-small-padding';
    $scrollbar-width: $sp-200;
    $scrollbar-padding-mobile: $sp-320;
    $scrollbar-width-mobile: $sp-160;
    $scrollbar-padding-desktop: 60px;
    $scrollbar-width-desktop: $sp-400;

    #{$name} {
        position: relative;

        &.os-host-overflow {
            &.os-host-overflow-y:not(.os-host-scrollbar-vertical-hidden) {
                padding-right: $scrollbar-width;

                > .os-scrollbar-horizontal {
                    padding-right: $scrollbar-width;
                }
            }

            &.os-host-overflow-x:not(.os-host-scrollbar-horizontal-hidden) {
                padding-bottom: $scrollbar-width;

                > .os-scrollbar-vertical {
                    padding-bottom: $scrollbar-width;
                }
            }

            > .os-scrollbar {
                display: flex;
                padding: 0;

                > .os-scrollbar-track {
                    display: flex;
                    background-color: $color-gray-80;
                    border-radius: 1px;

                    > .os-scrollbar-handle {
                        background-color: $color-gray-10;
                        border-radius: 1px;
                        cursor: pointer;
                    }
                }
            }

            > .os-scrollbar-vertical {
                justify-content: center;
                width: $scrollbar-width;

                > .os-scrollbar-track {
                    justify-content: center;
                    width: 2px;

                    > .os-scrollbar-handle {
                        width: 2px;

                        &::before {
                            left: -($scrollbar-width * 0.5);
                            right: -($scrollbar-width * 0.5);
                        }
                    }
                }
            }

            > .os-scrollbar-horizontal {
                align-items: center;
                height: $scrollbar-width;

                > .os-scrollbar-track {
                    align-items: center;
                    height: 2px;

                    > .os-scrollbar-handle {
                        height: 2px;

                        &::before {
                            top: -($scrollbar-width * 0.5);
                            bottom: -($scrollbar-width * 0.5);
                        }
                    }
                }
            }
        }

        &#{$style-horizontal-scrollbar-with-padding} {
            &.os-host-overflow-x:not(.os-host-scrollbar-horizontal-hidden) {
                padding-bottom: $scrollbar-width + $sp-240;

                @include breakpoint(medium) {
                    padding-bottom: $scrollbar-width + $sp-320;
                }
            }
        }

        &#{$style-horizontal-scrollbar-with-small-padding} {
            &.os-host-overflow-x:not(.os-host-scrollbar-horizontal-hidden) {
                padding-bottom: $scrollbar-width * 0.5 + $sp-240;

                @include breakpoint(mediumlarge) {
                    padding-bottom: $scrollbar-width * 0.5 + $sp-400;
                }
            }
        }

        &#{$style-width-m-240-d-400} {
            &.os-host-scrollbar-vertical-hidden {
                padding-right: $sp-240;

                @include breakpoint(medium) {
                    padding-right: $sp-400;
                }
            }

            &.os-host-overflow {
                &.os-host-overflow-y:not(.os-host-scrollbar-vertical-hidden) {
                    padding-right: $scrollbar-padding-mobile;

                    @include breakpoint(medium) {
                        padding-right: $scrollbar-padding-desktop;
                    }
                }

                > .os-scrollbar-vertical {
                    justify-content: center;
                    width: $scrollbar-width-mobile;

                    @include breakpoint(medium) {
                        width: $scrollbar-width-desktop;
                    }

                    > .os-scrollbar-track {
                        justify-content: center;
                        width: 1px;

                        > .os-scrollbar-handle {
                            width: 3px;

                            &::before {
                                left: -($scrollbar-width-mobile * 0.5);
                                right: -($scrollbar-width-mobile * 0.5);

                                @include breakpoint(medium) {
                                    left: -($scrollbar-width-desktop * 0.5);
                                    right: -($scrollbar-width-desktop * 0.5);
                                }
                            }
                        }
                    }
                }
            }
        }

        &#{$light} {
            .os-scrollbar.os-scrollbar-vertical,
            .os-scrollbar.os-scrollbar-horizontal {
                > .os-scrollbar-track {
                    background-color: $color-gray-20;

                    > .os-scrollbar-handle {
                        background-color: $color-accent;
                    }
                }
            }
        }
    }
}
