@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-video-player($name: '.video-player') {

    #{$name} {
        display: inline-block;
        position: relative;
        width: 300px;
        height: 169px;

        &__overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &__play-button {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            opacity: 1;
            z-index: 1;
            outline: none;
            transform: translate(-50%, -50%);
            transition: opacity ease 0.2s;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

        &__thumbnail-container {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.4;
                background-color: $color-black;
            }
        }

        &__thumbnail {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &__icon-play {
            color: $color-white;

            @include breakpoint(medium up) {
                width: 40px;
                height: 40px;
            }
        }

        &__title,
        &__subtitle {
            position: relative;
            margin: 0 20px;
            z-index: 1;
            font-size: 42px;
            color: $color-white;

            @include breakpoint(mediumsmall down) {
                font-size: 20px;
            }
        }

        &__title {
            margin-top: 60px;
            font-weight: bold;

            @include breakpoint(mediumsmall down) {
                margin-top: 15px;
            }
        }

        &__iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        // no choice but to use nested elemnt, cause modal is placed at the bodies end.
        &__modal {
            & #{$name}__modal-body {
                width: 100vw;
                max-width: 800px;
                height: 100vh;
                max-height: 450px;
            }

            & #{$name}__modal-content {
                width: 100vw;
                max-width: 800px;
                height: 100vh;
                max-height: 450px;
            }
        }

        /* TODO: if utility classes have highest specifity remove this class and replace with small-12 */
        &--full-width {
            width: 100%;
        }
    }

    @content;
}

@include shop-ui-video-player;
