@import '~ShopUiBundle/styles/shared';

@mixin product-bundle-product-tile-thumb($name: '.product-tile-thumb') {
    $wide-thumb-size-large: 150px;

    #{$name} {
        display: block;
        position: relative;

        &__img {
            display: block;
            position: relative;
            width: 100%;
            height: auto;
            object-fit: contain;
            object-position: 50% 50%;
        }
    }
}

@include product-bundle-product-tile-thumb;
