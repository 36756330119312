@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-newsletter-form($name: '.newsletter-form') {
    #{$name} {
        display: block;

        &__button-group {
            display: flex;
            position: relative;
            flex-wrap: wrap;
            align-items: stretch;
            width: 100%;
        }

        &__label {
            position: relative;
            flex: 1 1 auto;
            width: 1%;

            .form-error {
                margin-top: 0.5rem;
            }
        }

        &__input {
            // stylelint-disable-next-line declaration-no-important
            margin: 0 !important;
        }

        &__button-group-append {
            display: block;
        }

        &__submit-button {
            // stylelint-disable-next-line declaration-no-important
            width: auto !important;
            border-radius: $global-button-border-radius;
        }

        &__success,
        &__fail {
            display: block;
        }
    }
}

@include shop-ui-newsletter-form;
