.tabs {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  border-bottom: 1px solid $border-color;

  @include breakpoint(mediumsmall down) {
    display: none;

    a {
      display: none;
    }
  }

  &-title {
    margin-left: 1px;
    position: relative;
    @include font-din-regular;

    &:first-child {
      margin-left: 0;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      background: $color-gray-10;
      opacity: 0;
      transition: opacity $transition-300;
    }

    &.is-active {
      @include font-din-bold;

      &:after {
        opacity: 1;
      }
    }

    a {
      font-size: 16px;
      color: $color-gray-10;
      display: block;
      padding: 12px 16px;
      text-decoration: none;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: background $transition-300;
      outline: none;
    }

  }

  &-content.accordion {
    background: none;
    padding: 20px 0;

    .accordion-item {
      &.is-active {
        .accordion-content {
          display: block;
        }
      }
    }

    .accordion-title {
      @include button();
      margin-bottom: 1px;
      overflow: hidden;
      text-overflow: ellipsis;
      background: $color-white;
      // stylelint-disable-next-line declaration-no-important
      border-top: 1px solid $color-gray-80 !important; // overwrite global accordeon-style; refactoring after atomic design

      &:active,
      &:focus,
      &:hover {
        background: $color-white;
      }

      @include breakpoint(medium up) {
        display: none;
      }
    }

    .accordion-content {
      @include font-din-regular;
      background: none;
      padding: 0;
      font-size: $font-size-300;
      line-height: 20px;

        @include breakpoint(medium up) {
          line-height: 24px;
        }

      > * {
        margin-top: 0;
      }

      .img-geometric {
        @include breakpoint(medium up) {
          max-width: 80%;
        }
      }

      @include breakpoint(medium down) {
        > * {
          margin-top: 20px;
        }

        padding: 0 10px;
      }
    }

    @include breakpoint(medium up) {
      .tabs-panel {
        display: none;
        &.is-active {
          display: block;
        }
        .accordion-content {
          display: block;
        }
      }
    }

  }
}

#productdetails {
  @include breakpoint(large down) {
    margin-top: 40px;
  }
}

*[data-opentab] {
  cursor: pointer;
}
