@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-text-input($name: '.text-input') {
    $text-color: $color-gray-20;
    $placeholder-text-color: $color-gray-20;
    $text-background-color: $color-gray-90;
    $text-background-color-hover: $color-gray-90;
    $state-not-empty: '#{$name}--not-empty';
    $state-focus: '#{$name}--has-focus';
    $font-ratio: math.div(14, 16);

    %clear-appearance {
        background: none;
        border: 0;
        outline: 0;
        color: inherit;
        box-shadow: none;
        transition: none;
    }

    %clear-layout {
        flex: 0;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    #{$name} {
        @include font-din-regular;

        display: inline-flex;
        align-items: center;
        margin: 0;
        padding: 0 10px;
        overflow: hidden;
        background: $color-gray-90;
        border: 0;
        font-size: 16px;
        color: $text-color;
        transition: none;

        &__input-wrapper {
            @extend %clear-layout;
            @extend %clear-appearance;

            flex-grow: 1;
            margin: 0;
            font-size: 100%;
        }

        // FIXME:Cause of the input[type=search] reset we need higher rule specificity (#{&}).
        #{&}__input {
            @extend %clear-layout;
            @extend %clear-appearance;

            width: math.div(1, $font-ratio) * 100%;
            height: 100%;
            margin: 0;
            font-size: inherit;
            transform: scale($font-ratio);
            transform-origin: left center;

            // stylelint-disable property-no-vendor-prefix
            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                -webkit-appearance: none;
            }
            // stylelint-enable

            &:hover,
            &:focus {
                @extend %clear-appearance;
            }

            &::placeholder {
                // stylelint-disable-next-line declaration-no-important
                opacity: 1 !important;
                color: $placeholder-text-color;
            }

            &::-ms-clear {
                display: none;
            }
        }

        &__button {
            @extend %clear-layout;
            @extend %clear-appearance;

            height: 100%;
            cursor: pointer;
        }

        &__clear {
            display: inline-flex;
            position: absolute;
            flex: 0 0 auto;
            align-items: center;
            justify-content: center;
            width: 38px;
            opacity: 0;
            background-color: $text-background-color;
            transform: scale(0);
            transition: background-color 0.3s ease-in-out;
            cursor: text;

            &:disabled {
                background: none;
            }

            #{$state-focus} &,
            #{$state-not-empty} & {
                position: static;
                opacity: 1;
                transform: none;
                cursor: pointer;
            }

            &:focus,
            &:hover {
                background-color: $text-background-color-hover;
            }
        }

        &__icon-clear {
            box-sizing: content-box;
            outline: none;
            color: $color-gray-10;
        }
    }

    @content;
}
