@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-dropdown-navigation($name: '.dropdown-navigation') {

    #{$name} {
        @include font-din-regular;

        display: block;
        list-style: none;
        margin: 0;
        padding: 10px 0;

        &__item {
            display: block;
        }

        &__link {
            display: block;
            padding: 10px 20px;
            font-size: 14px;
            color: inherit;
            line-height: 1;
            text-decoration: none;

            &:hover {
                #{$name}__title {
                    text-decoration: underline;
                }
            }
        }

        &__title {
            display: inline-block;
            min-width: 50px;
        }
    }
}

@include shop-ui-dropdown-navigation;
