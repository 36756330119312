.pagination {
    list-style: none;
    margin: 15px 0;
    padding: 0;
    font-size: $font-size-400;
    display: flex;
    align-items: center;

    li {
        display: flex;
        align-content: flex-start;
        flex-direction: column;
    }
}

.pagination__previous,
.pagination__next {
    border: 1px solid $border-color;
    width: 37px;
    height: 35px;
    font-size: 0;
    margin: 0 15px 0 0;
    align-items: center;
    justify-content: center;
    color: $color-gray-10;

    a {
        width: 100%;
        padding: 11px 0 10px 0;
        text-align: center;
        color: $color-gray-10;
    }

    &--disabled {
        color: $color-gray-50;
    }
}

.pagination__next {
    margin: 0 0 0 15px;
}

.pagination__pagenumber {
    padding: 5px;
    @include font-din-regular;

    &--current {
        @include font-din-bold;
        text-decoration: none;
    }

    &--unlinked {
        color: $color-gray-20;
    }
}

.pagination__ellipsis {
    &::before {
        content: '...';
        display: block;
        text-align: center;
        padding: 5px;
    }
}
