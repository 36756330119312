@import '~ShopUiBundle/components/molecules/list-with-title/list-with-title';

@mixin shop-ui-list-shipment-icons($name: '.list-shipment-icons') {
    @include shop-ui-list-with-title($name);

    $is-cart: '#{$name}--is-cart';

    #{$name} {
        &__list {
            #{$is-cart} & {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }
}

@include shop-ui-list-shipment-icons;
