@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-lifestyle-gallery($name: '.lifestyle-gallery') {
    $slide-gap-desktop: rem-calc(20px);
    $width-without-gaps: $global-width - 2 * $slide-gap-desktop;
    $big-item-width-desktop: $width-without-gaps * 0.5;
    $item-width-mobile: 300px;
    $breakpoint-desktop: mediumlarge;
    $image-height-mobile: 496px;
    $image-height-small-mobile: 375px;
    $width-height-weight: math.div($item-width-mobile, $image-height-mobile);

    #{$name} {
        display: block;

        @include breakpoint(xlarge) {
            overflow: hidden;
        }

        &__list {
            display: flex;
            list-style: none;
            justify-content: space-between;
            box-sizing: border-box;
            width: auto;
            margin: auto;
            margin-top: 16px;
            padding: 0;

            @include breakpoint($breakpoint-desktop) {
                margin-top: 32px;
            }
        }

        &__item-content {
            display: block;
            text-decoration: none;
        }

        &__item-title {
            @include font-text-medium;

            display: block;
            margin: 8px 0 0;
            overflow: hidden;
            text-decoration: none;
            cursor: pointer;
        }

        &__slide-image {
            object-fit: cover;
            width: 100%;
            height: $image-height-small-mobile;

            @include breakpoint(mediumsmall) {
                height: $image-height-mobile;
            }

            @include breakpoint($breakpoint-desktop) {
                height: 460px;
            }
        }

        &__item {
            width: $image-height-small-mobile * $width-height-weight;
            margin-right: 8px;

            @include breakpoint(mediumsmall) {
                width: $item-width-mobile;
            }

            @include breakpoint($breakpoint-desktop) {
                flex: 0 0 $big-item-width-desktop * 0.5;
                min-width: $item-width-mobile;
                margin-right: $slide-gap-desktop;

                &:first-child {
                    flex: 1 1 auto;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        &__scrollbar {
            height: auto;
        }
    }
}
