@import '~ShopUiBundle/styles/shared';
@import '~ShopUiBundle/components/molecules/action-panel/action-panel';

@mixin shop-ui-action-panel-border($name: '.action-panel-border') {
    @include shop-ui-action-panel($name);

    $error: '#{$name}--error';
    $info: '#{$name}--info';

    #{$name} {
        align-items: stretch;
        padding: 0;
        overflow: hidden;
        border: none;
        box-shadow: none;

        &__wrapper {
            display: flex;
            flex-grow: 1;
            padding: $sp-200;
            background-color: $color-white;
            border: 2px solid $color-gray-80;
            border-left: none;
            border-radius: 0 $global-button-border-radius $global-button-border-radius 0;
        }

        &__title {
            @include font-text-small(('breakpoint': small));
        }

        &__text {
            @include font-text-small(('breakpoint': small));

            color: $color-gray-50;
        }

        &__border {
            flex-shrink: 0;
            width: 4px;
            border-radius: $global-button-border-radius 0 0 $global-button-border-radius;

            #{$error} & {
                background-color: $color-error;
            }

            #{$info} & {
                background-color: $color-info-50;
            }
        }
    }
}
