// Buttons style, can be used for buttons dropdowns, tabs, etc...
@mixin button() {
  display: block;
  margin: 0;
  padding: 12px 15px;
  font-size: $font-size-400;
  line-height: 1;
  @include font-din-regular();
  text-decoration: none;
  transition: color $transition-300, background-color $transition-300;
}

@mixin clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}
