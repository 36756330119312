@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-shop-header($name: '.shop-header') {
    $background-color: $white;
    $border: 1px solid $color-gray-80;
    $height: 60px;

    #{$name} {
        @include font-text-medium;

        display: block;
        position: relative;
        z-index: 99;
        color: $color-gray-10;

        &__top,
        &__bottom {
            display: block;
            position: relative;
            background-color: $background-color;

            @include breakpoint(large up) {
                border-bottom: $border;
            }

            &-content {
                display: flex;
                position: relative;
                align-items: center;
                justify-content: space-between;
                min-height: $height;
            }
        }

        &__top {
            &-content {
                min-height: 70px;

                @include breakpoint(large) {
                    min-height: 80px;
                }
            }
        }

        &__bottom {
            border-bottom: $border;
        }

        &__main,
        &__services,
        &__magazin,
        &__stores,
        &__bikeleasing, {
            @include breakpoint(mediumlarge down) {
                display: none;
            }
        }

        &__language {
            margin-left: auto;

            @include breakpoint(mediumlarge down) {
                display: none;
            }
        }

        &__menu-button {
            padding-left: 0;
            outline: none;
            cursor: pointer;

            @include breakpoint(large up) {
                display: none;
            }
        }

        &__logo {
            display: block;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        &__desktop-navigation-loader,
        &__mobile-navigation-loader {
            display: flex;
            position: static;
            min-height: 0;
            z-index: unset;
        }

        &__loader-content {
            position: static;
        }
    }
}
