@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-usercentrics-view-loader($name: '.usercentrics-view-loader') {
    $loading-state: #{$name}--loading;

    #{$name} {
        display: block;

        &__view-loader {
            display: block;
            width: 100%;
        }

        &__usercentrics-dialog-wrapper {
            height: 500px;
        }

        &__usercentrics-dialog {
            // stylelint-disable-next-line declaration-no-important
            width: 100% !important;
            // stylelint-disable-next-line declaration-no-important
            height: 100% !important;
        }

        .view-loader--success + #{$name}__usercentrics-dialog-wrapper {
            display: none;
        }
    }
}

@include shop-ui-usercentrics-view-loader;
