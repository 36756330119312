@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-footer-newsletter($name: '.footer-newsletter') {
    #{$name} {
        @include font-din-regular;

        display: block;
        padding: 80px 20px;
        background-color: $color-gray-10;

        @include breakpoint(large) {
            padding: 80px 0;
        }

        &__description {
            text-align: center;

            @include breakpoint(large) {
                max-width: inherit;
                text-align: left;
            }
        }

        &__title {
            @include font-head-medium;

            margin: 0;
            color: $color-white;

            @include breakpoint(large) {
                padding-right: 20px;
            }
        }

        &__text {
            @include font-text-xsmall;

            margin: 10px 0 0;
            color: $color-gray-70;

            @include breakpoint(large) {
                max-width: 480px;
            }

            > a {
                color: $color-gray-70;
            }
        }

        &__form {
            margin-top: 40px;

            @include breakpoint(large) {
                margin-top: 0;
            }
        }

        &__label {
            @include font-text-small;

            position: relative;

            // stylelint-disable-next-line declaration-no-important
            width: 100% !important;
            height: 73px;
            margin: 0;
            padding: 0;
            color: $color-gray-70;
            line-height: 1;

            @include breakpoint(large) {
                margin-right: 20px;
            }

            &.has-error {
                @include breakpoint(large down) {
                    margin-bottom: $spacing-lg;
                }
            }

            .form-error {
                @include font-text-small;

                position: absolute;
                top: 100%;
                margin-top: 10px;
                margin-bottom: 20px;
                color: $color-error;
            }
        }

        &__button-group {
            @include breakpoint(large) {
                display: flex;
                align-items: flex-end;
            }
        }

        &__button-group-append {
            display: flex;
            margin-top: $sp-200;

            @include breakpoint(large) {
                margin-top: 0;
            }
        }

        &__outer-label {
            position: absolute;
            top: 0;

            // stylelint-disable-next-line declaration-no-important
            visibility: visible !important;
            color: $color-white;
        }

        &__input {
            @include font-din-regular;

            position: absolute;
            bottom: 0;
            width: 100%;

            // stylelint-disable declaration-no-important
            height: 50px !important;
            margin: 0 !important;
            padding: 0 20px;
            background-color: $color-gray-20;
            border: 2px solid transparent !important;
            border-radius: 5px;
            color: $color-white !important;
            // stylelint-enable

            &::placeholder {
                color: $color-gray-50;
            }

            &:focus {
                // stylelint-disable declaration-no-important
                background-color: $color-gray-20 !important;
                border-color: $color-white !important;
                // stylelint-enable

                &::placeholder {
                    color: transparent;
                }
            }

            &.error ~ .icon-close {
                visibility: visible;
                color: $color-error;
            }

            &.valid ~ .icon-check {
                visibility: visible;
                color: $color-positive;
            }

            &.error {
                // stylelint-disable-next-line declaration-no-important
                background-image: none !important;
            }

            &.valid {
                // stylelint-disable-next-line declaration-no-important
                background-image: none !important;
            }

            &:disabled {
                background-color: $color-black;
                // stylelint-disable-next-line declaration-no-important
                color: $color-gray-50 !important;
            }
        }

        .error,
        .valid {
            background-color: $color-black;
            color: $color-white;
        }

        &__icon {
            position: absolute;
            bottom: 20px;
            right: 20px;
            width: 12px;
            height: 12px;
            visibility: hidden;
        }
    }
}
