@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-header-navigation-services($name: '.header-navigation-services') {

    #{$name} {
        &__list {
            display: flex;
            list-style: none;
            align-items: center;
            margin: 0;
            padding: 0;
        }

        &__item {
            display: block;
            padding: 0;
        }
    }
}

@include shop-ui-header-navigation-services;
