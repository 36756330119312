@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-panel-btn($name: '.panel-btn') {
    $small: #{$name}--small;
    $background-gray: #{$name}--gray;
    $background-black: #{$name}--black;
    $disabled: #{$name}--disabled;

    #{$name} {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: $color-white;
        border-radius: 50%;
        color: $color-gray-20;
        cursor: pointer;

        &#{$small} {
            width: 35px;
            height: 35px;
        }

        &#{$background-gray} {
            background-color: $color-gray-90;
            color: $color-gray-10;
        }

        &#{$background-black} {
            background-color: $color-gray-10;
            color: $color-white;
        }

        &:hover:not(#{$disabled}),
        &:focus:not(#{$disabled}) {
            background-color: $color-gray-90;
            outline: none;
            color: $color-gray-10;

            &#{$background-gray},
            &#{$background-black} {
                background-color: $color-gray-20;
                outline: none;
                color: $color-white;
            }
        }

        &:active {
            background-color: $color-white;
            color: $color-gray-20;

            &#{$background-gray} {
                background-color: $color-gray-90;
                color: $color-gray-10;
            }
        }

        &#{$disabled} {
            opacity: 0.5;
            cursor: default;
        }
    }
}
