@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-seo-text($name: '.seo-text') {

    #{$name} {
        display: block;
        padding: $spacing-xl 0;
        background-color: $color-gray-90;

        @include breakpoint(mediumlarge) {
            padding: $spacing-xxl 0;
        }

        &__text {
            margin-top: $spacing-md;

            @include breakpoint(mediumlarge) {
                margin-top: $spacing-lg;
            }
        }

        // Overwrite inline Styles
        // TODO: Delete on implementing accordion for SEO-Text
        p {
            @include font-text-medium((
                'color': $color-gray-20,
            ));
            // stylelint-disable-next-line declaration-no-important
            float: none !important;
            // stylelint-disable-next-line declaration-no-important
            width: auto !important;
            margin-bottom: $spacing-sm;
            // stylelint-disable-next-line declaration-no-important
            padding: 0 !important;

            + p {
                margin-bottom: $spacing-md;
            }
        }

        ol {
            margin: 0;
            padding: 0;

            > ul,
            > ol {
                padding: 0 0 0 $spacing-md;
            }

            li {
                margin-bottom: $spacing-sm;
                color: $color-gray-20;
            }
        }

        ul {
            margin: 0;
            padding: 0 0 0 $spacing-md;

            > ul,
            > ol {
                padding: 0 0 0 $spacing-md;
            }

            li {
                margin-bottom: $spacing-sm;
                color: $color-gray-20;
            }
        }

        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6,
        p,
        li {
            b,
            strong {
                @include font-default((
                    'bold': false
                ));
            }
        }

        h1,
        .h1 {
            @include font-head-large((
                'bold': false
            ));
        }

        h2,
        .h2 {
            @include font-head-medium((
                'bold': false
            ));
        }

        h3,
        .h3,
        h4,
        .h4 {
            @include font-text-xlarge((
                'bold': false
            ));
        }

        h5,
        .h5 {
            @include font-text-large;
        }

        .row .columns div {
            // stylelint-disable-next-line declaration-no-important
            padding: 0 !important;
            // stylelint-disable-next-line declaration-no-important
            background-color: transparent !important;
        }

        table {
            border: none;

            tbody tr:nth-child(1n) {
                background: transparent;
            }

            tbody tr:nth-child(2n) {
                background: transparent;
            }

            tbody {
                th,
                td {
                    @include font-text-medium((
                        'color': $color-gray-20,
                    ));

                    border-color: $color-gray-70;
                }
            }
        }
    }
}

@include shop-ui-seo-text;
