.cms {

  // headlines
  &__module {
    margin-top: 20px;
    position: relative;
    overflow: hidden;

    &__image-wrapper {
      padding-top: 50.8583%;
      position: relative;
      .cms__image {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .row:first-child .cms__module {
      margin-top: 0;
    }
  }

  // headlines
  &__headline {
    margin: 0;
    padding: 0;

    &--a {
      @include font-din-medium();
      @include breakpoint(medium up) {
        font-size: 200px;
      }
    }

    &--b {
      @include font-din-medium();
      @include breakpoint(medium up) {
        font-size: 92px;
      }
    }

    &--e {
      @include font-din-medium();
      @include breakpoint(medium up) {
        font-size: 92px;
      }
      @include breakpoint(large up) {
        font-size: 180px;
      }
    }

    @include breakpoint(medium up) {
      & + .cms__columns {
        margin-top: 20px;
      }

      &.is-full-underlined {
        margin-bottom: 20px;
      }
    }

    &--f {
      @include font-din-medium();
      @include breakpoint(medium up) {
        font-size: 72px;
      }
    }

  }

  // columns
  &__columns {
    column-gap: 20px;

    &--2 {
      @include breakpoint(medium up) {
         column-count: 2;
      }

      @include breakpoint(medium only) {
        &.medium-6 {
          column-count: 1;
        }
      }
    }

    @include breakpoint(medium up) {
      .cms__headline {
        margin-bottom: 15px;
      }
    }

  }

  // links
  &__link {
    text-decoration: none;

    & + .cms__headline {
      margin-top: 15px;
    }
  }

  // images
  &__image {
    width: 100%;

    & + .cms__headline {
      margin-top: 15px;
    }
  }

  // flags
  &__flag {
    position: absolute;
    top: 0;
    right: 40px;
    width: auto;
    max-width: 100px;
    pointer-events: none;

    @include breakpoint(medium up) {
      right: 80px;
      max-width: 200px;
    }
  }

  // paddingbox
  &__paddingbox {
    background-color: $color-white;
    padding: 15px 20px 5px;

    // colors
    &--transparent {
      color: $color-white;
      background-color: transparent;
    }

    &--dark {
      color: $color-white;
      background-color: $color-gray-20;

      .button--highlight {
        &:hover {
          color: $color-gray-20;
          background-color: $color-white;
        }
      }
    }

    // sizing
    &--medium {
      padding: 15px 20px;
    }

    &--large {
      padding: 60px 20px 15px;

      @include breakpoint(large down) {
        padding-top: 30px;
      }
    }

    &--extralarge {
      padding: 30px 20px;

      @include breakpoint(medium up) {
        padding: 50px 20px;
      }
    }

    // positioning
    &--positioned {
      background-color: $color-gray-20;
      pointer-events: none;

      > .row {
        width: 100%;
      }

      @include breakpoint(mediumsmall up) {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 10px;
        right: 10px;
        bottom: 0;

        .cms__module .cms__module &,
        .cms__module--xl & {
          left: 0;
          right: 0;
        }

        &--top {
          justify-content: flex-start;
        }

        &--middle {
          justify-content: center;
        }

        &--bottom {
          justify-content: flex-end;
        }
      }

      @include breakpoint(medium up) {
        left: 15px;
        right: 15px;
      }

    }

    // gradient
    &--gradient {
      @include breakpoint(mediumsmall up) {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%);

        &.cms__paddingbox--positioned--middle,
        &.cms__paddingbox--positioned--discount {
          background: linear-gradient(to right, rgba(0, 0, 0, .6) 0%, rgba(0, 0, 0, 0) 100%);
        }
      }
    }

    // no gradient
    &--no-gradient {
      @include breakpoint(mediumsmall up) {
        background: none;
      }
    }

    // specials
    &--product {
      padding: 25px 20px;
      color: $text-color;
      background-color: transparent;

      @include breakpoint(large up) {
        padding-bottom: 100px;
      }

      .cms__image {
        width: auto;
      }
    }

    &--link {
      display: flex;
      flex-direction: column;
      padding: 15px 20px;

      @include breakpoint(medium up) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      @include breakpoint(large up) {
        padding-left: 100px;
        padding-right: 100px;
      }
    }

  }

  // backgroundbox
  &__backgroundbox {
    display: flex;
    align-items: center;
    background-position: center right;
    background-size: cover;
    height: 260px;
    position: relative;

    .cmsteaser-bg-orientation-left & {
      background-position: left;
    }

    &--discount {
      @include breakpoint(medium up) {
        align-items: flex-start;

        .h2 {
          margin-left: 20px;
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: none;
    }

    &__content {
      display: flex;
      position: relative;
      padding: 0 20px;
      color: $color-white;

      &--center-vertical {
        height: 100%;
        width: 100%;
        align-items: center;

        .cms__headline {
          padding-top: 0 !important;
        }
      }

      .cms__backgroundbox--discount & {

        @include breakpoint(medium up) {
          padding-left: 0;
        }

        .cms__headline {
          @include breakpoint(medium up) {
            padding-top: 38px;
          }
          @include breakpoint(large up) {
            padding-top: 52px;
          }
        }

      }
    }

    &__interstitial {
      display: none;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 150px;
      background-color: $color-error;
      font-size: 42px;
      @include font-din-medium();

      @include breakpoint(medium up) {
        display: flex;
        width: 120px;
        height: 120px;
      }

      @include breakpoint(large up) {
        width: 150px;
        height: 150px;
      }

    }

  }

  // product
  &__product {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .cms__product__headline {
      position: absolute;
      top: 20px;
      right: 60px;
      text-align: right;
      color: $color-white;
    }

  }

  // xl
  &__xl {
    .small-12 {
      @include breakpoint(mediumsmall down) {
        padding: 0;
      }
    }
  }

}

main .row:first-child > .has--image,
main .row:first-child > .has--video {
  margin-top: 0;
}
