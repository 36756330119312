@use 'sass:math';

@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-image-slider-with-description($name: '.image-slider-with-description') {
    $breakpoint: large;
    $text-color-white: '#{$name}--text-color-white';
    $text-color-white-value: $color-gray-90;
    $image-right: '#{$name}--image-right';
    $image-small-round: '#{$name}--image-small-round';
    $description-padding: '#{$name}--description-padding';
    $description-large: '#{$name}--description-large';

    #{$name} {
        display: block;
        background-color: transparent;

        &__title {
            @include font-head-large((
                'breakpoint': $breakpoint,
            ));

            margin: 0 0 24px;
            text-align: center;

            @include breakpoint($breakpoint) {
                margin: 0 0 32px;
            }
        }

        &__basic-slider {
            width: 100%;
        }

        &__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &__picture {
            display: block;
            position: relative;
            width: 100%;

            &::before {
                content: '';
                display: block;
                padding-top: percentage(math.div(256, 360));
            }

            #{$image-small-round} & {
                width: 150px;
                height: 150px;
                overflow: hidden;
                border-radius: 50%;

                @include breakpoint(mediumsmall down) {
                    width: 108px;
                    height: 108px;
                }
            }
        }

        &__content-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            @include breakpoint($breakpoint) {
                flex-direction: row;
            }

            #{$image-small-round} & {
                flex-direction: column;
                margin-left: -$sp-240;

                @include breakpoint(medium) {
                    flex-direction: row;
                    margin-left: 0;
                }
            }
        }

        &__image-container {
            flex: 1 1 60%;
            margin: 0;

            @include breakpoint($breakpoint down) {
                padding: 0;
            }

            #{$image-right} & {
                @include breakpoint($breakpoint) {
                    order: 1;
                }
            }

            #{$name}--image-full-width & {
                @include breakpoint($breakpoint down) {
                    margin-left: -$grid-column-gutter * 0.5;
                    margin-right: -$grid-column-gutter * 0.5;
                }
            }

            #{$image-small-round} & {
                order: 2;
                width: 108px;
                // stylelint-disable-next-line declaration-no-important
                max-width: 108px !important;
                // stylelint-disable-next-line declaration-no-important
                margin: auto !important;

                @include breakpoint(medium) {
                    order: 0;
                    width: 150px;
                    // stylelint-disable-next-line declaration-no-important
                    max-width: 150px !important;
                }
            }
        }

        &__description-container {
            display: inline-flex;
            flex: 1 1 40%;
            justify-content: center;

            #{$name}--multiple-slides & {
                @include breakpoint($breakpoint) {
                    padding-bottom: 45px;
                }
            }

            #{$image-small-round} & {
                justify-content: flex-start;
                padding: 0;
            }
        }

        &__description {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: $sp-320 0 $sp-400 0;

            @include breakpoint($breakpoint) {
                padding: 0 $sp-400;
                text-align: left;
            }

            #{$name}--center-description-mobile & {
                @include breakpoint(mediumsmall down) {
                    padding: 0;
                    text-align: center;
                }
            }

            #{$description-padding} & {
                padding-left: $row-padding-mobile-200;
                padding-right: $row-padding-mobile-200;

                @include breakpoint($breakpoint) {
                    padding-left: $sp-400;
                    padding-right: $sp-400;
                }
            }

            #{$image-small-round} & {
                padding-top: 0;
                padding-bottom: 0;
                text-align: left;

                @include breakpoint(medium) {
                    padding: 0 0 0 $sp-400;
                }
            }
        }

        &__description-title {
            @include font-head-medium((
                'breakpoint': $breakpoint,
            ));

            margin: 0 0 $sp-040 0;

            @include breakpoint($breakpoint) {
                margin: 0 0 $sp-080 0;
            }

            #{$text-color-white} & {
                color: $text-color-white-value;
            }

            #{$description-padding} & {
                margin: 0 0 $sp-160 0;

                @include breakpoint($breakpoint) {
                    margin: 0 0 $sp-240 0;
                }
            }

            #{$description-large} & {
                @include font-head-large((
                    'breakpoint': $breakpoint,
                ));

                margin: 0 0 8px;
            }

            #{$description-large}#{$text-color-white} & {
                @include font-head-large((
                    'breakpoint': $breakpoint,
                    'color': $text-color-white-value,
                ));
            }
        }

        &__description-subtitle {
            @include font-head-medium((
                'breakpoint': $breakpoint,
                'color': $color-gray-50,
            ));

            margin: 0 0 $sp-240 0;

            @include breakpoint($breakpoint) {
                margin: 0 0 $sp-320 0;
            }

            #{$text-color-white} & {
                color: $text-color-white-value;
            }
        }

        &__description-text {
            @include font-text-medium((
                'breakpoint': $breakpoint,
            ));

            margin: 0;

            &--parent-h1 {
                @include font-text-large((
                    'breakpoint': $breakpoint,
                ));
            }

            #{$text-color-white} & {
                color: $text-color-white-value;
            }
        }

        &__description-footnote {
            @include font-text-xsmall((
                'color': $color-gray-50,
            ));

            margin: $sp-320 0 0 0;

            #{$text-color-white} & {
                color: $text-color-white-value;
            }
        }

        &__description-text-link {
            margin: $sp-240 0 0 0;

            @include breakpoint($breakpoint) {
                margin: $sp-320 0 0 0;
            }
        }

        &__description-button-link {
            width: 100%;
            margin: $sp-240 0 0 0;

            @include breakpoint($breakpoint) {
                width: auto;
                margin: $sp-400 0 0 0;
            }
        }

        &__nav-right {
            right: 14px;
        }

        &__nav-left {
            left: 14px;
        }

        &__pagination {
            height: 20px;
        }
    }
}
