@import '~ShopUiBundle/components/molecules/main-navigation-category/main-navigation-category';

@mixin shop-ui-main-navigation-cms-category-pictures($name: '.main-navigation-cms-category-pictures') {
    @include shop-ui-main-navigation-category($name);

    #{$name} {
        display: flex;
        flex-direction: column;
        margin-left: -2px;
        margin-right: -2px;

        &__list {
            display: flex;
            position: relative;
            flex-basis: 100%;
            align-items: center;

            &:first-child {
                margin-top: 7px;
            }
        }

        &__item {
            display: flex;
            height: 100%;
            margin: 0;
            padding: 0 2px;
        }
    }
}
