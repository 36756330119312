@import '~ShopUiBundle/styles/shared';

@function product-bundle-product-tile-flag-border-color($bColor) {
    $out: $bColor transparent transparent transparent;

    @return $out;
}

@mixin product-bundle-product-tile-flag($name: '.product-tile-flag') {
    $small-stock: '#{$name}--smallstock';
    $sale: '#{$name}--sale';
    $topoffer: '#{$name}--topoffer';
    $free-text-flag: '#{$name}--freetextflag';
    $rose-tipp: '#{$name}--rose-tipp';
    $new-product: '#{$name}--newproduct';
    $top-rated: '#{$name}--toprated';
    $discount: '#{$name}--discount';
    $height: 22px;
    $height-large: 25px;
    $no-scaling: '#{$name}--no-scaling';

    #{$name} {
        @include font-text-small((
            'color': $color-gray-20,
        ));

        display: inline-flex;
        position: relative;
        align-items: center;
        max-width: 100%;
        height: $height;
        padding: 0 $spacing-sm;
        background-color: $color-white;
        border-radius: $height * 0.5;

        @include breakpoint(mediumlarge) {
            height: $height-large;
            border-radius: $height-large * 0.5;
        }

        &#{$no-scaling} {
            @include font-size-small;

            height: $height-large;
            border-radius: $height-large * 0.5;
        }

        &__title {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-transform: lowercase;
            word-break: keep-all;

            #{$free-text-flag},
            #{$small-stock} & {
                text-transform: capitalize;
            }

            &::first-letter {
                text-transform: capitalize;
            }
        }
    }

    #{$sale},
    #{$topoffer} {
        color: $color-sale;
    }

    #{$discount} {
        color: $color-sale;
    }
}
