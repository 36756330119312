@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-account-key-value-list($name: '.account-key-value-list') {
    $desktop-inline: '#{$name}--desktop-inline';
    $desktop-row: '#{$name}--desktop-row';

    #{$name} {
        &__list {
            display: block;
            list-style: none;
            margin: 0;
            padding: 0;

            #{$desktop-inline} & {
                padding: 0;

                @include breakpoint(medium) {
                    display: flex;
                }
            }

            #{$desktop-row} & {
                padding: 0 0 $spacing-lg 0;

                @include breakpoint(large) {
                    padding: $sp-080 0 $sp-400 0;
                }
            }
        }

        &__list-item {
            @include font-din-regular;

            display: flex;
            padding-bottom: $sp-020;
            font-size: 14px;
            color: $color-gray-10;
            line-height: 20px;

            @include breakpoint(medium) {
                display: block;
                padding-bottom: 10px;
            }

            #{$desktop-row} & {
                @include breakpoint(medium) {
                    display: flex;
                    padding-bottom: $sp-080;
                }
            }

            #{$desktop-inline} & {
                @include breakpoint(medium) {
                    min-width: 164px;
                    padding-bottom: 0;
                    padding-right: $sp-020;
                }
            }

            &:last-child {
                padding: 0;
            }
        }

        &__key {
            padding-right: $sp-040;
            color: $color-gray-50;

            #{$desktop-row} & {
                @include breakpoint(medium) {
                    width: 120px;
                    color: $color-gray-20;

                    &::after {
                        content: ':';
                        display: 'block';
                    }
                }
            }
        }
    }
}
