.availability {
    @include font-text-small((
        'color': $color-gray-50
    ));
    display: flex;
    align-items: center;

    .icon {
        margin-right: 8px;
        width: 8px;
        height: 8px;
    }

    &--green {
        .icon {
            color: $color-positive;
        }
    }

    &--yellow {
        .icon {
            color: $color-availability-limited;
        }
    }

    &--red {
        .icon {
            color: $color-availability-out-of-stock;
        }
    }

    &-text {
        @include font-text-small((
            'color': $color-gray-50,
        ));

        &--clarification {
            color: $color-availability-out-of-stock;
        }
    }
}
