@import '~SprykerProductBundle/components/molecules/product-tile-base/product-tile-base';

@mixin product-bundle-products-slider-tile($name: '.products-slider-tile') {
    @include product-bundle-product-tile-base($name);

    $swiper-slide: '.swiper-slide';

    #{$name} {
        &#{$swiper-slide} {
            width: 100%;
            max-width: 300px;
            height: auto;
            border: 0;
            box-shadow: none;

            &:last-child {
                // stylelint-disable-next-line declaration-no-important
                margin-right: 0 !important;
            }
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 100%;
            padding: 0;
        }

        &__thumb-wrapper {
            aspect-ratio: 336 / 400;
            padding: $spacing-lg;
        }

        &__flags {
            padding: $spacing-md $spacing-md 0 $spacing-md;
        }

        &__header {
            margin-top: $spacing-md;
            padding-right: $spacing-md;
        }

        &__product-name,
        &__product-brand {
            @include font-din-regular((
                'color': $color-gray-20,
            ));
            @include font-size-medium;

            margin: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__price {
            margin-top: $spacing-md;
        }
    }
}
