@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-main-navigation-item($name: '.main-navigation-item') {
    $highlight-color: $color-sale;
    $hidden-sublevel: '--hidden-sublevel';
    $highlight: '--highlight';

    #{$name} {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        color: $color-gray-10;

        &__icon {
            margin-left: $sp-160;
            opacity: 0;
        }

        &__link {
            display: block;
            padding: 10px $sp-040 10px 0;
            color: $color-gray-10;
            white-space: wrap;
            text-decoration: none;
            text-overflow: ellipsis;
            word-break: normal;

            &[href]:hover {
                text-decoration: underline;
            }

            &#{$highlight} {
                color: $highlight-color;
            }
        }

        &--open {
            @supports not (-ms-high-contrast: none) {
                &:not(#{$name}#{$hidden-sublevel}) #{$name}__icon {
                    animation: 0.35s main-navigation-item-slide 0.2s ease forwards;

                    @include breakpoint(xlarge up) {
                        margin-left: $sp-200;
                    }
                }
            }

            #{$name}__link {
                text-decoration: underline;
            }
        }

        @keyframes main-navigation-item-slide {
            0% {
                opacity: 0;
                transform: translateX(-15px);
            }

            100% {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
}
