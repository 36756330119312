@import '~ShopUiBundle/styles/shared';

@mixin product-bundle-product-tile-make-container($name: '.product-tile') {
    #{$name} {
        display: flex;
        position: relative;
        box-sizing: border-box;
        height: 100%;
    }
}

@mixin product-bundle-product-tile-base($name: '.product-tile-base') {

    @include product-bundle-product-tile-make-container($name);

    #{$name} {
        &__link {
            display: flex;
            width: 100%;
            color: inherit;
            text-decoration: none;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &__thumb-wrapper {
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            margin-bottom: $spacing-sm;
            padding: $spacing-md;
            overflow: hidden;
            background-color: $color-white;
            border-radius: $global-button-border-radius;
            aspect-ratio: 182 / 240;

            @include breakpoint(mediumlarge) {
                aspect-ratio: 336 / 400;
                margin-bottom: $spacing-md;
                padding: $spacing-lg;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                background-color: rgba($color-black, 0.04);
                transition: background-color ease 0.1s;
            }
        }

        &__flags {
            display: flex;
            position: absolute;
            flex-wrap: wrap;
            align-items: flex-start;
            top: 0;
            left: 0;
            width: 100%;
            padding: $spacing-sm $spacing-sm 0 $spacing-sm;
            z-index: 1;

            @include breakpoint(mediumlarge) {
                padding: $spacing-md $spacing-md 0 $spacing-md;
            }
        }

        &__flag {
            flex: 0 1 auto;
            margin: 0 $spacing-xs $spacing-xs 0;
        }

        &__sponsored-flag {
            display: flex;
            position: absolute;
            justify-content: center;
            bottom: $spacing-sm;
            left: 0;
            width: 100%;
            margin: 0 auto;
            text-align: center;
        }

        &__rating {
            margin-bottom: $spacing-sm;
            padding-right: $spacing-md;
        }

        &__title {
            @include font-text-medium((
                'color': $color-gray-20,
            ));

            padding-right: $spacing-md;
        }

        &__price {
            margin-top: $spacing-sm;

            @include breakpoint(mediumlarge) {
                margin-top: $spacing-md;
            }
        }
    }
}
