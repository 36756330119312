@import '~ShopUiBundle/styles/shared';

@mixin shop-ui-icon-arrow-animated($name: '.icon-arrow-animated') {
    $left: '#{$name}--left';
    $right: '#{$name}--right';
    $trigger-hover: '#{$name}__trigger:hover';

    #{$name} {
        transform: translate(0, 0);
        transition: transform $transition-200;

        &#{$right} {
            &:hover,
            #{$trigger-hover} & {
                transform: translate(8px, 0);
            }
        }

        &#{$left} {
            &:hover,
            #{$trigger-hover} & {
                transform: translate(-8px, 0);
            }
        }

        &__line {
            transition: transform $transition-200;

            #{$right} & {
                transform-origin: right;
            }

            #{$left} & {
                transform-origin: left;
            }

            #{$name}:hover &,
            #{$trigger-hover} & {
                transform: scale(1, 1);
            }
        }
    }
}
